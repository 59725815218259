@tailwind base;
@tailwind components;
@tailwind utilities;

/* ** syncfusion css-start ** */


@import '../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-schedule/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-base/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-kanban/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-react-inplace-editor/styles/tailwind.css';

/* ** syncfusion css-end ** */
