@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@font-face {
  font-family: "Ovo";
  src: url("../../../public/fonts/Ovo/Ovo.eot");
  src: local("☺"), url("../../../public/fonts/Ovo/Ovo.woff") format("woff"),
    url("../../../public/fonts/Ovo/Ovo.ttf") format("truetype"),
    url("../../../public/fonts/Ovo/Ovo.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Black";
  src: url("../../../public/fonts/Biotif-Black/Biotif-Black.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Black/Biotif-Black.woff") format("woff"),
    url("../../../public/fonts/Biotif-Black/Biotif-Black.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-Black/Biotif-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-BlackItalic";
  src: url("../../../public/fonts/Biotif-BlackItalic/Biotif-BlackItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-BlackItalic/Biotif-BlackItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-BlackItalic/Biotif-BlackItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-BlackItalic/Biotif-BlackItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Bold";
  src: url("../../../public/fonts/Biotif-Bold/Biotif-Bold.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Bold/Biotif-Bold.woff") format("woff"),
    url("../../../public/fonts/Biotif-Bold/Biotif-Bold.ttf") format("truetype"),
    url("../../../public/fonts/Biotif-Bold/Biotif-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-BoldItalic";
  src: url("../../../public/fonts/Biotif-BoldItalic/Biotif-BoldItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-BoldItalic/Biotif-BoldItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-BoldItalic/Biotif-BoldItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-BoldItalic/Biotif-BoldItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Book";
  src: url("../../../public/fonts/Biotif-Book/Biotif-Book.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Book/Biotif-Book.woff") format("woff"),
    url("../../../public/fonts/Biotif-Book/Biotif-Book.ttf") format("truetype"),
    url("../../../public/fonts/Biotif-Book/Biotif-Book.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-BookItalic";
  src: url("../../../public/fonts/Biotif-BookItalic/Biotif-BookItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-BookItalic/Biotif-BookItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-BookItalic/Biotif-BookItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-BookItalic/Biotif-BookItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-ExtraBold";
  src: url("../../../public/fonts/Biotif-ExtraBold/Biotif-ExtraBold.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-ExtraBold/Biotif-ExtraBold.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-ExtraBold/Biotif-ExtraBold.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-ExtraBold/Biotif-ExtraBold.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-ExtraBoldItalic";
  src: url("../../../public/fonts/Biotif-ExtraBoldItalic/Biotif-ExtraBoldItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-ExtraBoldItalic/Biotif-ExtraBoldItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-ExtraBoldItalic/Biotif-ExtraBoldItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-ExtraBoldItalic/Biotif-ExtraBoldItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Light";
  src: url("../../../public/fonts/Biotif-Light/Biotif-Light.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Light/Biotif-Light.woff") format("woff"),
    url("../../../public/fonts/Biotif-Light/Biotif-Light.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-Light/Biotif-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-LightItalic";
  src: url("../../../public/fonts/Biotif-LightItalic/Biotif-LightItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-LightItalic/Biotif-LightItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-LightItalic/Biotif-LightItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-LightItalic/Biotif-LightItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Medium";
  src: url("../../../public/fonts/Biotif-Medium/Biotif-Medium.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Medium/Biotif-Medium.woff") format("woff"),
    url("../../../public/fonts/Biotif-Medium/Biotif-Medium.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-Medium/Biotif-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-MediumItalic";
  src: url("../../../public/fonts/Biotif-MediumItalic/Biotif-MediumItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-MediumItalic/Biotif-MediumItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-MediumItalic/Biotif-MediumItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-MediumItalic/Biotif-MediumItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-Regular";
  src: url("../../../public/fonts/Biotif-Regular/Biotif-Regular.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-Regular/Biotif-Regular.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-Regular/Biotif-Regular.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-Regular/Biotif-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-RegularItalic";
  src: url("../../../public/fonts/Biotif-RegularItalic/Biotif-RegularItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-RegularItalic/Biotif-RegularItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-RegularItalic/Biotif-RegularItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-RegularItalic/Biotif-RegularItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-SemiBold";
  src: url("../../../public/fonts/Biotif-SemiBold/Biotif-SemiBold.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-SemiBold/Biotif-SemiBold.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-SemiBold/Biotif-SemiBold.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-SemiBold/Biotif-SemiBold.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Biotif-SemiBoldItalic";
  src: url("../../../public/fonts/Biotif-SemiBoldItalic/Biotif-SemiBoldItalic.eot");
  src: local("☺"),
    url("../../../public/fonts/Biotif-SemiBoldItalic/Biotif-SemiBoldItalic.woff")
      format("woff"),
    url("../../../public/fonts/Biotif-SemiBoldItalic/Biotif-SemiBoldItalic.ttf")
      format("truetype"),
    url("../../../public/fonts/Biotif-SemiBoldItalic/Biotif-SemiBoldItalic.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primaryColor: #1776ba;
  --secondaryColor: #000000;
  --primaryColor__hoverDark: #1168a6;
  --black__TextColor900: #1a1a1a;
  --black__TextColor800: #333333;
  --black__TextColor700: #4d4d4d;
  --black__TextColor600: #666666;
  --black__TextColor500: #808080;
  --black__TextColor400: #999999;
  --black__TextColor300: #b3b3b3;
  --black__TextColor200: #cccccc;
  --black__TextColor100: #e5e5e5;
  --black__BorderColor900: #1a1a1a;
  --black__BorderColor800: #333333;
  --black__BorderColor700: #4d4d4d;
  --black__BorderColor600: #666666;
  --black__BorderColor500: #808080;
  --black__BorderColor400: #999999;
  --black__BorderColor300: #b3b3b3;
  --black__BorderColor200: #cccccc;
  --black__BorderColor100: #e5e5e5;
  --gray__BGColor: #f8f8f8;
  --lightScreenBg: #f4f4f4;
  --lightScreenDarkBg: #e5e5e5;
  --ipWhite__bgColor: #ffffff;
  --ipBlack__bgColor: #000000;
  --ipWhite__textColor: #ffffff;
  --ipBlack__textColor: #000000;
  --ipWhite__borderColor: #ffffff;
  --ipBlack__borderColor: #000000;
  --primary__transparentBG: #e8f1f8;
  --primaryColor__transparentBG: #e8f1f8;
  --formLabel__blueColor: #6697bf;
  --ip__black__text__color: #000000;
  --ip__white__text__color: #ffffff;
  --dark__TextColor: #343434;
  --mediumDark__TextColor: #4d4d4d;
  --light__TextColor: #787878;
  --disableLight__TextColor: #babbbc;
  --whiteScreen__BorderColor: #e5e5e5;
  --greyScreen__BorderColor: #e8e8e8;
  --parentBgWhite__grayBtnBG: #ececec;
  --parentBgGray__grayBtnBG: #e6e6e6;
  --parentBgGray__grayBtnBG__hover: #cecece;
  --ip__Blue: #1776ba;
  --ip__Orange: #f78310;
  --ip__Green: #7ea838;
  --ip__SuccessGreen: #27ae60;
  --ip__Red: #ff3c3c;
  --ip__Yellow: #ffbf00;
  --ipBlue__transparentBG: #e8f1f8;
  --ipOrange__transparentBG: #fef3e7;
  --ipGreen__transparentBG: #f2f6eb;
  --ipSuccessGreen__transparentBG: #d4efdf;
  --ipRed__transparentBG: #ffefef;
  --ipYellow__transparentBG: #fffbee;
  --ipGray__transparentBG: #f4f4f4;
  --ipGray__transparentBG__light: #f9f9f9;
  --ip__Blue__hoverDark: #1168a6;
  --ip__Orange__hoverDark: #df750b;
  --ip__Green__hoverDark: #668a2b;
  --ip__SuccessGreen__hoverDark: #198e4b;
  --ip__Red__hoverDark: #ee2b2b;
  --ip__Yellow__hoverDark: #dfa803;
  --ip__Grey__hoverDark: #e6e6e6;
  --DarkBtn__BGcolor: #2e3234;
  --secondary__Btn__BGColor: #e1e1e1;
  --secondary__Btn__BGColor__hover: #cccccc;
  --rounded__Btn__BGColor: #e8f1f8;
  --lightGray__BgColor: #f4f4f4;
  --formField__BGColor: #f6f6f6;
  --skeleton__BoxBG: #dddbdd;
  --badge__primary: #1776ba;
  --badge__success: #198e4b;
  --badge__danger: #ff3c3c;
  --badge__warning: #ffbf00;
  --badge__orange: #df750b;
  --badge__info: #6b21a8;
  --badge__primary__bg: #e8f1f8;
  --badge__secondary__bg: #dddddd;
  --badge__success__bg: #d4efdf;
  --badge__danger__bg: #ffefef;
  --badge__warning__bg: #fffbee;
  --badge__orange__bg: #fef3e7;
  --badge__info__bg: #f3e8ff;
  --badge__light__bg: #f8f9fa;
  --badge__primaryDark__bg: #1776ba;
  --badge__secondaryDark__bg: #dddddd;
  --badge__successDark__bg: #198e4b;
  --badge__dangerDark__bg: #ff3c3c;
  --badge__warningDark__bg: #ffbf00;
}

/* ============================== new style start ============================== */
@layer base {
  /* common css start */
  * {
    @apply box-border break-words;
  }
  body,
  html {
    @apply m-0 p-0 !w-screen !overflow-x-hidden !bg-white;
  }
  body {
    @apply !w-screen !overflow-x-hidden text-[14px];
  }
  html.modal-open{
    @apply !overflow-hidden;
  }
  html.sideMenu__open,
  html.sideMenu__open body {
    @apply md:h-[100dvh] md:!overflow-hidden;
  }
  html.modal-open {
    @apply overflow-hidden;
  }
  .h-screen {
    @apply !h-[100dvh];
  }
  .min-h-screen {
    @apply !min-h-[100dvh];
  }

  /* fancy scroll css start */
  .ip__FancyScroll,
  .select2-results__options,
  .ip__Select div[class$="MenuList"],
  .ip__Select .ip__select__dynamic__menu-list,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list,
  .react-datepicker-popper .react-datepicker__month-dropdown,
  .react-datepicker-popper .react-datepicker__year-dropdown,
  .ip__V__Content .inner__scroll__wrapper,
  body .e-dialog .e-dlg-content,
  body .e-timepicker.e-popup,
  body .e-daterangepicker.e-popup .e-presets,
  body .e-more-popup-wrapper .e-more-event-content,
  .lead__details__temperature__tippy .tippy__dropdown__ul,
  .dialer__modal .react-tel-input .country-list,
  body .compose__mail__textarea .e-richtexteditor .e-rte-content .e-content,
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content,
  body .e-schedule .e-month-view .e-content-wrap,
  body .e-schedule .e-vertical-view .e-content-wrap,
  body .e-schedule .e-vertical-view .e-time-cells-wrap,
  .tippy__dashboard__ActivityType .tippy-content,
  body .e-richtexteditor .e-rte-content .e-content{
    scrollbar-color: #bfbfbf #ebebeb;
    scrollbar-width: thin;
  }
  .ip__FancyScroll::-webkit-scrollbar,
  .select2-results__options::-webkit-scrollbar,
  .ip__Select div[class$="MenuList"]::-webkit-scrollbar,
  .ip__Select .ip__select__dynamic__menu-list::-webkit-scrollbar,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar,
  .react-datepicker-popper .react-datepicker__month-dropdown::-webkit-scrollbar,
  .react-datepicker-popper .react-datepicker__year-dropdown::-webkit-scrollbar,
  .ip__V__Content .inner__scroll__wrapper::-webkit-scrollbar,
  body .e-dialog .e-dlg-content::-webkit-scrollbar,
  body .e-timepicker.e-popup::-webkit-scrollbar,
  body .e-daterangepicker.e-popup .e-presets::-webkit-scrollbar,
  body .e-more-popup-wrapper .e-more-event-content::-webkit-scrollbar,
  .tippy-box.tippy__dropdown.tippy__dropdown__emoji
    .emoji__wrapper::-webkit-scrollbar,
  .lead__details__temperature__tippy .tippy__dropdown__ul::-webkit-scrollbar,
  .dialer__modal .react-tel-input .country-list::-webkit-scrollbar,
  body
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar,
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar,
  body .e-schedule .e-month-view .e-content-wrap::-webkit-scrollbar,
  body .e-schedule .e-vertical-view .e-content-wrap::-webkit-scrollbar,
  body .e-schedule .e-vertical-view .e-time-cells-wrap::-webkit-scrollbar,
  .tippy__dashboard__ActivityType .tippy-content::-webkit-scrollbar,
  body .e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar{
    width: 5px;
  }
  .ip__FancyScroll::-webkit-scrollbar-track,
  .select2-results__options::-webkit-scrollbar-track,
  .ip__Select div[class$="MenuList"]::-webkit-scrollbar-track,
  .ip__Select .ip__select__dynamic__menu-list::-webkit-scrollbar-track,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-track,
  .react-datepicker-popper
    .react-datepicker__month-dropdown::-webkit-scrollbar-track,
  .react-datepicker-popper
    .react-datepicker__year-dropdown::-webkit-scrollbar-track,
  .ip__V__Content .inner__scroll__wrapper::-webkit-scrollbar-track,
  body .e-dialog .e-dlg-content::-webkit-scrollbar-track,
  body .e-timepicker.e-popup::-webkit-scrollbar-track,
  body .e-daterangepicker.e-popup .e-presets::-webkit-scrollbar-track,
  body .e-more-popup-wrapper .e-more-event-content::-webkit-scrollbar-track,
  .tippy-box.tippy__dropdown.tippy__dropdown__emoji
    .emoji__wrapper::-webkit-scrollbar-track,
  .lead__details__temperature__tippy
    .tippy__dropdown__ul::-webkit-scrollbar-track,
  .dialer__modal .react-tel-input .country-list::-webkit-scrollbar-track,
  body
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-track,
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-track,
  body .e-schedule .e-month-view .e-content-wrap::-webkit-scrollbar-track,
  body .e-schedule .e-vertical-view .e-content-wrap::-webkit-scrollbar-track,
  body .e-schedule .e-vertical-view .e-time-cells-wrap::-webkit-scrollbar-track,
  .tippy__dashboard__ActivityType .tippy-content::-webkit-scrollbar-track,
  body .e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar-track{
    background: #ebebeb;
  }
  .ip__FancyScroll::-webkit-scrollbar-thumb,
  .select2-results__options::-webkit-scrollbar-thumb,
  .ip__Select div[class$="MenuList"]::-webkit-scrollbar-thumb,
  .ip__Select .ip__select__dynamic__menu-list::-webkit-scrollbar-thumb,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-thumb,
  .react-datepicker-popper
    .react-datepicker__month-dropdown::-webkit-scrollbar-thumb,
  .react-datepicker-popper
    .react-datepicker__year-dropdown::-webkit-scrollbar-thumb,
  .ip__V__Content .inner__scroll__wrapper::-webkit-scrollbar-thumb,
  body .e-dialog .e-dlg-content::-webkit-scrollbar-thumb,
  body .e-timepicker.e-popup::-webkit-scrollbar-thumb,
  body .e-daterangepicker.e-popup .e-presets::-webkit-scrollbar-thumb,
  body .e-more-popup-wrapper .e-more-event-content::-webkit-scrollbar-thumb,
  .tippy-box.tippy__dropdown.tippy__dropdown__emoji
    .emoji__wrapper::-webkit-scrollbar-thumb,
  .lead__details__temperature__tippy
    .tippy__dropdown__ul::-webkit-scrollbar-thumb,
  .dialer__modal .react-tel-input .country-list::-webkit-scrollbar-thumb,
  body
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-thumb,
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-thumb,
  body .e-schedule .e-month-view .e-content-wrap::-webkit-scrollbar-thumb,
  body .e-schedule .e-vertical-view .e-content-wrap::-webkit-scrollbar-thumb,
  body .e-schedule .e-vertical-view .e-time-cells-wrap::-webkit-scrollbar-thumb,
  .tippy__dashboard__ActivityType .tippy-content::-webkit-scrollbar-thumb,
  body .e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar-thumb{
    background: #bfbfbf;
  }
  .ip__FancyScroll::-webkit-scrollbar-thumb:hover,
  .select2-results__options::-webkit-scrollbar-thumb:hover,
  .ip__Select div[class$="MenuList"]::-webkit-scrollbar-thumb:hover,
  .ip__Select .ip__select__dynamic__menu-list::-webkit-scrollbar-thumb:hover,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-thumb:hover,
  .react-datepicker-popper
    .react-datepicker__month-dropdown::-webkit-scrollbar-thumb:hover,
  .react-datepicker-popper
    .react-datepicker__year-dropdown::-webkit-scrollbar-thumb:hover,
  .ip__V__Content .inner__scroll__wrapper::-webkit-scrollbar-thumb:hover,
  body .e-dialog .e-dlg-content::-webkit-scrollbar-thumb:hover,
  body .e-timepicker.e-popup::-webkit-scrollbar-thumb:hover,
  body .e-daterangepicker.e-popup .e-presets::-webkit-scrollbar-thumb:hover,
  body
    .e-more-popup-wrapper
    .e-more-event-content::-webkit-scrollbar-thumb:hover,
  .tippy-box.tippy__dropdown.tippy__dropdown__emoji
    .emoji__wrapper::-webkit-scrollbar-thumb:hover,
  .lead__details__temperature__tippy
    .tippy__dropdown__ul::-webkit-scrollbar-thumb:hover,
  .dialer__modal .react-tel-input .country-list::-webkit-scrollbar-thumb:hover,
  body
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-thumb:hover,
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-thumb:hover,
  body .e-schedule .e-month-view .e-content-wrap::-webkit-scrollbar-thumb:hover,
  body
    .e-schedule
    .e-vertical-view
    .e-content-wrap::-webkit-scrollbar-thumb:hover,
  body
    .e-schedule
    .e-vertical-view
    .e-time-cells-wrap::-webkit-scrollbar-thumb:hover,
  .tippy__dashboard__ActivityType .tippy-content::-webkit-scrollbar-thumb:hover,
  body
    .e-richtexteditor
    .e-rte-content
    .e-content::-webkit-scrollbar-thumb:hover{
    background: #979797;
  }
  /* fancy scroll css end */

  .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal{
    scrollbar-color: #bfbfbf #ebebeb !important;
    scrollbar-width: thin !important;
  }
  .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal::-webkit-scrollbar{
    width:0px !important;
  }
  .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-track{
    background: #ebebeb !important;
  }
  .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb{
    background: #bfbfbf !important;
  }
  .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal::-webkit-scrollbar-thumb:hover{
    background: #979797 !important;
  }

  /* scrollbar hide css start */
  .ip__hideScrollbar::-webkit-scrollbar,
  .ip__Select div[class$="ValueContainer"]::-webkit-scrollbar,
  .ip__Select .ip__select__dynamic__value-container::-webkit-scrollbar,
  .ip__note__form .ip__textarea::-webkit-scrollbar,
  body
    .createActivityModal
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap::-webkit-scrollbar,
  body .e-multi-select-wrapper .e-chips-collection::-webkit-scrollbar,
  .guest__box__mobile__wrapper::-webkit-scrollbar,
  .breadcrumbs::-webkit-scrollbar,
  .horizontalTabs__ul::-webkit-scrollbar,
  .emailInbox__tabs::-webkit-scrollbar,
  .inboxMail__wrapper::-webkit-scrollbar,
  .ag__grid__mobile__lead .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__contact .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__deal .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__account .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__activity
    .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__user .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__profilePermission
    .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ip__V__Content .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .ag__grid__mobile__department
    .mobile_infiniteScroll_hWrapper::-webkit-scrollbar,
  .activity__add__skeleton__right::-webkit-scrollbar,
  .tippy-box.tippy__dropdown.activityType__tippy .items::-webkit-scrollbar,
  iframe::-webkit-scrollbar,
  .epr-body::-webkit-scrollbar,
  .notification__modal .following__body .following__tab::-webkit-scrollbar {
    display: none;
  }
  .ip__hideScrollbar,
  .ip__Select div[class$="ValueContainer"],
  .ip__Select .ip__select__dynamic__value-container,
  .ip__note__form .ip__textarea,
  body
    .createActivityModal
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap,
  body .e-multi-select-wrapper .e-chips-collection,
  /* .ag__grid__infinite__scroll .ag-body-viewport.ag-layout-normal, */
  .guest__box__mobile__wrapper,
  .breadcrumbs,
  .horizontalTabs__ul,
  .emailInbox__tabs,
  .inboxMail__wrapper,
  .ag__grid__mobile__lead .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__contact .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__deal .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__account .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__activity .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__user .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__profilePermission .mobile_infiniteScroll_hWrapper,
  .ip__V__Content .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__department .mobile_infiniteScroll_hWrapper,
  .activity__add__skeleton__right,
  iframe,
  .tippy-box.tippy__dropdown.activityType__tippy .items,
  .epr-body,
  .notification__modal .following__body .following__tab {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* scrollbar hide css end */

  /* ellipsis text css start */
  .ellipsis,
  .ellipsis__1,
  .ellipsis__2,
  .ellipsis__3,
  .ellipsis__4,
  .ellipsis__5,
  .ellipsis__6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .ellipsis__1 {
    -webkit-line-clamp: 1;
  }
  .ellipsis__2 {
    -webkit-line-clamp: 2;
  }
  .ellipsis__3 {
    -webkit-line-clamp: 3;
  }
  .ellipsis__4 {
    -webkit-line-clamp: 4;
  }
  .ellipsis__5 {
    -webkit-line-clamp: 5;
  }
  .ellipsis__6 {
    -webkit-line-clamp: 6;
  }
  /* ellipsis text css end */


  /* skeleton__round__loader */
  .skeleton__round__loader__wrapper{
    width:60px;
    height:60px;
    padding:10px 10px;
    position:relative;
    z-index:2;
    background-color:white;
    border-radius:50%;
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.90), -1px -1px 2px 0px rgba(255, 255, 255, 0.90), 1px -1px 2px 0px rgba(207, 207, 207, 0.20), -1px 1px 2px 0px rgba(207, 207, 207, 0.20); 
  }
  .skeleton__round__loader{
    width:100%;
    height:100%;
    display:inline-block;
    border-radius: 50%;
    background-color: transparent;
    border:3px solid var(--ipGray__transparentBG);
    border-top-color: var(--primaryColorSD);
    border-bottom-color:var(--primaryColorSD);
    -webkit-animation: 1s skeleton__round__loader linear infinite;
    animation: 1s skeleton__round__loader linear infinite;
  }
  @keyframes skeleton__round__loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* skeleton__round__loader__end */

  /* page round loader css start */
  .page__round__loader {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    border: 4px solid var(--ipGray__transparentBG);
    border-top-color: var(--ip__Blue);
    border-bottom-color: var(--ip__Blue);
    -webkit-animation: 1s page__round__loader linear infinite;
    animation: 1s page__round__loader linear infinite;
  }
  @keyframes page__round__loader {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* page round loader css end */

  .ip__disabled,
  *[disabled] {
    @apply opacity-50 cursor-not-allowed pointer-events-none;
  }
  .ip__disabled.form__Group {
    @apply opacity-100;
  }
  .ip__disabled.form__Group .ip__Select {
    @apply opacity-50;
  }
  .ip__disabled.form__Group
    .ip__Select
    .ip__select__dynamic__single-value--is-disabled,
  .ip__disabled.form__Group .ip__Select .ip__select__dynamic__placeholder {
    @apply text-[#4d4d4d];
  }
  .if__label.if__label__disabled .text {
    opacity: 0.6;
  }
  img {
    @apply inline-block max-w-full;
  }

  /* site logo draw loader css start */
  .i__Site__Loader {
    @apply fixed top-0 left-0 w-screen h-screen bg-[#ffffff80] flex items-center justify-center z-10 backdrop-blur-[5px];
    background-image: linear-gradient(
      45deg,
      rgba(255, 44, 70, 0.1),
      rgba(116, 103, 183, 0.1)
    );
  }
  .i__Site__Loader path.iLetter.iLetter1 {
    stroke: var(--ip__Orange);
    fill: transparent;
    stroke-dasharray: 80;
    stroke-dashoffset: 0;
    animation-name: iLetter1;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes iLetter1 {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    40% {
      stroke-dashoffset: 80;
      fill: transparent;
    }
    90% {
      stroke-dashoffset: 160;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 160;
      fill: var(--ip__Orange);
    }
  }
  .i__Site__Loader path.iLetter.iLetter2 {
    stroke: var(--ip__Orange);
    fill: transparent;
    stroke-dasharray: 26;
    stroke-dashoffset: 0;
    animation-name: iLetter2;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes iLetter2 {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    40% {
      stroke-dashoffset: 26;
      fill: transparent;
    }
    90% {
      stroke-dashoffset: 52;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 52;
      fill: var(--ip__Orange);
    }
  }
  .i__Site__Loader path.pLetter {
    stroke: var(--ip__Blue);
    fill: transparent;
    stroke-dasharray: 170;
    stroke-dashoffset: 0;
    animation-name: pLetter;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pLetter {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    40% {
      stroke-dashoffset: 170;
      fill: transparent;
    }
    90% {
      stroke-dashoffset: 340;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 340;
      fill: var(--ip__Blue);
    }
  }

  .i__Site__Loader .new__logo {
    @apply w-[150px];
  }
  .i__Site__Loader path.iLetter.iLetter4 {
    stroke: #ff2c46;
    stroke-width: 5px;
    fill: transparent;
    stroke-dasharray: 475;
    stroke-dashoffset: 0;
    animation-name: iLetter4;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes iLetter4 {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    40% {
      stroke-dashoffset: 475;
      fill: transparent;
    }
    80% {
      stroke-dashoffset: 950;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 950;
      fill: #ff2c46;
    }
  }
  .i__Site__Loader path.iLetter.iLetter5 {
    stroke: #7467b7;
    stroke-width: 5px;
    fill: transparent;
    stroke-dasharray: 475;
    stroke-dashoffset: 0;
    animation-name: iLetter5;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes iLetter5 {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    40% {
      stroke-dashoffset: 475;
      fill: transparent;
    }
    80% {
      stroke-dashoffset: 950;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 950;
      fill: #7467b7;
    }
  }
  /* site logo draw loader css end */

  /* skeleton loader css start */
  .skeletonBox {
    @apply block w-full h-[12px] bg-skeleton__BoxBG rounded-[20px] relative overflow-hidden;
  }
  .skeletonBox:after {
    @apply content-[''] absolute top-0 right-0 bottom-0 left-0 translate-x-[-100%];
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: skeleton__animation__glow 2s infinite;
  }
  @keyframes skeleton__animation__glow {
    100% {
      @apply translate-x-[100%];
    }
  }

  .personal__settings__skeleton .ipInfo__View__Label,
  .company__settings__skeleton .ipInfo__View__Label {
    @apply after:hidden;
  }
  /* skeleton loader css end */

  /* update version toast css start */
  .update__version__box .closeBtn {
    @apply absolute top-[50%] translate-y-[-50%] right-[20px] w-[30px] h-[30px] p-0 flex flex-wrap items-center justify-center duration-500 cursor-pointer rounded-full hover:bg-white/20;
  }
  /* update version toast css end */

  /* no-data css start */
  .no__data__wrapper {
    @apply py-[30px];
  }
  .no__data__wrapper .image {
    @apply w-[300px] max-w-full mx-auto;
  }
  .no__data__wrapper .title {
    @apply text-[20px] font-biotif__Bold text-center text-ip__black__text__color mb-[5px] mt-[20px];
  }
  .no__data__wrapper .text {
    @apply text-[14px] font-biotif__Regular text-light__TextColor;
  }
  /* no-data css end */

  /* color-box css start */
  .color__box .if__label {
    @apply hidden;
  }
  .color__box .form__Group {
    @apply mb-0;
  }
  .color__box input {
    @apply absolute top-0 left-0 w-full h-full z-[2] opacity-0;
  }
  .color__box.active .arrow,
  .color__box input:checked + .arrow {
    @apply opacity-100;
  }
  /* color-box css end */

  /* tooltip css start */
  .tooltip__Target {
    @apply relative;
  }
  .tooltip__Wrapper {
    @apply z-[11];
  }
  .tooltip__Wrapper.top {
    @apply bg-black absolute bottom-[calc(100%_+_12px)] left-[50%] translate-x-[-50%] rounded-[6px] text-white text-[14px] font-biotif__Regular py-[8px] px-[10px] w-auto whitespace-pre hidden before:content-[''] before:border-[8px] before:border-transparent before:border-t-black before:bottom-[-15px] before:left-[50%] before:translate-x-[-50%] before:absolute;
  }
  .tooltip__Wrapper.right {
    @apply bg-black absolute top-[50%] translate-y-[-50%] left-[calc(100%_+_12px)] rounded-[6px] text-white text-[14px] text-left font-biotif__Regular py-[8px] px-[10px] w-auto whitespace-pre hidden before:content-[''] before:border-[8px] before:border-transparent before:border-r-black before:top-[50%] before:left-[-15px] before:translate-y-[-50%] before:absolute;
  }
  .tooltip__Wrapper.left {
    @apply bg-black absolute top-[50%] translate-y-[-50%] right-[calc(100%_+_12px)] rounded-[6px] text-white text-[14px] text-left font-biotif__Regular py-[8px] px-[10px] w-auto whitespace-pre hidden before:content-[''] before:border-[8px] before:border-transparent before:border-l-black before:top-[50%] before:right-[-15px] before:translate-y-[-50%] before:absolute;
  }
  .tooltip__Wrapper.bottom {
    @apply bg-black absolute top-[calc(100%_+_12px)] left-[50%] translate-x-[-50%] rounded-[6px] text-white text-[14px] font-biotif__Regular py-[8px] px-[10px] w-auto whitespace-pre hidden before:content-[''] before:border-[8px] before:border-transparent before:border-b-black before:top-[-15px] before:left-[50%] before:translate-x-[-50%] before:absolute;
  }
  .tooltip__Target:hover .tooltip__Wrapper {
    @apply block;
  }
  /* tooltip css end */

  /* tooltip-i-button css start */
  .tooltip__iButton {
    @apply w-[16px] h-[16px] rounded-full border border-ipBlack__borderColor flex items-center justify-center;
  }
  .tooltip__iButton .inner__wrapper {
    @apply inline-block;
  }
  .tooltip__iButton .dot {
    @apply block w-[2px] h-[2px] bg-ipBlack__bgColor rounded-full mb-[1px];
  }
  .tooltip__iButton .line {
    @apply block w-[2px] h-[6px] bg-ipBlack__bgColor rounded-[10px];
  }
  /* tooltip-i-button css end */

  /* breadcrumbs css start */
  .breadcrumbs {
    @apply flex items-center;
  }
  .breadcrumbs .breadcrumbLink {
    @apply inline-block text-[14px] font-biotif__Medium text-black__TextColor500 pr-[15px] mr-[15px] relative mb-[5px] hover:underline hover:text-primaryColor before:content-[''] before:absolute before:w-[9px] before:h-[9px] before:border-l-[1px] before:border-l-black__TextColor700 before:border-b-[1px] before:border-b-black__TextColor700 before:rotate-[-135deg] before:right-0 before:top-[50%] before:translate-y-[-50%];
  }
  .breadcrumbs span.breadcrumbLink {
    @apply hover:no-underline;
  }
  .breadcrumbs .breadcrumbLink:last-child {
    @apply mr-0 pr-0 before:hidden;
  }
  .breadcrumbs .breadcrumbLink.active {
    @apply text-black;
  }
  .breadcrumbs > .breadcrumbLink:last-child {
    @apply mr-[15px];
  }
  /* breadcrumbs css end */

  /* badge css start */
  .badge {
    @apply inline-flex items-center font-biotif__Medium text-[12px] px-[16px] py-[5px] bg-green-50 rounded-[50px];
  }
  .badge.badge__primary {
    @apply bg-badge__primary__bg text-badge__primary;
  }
  .badge.badge__primary__light {
    @apply bg-badge__primary__bg text-badge__primary;
  }
  .badge.badge__secondary {
    @apply bg-badge__secondary__bg text-black;
  }
  .badge.badge__success {
    @apply bg-badge__success__bg text-badge__success;
  }
  .badge.badge__danger {
    @apply bg-badge__danger__bg text-badge__danger;
  }
  .badge.badge__warning {
    @apply bg-badge__warning__bg text-badge__warning;
  }
  .badge.badge__orange {
    @apply bg-badge__orange__bg text-badge__orange;
  }
  .badge.badge__info {
    @apply bg-badge__info__bg text-badge__info;
  }
  .badge.badge__light {
    @apply bg-badge__light__bg text-black;
  }
  .badge.badge__dark {
    @apply bg-black text-white;
  }
  .badge.badge__primaryDark {
    @apply bg-badge__primaryDark__bg text-white;
  }
  .badge.badge__secondaryDark {
    @apply bg-badge__secondaryDark__bg text-black;
  }
  .badge.badge__successDark {
    @apply bg-badge__successDark__bg text-white;
  }
  .badge.badge__dangerDark {
    @apply bg-badge__dangerDark__bg text-white;
  }
  .badge.badge__warningDark {
    @apply bg-badge__warningDark__bg text-white;
  }
  .badge.badge__orangeDark {
    @apply bg-badge__orangeDark__bg text-white;
  }
  .badge.badge__infoDark {
    @apply bg-badge__infoDark__bg text-white;
  }
  .badge.badge__lightDark {
    @apply bg-badge__lightDark__bg text-black;
  }
  .badge.square__round {
    @apply rounded-[6px];
  }
  .badge.primary__badge {
    @apply duration-500 bg-ip__Blue text-ip__white__text__color hover:bg-ip__Blue__hoverDark;
  }

  .badge.badge__primary .close__btn {
    @apply before:bg-badge__primary after:bg-badge__primary;
  }
  .badge.badge__primary__light .close__btn {
    @apply before:bg-badge__primary after:bg-badge__primary;
  }
  .badge.badge__secondary .close__btn {
    @apply before:bg-black after:bg-black;
  }
  .badge.badge__success .close__btn {
    @apply before:bg-badge__success after:bg-badge__success;
  }
  .badge.badge__danger .close__btn {
    @apply before:bg-badge__danger after:bg-badge__danger;
  }
  .badge.badge__warning .close__btn {
    @apply before:bg-badge__warning after:bg-badge__warning;
  }
  .badge.badge__info .close__btn {
    @apply before:bg-badge__info after:bg-badge__info;
  }
  .badge.badge__light .close__btn {
    @apply before:bg-black after:bg-black;
  }
  .badge.badge__dark .close__btn {
    @apply before:bg-white after:bg-white;
  }

  .badge .close__btn{
    @apply hidden;
  }
  .badge:hover .close__btn{
    @apply flex;
  }
  /* badge css end */

  /* toast css start */
  .ip__ToastWrapper {
    @apply fixed w-[400px] max-w-[calc(100%_-_30px)] max-h-[calc(100dvh_-_60px)] bottom-[30px] right-[15px] z-[10] overflow-y-auto p-[10px];
  }
  .ip__ToastWrapper .ip__Toast__CNabsolute {
    @apply w-full;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper {
    @apply w-full max-w-full relative bg-[#f7f7f7] shadow-[5px_10px_20px_#dee2e64d] border border-[#e3e3e3] border-l-ip__Blue rounded-[12px] p-[12px] pr-[44px] flex flex-wrap items-center mb-[15px] opacity-0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper:last-child {
    @apply mb-0;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.show {
    @apply opacity-0;
    animation-name: toast__show;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }
  @keyframes toast__show {
    0% {
      @apply opacity-0;
    }
    100% {
      @apply opacity-100;
    }
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.hide {
    @apply translate-y-[0px] opacity-100;
    animation-name: toast__hide;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
  @keyframes toast__hide {
    from {
      @apply translate-y-[0px] opacity-100;
    }
    to {
      @apply translate-y-[-30px] opacity-0;
    }
  }
  .ip__ToastWrapper .symbol__Icon {
    @apply w-[26px] h-[26px] bg-white flex items-center justify-center rounded-[8px] p-[7px];
  }
  .ip__ToastWrapper .ip__Toast__Text {
    @apply text-black text-[14px] leading-[20px] font-biotif__Medium pl-[10px] relative top-[2px] w-[calc(100%_-_45px)];
  }
  .ip__ToastWrapper .close__Btn {
    @apply w-[26px] h-[26px] absolute top-[50%] translate-y-[-50%] right-[8px] before:content-[''] before:w-[26px] before:h-[26px] before:bg-white before:rounded-full before:absolute before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:opacity-0 before:duration-500 hover:before:opacity-100;
  }
  .ip__ToastWrapper .close__Btn .i__Icon {
    @apply w-full h-full relative z-[1] p-0;
  }
  .ip__ToastWrapper .close__Btn svg {
    @apply w-full h-full;
  }
  .ip__ToastWrapper .close__Btn svg path {
    @apply stroke-black;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.success {
    @apply bg-ipGreen__transparentBG border-ip__Green;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.success .symbol__Icon {
    @apply bg-ip__Green;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.success .symbol__Icon path {
    @apply fill-white;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.error {
    @apply bg-ipRed__transparentBG border-ip__Red;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.error .symbol__Icon {
    @apply bg-ip__Red;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.error .symbol__Icon path {
    @apply fill-white;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.warning {
    @apply bg-ipYellow__transparentBG border-ip__Yellow;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.warning .symbol__Icon {
    @apply bg-ip__Yellow;
  }
  .ip__ToastWrapper .ip__Toast__CNwrapper.warning .symbol__Icon path {
    @apply fill-white;
  }
  /* toast css end */

  /* close button css start */
  .close__btn {
    @apply w-[20px] h-[20px] relative text-[0px] before:content-[""] before:w-full before:h-[1px] before:bg-ip__white__text__color before:rotate-45 before:absolute before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[""] after:w-full after:h-[1px] after:bg-ip__white__text__color after:rotate-[-45deg] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%];
  }
  /* close button css end */

  /* roundTick__UL css start */
  .roundTick__UL li {
    @apply text-[14px] leading-[18px] text-dark__TextColor font-biotif__Medium pl-[30px] relative mb-[20px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-[18px] before:h-[18px] before:rounded-full before:bg-ipOrange__transparentBG after:content-[''] after:absolute after:top-[6px] after:left-[6px] after:w-[6px] after:h-[4px] after:border-l after:border-b after:border-l-ip__Orange after:border-b-ip__Orange after:rotate-[-45deg] after:z-[2] xl:mb-[10px];
  }
  .roundTick__UL li:last-child {
    @apply mb-0;
  }
  /* roundTick__UL css end */

  /* timepicker ios css start */
  .react-ios-time-picker button,
  .react-ios-time-picker input {
    @apply border-0 bg-transparent cursor-pointer;
  }
  .react-ios-time-picker input:focus {
    outline: none;
  }
  .react-ios-time-picker {
    @apply overflow-hidden;
  }
  .react-ios-time-picker-transition {
    animation: react-ios-time-picker-transition 150ms ease-out;
  }
  @keyframes react-ios-time-picker-transition {
    0% {
      transform: translateY(150px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .react-ios-time-picker-container {
    @apply flex justify-center relative w-full overflow-hidden pt-[20px] pb-0 pl-0 pr-0;
  }
  .react-ios-time-picker-hour,
  .react-ios-time-picker-minute {
    @apply relative w-[50px] overflow-hidden z-[100] mr-[5px];
  }
  .react-ios-time-picker-hour-format {
    @apply relative w-[40px] overflow-hidden z-[100];
  }
  .react-ios-time-picker-fast {
    transition: transform 100ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
  }
  .react-ios-time-picker-slow {
    transition: transform 100ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
  }
  .react-ios-time-picker-selected-overlay {
    @apply absolute rounded-[6px] bg-[#dfdfdf] pointer-events-none my-[0px] mx-[10px] left-0 right-0 z-[1] translate-y-[1px];
  }
  .react-ios-time-picker-top-shadow {
    @apply absolute top-0 w-full;
    background: linear-gradient(180deg, #0009 0%, #1c1c1c 100%);
  }
  .react-ios-time-picker-bottom-shadow {
    @apply absolute top-0 w-full;
    background: #0009;
    background: linear-gradient(0deg, #0009 0%, hsla(0, 0%, 11%, 1) 100%);
  }
  .react-ios-time-picker-cell-hour,
  .react-ios-time-picker-cell-minute,
  .react-ios-time-picker-cell-hour-format {
    @apply w-full text-center flex justify-center items-center;
    user-select: none;
    transition: all 100ms linear;
  }
  .react-ios-time-picker-cell-inner-hour,
  .react-ios-time-picker-cell-inner-hour-format,
  .react-ios-time-picker-cell-inner-minute {
    @apply h-full cursor-pointer rounded-[7px] leading-[35px] text-center flex justify-center items-center text-[14px] text-[#666] py-[0px] px-[10px];
    width: fit-content;
    transition: all 100ms linear;
  }
  .react-ios-time-picker-cell-inner-hour:hover,
  .react-ios-time-picker-cell-inner-minute:hover,
  .react-ios-time-picker-cell-inner-hour-format:hover {
    @apply bg-primaryColor text-[#000000];
  }
  .react-ios-time-picker-cell-inner-selected {
    @apply text-[#f7f7f7] text-[16px];
  }
  .react-ios-time-picker-cell-inner-hour-format-selected {
    @apply font-normal text-[#f7f7f7];
  }
  .react-ios-time-picker-btn-container {
    @apply relative flex justify-between bg-[#292929] border-b border-b-[#333] z-[100];
  }
  .react-ios-time-picker-btn {
    @apply py-[10px] px-[15px] text-[13px] text-[#fe9f06] font-medium z-[1];
  }
  .react-ios-time-picker-btn:hover {
    @apply opacity-60;
  }
  .react-ios-time-picker-btn-cancel {
    @apply text-[12px] font-light;
  }
  .react-ios-time-picker-popup {
    @apply fixed top-0 bottom-0 left-0 right-0 flex justify-center items-end z-[99998];
  }
  .react-ios-time-picker-popup-overlay {
    @apply fixed top-0 bottom-0 left-0 right-0;
  }
  .react-ios-time-picker-input {
    @apply py-[5px] px-[10px] rounded-[5px] border border-[#0005];
  }
  .react-ios-time-picker-colon {
    @apply flex justify-center items-center h-full text-[#f7f7f7] relative z-[100] font-semibold;
  }
  .react-ios-time-picker-cell-inner-hidden {
    @apply opacity-0 pointer-events-none;
  }
  .react-ios-time-picker-hour-format-transition {
    transition: transform 100ms ease-out;
  }
  /* .tippy__timePicker__root{
  @apply sm:flex sm:items-end sm:!translate-x-0 sm:!translate-y-0 sm:!w-screen sm:!max-w-[100vw] sm:!h-[100vh] sm:bg-[#00000080];
} */
  html.TimePickerOpenTarget {
    @apply !overflow-hidden;
  }
  .timePicker__open {
    @apply h-screen !overflow-hidden before:content-[''] before:fixed before:left-0 before:top-0 before:w-screen before:h-screen before:bg-[#0009] before:z-[11] before:hidden sm:before:block;
  }
  .tippy__timePicker__root {
    @apply sm:!w-screen sm:!max-w-[100vw] sm:!left-0 sm:!fixed sm:!bottom-0 sm:!inset-unset;
  }
  body .tippy-box.tippy__timePicker {
    @apply w-[350px] !bg-transparent !shadow-none !rounded-none !p-0 !duration-0 sm:!w-screen sm:!max-w-[100vw] sm:!translate-x-0 sm:!translate-y-0;
  }
  body .tippy-box.tippy__timePicker .tippy-content {
    @apply bg-[#ffffff] shadow-[0px_3px_17px_#0000001a] rounded-[22px] px-[15px] pt-[24px] pb-[20px] sm:rounded-b-none sm:!w-screen sm:!max-w-[100vw];
  }
  body .tippy-box.tippy__timePicker[data-placement="bottom-start"] {
    @apply !translate-y-[-7px] sm:!translate-y-[0px];
  }
  body .tippy-box.tippy__timePicker[data-placement="top-start"] {
    @apply !translate-y-[7px] sm:!translate-y-[0px];
  }
  body .tippy-box.tippy__timePicker .tippy-content div {
    @apply text-[#aaa];
  }
  body .tippy-box.tippy__timePicker .tippy-content div:hover {
    @apply text-[#ffffff];
  }
  body
    .tippy-box.tippy__timePicker
    .tippy-content
    div.react-ios-time-picker-cell-inner-selected,
  body
    .tippy-box.tippy__timePicker
    .tippy-content
    div.react-ios-time-picker-cell-inner-hour-format-selected {
    @apply text-[#000000];
  }
  body
    .tippy-box.tippy__timePicker
    .tippy-content
    div.react-ios-time-picker-cell-inner-selected:hover,
  body
    .tippy-box.tippy__timePicker
    .tippy-content
    div.react-ios-time-picker-cell-inner-hour-format-selected:hover {
    @apply text-[#ffffff];
  }
  .anytime__box .custom__checkbox {
    @apply relative flex items-center w-[42px] h-[24px];
  }
  .anytime__box .custom__checkbox input {
    @apply absolute top-[0px] left-[0px] w-[100%] h-[100%] cursor-pointer opacity-0 z-[2];
  }
  .anytime__box .custom__checkbox .rc__Label {
    @apply absolute top-[0px] left-[0px] h-full w-full rounded-[50px] bg-[#d9d9d9] p-0 text-[0px] duration-300 before:content-[''] before:absolute before:top-[3px] before:left-[4px] before:h-[18px] before:w-[18px] before:rounded-full before:duration-300 before:bg-[#fff] before:shadow-[-1px_0px_2px_#b9b9b9];
  }
  .anytime__box .custom__checkbox input:checked + .rc__Label {
    @apply bg-ip__Blue before:left-[20px] before:shadow-none;
  }
  /* timepicker ios css end */

  /* dropdown without tippy start */
  .add__dropdown__menu .inner__wrapper {
    @apply shadow-[0px_3px_17px_#0000001a];
  }
  .add__dropdown__menu .item {
    @apply relative cursor-pointer px-[14px] py-[7px] text-[14px] text-ipBlack__textColor font-biotif__SemiBold before:content-[''] before:absolute before:left-[50%] before:translate-x-[-50%] before:bottom-0 before:w-[calc(100%_-_20px)] before:h-[1px] before:bg-whiteScreen__BorderColor;
  }
  .add__dropdown__menu .item:last-child {
    @apply before:hidden;
  }
  .add__dropdown__menu .item:hover .text {
    @apply text-primaryColor;
  }
  .add__dropdown__menu .i__Icon svg path[fill] {
    @apply fill-primaryColor;
  }
  .add__dropdown__menu .i__Icon svg path[stroke] {
    @apply stroke-primaryColor;
  }
  .add__dropdown__menu.toggle__table__menu__bottom {
    @apply !pt-0 !pb-[15px] bottom-[calc(100%_-_7px)];
    top: unset;
  }
  .add__dropdown__menu.toggle__table__menu__bottom > div:before {
    top: unset;
    @apply bottom-[8px] rotate-[-135deg];
  }
  .add__dropdown__menu .i__Icon {
    @apply !w-[28px] !h-[28px] p-[5px];
  }
  .add__dropdown__menu .animated__icon__wrapper {
    @apply !p-0;
  }
  .add__dropdown__menu .animated__icon__wrapper .socian__ani__icon__wrapper {
    @apply !w-[24px] !h-[24px] !bg-transparent;
  }
  /* dropdown without tippy end */

  /* custom icons css start */
  .i__Icon {
    @apply w-[24px] h-[24px] p-[3px] flex items-center justify-center;
  }
  .i__Icon > div {
    @apply w-full h-full;
  }
  .i__Icon svg {
    @apply w-full h-full;
  }
  .i__Icon.highlighted {
    @apply w-[46px] h-[46px] bg-parentBgWhite__grayBtnBG rounded-[12px];
  }
  .i__Icon.highlighted > div {
    @apply w-full h-full;
  }
  .i__Icon.plus__black {
    @apply !top-[-1px];
  }
  /* custom icons css end */

  /* buttons style start */
  .i__Button {
    @apply inline-flex items-center justify-center text-center px-[30px] py-[14px] font-biotif__SemiBold bg-DarkBtn__BGcolor text-ipWhite__textColor rounded-[6px] text-[14px] leading-[14px] duration-300 hover:bg-ip__Blue sm:px-[15px] sm:py-[11px] sm:rounded-[6px] text-[12px] font-biotif__SemiBold px-[20px];
  }
  .i__Button .i__Icon {
    @apply mr-[2px] ml-[-5px];
  }
  .i__Button .i__Icon svg {
    @apply relative top-[-1px] w-full h-full;
  }
  .i__Button.smaller {
    @apply py-[9px];
  }
  .i__Button.smaller__with__icon {
    @apply py-[6px];
  }
  .i__Button.whiteShadow__Btn {
    @apply !bg-transparent shadow-[0px_4px_4px_#dee2e64d];
  }
  .i__Button.whiteShadow__Btn .i__Icon {
    @apply top-0;
  }
  .i__Button.delete__Btn {
    @apply bg-ip__Red text-white duration-300 hover:bg-ip__Red__hoverDark;
  }
  .i__Button.primary__Btn {
    @apply bg-primaryColorSD text-white duration-300;
  }
  .i__Button.secondary__Btn {
    @apply bg-secondary__Btn__BGColor text-dark__TextColor duration-300 hover:bg-secondary__Btn__BGColor__hover;
  }
  .i__Button.secondary__Btn .i__Icon.plus__black path {
    @apply stroke-dark__TextColor;
  }
  .i__Button.transpatent__Btn {
    @apply bg-transparent text-light__TextColor;
  }
  .i__Button.i__Round__Button {
    @apply bg-rounded__Btn__BGColor hover:bg-ip__Blue__hoverDark duration-300 p-0 rounded-full w-[40px] h-[40px] flex items-center justify-center;
  }
  .i__Button.i__Round__Button .i__Icon {
    @apply m-0 static;
  }
  .i__Button.i__Round__Button:hover .i__Icon path,
  .i__Button.i__Round__Button:hover .i__Icon rect {
    @apply fill-white;
  }
  .rounded__Btn {
    @apply w-[70px] h-[70px] rounded-full bg-dark__TextColor inline-flex items-center justify-center p-[10px];
  }
  .rounded__Btn .i__Icon {
    @apply w-auto h-auto;
  }
  .i__Button.disabled,
  .i__Button[disabled] {
    @apply opacity-50 cursor-no-drop;
  }
  .i__ButtonLoader {
    @apply w-0 h-0 p-[7px] rounded-full border-[3px] border-[#ffffff80] border-r-white relative top-[-2px] mr-[10px];
    animation-name: i__ButtonLoader;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes i__ButtonLoader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .i__ButtonLoader.i__ButtonLoader__ForLight {
    @apply border-[#00000060] border-r-black;
  }
  .singupScreen .i__Button {
    @apply pt-[15px] pb-[15px];
  }
  /* buttons style end */

  /* tippy tooltip css start */
  body .tippy-box {
    @apply bg-ip__black__text__color;
  }
  body .tippy-box .tippy-content {
    @apply p-[5px] px-[12px];
  }
  body .tippy-box .tippy-content,
  body .tippy-box .tippy-content div {
    @apply text-[14px] font-biotif__Medium text-ipWhite__textColor leading-[18px];
  }
  body .tippy-box .tippy-arrow {
    @apply text-ip__black__text__color;
  }
  /* tippy tooltip css end */

  /* tippy dropdown css start */
  .tippy-box.tippy__dropdown {
    @apply bg-ipWhite__bgColor !shadow-[0px_3px_17px_#0000001a] rounded-[10px];
  }
  .tippy-box.tippy__dropdown .tippy-arrow {
    @apply hidden;
  }
  .tippy-box.tippy__dropdown .tippy__dropdown__ul {
    @apply py-[4px] min-w-[110px];
  }
  .tippy-box.tippy__dropdown .item__link {
    @apply flex items-center relative z-[2] cursor-pointer;
  }
  .tippy-box.tippy__dropdown .item__link .item__text {
    @apply text text-ipBlack__textColor text-[14px] font-biotif__Medium inline-block;
  }
  .tippy-box.tippy__dropdown .item:hover .item__link .item__text {
    @apply text-primaryColorSD;
  }
  .tippy-box.tippy__dropdown .tippy-content {
    @apply p-0;
  }
  .tippy-box.tippy__dropdown[data-placement^="bottom"] > .tippy-arrow::before {
    @apply bg-ip__white__text__color top-[-17px] w-[12px] h-[12px] !border-l-[1px] border-l-whiteScreen__BorderColor !border-b-[1px] !border-b-whiteScreen__BorderColor !border-t-0 !border-r-0 rotate-[135deg];
  }
  .tippy-box.tippy__dropdown[data-placement^="top"] > .tippy-arrow::before {
    @apply bg-ip__white__text__color top-[12px] w-[12px] h-[12px] !border-l-[1px] border-l-whiteScreen__BorderColor !border-b-[1px] !border-b-whiteScreen__BorderColor !border-t-0 !border-r-0 rotate-[-45deg];
  }
  .tippy-box.tippy__dropdown[data-placement^="bottom"],
  .tippy-box.tippy__dropdown[data-placement^="bottom-start"],
  .tippy-box.tippy__dropdown[data-placement^="bottom-end"] {
    @apply translate-y-[-8px];
  }
  .tippy-box.tippy__dropdown[data-placement^="top"],
  .tippy-box.tippy__dropdown[data-placement^="top-start"],
  .tippy-box.tippy__dropdown[data-placement^="top-end"] {
    @apply translate-y-[8px];
  }
  .tippy-box.tippy__dropdown[data-placement^="top-start"],
  .tippy-box.tippy__dropdown[data-placement^="bottom-start"] {
    @apply translate-x-[-1px];
  }
  .tippy-box.tippy__dropdown[data-placement^="top-end"],
  .tippy-box.tippy__dropdown[data-placement^="bottom-end"] {
    @apply translate-x-[1px];
  }
  .tippy-box.tippy__dropdown[data-placement^="bottom-start"]
    > .tippy-arrow::before,
  .tippy-box.tippy__dropdown[data-placement^="top-start"]
    > .tippy-arrow::before {
    @apply left-[6px];
  }
  .tippy-box.tippy__dropdown[data-placement^="bottom-end"]
    > .tippy-arrow::before,
  .tippy-box.tippy__dropdown[data-placement^="top-end"] > .tippy-arrow::before {
    @apply left-[-9px];
  }
  .tippy-box.tippy__dropdown .item {
    @apply relative cursor-pointer px-[18px] py-[7px] text-[14px] text-ipBlack__textColor font-biotif__SemiBold before:content-[''] before:absolute before:left-[50%] before:translate-x-[-50%] before:bottom-0 before:w-[calc(100%_-_20px)] before:h-[1px] before:bg-whiteScreen__BorderColor;
  }
  .tippy-box.tippy__dropdown .item:last-child {
    @apply before:hidden;
  }
    /* HELLO CHANGE IN Z-INDEX FOR DASHBOARD TIMELINE MODEL NOT OPEN*/
  *[id^="tippy-"] {
    pointer-events: auto !important;
      z-index: 99 !important;
  }
  /* tippy dropdown css end */

  /* section-header */
  .section__header {
    @apply flex flex-wrap items-center rounded-[12px] py-[10px] px-[24px] pr-[8px] mb-[15px] 3xl:pl-[15px] cursor-pointer relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-[0.06] before:rounded-[12px];
  }
  .section__header .section__toggle__btn.active {
    @apply before:rotate-[135deg] before:top-[12px];
  }
  /* section-header-end */

  /* modal css start */
  .ip__Modal__Wrapper {
    @apply fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[99];
  }
  .ip__Modal__Wrapper .ip__Modal__Overlay {
    @apply fixed top-0 left-0 w-screen h-screen bg-[#0009] pointer-events-none;
  }
  .ip__Modal__Wrapper .ip__Modal__ContentWrap {
    @apply bg-white rounded-[12px] relative z-[2] max-w-[calc(100%_-_20px)] lg:mb-0;
  }
  .ip__Modal__Wrapper .ip__Modal__Header {
    @apply flex items-center justify-between border-b border-whiteScreen__BorderColor px-[24px] py-[20px] pr-[16px] rounded-t-[12px] bg-gray__BGColor sm:py-[12px] sm:pt-[17px] sm:px-[15px];
  }
  .ip__Modal__Wrapper .ip__Modal__Header .title {
    @apply whitespace-pre overflow-hidden text-ellipsis text-ipBlack__textColor text-[22px] font-biotif__Medium w-[calc(100%_-_32px)] sm:text-[20px] sm:leading-[23px];
  }
  .ip__Modal__Wrapper .ip__Modal__Header .i__Icon {
    @apply w-[30px] h-[30px] shrink-0 rounded-full flex flex-wrap items-center justify-center p-0 duration-300 hover:bg-parentBgGray__grayBtnBG cursor-pointer;
  }
  .ip__Modal__Wrapper .ip__Modal__Header .i__Icon svg {
    @apply w-full h-full;
  }
  .ip__Modal__Wrapper .ip__Modal__Header .i__Icon svg path {
    @apply stroke-ipBlack__bgColor;
  }
  .ip__Modal__Wrapper .ip__Modal__Body {
    @apply p-[30px] max-h-[calc(100dvh_-_250px)] overflow-y-auto md:p-[20px] lg:max-h-[calc(100dvh_-_270px)] sm:p-[15px] sm:max-h-[calc(100dvh_-_230px)];
  }
  .ip__Modal__Wrapper .ip__Modal__Footer {
    @apply py-[15px] px-[24px] flex flex-wrap justify-end rounded-b-[12px] border-t border-whiteScreen__BorderColor md:!pl-[15px] md:!pr-[15px] md:!pt-[15px];
  }
  .ip__Modal__Wrapper .ip__Modal__Footer .i__Button {
    @apply mr-[20px];
  }
  .ip__Modal__Wrapper .ip__Modal__Footer .i__Button:last-child {
    @apply mr-0;
  }

  /* small-modal */
  .ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__ContentWrap {
    @apply !w-[450px] rounded-[24px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Header {
    @apply p-0 bg-transparent border-0 justify-end pt-[8px] pr-[8px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Header .title {
    @apply hidden;
  }
  .ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Body {
    @apply pb-[10px] pt-[0px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Footer {
    @apply justify-center border-t-0 pb-[30px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal .confirmation__icon {
    @apply w-[64px] h-[64px] mx-auto rounded-full p-[17px] mb-[20px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal .confirmation__icon svg {
    @apply w-full h-full;
  }
  .ip__Modal__Wrapper.small__without__HT__modal .confirmation__image__wrapper {
    @apply w-[70px] h-[70px] mx-auto mb-[15px] p-[10px] rounded-full flex items-center justify-center bg-ipGray__transparentBG;
  }
  .ip__Modal__Wrapper.small__without__HT__modal .confirmation__title {
    @apply text-ip__black__text__color text-[24px] font-biotif__SemiBold text-center w-[300px] max-w-full mx-auto sm:text-[22px] sm:leading-[30px];
  }
  /* small-modal-end */
  /* modal css end */

  /* form css start */
  .form__Group {
    @apply mb-[20px];
  }
  .if__label {
    @apply block text-black__TextColor700 text-[14px] mb-[5px] font-biotif__Medium leading-[19px] sm:mb-[6px];
  }
  .if__label:empty {
    @apply hidden;
  }
  .if__label.if__label__blue {
    @apply text-[16px] mb-[10px] font-biotif__Medium text-formLabel__blueColor sm:mb-[6px];
  }
  .if__label.if__label__black {
    @apply text-[16px] mb-[10px] font-biotif__Medium sm:mb-[6px];
  }
  .if__label.if__label__blue .i__Icon {
    @apply mt-[-5px];
  }
  .if__label.if__label__blue .animated__icon__btn{
    @apply mt-[-8px];
  }
  .if__label.if__label__blue .animated__icon__btn .icon__wrapper{
    @apply !w-[30px] !h-[30px];
  }
  .if__label .required__sign {
    @apply text-ip__Red;
  }
  .ip__input,
  .ip__react__datepicker input {
    @apply rounded-[6px] border border-transparent bg-formField__BGColor py-[9px] px-[13px] text-black__TextColor800 text-[16px] font-biotif__Regular placeholder:opacity-100 placeholder:text-black__TextColor300 w-full focus:shadow-none focus:outline-none focus:border-ipBlack__borderColor;
  }
  .ip__input[type="color"] {
    @apply h-[45px] w-[70px] !p-[5px];
  }
  .zipCode__wrapper input[type="number"]::-webkit-outer-spin-button,
  .zipCode__wrapper input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .zipCode__wrapper input[type="number"] {
    -moz-appearance: textfield;
  }
  .ip__react__datepicker {
    @apply relative;
  }
  .ip__react__datepicker .react-datepicker-wrapper {
    @apply w-full;
  }
  .ip__react__datepicker input {
    @apply bg-[url('assets/images/icons/filled-icons/calendarFilledIcon.svg')] bg-no-repeat bg-[length:20px_20px] bg-[calc(100%_-_12px)_center] pr-[60px];
  }
  .ip__react__datepicker .react-datepicker__close-icon{
    @apply right-[40px] w-[16px] h-[16px] p-0 top-[calc(50%_-_2px)] translate-y-[-50%] after:content-['×'] after:flex after:items-center after:justify-center after:text-[24px] after:leading-[16px] after:bg-transparent after:pt-0 after:text-black/50 hover:after:text-black;
  }

  .ip__select {
    @apply rounded-[12px] bg-formField__BGColor py-[12px] px-[16px] text-dark__TextColor text-[16px] font-biotif__Regular placeholder:opacity-100 placeholder:text-disableLight__TextColor w-full appearance-none relative;
  }
  .ip__Select div[class$="control"],
  .ip__Select div[class$="control"]:hover,
  .ip__Select .ip__select__dynamic__control,
  .ip__Select .ip__select__dynamic__control:hover {
    @apply min-h-[44px] border-none bg-formField__BGColor rounded-[6px] px-[16px] py-[7px] pr-[38px] shadow-none;
  }
  .ip__Select .ip__select__dynamic__control.ip__select__dynamic__control--is-focused{
    @apply !outline !outline-[1px] !outline-primaryColorSD !outline-offset-[-1px];
  }
  .ip__Select
    .ip__select__dynamic__control--menu-is-open.ip__select__dynamic__control,
  .ip__Select
    .ip__select__dynamic__control--menu-is-open.ip__select__dynamic__control:hover {
    @apply pt-[7px] pb-[7px];
  }
  .ip__Select div[class$="ValueContainer"],
  .ip__Select .ip__select__dynamic__value-container {
    @apply p-0 leading-[30px] h-[30px] w-full block relative !pr-[10px];
  }
  .ip__Select div[class$="ValueContainer"] div[class$="multiValue"],
  .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value {
    @apply m-0 inline-flex relative bg-primaryColor rounded-[6px] pt-[4px] pb-[3px] pl-[10px] pr-[22px] mr-[5px] mb-[5px];
  }
  .ip__Select
    div[class$="ValueContainer"]
    div[class$="multiValue"]:nth-last-of-type(2),
  .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value:nth-last-of-type(2) {
    @apply mr-[10px];
  }
  .ip__Select
    div[class$="ValueContainer"]
    div[class$="multiValue"]
    > div:first-child,
  .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value
    > div:first-child {
    @apply text-[12px] leading-[18px] font-biotif__Medium text-ipWhite__textColor p-0;
  }
  .ip__Select
    div[class$="ValueContainer"]
    div[class$="multiValue"]
    > div:last-child,
  .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value
    > div:last-child {
    @apply p-0 w-[12px] h-[12px] absolute top-[6px] right-[6px] hover:bg-[#fff3] before:content-[''] before:absolute before:w-[10px] before:h-[1px] before:bg-ip__white__text__color before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[10px] after:h-[1px] after:bg-ip__white__text__color after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%];
  }
  .ip__Select div[class$="placeholder"],
  .ip__Select .ip__select__dynamic__placeholder {
    @apply text-[16px] font-biotif__Regular text-black__TextColor300 opacity-100 whitespace-pre overflow-hidden text-ellipsis;
  }
  .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder {
    @apply relative top-[-1px];
  }
  /* .ip__Select div[class$="singleValue"],
  .ip__Select .ip__select__dynamic__single-value {
    @apply text-black text-[16px] font-biotif__Regular;
  } */
  .ip__Select
    .ip__select__dynamic__control--menu-is-open
    .ip__select__dynamic__value-container--has-value {
    @apply flex;
  }
  .ip__Select div[class$="Input"],
  .ip__Select .ip__select__dynamic__input,
  .ip__Select .ip__select__dynamic__input-container {
    @apply absolute top-0 left-0 w-full h-[30px] m-0 p-0 !text-[16px] !font-biotif__Regular;
  }
  .ip__Select div[class$="multiValue"] + div[class$="Input"],
  .ip__Select
    .ip__select__dynamic__multi-value
    + .ip__select__dynamic__input-container
    .ip__select__dynamic__input,
  .ip__Select
    .ip__select__dynamic__multi-value
    + .ip__select__dynamic__input-container {
    @apply static w-auto;
  }
  .ip__Select div[class$="multiValue"] + div[class$="Input"] input,
  .ip__Select
    .ip__select__dynamic__multi-value
    + .ip__select__dynamic__input-container
    .ip__select__dynamic__input
    input,
  .ip__Select
    .ip__select__dynamic__multi-value
    + .ip__select__dynamic__input-container
    input,
  .ip__Select div[class$="multiValue"] div[class$="Input"] input,
  .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    .ip__select__dynamic__input
    input,
  .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    input {
    @apply !relative top-[-2px];
  }
  .ip__Select span[class$="indicatorSeparator"],
  .ip__Select .ip__select__dynamic__indicator-separator {
    @apply hidden;
  }
  .ip__Select div[class$="IndicatorsContainer"],
  .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-28px] cursor-pointer;
  }
  .ip__Select div[class$="indicatorContainer"]:last-child,
  .ip__Select .ip__select__dynamic__indicator-separator:last-child {
    @apply p-0 w-[20px] h-[20px] relative before:content-[''] before:absolute before:w-[9px] before:h-[1px] before:bg-ipBlack__textColor before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_3px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[9px] after:h-[1px] after:bg-ipBlack__textColor after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_+_3px)] after:translate-x-[-50%] after:translate-y-[-50%];
  }
  .ip__Select div[class$="indicatorContainer"]:nth-last-of-type(2),
  .ip__Select .ip__select__dynamic__indicator-separator:nth-last-of-type(2) {
    @apply p-0 w-[20px] h-[20px] relative cursor-pointer before:content-[''] before:absolute before:w-[12px] before:h-[1px] before:bg-ipBlack__textColor hover:before:bg-ip__Red before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_1px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[12px] after:h-[1px] after:bg-ipBlack__textColor hover:after:bg-ip__Red after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_-_1px)] after:translate-x-[-50%] after:translate-y-[-50%];
  }
  .ip__Select div[class$="indicatorContainer"] svg,
  .ip__Select .ip__select__dynamic__indicator-separator svg {
    @apply !opacity-0;
  }
  .ip__Select svg[class$="Svg"],
  .ip__Select .ip__select__dynamic__svg {
    @apply fill-black opacity-0;
  }
  /* .ip__Select div[class$='menu'],
.ip__Select .ip__select__dynamic__menu {
  @apply mt-[-3px] mb-0 rounded-t-[0px] bg-formField__BGColor border-t border-t-whiteScreen__BorderColor;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -10px,
    rgba(0, 0, 0, 0.3) 0px 10px 30px -20px;
} */
  .ip__Select div[class$="menu"],
  .ip__Select .ip__select__dynamic__menu {
    @apply mt-[3px] mb-0 rounded-t-[6px] bg-formField__BGColor rounded-[6px];
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -10px,
      rgba(0, 0, 0, 0.3) 0px 10px 30px -20px;
  }
  .ip__Select div[class$="MenuList"],
  .ip__Select .ip__select__dynamic__menu-list {
    @apply pt-[6px] pb-[6px] max-h-[170px];
  }
  .ip__Select div[class$="option"],
  .ip__Select .ip__select__dynamic__option {
    @apply text-ipBlack__textColor text-[16px] font-biotif__Regular bg-transparent hover:bg-ipWhite__bgColor focus:bg-ipWhite__bgColor border-b border-b-whiteScreen__BorderColor;
  }
  .ip__Select div[class$="option"].ip__select__dynamic__option--is-focused,
  .ip__Select
    .ip__select__dynamic__option.ip__select__dynamic__option--is-focused {
    @apply bg-ipWhite__bgColor hover:bg-ipWhite__bgColor focus:bg-ipWhite__bgColor;
  }
  .ip__Select div[class$="option"]:last-child,
  .ip__Select .ip__select__dynamic__option:last-child {
    @apply border-b-0;
  }
  .ip__Select div.css-9gakcf-option[class$="option"],
  .ip__Select .ip__select__dynamic__option.css-9gakcf-option,
  .ip__Select
    .ip__select__dynamic__option.ip__select__dynamic__option--is-selected {
    @apply !text-primaryColor relative bg-white before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-10;
  }
  .ip__Select .ip__select__dynamic__menu-notice--no-options {
    @apply text-black__TextColor600;
  }
  .ip__Select
    .ip__select__dynamic__value-container.ip__select__dynamic__value-container--is-multi {
    @apply h-auto flex flex-wrap content-start relative top-[2px];
  }
  .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container {
    @apply static text-black__TextColor800;
  }
  .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder
    + .ip__select__dynamic__input-container {
    @apply absolute top-0 left-0 m-0 h-[30px] w-full p-0 font-biotif__Regular text-[16px];
  }
  .ip__Select .dropdown__position__static .ip__select__dynamic__menu-list {
    @apply max-h-[208px];
  }

  .country__select__box .ip__Select div[class$="option"],
  .country__select__box .ip__Select .ip__select__dynamic__option {
    @apply border-b-0;
  }

  /* transparent form field */
  .transparent__formField .ip__input {
    @apply bg-transparent border-whiteScreen__BorderColor focus:border-ip__Blue;
  }
  /* transparent form field end */

  /* form elements has icon */
  .ip__Select.ip__Select__hasIcon,
  .ip__form__hasIcon {
    @apply relative;
  }
  .ip__Select.ip__Select__hasIcon .i__Icon,
  .ip__form__hasIcon .i__Icon {
    @apply absolute top-[9px] left-[8px] translate-x-unset translate-y-unset opacity-50;
  }
  /* .ip__Select.ip__Select__hasIcon > .i__Icon svg path[fill],
  .ip__form__hasIcon > .i__Icon svg path[fill] {
    @apply fill-black__TextColor500;
  } */
  /* .ip__Select.ip__Select__hasIcon > .i__Icon svg path[stroke],
  .ip__form__hasIcon > .i__Icon svg path[stroke] {
    @apply stroke-black__TextColor500;
  } */
  .ip__Select.ip__Select__hasIcon div[class$="control"],
  .ip__form__hasIcon .ip__input{
    @apply pl-[35px];
  }
  .ip__form__hasIcon .e-input-group .ip__input{
    @apply !pl-[35px];
  }
  .ip__Select.ip__form__hasIcon div[class$="control"] {
    @apply pl-[36px];
  }
  .ip__form__hasIcon.ip__form__hasIcon__right .ip__input {
    @apply pl-[13px] pr-[35px];
  }
  .ip__form__hasIcon.ip__form__hasIcon__right .i__Icon {
    @apply top-[9px] left-unset right-[8px];
  }
  /* form elements has icon end */

  .ip__textarea,
  body .e-richtexteditor .e-rte-content {
    @apply rounded-[6px] bg-formField__BGColor py-[12px] px-[16px] min-h-[150px] text-black__TextColor800 text-[16px] font-biotif__Regular placeholder:opacity-100 placeholder:text-black__TextColor300 w-full resize-none border border-transparent focus:outline-none focus:border-primaryColorSD;
  }
  .transparent__textarea .ip__textarea {
    @apply p-0 bg-transparent focus:border-transparent;
  }

  .ip__Checkbox {
    @apply inline-block relative;
  }
  .ip__Checkbox input {
    @apply absolute top-0 left-0 w-full h-full z-[2] opacity-0 cursor-pointer;
  }
  .ip__Checkbox .rc__Label {
    @apply text-light__TextColor inline-block text-[14px] font-biotif__Regular pl-[26px] before:content-[''] before:absolute before:top-[2px] before:left-0 before:w-[18px] before:h-[18px] before:border before:border-DarkBtn__BGcolor before:rounded-[3px] after:content-[''] after:w-[10px] after:h-[5px] after:absolute after:top-[7px] after:left-[4px] after:border-b-[2px] after:border-l-[2px] after:border-[#ffffff] after:rotate-[-45deg] after:opacity-0 after:duration-[500ms];
  }
  .form__Group.form__Groupip__Checkbox {
    @apply mb-0;
  }
  .ip__Checkbox input:checked + .rc__Label:before {
    @apply bg-DarkBtn__BGcolor;
  }
  .ip__Checkbox input:checked + .rc__Label:after {
    @apply opacity-100;
  }

  .ip__Radio {
    @apply inline-block relative;
  }
  .ip__Radio input {
    @apply absolute top-0 left-0 w-full h-full z-[2] opacity-0 cursor-pointer;
  }
  .ip__Radio .rc__Label {
    @apply text-light__TextColor inline-block text-[14px] font-biotif__Regular pl-[26px] before:content-[''] before:absolute before:top-[2px] before:left-0 before:w-[18px] before:h-[18px] before:border before:border-DarkBtn__BGcolor before:rounded-full after:content-[''] after:absolute after:top-[7px] after:left-[5px] after:opacity-0 after:duration-[500ms] after:w-[8px] after:h-[8px] after:rounded-full after:bg-ip__white__text__color;
  }
  .ip__Radio input:checked + .rc__Label:before {
    @apply bg-primaryColorSD border-primaryColorSD;
  }
  .ip__Radio input:checked + .rc__Label:after {
    @apply opacity-100;
  }

  .ip__Checkbox.full__Checkbox,
  .ip__Radio.full__Radio__Button {
    @apply w-full relative shadow-[5px_10px_20px_#dee2e64d] rounded-[12px] border border-transparent;
  }
  .ip__Checkbox.full__Checkbox input,
  .ip__Radio.full__Radio__Button input {
    @apply absolute top-0 left-0 w-full h-full z-[4] opacity-0 cursor-pointer;
  }
  .ip__Checkbox.full__Checkbox input[disabled] {
    @apply cursor-not-allowed;
  }
  .ip__Checkbox.full__Checkbox input[disabled] + .rc__Label,
  .ip__Radio.full__Radio__Button input[disabled] .rc__Label {
    @apply before:opacity-50 after:opacity-50;
  }
  .ip__Checkbox.full__Checkbox .rc__Label {
    @apply font-biotif__Medium text-[18px] text-dark__TextColor py-[23px] px-[16px] pr-[60px] block relative w-full before:content-[''] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[unset] before:right-[16px] before:w-[20px] before:h-[20px] before:rounded-[3px] before:duration-[500ms] before:border before:border-dark__TextColor before:z-[3] after:content-[''] after:w-[11px] after:h-[6px] after:absolute after:top-[calc(50%_-_1px)] after:translate-y-[-50%] after:left-[unset] after:right-[20px] after:border-b-[2px] after:border-l-[2px] after:border-white after:rotate-[-45deg] after:opacity-0 after:duration-[500ms] after:z-[3];
  }
  .ip__Checkbox.full__Checkbox input:checked + .rc__Label {
    @apply before:bg-dark__TextColor after:opacity-100;
  }
  .ip__Radio.full__Radio__Button .rc__Label {
    @apply font-biotif__Medium text-[18px] text-dark__TextColor py-[23px] px-[16px] pr-[60px] block relative w-full before:content-[''] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[unset] before:right-[16px] before:w-[20px] before:h-[20px] before:duration-[500ms] before:border-2 before:border-dark__TextColor before:z-[3] before:rounded-full after:content-[''] after:absolute after:top-[50%] after:translate-y-[-50%] after:left-[unset] after:right-[20px] after:w-[12px] after:h-[12px] after:duration-[500ms] after:bg-dark__TextColor after:z-[3] after:rounded-full after:opacity-0;
  }
  .ip__Radio.full__Radio__Button input:checked + .rc__Label {
    @apply after:opacity-100;
  }

  .ip__Checkbox.toggleSwitch {
    @apply relative w-[42px] h-[20px] flex items-center;
  }
  .ip__Checkbox.toggleSwitch .rc__Label {
    @apply text-[0px] absolute top-0 left-0 w-full h-full border-[2px] border-light__TextColor rounded-[50px] p-0 duration-300 before:w-[12px] before:h-[12px] before:rounded-full before:border-0 before:bg-light__TextColor before:top-[2px] before:left-[2px] before:duration-300;
  }
  .ip__Checkbox.toggleSwitch input:checked + .rc__Label {
    @apply border-primaryColorSD before:bg-primaryColorSD before:left-[23px] after:hidden;
  }

  .upload__File.upload__File__grayBox {
    @apply px-[20px] py-[20px] bg-formField__BGColor rounded-[12px] mb-[25px];
  }
  .upload__File .delete__Btn {
    @apply absolute top-0 right-0;
  }
  .upload__File .delete__Btn .i__Icon {
    @apply w-[20px] h-[20px] bg-white hover:bg-white duration-300 p-0 rounded-full shadow-[0px_0px_10px_#c9c9c9];
  }
  .upload__File .delete__Btn .i__Icon svg {
    @apply w-full h-full;
  }
  .upload__File .delete__Btn .i__Icon svg path {
    @apply stroke-ip__Red;
  }
  .upload__File > div {
    @apply mb-0;
  }
  .upload__File .profile__img {
    @apply w-[70px] h-[70px];
  }
  .upload__File .profile__img .noName__letter,
  .upload__File .profile__img .img__loader,
  .upload__File .profile__img .img__element,
  .upload__File .profile__img .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .upload__File .profile__img .no__img__icon {
    @apply bg-ipGray__transparentBG p-[12px];
  }
  .upload__File .profile__img .img__loader {
    @apply bg-ipGray__transparentBG sm:bg-ipWhite__bgColor;
  }
  .upload__File .profile__img .img__element {
    @apply object-cover object-center;
  }
  .upload__File .profile__img .noName__letter .noName__letter__text {
    @apply text-[22px] leading-[22px] pt-[4px];
  }

  /* fileDropZone css start */
  .fileDropZone__wrapper {
    @apply py-[20px] px-[20px] rounded-[6px] bg-ipGray__transparentBG border-[2px] border-dashed border-greyScreen__BorderColor min-h-[190px] flex items-center justify-center relative;
  }
  .fileDropZone__wrapper .fileDropZone__input {
    @apply absolute top-0 left-0 w-full h-full z-[2] opacity-0;
  }
  .fileDropZone__wrapper .i__Icon {
    @apply p-0 w-[40px] h-[40px];
  }
  .fileDropZone__wrapper .text {
    @apply text-[18px] text-center text-ip__black__text__color font-biotif__Medium mt-[24px];
  }
  /* fileDropZone css end */

  /* DropZone preview css start */
  .dropZone__file__preview__wrapper {
    @apply flex flex-wrap items-center mx-[-10px] mt-[15px];
  }
  .dropZone__file__preview__wrapper .dropZone__file__preview {
    @apply px-[10px] w-1/4 mb-[15px];
  }
  .dropZone__file__preview__wrapper .dropZone__file__preview .inner__wrapper {
    @apply w-full pt-[75%] relative;
  }
  .dropZone__file__preview__wrapper .dropZone__file__preview img {
    @apply w-full h-full rounded-[10px] absolute top-0 left-0;
  }
  .dropZone__file__preview__wrapper
    .dropZone__file__preview
    .dropZone__close__btn {
    @apply absolute top-[4px] right-[4px] bg-white rounded-full w-[20px] h-[20px] z-[3] hidden;
  }
  .dropZone__file__preview__wrapper .dropZone__file__preview .i__Icon {
    @apply w-full h-full p-0;
  }
  .dropZone__file__preview__wrapper
    .dropZone__file__preview
    .dropZone__close__btn
    svg {
    @apply invert w-full h-full;
  }
  .dropZone__file__preview__wrapper
    .dropZone__file__preview:hover
    .dropZone__close__btn {
    @apply block;
  }
  /* DropZone preview css end */

  .ip__Error {
    @apply font-biotif__Regular text-[16px] text-ip__Red mt-[8px] leading-[18px] sm:text-[12px] sm:leading-[16px];
  }
  .ip__Error:empty {
    @apply hidden;
  }
  .ip__input.invalid,
  .ip__select.invalid,
  .ip__textarea.invalid,
  .ip__Checkbox.full__Checkbox.invalid,
  .ip__Radio.full__Radio__Button.invalid {
    @apply border border-ip__Red;
  }
  .ip__input.valid,
  .ip__select.valid,
  .ip__textarea.valid,
  .ip__Checkbox.full__Checkbox.valid,
  .ip__Radio.full__Radio__Button.valid {
    @apply border border-ip__Green;
  }

  /* repeater fields css start */
  .repeater__email__box,
  .repeater__phone__box,
  .repeater__Rcontact__box.withPrimary,
  .repeater__Rcontact__box {
    @apply flex flex-wrap;
  }
  .repeater__email__box .action__box .ip__Checkbox,
  .repeater__phone__box .action__box .ip__Checkbox,
  .repeater__Rcontact__box .action__box .ip__Checkbox,
  .repeater__jobrole__box .action__box .ip__Checkbox {
    @apply w-[20px] h-[20px] top-[-1px];
  }
  .repeater__email__box .action__box .ip__Checkbox .rc__Label,
  .repeater__phone__box .action__box .ip__Checkbox .rc__Label,
  .repeater__Rcontact__box .action__box .ip__Checkbox .rc__Label,
  .repeater__jobrole__box .action__box .ip__Checkbox .rc__Label {
    @apply text-[0px] w-full h-full pl-0;
  }
  .repeater__email__box .delete__btn,
  .repeater__phone__box .delete__btn,
  .repeater__Rcontact__box .delete__btn,
  .repeater__jobrole__box .delete__btn {
    @apply bg-ipRed__transparentBG relative duration-500 hover:bg-ip__Red;
  }
  .repeater__email__box .delete__btn svg path,
  .repeater__phone__box .delete__btn svg path,
  .repeater__Rcontact__box .delete__btn svg path,
  .repeater__jobrole__box .delete__btn svg path {
    @apply fill-ip__Red duration-500;
  }
  .repeater__email__box .delete__btn:hover svg path,
  .repeater__phone__box .delete__btn:hover svg path,
  .repeater__Rcontact__box .delete__btn:hover svg path,
  .repeater__jobrole__box .delete__btn:hover svg path {
    @apply fill-[#ffffff];
  }
  .repeater__phone__box .form__Group .if__label,
  .repeater__jobrole__box .form__Group .if__label {
    @apply hidden;
  }
  .repeater__email__box .ip__Error {
    @apply sm:w-[calc(100%_-_124px)];
  }
  .repeater__email__box > div:first-child .action__box {
    @apply sm:top-[32px];
  }
  .repeater__phone__box .ip__Select div[class$="control"],
  .repeater__phone__box .ip__Select div[class$="control"]:hover,
  .repeater__phone__box .ip__Select .ip__select__dynamic__control,
  .repeater__phone__box .ip__Select .ip__select__dynamic__control:hover,
  .repeater__Rcontact__box .ip__Select div[class$="control"],
  .repeater__Rcontact__box .ip__Select div[class$="control"]:hover,
  .repeater__Rcontact__box .ip__Select .ip__select__dynamic__control,
  .repeater__Rcontact__box
    .ip__Select
    .ip__select__dynamic__control:hover
    .repeater__jobrole__box
    .ip__Select
    div[class$="control"],
  .repeater__jobrole__box .ip__Select div[class$="control"]:hover,
  .repeater__jobrole__box .ip__Select .ip__select__dynamic__control,
  .repeater__jobrole__box .ip__Select .ip__select__dynamic__control:hover {
    @apply sm:pl-[8px] sm:pr-[25px];
  }
  .repeater__phone__box .ip__Select div[class$="IndicatorsContainer"],
  .repeater__phone__box .ip__Select .ip__select__dynamic__indicators,
  .repeater__Rcontact__box .ip__Select div[class$="IndicatorsContainer"],
  .repeater__Rcontact__box
    .ip__Select
    .ip__select__dynamic__indicators
    .repeater__jobrole__box
    .ip__Select
    div[class$="IndicatorsContainer"],
  .repeater__jobrole__box .ip__Select .ip__select__dynamic__indicators {
    @apply sm:mr-[-20px];
  }
  .repeater__phone__box .ip__Select + div .ip__Error,
  .repeater__Rcontact__box
    .ip__Select
    + div
    .ip__Error
    .repeater__jobrole__box
    .ip__Select
    + div
    .ip__Error {
    @apply sm:w-full xsm:w-full;
  }
  .repeater__reminder__box .repeater__reminde__inside .delete__btn .i__Icon {
    @apply relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-ip__Red before:opacity-10 hover:before:opacity-100;
  }
  .repeater__reminder__box .repeater__reminde__inside .delete__btn svg {
    @apply relative z-[2];
  }
  .repeater__reminder__box .repeater__reminde__inside .delete__btn path {
    @apply fill-ip__Red;
  }
  .repeater__reminder__box .repeater__reminde__inside .delete__btn:hover path {
    @apply fill-white;
  }
  .repeater__Rcontact__box.withPrimary .action__box {
    @apply justify-end;
  }
  .repeater__phone__box .ip__Select div[class$="control"],
  .repeater__phone__box .ip__Select .ip__select__dynamic__control {
    @apply pl-[8px] pr-[25px];
  }
  .repeater__phone__box .ip__Select div[class$="IndicatorsContainer"],
  .repeater__phone__box .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-21px];
  }
  /* repeater fields css end */
  /* form css end */

  /* setting-pages */
  .setting__FieldTitle {
    @apply text-ipBlack__textColor text-[20px] font-biotif__Medium mb-[20px] flex flex-wrap items-center justify-between md:mb-[12px] sm:text-[18px];
  }
  /* setting-pages-end */
  /* attachments box preview css start */
  .attachments__box {
    @apply flex flex-wrap items-center mb-[13px];
  }
  .attachments__box:last-child {
    @apply mb-0;
  }
  .attachments__box .attachments__details {
    @apply flex flex-wrap items-center w-[calc(50%_-_116px)];
  }
  .attachments__box .attachments__details .i__Icon {
    @apply w-[30px] h-[30px] relative top-[-2px] sm:w-[24px] sm:h-[24px] sm:top-[-1px];
  }
  .attachments__box .attachments__details .attachments__name {
    @apply text-[16px] font-biotif__Regular text-ip__black__text__color w-[calc(100%_-_32px)] overflow-hidden text-ellipsis pl-[5px] sm:text-[14px] sm:leading-[18px] sm:pl-[3px];
  }
  .attachments__box .attachments__size {
    @apply text-[15px] font-biotif__Regular text-ip__black__text__color px-[10px] w-[200px] sm:text-[14px];
  }
  .attachments__box .attachments__progress__wrapper {
    @apply w-[calc(50%_-_116px)] px-[15px];
  }
  .attachments__box .attachments__progress {
    @apply h-[6px] w-full relative bg-ip__Grey__hoverDark;
  }
  .attachments__box .attachments__progress .attachments__progress__value {
    @apply bg-ip__Blue h-full absolute top-0 left-0;
  }
  .attachments__box .attachment__close__btn {
    @apply duration-500 rounded-full cursor-pointer hover:bg-ipGray__transparentBG sm:relative sm:top-[-1px];
  }
  .attachments__box .attachment__close__btn svg {
    @apply w-full h-full;
  }
  .attachments__box .attachment__close__btn svg path {
    @apply stroke-mediumDark__TextColor;
  }
  .attachments__box .attachment__close__btn .i__Icon {
    @apply p-0 w-[30px] h-[30px] sm:w-[24px] sm:h-[24px];
  }
  /* attachments box preview css end */
  .animated__icon__btn svg{
    @apply w-full h-full;
  }
  /* common css end */

  /* card-style */
  .card {
    @apply shadow-[0px_4px_58px_#0000000a] rounded-[12px];
  }
  /* card-style-end */

  /* login signup screens css start */
  .google__Login button > div:not(.i__ButtonLoader),
  .google__Login button > div div,
  .google__Login button > div iframe {
    @apply !absolute !top-0 !left-0 !h-full !w-full opacity-[0.00000001] !m-0 !p-0;
  }
  .signup__Card {
    @apply relative z-[2];
  }
  .signup__Card__Header {
    @apply px-[50px] pt-[36px] pb-[30px] sm:px-[20px] sm:pt-[30px] border-b border-whiteScreen__BorderColor;
  }
  .signup__Card__Header .i__Heading {
    @apply text-[28px] text-black leading-[38px] sm:text-[26px] sm:leading-[36px] font-biotif__Medium mb-[8px] sm:mb-[6px];
  }
  .signup__Card__Header .i__Text {
    @apply text-[16px] text-mediumDark__TextColor leading-[24px] sm:text-[16px] sm:leading-[22px];
  }
  .signup__Card__Body {
    @apply px-[46px] pt-[42px] py-[30px] sm:px-[20px] sm:pt-[30px] sm:pb-[30px];
  }
  .form__Group.full__Radio__Button.question__Radio,
  .form__Group.full__Checkbox.question__Checkbox {
    @apply bg-formField__BGColor rounded-[12px] shadow-none;
  }
  .form__Group.full__Radio__Button.question__Radio .rc__Label,
  .form__Group.full__Checkbox.question__Checkbox .rc__Label {
    @apply py-[18px];
  }
  .form__Group.full__Radio__Button.question__Radio
    .ip__Radio.full__Radio__Button,
  .form__Group.full__Checkbox.question__Checkbox .ip__Checkbox.full__Checkbox {
    @apply shadow-none border-b border-b-greyScreen__BorderColor rounded-none;
  }
  .form__Group.full__Radio__Button.question__Radio
    .ip__Radio.full__Radio__Button:last-child,
  .form__Group.full__Checkbox.question__Checkbox
    .ip__Checkbox.full__Checkbox:last-child {
    @apply border-b-0;
  }

  .roundTick__UL.roundTick__UL__login li{
    @apply before:!content-[""] before:!bg-[#ebebeb] after:!border-l-[#afafaf] after:!border-b-[#afafaf];
  }
  .roundTick__UL.roundTick__UL__login li.active{
    @apply before:!bg-primaryColorSD after:!border-l-[#ffffff] after:!border-b-[#ffffff];
  }
  /* login signup screens css end */

  /* sidebar css start */
  .s__Menu__Item {
    @apply w-full mb-[5px];
  }
  .s__Menu__Item .s__Menu__Item__Link {
    @apply w-full h-[50px] flex items-center duration-[300ms] rounded-[8px] px-[16px] xl:px-[10px] relative hover:bg-parentBgGray__grayBtnBG;
  }
  .s__Menu__Item .s__Menu__Item__Link > .i__Icon {
    @apply absolute top-[10px] right-[15px];
  }
  .s__Menu__Item .s__Menu__Icon {
    @apply w-[28px] h-[28px] flex items-center justify-center p-[4px] relative top-[-2px];
  }
  .s__Menu__Item .s__Menu__Icon .i__Icon {
    @apply p-0;
  }
  .s__Menu__Item .s__Menu__Icon svg path[fill]{
    @apply !fill-primaryColorSD;
  }
  .s__Menu__Item .s__Menu__Icon svg path[stroke]{
    @apply !stroke-primaryColorSD;
  }
  .s__Menu__Item .s__Menu__Icon img {
    @apply duration-500;
  }
  .s__Menu__Item .s__Menu__Icon .animated__icon__btn svg{
    @apply !w-[34px] relative left-[-5px] top-[-5px];
  }
  .s__Menu__Item .s__Menu__Text {
    @apply text-[16px] leading-[16px] text-black__TextColor600 font-biotif__Regular ml-[7px] duration-[500ms] md:inline-block;
  }
  .s__Menu__Item.no__Icon .s__Menu__Item__Link {
    @apply pl-[22px];
  }
  .s__Menu__Item.no__Icon .s__Menu__Text {
    @apply ml-0;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .s__Menu__Icon img,
  .s__Menu__Item .s__Menu__Item__Link:hover > .s__Menu__Icon img {
    @apply brightness-[100];
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .s__Menu__Icon svg path,
  .s__Menu__Item .s__Menu__Item__Link:hover > .s__Menu__Icon svg path {
    @apply fill-primaryColorSD;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link .s__Menu__Text,
  .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Text {
    @apply text-primaryColorSD font-biotif__SemiBold;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .i__Icon svg path,
  .s__Menu__Item:hover > .s__Menu__Item__Link > .i__Icon svg path {
    @apply stroke-primaryColorSD;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .i__Icon {
    @apply scale-y-[-1];
  }
  .s__Menu__Item.active > .s__Menu__Item__Link {
    @apply bg-primaryColorSD;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link .s__Menu__Text {
    @apply text-white;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .s__Menu__Icon svg path {
    @apply fill-white;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link > .i__Icon path {
    @apply stroke-white;
  }
  .s__Submenu__Wrapper {
    @apply bg-[#EEEEEE];
  }
  .s__Submenu__Wrapper .s__Menu__Item {
    @apply mb-0;
  }
  .s__Menu__Item .s__Submenu__Wrapper .s__Menu__Item__Link:hover .s__Menu__Text,
  .s__Menu__Item
    .s__Submenu__Wrapper
    .s__Menu__Item__Link.active
    .s__Menu__Text {
    @apply text-ip__Blue;
  }
  .s__Menu__Item .s__Menu__Item__Link .animated__icon__btn{
    @apply flex items-center justify-start !p-0 !top-0 !w-full !h-full;
  }
  .s__Menu__Item .s__Menu__Item__Link .animated_Icon{
    @apply w-[34px] h-[34px];
  }
  .s__Menu__Item .animated_Icon .s__Menu__Icon{
    @apply !w-full !h-full;
  }
  .s__Menu__Item.active .s__Menu__Icon svg path[fill]{
    @apply !fill-[#ffffff];
  }
  .s__Menu__Item.active .s__Menu__Icon svg path[stroke]{
    @apply !stroke-[#ffffff];
  }
  .s__Menu__Item.logout {
    @apply absolute bottom-0 mb-0;
  }
  .s__Menu__Item.logout .s__Menu__Item__Link {
    @apply !px-[32px] !pl-[32px] bg-parentBgGray__grayBtnBG hover:!bg-parentBgGray__grayBtnBG rounded-none md:!px-[26px];
  }
  .s__Menu__Item.logout .s__Menu__Item__Link:hover > .s__Menu__Icon svg path {
    @apply fill-primaryColor;
  }
  .s__Menu__Item.logout .s__Menu__Icon img {
    @apply !filter-none;
  }
  .s__Menu__Item.logout .s__Menu__Text {
    @apply text-dark__TextColor;
  }

  .sidebar > .sidebar__CollapseBtn svg path{
    @apply fill-primaryColorSD;
  }
  .sidebar > .sidebar__CollapseBtn:hover svg path{
    @apply fill-[#ffffff];
  }

  /* sidebar collapse css start */
  .dashMainWrapper:not(.sidebar__Collapse) .sidebar {
    /* @apply w-[60px]; */
    @apply w-[70px];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Box {
    /* @apply w-[38px] h-[38px]; */
    @apply w-[50px] h-[50px] !bg-transparent;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Box img {
    /* @apply w-[22px]; */
    @apply w-[40px];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Text {
    @apply hidden;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Submenu__Wrapper {
    @apply hidden;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item {
    /* @apply mb-[15px]; */
    @apply mb-[5px];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item:last-child {
    @apply mb-0;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item.logout {
    @apply mb-0;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Text {
    @apply hidden;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Item__Link {
    /* @apply w-[40px] h-[40px] px-0 pt-[3px] flex items-center justify-center; */
    @apply w-[50px] h-[50px] px-0 pt-[0px] flex items-center justify-center;
  }
  .dashMainWrapper:not(.sidebar__Collapse)
    .s__Menu__Item
    .s__Menu__Item__Link:hover
    .tooltip__Wrapper {
    @apply flex;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Icon {
    /* @apply p-0 top-[-2px]; */
    @apply p-0 top-0;
  }
  .dashMainWrapper:not(.sidebar__Collapse)
    .s__Menu__Item
    .s__Menu__Icon
    .i__Icon {
    @apply p-[1px];
  }
  .dashMainWrapper:not(.sidebar__Collapse)
    .s__Menu__Item.logout
    .s__Menu__Item__Link {
    @apply !px-0 !w-full;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .i__Dashboard__Wrapper {
    /* @apply w-[calc(100%_-_60px)]; */
    @apply w-[calc(100%_-_70px)];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .sidebar__CollapseBtn .i__Icon {
    @apply scale-[-1];
  }
  .dashMainWrapper:not(.sidebar__Collapse)
    .s__Menu__Item
    .s__Menu__Item__Link
    > .i__Icon {
    @apply hidden;
  }
  .dashMainWrapper:not(.sidebar__Collapse) .sMenu__Wrapper {
    /* @apply h-[calc(100dvh_-_164px)] xl:h-[calc(100dvh_-_116px)] px-[10px]; */
    @apply h-[calc(100dvh_-_200px)] xl:h-[calc(100dvh_-_116px)] px-[10px];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Item__Link .animated_Icon{
    @apply !w-full !h-full !p-[13px];
  }
  .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Item__Link .animated__icon__btn .animated_Icon{
    @apply !w-full !h-full !p-[10px];
  }
  /* sidebar collapse css end */

  @media screen and (max-width: 767px) {
    .sidebar {
      @apply translate-x-[-110%];
    }
    .sidebar__Collapse .sidebar {
      @apply translate-x-[0px];
    }
    .sidebar__Collapse .sidebar .sidebar__CollapseBtn {
      @apply block rounded-full right-[-16px];
    }
    .sidebar__Collapse .sidebar .sidebar__CollapseBtn .mobile__btn__img {
      @apply hidden;
    }
    .sidebar__Collapse .sidebar .sidebar__CollapseBtn .i__Icon {
      @apply block;
    }
    .dashMainWrapper > .mobile__menu__overllay {
      @apply block z-[-2] opacity-0 duration-100;
    }
    .dashMainWrapper.sidebar__Collapse > .mobile__menu__overllay {
      @apply z-[10] opacity-100;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .sidebar {
      @apply w-[280px];
    }
    .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Box {
      @apply w-[52px] h-[52px];
    }
    .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Box img {
      /* @apply w-[22px]; */
      @apply w-[28px];
    }
    .dashMainWrapper:not(.sidebar__Collapse) .sidebar .logo__Text {
      @apply block;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .s__Submenu__Wrapper {
      @apply hidden;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Text {
      @apply inline-block;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item {
      @apply mb-[5px];
    }
    .dashMainWrapper:not(.sidebar__Collapse)
      .s__Menu__Item
      .s__Menu__Item__Link {
      @apply w-full px-[10px] flex items-center justify-start h-[50px];
    }
    .dashMainWrapper:not(.sidebar__Collapse)
      .s__Menu__Item
      .s__Menu__Item__Link:hover
      .tooltip__Wrapper {
      @apply flex;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .s__Menu__Item .s__Menu__Icon {
      @apply p-[4px] top-[-2px];
    }
    .dashMainWrapper:not(.sidebar__Collapse)
      .s__Menu__Item
      .s__Menu__Icon
      .i__Icon {
      @apply p-0;
    }
    .dashMainWrapper:not(.sidebar__Collapse)
      .s__Menu__Item.logout
      .s__Menu__Item__Link {
      @apply !px-[10px] !w-full;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .i__Dashboard__Wrapper {
      @apply w-full;
    }
    .dashMainWrapper .sidebar__CollapseBtn .i__Icon {
      @apply scale-[-1];
    }
    .dashMainWrapper:not(.sidebar__Collapse)
      .s__Menu__Item
      .s__Menu__Item__Link
      > .i__Icon {
      @apply hidden;
    }
    .dashMainWrapper:not(.sidebar__Collapse) .sMenu__Wrapper {
      @apply h-[calc(100dvh_-_164px)] xl:h-[calc(100dvh_-_116px)];
    }
  }
  @media screen and (max-width: 460px) {
    .icon__libraryBox__wrapper .inner__wrapper > div {
      @apply w-[14.28%];
    }
  }
  /* sidebar css end */

  /* top header css start */
  /* voip call css start */
  .callDialer__btn svg path {
    @apply fill-[#2E3234]/40;
  }
  .callDialer__btn.active {
    @apply !bg-ipSuccessGreen__transparentBG;
  }
  .callDialer__btn:hover svg path {
    @apply fill-ip__SuccessGreen;
  }
  .callDialer__btn.active svg path {
    @apply fill-ip__SuccessGreen;
  }
  .dialer__modal .profile__details .img__wrapper .noName__letter,
  .dialer__modal .profile__details .img__wrapper .img__loader,
  .dialer__modal .profile__details .img__wrapper .img__element,
  .dialer__modal .profile__details .img__wrapper .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .dialer__modal .profile__details .img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .dialer__modal .profile__details .img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .dialer__modal
    .profile__details
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[16px] leading-[28px] pb-[2px] !text-primaryColor;
  }
  .dialer__modal .react-tel-input {
    @apply w-[calc(100%_-_38px)];
  }
  .dialer__modal .react-tel-input .special-label {
    @apply hidden;
  }
  .dialer__modal .react-tel-input .form-control {
    @apply text-[#2E3234] text-[14px] font-biotif__Regular py-[5px] pl-[52px] pr-[8px] !border !border-[#CCCCCC]/50 w-full focus:shadow-none focus:!border focus:!border-black;
  }
  .dialer__modal .react-tel-input .selected-flag:focus:before,
  .dialer__modal .react-tel-input .selected-flag.open:before {
    @apply !shadow-none !border-0 bg-[#f4f4f4];
  }
  .dialer__modal .react-tel-input .country-list .country {
    @apply pt-[7px] pb-[8px] pl-[46px] pr-[9px];
  }
  .dialer__modal .react-tel-input .country-list .flag {
    @apply mt-[-2px];
  }
  .dialer__modal .react-tel-input .country-list {
    @apply m-0 w-[263px];
  }
  .dialer__modal .afterCall__Btn__wrapper button.active {
    @apply bg-primaryColor;
  }
  .dialer__modal .afterCall__Btn__wrapper button.active svg path[fill],
  .dialer__modal .afterCall__Btn__wrapper button.active circle[fill],
  .dialer__modal .afterCall__Btn__wrapper button.active line[fill] {
    @apply fill-white;
  }
  .dialer__modal .afterCall__Btn__wrapper button.active svg path[stroke],
  .dialer__modal .afterCall__Btn__wrapper button.active circle[stroke],
  .dialer__modal .afterCall__Btn__wrapper button.active line[stroke] {
    @apply stroke-white;
  }
  .dialer__modal .afterCall__Btn__wrapper button.cc__btn.active {
    @apply text-white;
  }
  .dialer__modal .callBtn .i__Icon svg path {
    @apply fill-white;
  }
  /* voip call css end */
  

  /* voip incoming call screen css start */
  .incomingCall__screen .button__wrapper path {
    @apply fill-white;
  }
  .incomingCall__screen .button__wrapper .recive__btn {
    @apply -rotate-45;
  }
  .incomingCall__screen .button__wrapper .reject__btn {
    @apply rotate-[135deg];
  }
  /* voip incoming call screen css end */



.add__update__modal .breadcrumbs__wrapper{
  @apply hidden;
}
.add__update__modal .action__fixed__btn__leadEdit,
.add__update__modal .action__fixed__btn__dealEdit,
.add__update__modal .action__fixed__btn__accountEdit,
.add__update__modal .action__fixed__btn__contactEdit{
  @apply justify-end static w-full shadow-none border-t border-t-whiteScreen__BorderColor rounded-b-[12px];
}
.add__update__modal .fixed__wrapper__leadEdit,
.add__update__modal .fixed__wrapper__dealEdit,
.add__update__modal .fixed__wrapper__accountEdit,
.add__update__modal .fixed__wrapper__contactEdit{
  @apply p-[30px] max-h-[calc(100dvh_-_250px)] overflow-y-auto;
}
.add__update__modal .ip__Modal__Body{
  @apply max-h-unset p-0;
}
.add__update__modal .ip__Modal__Footer{
  @apply hidden;
}


  /* notification css start */
  .notification__btn svg path {
    @apply fill-[#2E3234]/40;
  }
  .notification__btn.notification__available svg path {
    @apply fill-primaryColorSD;
  }
  .notification__btn.active {
    @apply !bg-[#E8F1F8];
  }
  .notification__btn:hover svg path,
  .notification__btn.active svg path {
    @apply fill-primaryColorSD;
  }
  .notification__btn.notification__btn__animate svg {
    @apply origin-center;
    animation: notification__icon__animation 1.5s 2;
  }
  @keyframes notification__icon__animation {
    0%,
    25%,
    83%,
    100% {
      transform: rotatez(0deg);
    }
    32.5%,
    62.5% {
      transform: rotatez(-12deg);
    }
    47.5%,
    75.5% {
      transform: rotatez(12deg);
    }
  }
  .notification__header .setting__btn path {
    @apply fill-black/50 duration-300;
  }
  .notification__header .setting__btn:hover path {
    @apply fill-primaryColor;
  }
  .notification__header .back__btn:hover path {
    @apply fill-primaryColor;
  }
  .notification__item .img__wrapper img {
    @apply w-full h-full object-cover object-center rounded-full;
  }
  .notification__item .img__wrapper .noName__letter,
  .notification__item .img__wrapper .img__loader,
  .notification__item .img__wrapper .img__element,
  .notification__item .img__wrapper .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .notification__item .img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .notification__item .img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .notification__item .img__wrapper .noName__letter .noName__letter__text {
    @apply text-[16px] leading-[28px] pb-[2px] !text-primaryColor;
  }
  .notification__item:last-child {
    @apply border-b-0;
  }
  .notification__item.unread {
    @apply bg-[#F8F8F8];
  }
  .notification__item .custom__checkbox input:checked + .custom__checkbox__DE {
    @apply bg-primaryColor border-primaryColor before:opacity-100;
  }
  .notification__item .more__account__wrapper .img__wrapper img {
    @apply w-full h-full object-cover object-center rounded-full;
  }
  .notification__item .more__account__wrapper .img__wrapper .noName__letter,
  .notification__item .more__account__wrapper .img__wrapper .img__loader,
  .notification__item .more__account__wrapper .img__wrapper .img__element,
  .notification__item .more__account__wrapper .img__wrapper .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .notification__item .more__account__wrapper .img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .notification__item .more__account__wrapper .img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .notification__item
    .more__account__wrapper
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[16px] leading-[28px] pb-[2px] !text-primaryColor;
  }

  .following__body .item .fol__icon path[fill] {
    @apply fill-primaryColor;
  }
  .following__body .item .fol__icon path[stroke] {
    @apply stroke-primaryColor;
  }

  .permission__box input:hover + span {
    @apply bg-[#eee];
  }
  .permission__box input:checked + span {
    @apply bg-primaryColor text-white hover:bg-primaryColor hover:text-white border-r-[1px] border-r-white;
  }
  .permission__box:last-child .text {
    @apply !border-r-0;
  }

  html.notification__open{
    @apply overflow-hidden;
  }
  /* html.notification__open .sidebar{
    @apply z-0;
  } */
  .notification__modal{
    @apply translate-x-[110%] !left-unset pl-[56px] duration-500;
  }
  
  html.notification__open .notification__modal{
    @apply translate-x-0;
  }
  /* notification css end */

  /* personal settings / company details edit page css start */
  .personalDetails__address__fields .field__box__wrapper,
  .companyDetails__address__fields .field__box__wrapper {
    @apply mx-[-30px] xl:mx-[-15px] lg:mx-[-10px];
  }
  .personalDetails__address__fields .field__box__wrapper .field__box,
  .companyDetails__address__fields .field__box__wrapper .field__box {
    @apply px-[30px] xl:px-[15px] lg:px-[10px];
  }

  .fixed__wrapper__CompanySettings .companyDetails__address__fields {
    @apply mb-0;
  }
  /* personal settings / company details edit page css end */

  /* header create lead,contact,account & deal dropdown css start */
  /* .header__quick__wrapper:hover .add__dropdown__menu {
    @apply block;
  } */
  /* header create lead,contact,account & deal dropdown css end */

  /* profile css start */
  .header__quick__wrapper .i__Button .i__Icon {
    @apply mr-0 ml-0 p-[2px];
  }
  .topH__profile__wrapper .noName__letter,
  .topH__profile__wrapper .img__loader,
  .topH__profile__wrapper .img__element,
  .topH__profile__wrapper .no__img__icon {
    @apply w-full h-full rounded-[8px];
  }
  .topH__profile__wrapper .img__loader {
    @apply sm:bg-white;
  }
  .topH__profile__wrapper .img__element {
    @apply object-cover object-center;
  }
  .topH__profile__wrapper .noName__letter .noName__letter__text {
    @apply text-[17px] leading-[40px] pt-[2px];
  }
  .topH__profile__wrapper .no__img__icon .i__Icon {
    @apply p-[10px];
  }
  .topH__profile__wrapper:hover .add__dropdown__menu {
    @apply block;
  }

  .profile__btn .i__Icon {
    @apply p-0 ml-0 mr-[4px] w-[18px] h-[18px];
  }
  .profile__btn .i__Icon path {
    @apply fill-white;
  }
  /* profile css end */
  /* top header css end */

  /* dashboard details card css start */
  .ip__Counter__Box {
    @apply w-full bg-lightGray__BgColor rounded-[12px] py-[24px] px-[18px];
  }
  .ip__Counter__Box .ip__Counter__Header {
    @apply flex items-center justify-between;
  }
  .ip__Counter__Box .ip__Counter__Header .ip__Counter__Title {
    @apply text-black font-biotif__Bold text-[18px] w-[calc(100%_-_32px)] pr-[15px];
  }
  .ip__Counter__Box .ip__Counter__Header .ip__Counter__PlusBtn {
    @apply w-[30px] h-[30px] flex items-center justify-center bg-black rounded-full;
  }
  .ip__Counter__Box .ip__Counter__Number {
    @apply text-mediumDark__TextColor text-[60px] leading-[60px] font-biotif__Regular mt-[18px] mb-[10px];
  }
  .ip__Counter__Box button.ip__Counter__Preview__Drop {
    @apply relative z-[2] py-[5px] px-[10px] rounded-[10px] duration-500 ml-[-10px] mb-[-10px] mt-[-5px] hover:bg-ipWhite__bgColor;
  }
  .ip__Counter__Box .ip__Counter__Preview__Drop {
    @apply flex items-center;
  }
  .ip__Counter__Box .ip__Counter__Preview__Drop .i__Icon {
    @apply w-[17px] h-[17px] ml-[2px];
  }
  .ip__Counter__Box .ip__Counter__Preview__Drop .i__Icon svg path {
    @apply stroke-light__TextColor;
  }
  .ip__Counter__Box .ip__Counter__Preview__Drop .text {
    @apply text-light__TextColor text-[14px] font-biotif__Medium;
  }
  /* dashboard details card css end */

  /* dashboard Past,Upcoming & Today's Activities css start */
  .upcomingActivities__wrapper .header .acType__btn {
    @apply duration-500 hover:bg-primaryColor hover:text-white;
  }
  .upcomingActivities__wrapper .header .i__Icon {
    @apply duration-500 hover:bg-primaryColor;
  }
  .upcomingActivities__wrapper .header .i__Icon:hover svg path {
    @apply fill-white;
  }
  .upcomingActivities__wrapper
    .dashboard__upcoming__act__date__filter
    .e-input-group {
    @apply w-[34px] h-[32px] opacity-0 relative overflow-hidden z-[3];
  }
  .upcomingActivities__wrapper
    .dashboard__upcoming__act__date__filter
    .e-input-group
    .e-input-group-icon {
    @apply absolute top-0 left-0 w-full h-full min-w-full min-h-full box-border !p-0 z-[3];
  }
  .upcomingActivities__wrapper
    .dashboard__upcoming__act__date__filter
    .e-input-group:hover
    + .i__Icon {
    @apply !bg-primaryColor;
  }
  .upcomingActivities__wrapper
    .dashboard__upcoming__act__date__filter
    .e-input-group:hover
    + .i__Icon
    path {
    @apply !fill-white;
  }
  .up__ac__timeline__box .cn__text .i__Icon svg path[fill] {
    @apply fill-white;
  }
  .up__ac__timeline__box .cn__text .i__Icon svg path[white] {
    @apply stroke-white;
  }
  .up__ac__timeline__box .cn__text .due__sign .i__Icon svg path[fill] {
    @apply fill-[#FF4F3C];
  }
  .up__ac__timeline__box .cn__text .due__sign .i__Icon svg circle[stroke] {
    @apply stroke-ip__Red;
  }
  .up__ac__timeline__box .toggle__btn svg path {
    @apply fill-black/50;
  }
  .up__ac__timeline__box:last-child {
    @apply border-b-0;
  }
  .up__ac__timeline__box:last-child
    .up__ac__item:nth-last-child(1)
    .add__dropdown__menu,
  .up__ac__timeline__box:last-child
    .up__ac__item:nth-last-child(2)
    .add__dropdown__menu,
  .up__ac__timeline__box:last-child
    .up__ac__item:nth-last-child(3)
    .add__dropdown__menu {
    @apply top-unset bottom-[calc(100%_+_5px)];
  }
  .up__ac__timeline__box:first-child
    .up__ac__item:nth-last-child(3)
    .add__dropdown__menu {
    @apply top-[calc(100%_+_5px)];
  }
  .up__ac__timeline__box:first-child
    .up__ac__item:nth-child(1)
    .add__dropdown__menu,
  .up__ac__timeline__box:first-child
    .up__ac__item:nth-child(2)
    .add__dropdown__menu,
  .up__ac__timeline__box:first-child
    .up__ac__item:nth-child(3)
    .add__dropdown__menu {
    @apply top-[calc(100%_+_5px)];
  }
  .up__ac__timeline__box .main__day__title + .inner__day__sub__title {
    @apply !mt-0;
  }
  .up__ac__item .toggleBtn svg circle {
    @apply fill-black/40;
  }
  .up__ac__item .toggleBtn:hover svg circle {
    @apply fill-ipBlack__textColor;
  }
  .up__ac__item .cn__text .img__element {
    @apply w-[28px] h-[28px] rounded-[6px];
  }
  .up__ac__item .cn__text .img__loader {
    @apply w-[28px] h-[28px] rounded-[6px];
  }
  .up__ac__item.up__ac__item__done .text__inside {
    @apply relative before:content-[""] before:absolute before:top-[calc(50%_-_1px)] before:translate-y-[-50%] before:h-[1px] before:bg-black/50 before:w-full;
  }
  .up__ac__item.up__ac__item__done .time {
    @apply before:bg-[#24BD64];
  }
  .up__ac__item.up__ac__item__due .time {
    @apply before:bg-[#FF0000];
  }
  .up__ac__item.up__ac__item__upcoming .time {
    @apply before:bg-primaryColor;
  }
  .up__ac__item .cn__text .i__Icon:nth-child(1) svg path[fill]{
    @apply fill-[#ffffff];
  }
  .up__ac__item .cn__text .i__Icon:nth-child(1) svg path[stroke]{
    @apply stroke-[#ffffff];
  }
  .tippy-box.tippy__dropdown.tippy__dashboard__ActivityType {
    @apply !w-[160px] !max-w-[160px];
  }
  .tippy__dashboard__ActivityType .tippy-content {
    @apply max-h-[200px] overflow-y-auto;
  }
  .tippy__dashboard__ActivityType .item__text {
    @apply w-full;
  }
  /* dashboard Past,Upcoming & Today's Activities css end */

  /* dashboard recent logs css start */
  .rc__ActivityLogs .rc__ac__item:last-child {
    @apply border-b-0;
  }
  .rc__ActivityLogs .rc__ac__item .img__wrapper img {
    @apply w-full h-full object-cover object-center rounded-[6px];
  }
  .rc__ActivityLogs .rc__ac__item .img__wrapper .noName__letter,
  .rc__ActivityLogs .rc__ac__item .img__wrapper .img__loader,
  .rc__ActivityLogs .rc__ac__item .img__wrapper .img__element,
  .rc__ActivityLogs .rc__ac__item .img__wrapper .no__img__icon {
    @apply w-full h-full rounded-[6px];
  }
  .rc__ActivityLogs .rc__ac__item .img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .rc__ActivityLogs .rc__ac__item .img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .rc__ActivityLogs
    .rc__ac__item
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[14px] leading-[28px] pt-[4px];
  }
  .rc__ActivityLogs .timeline__contant__wrapper{
    @apply mt-[8px] mb-[8px] !p-[14px] !pr-[52px] !rounded-[10px] shadow-[1px_1px_3px_0px_#ececece6_inset,_-1px_-1px_2px_0px_#ffffffe6_inset,_1px_-1px_2px_0px_#ececec33_inset,_-1px_1px_2px_0px_#ececec33_inset,_-1px_-1px_2px_0px_#ececec80,_1px_1px_2px_0px_#ffffff4d];
  }
  /* dashboard recent logs css end */

  /* dashboard deal revenue forecast css start */
  .deal__revenue__forecast .highcharts-title {
    @apply hidden;
  }
  .deal__revenue__forecast
    .companyGoals__wrapper
    .companyGoals__box:last-child {
    @apply mb-0;
  }
  /* dashboard deal revenue forecast css end */

  /* dashboard activities revenue css start */
  .activities__revenue .highcharts-title {
    @apply !text-[14px] font-biotif__Medium text-ipBlack__textColor;
  }
  .activities__revenue .highcharts-legend-item text {
    @apply !text-[14px] !font-normal font-biotif__Medium !text-ipBlack__textColor;
  }
  .activities__revenue .highcharts-legend-item .highcharts-point {
    @apply translate-y-[-2px];
  }
  /* dashboard activities revenue css end */

  /* quick view activity modal css start */
  .quickView__activity__modal .activityRR__details {
    @apply w-[270px] xl:mt-0 md:mt-[14px] md:w-full;
  }
  .quickView__activity__modal .main__details {
    @apply w-[calc(100%_-_270px)] md:w-full;
  }
  .quickView__activity__modal .detailsPage__action__breadcrumbs {
    @apply justify-end;
  }
  .quickView__activity__modal .meetingCN__box {
    @apply justify-start pr-0;
  }
  .quickView__activity__modal .meetingCN__box > div {
    @apply mr-0 pr-0 before:hidden md:mr-[7px] md:pr-[7px] md:before:block;
  }
  .quickView__activity__modal .meetingCN__box > div:nth-child(2) {
    @apply mr-[8px] pr-[8px] before:!block;
  }
  .quickView__activity__modal .meetingCN__box > div:nth-child(1) {
    @apply mr-[4px] pr-[3px] md:mr-[8px] md:pr-[8px];
  }
  .quickView__activity__modal .meetingCN__box > div:nth-child(4) {
    @apply mr-[8px] pr-[8px] before:block;
  }
  .quickView__activity__modal .details__RelatedContact__row {
    @apply min-w-[730px];
  }
  .quickView__activity__modal
    .details__RelatedContact__body.details__Guests
    .details__RelatedContact__cell.details__cell,
  .quickView__activity__modal
    .details__RelatedContact__body.details__Guests
    .details__RelatedContact__cell.phone__cell {
    @apply w-[calc(50%_-_63px)];
  }
  .quickView__activity__modal
    .details__RelatedContact__body.details__collaborators
    .details__RelatedContact__cell {
    @apply w-[50%] max-w-[50%];
  }
  .quickView__activity__modal .customFields__wrapper .inner__width {
    @apply w-[470px] max-w-full;
  }
  .quickView__activity__modal .ip__nextStep__box {
    @apply max-w-[50%];
  }
  .quickView__activity__modal .guest__card,
  .quickView__activity__modal .related__contact__card,
  .quickView__activity__modal .follower__card {
    @apply w-1/2 sm:w-full;
  }
  .quickView__activity__modal .detailsPageNew {
    @apply sm:pb-0;
  }
  .quickView__activity__modal .details__page__sticky__btns {
    @apply sm:hidden;
  }
  .quickView__activity__modal .followers__listing {
    @apply sm:mb-[10px];
  }
  .ip__Modal__Wrapper.quickView__activity__modal .ip__Modal__Body {
    @apply sm:max-h-[calc(100dvh_-_180px)];
  }
  .quickView__activity__modal .activityCall__wrapper .i__Button {
    @apply md:px-[12px];
  }
  .quickView__activity__modal .schedule__followUP__wrapper .i__Button,
  .quickView__activity__modal .action__bar > button:nth-child(2),
  .quickView__activity__modal .action__bar > button:nth-child(4) {
    @apply md:px-[12px];
  }
  .quickView__activity__modal .copyMeetingLink {
    @apply !justify-start;
  }
  /* quick view activity modal css end */

  /* create activity modal css start */
  .createActivityModal.ip__Modal__Wrapper .ip__Modal__Header .i__Icon {
    @apply relative z-[3] hover:bg-[#ffffff];
  }
  .createActivityModal.ip__Modal__Wrapper .ip__Modal__Body {
    @apply max-h-unset pr-0 overflow-y-visible static;
  }
  .createActivityModal.createActivityModal__leading.ip__Modal__Wrapper
    .ip__Modal__Body {
    @apply !h-[calc(100dvh_-_250px)] !max-h-[calc(100dvh_-_250px)] overflow-y-hidden;
  }
  .createActivityModal .schedule__activity__contant__wrapper .right {
    @apply absolute top-0 right-0 h-full bg-[#FEEDDB] rounded-r-[12px] overflow-hidden duration-500 sm:rounded-l-[12px] sm:translate-x-[110%];
  }
  .e-tbar-btn.e-btn:hover
    .createActivityModal
    .schedule__activity__contant__wrapper
    .right
    .calendar__title {
    @apply border-b border-b-[#0000001a] pt-[25px] pr-[50px] pb-[24px] pl-[20px];
  }
  .createActivityModal .schedule__activity__contant__wrapper .right.show {
    @apply sm:translate-x-0;
  }
  .createActivityModal
    .schedule__activity__contant__wrapper
    .right
    .calendar__title {
    @apply pt-[25px] pb-[24px] pr-[50px] pl-[20px] border-b border-b-[#0000001a] sm:pt-[17px] sm:pb-[18px];
  }
  .createActivityModal
    .e-spinner-pane
    .e-spinner-inner
    .e-spin-tailwind
    .e-path-circle {
    @apply stroke-[#ffffff];
  }
  .createActivityModal
    .e-spinner-pane
    .e-spinner-inner
    .e-spin-tailwind
    .e-path-arc {
    @apply stroke-ip__Orange;
  }
  .createActivityModal.ip__Modal__Wrapper .ip__Modal__Footer {
    @apply justify-start;
  }
  .createActivityModal .e-schedule {
    @apply bg-transparent !h-[calc(100%_-_74px)];
  }
  .createActivityModal .e-header-cells.e-disable-dates {
    @apply hidden;
  }
  body .createActivityModal .activity__timing__wrapper .e-input-group.e-time-wrapper input.e-input{
    @apply !font-normal !font-biotif__Medium !text-sdNormal__textColor !pt-[13px];
  }
 /* .createActivityModal
    .activity__timing__wrapper
    > div:nth-child(2)
    .e-input-group {
    @apply hidden;
  }
  .createActivityModal
    .activity__timing__wrapper
    > div:nth-child(2)
    button
    .if__label {
    @apply opacity-0 md:hidden;
  }
  .createActivityModal .activity__timing__wrapper > div:nth-child(2) button {
    @apply md:w-full;
  }
  .createActivityModal
    .activity__timing__wrapper
    > div:nth-child(2)
    button
    .form__Group {
    @apply md:mb-[5px];
  }
  .createActivityModal .activity__timing__wrapper .duration .ip__Error {
    @apply whitespace-pre;
  }
  .createActivityModal
    .activity__timing__wrapper
    .duration
    .form__Group
    .if__label {
    @apply hidden;
  } */
  .createActivityModal .ip__Modal__Footer {
    @apply w-[calc(100%_-_332px)] !justify-end items-center;
  }
  .createActivityModal .ip__Modal__Footer .i__Button {
    @apply !mr-[10px] last:!mr-[0px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-week-view
    .e-work-cells,
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-day-view
    .e-work-cells {
    @apply bg-transparent border-r-[#0000000d] border-b-[#0000000d];
  }
  body
    .createActivityModal
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap {
    @apply border border-[#0000000d];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-week-view
    .e-work-cells:hover,
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-day-view
    .e-work-cells:hover {
    @apply bg-[#f9e4cf];
  }
  body
    .createActivityModal
    .e-toolbar
    .e-toolbar-items.e-tbar-pos
    .e-toolbar-right {
    @apply hidden;
  }
  body
    .createActivityModal
    .e-toolbar
    .e-toolbar-items
    .e-toolbar-item.e-date-range {
    @apply hidden;
  }
  body
    .createActivityModal
    .e-toolbar
    .e-toolbar-items
    .e-toolbar-item
    .e-tbar-btn.e-btn {
    @apply p-0 !pt-[2px] border-0 !h-[26px] !w-[26px] !rounded-full bg-transparent min-w-[0px] min-h-[0px] hover:!p-0 hover:bg-[#ffffff] focus:bg-[#ffffff] focus:!p-0;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent,
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:nth-child(2)
    > td:nth-child(1) {
    @apply w-[80px] !important;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td {
    /* @apply relative left-[-63px] pb-[32px]; */
    @apply relative left-[-63px] pb-[10px];
  }
  body .createActivityModal .e-schedule .e-vertical-view .e-date-header-wrap {
    overflow: visible;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead {
    @apply relative top-[18px] !h-[26px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-all-day-appointment-wrapper {
    @apply !flex !h-auto;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment {
    /* @apply !border-b-0 left-[63px] !border-l-0 !bg-[#1776ba1a] !rounded-[4px]; */
    @apply !border-b-0 !border-l-0 !bg-[#1776ba1a] !rounded-[4px] !static !w-full mr-[5px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment.e-appointment-hide {
    @apply !hidden;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment:not(.e-read-only) {
    @apply !bg-ipSuccessGreen__transparentBG;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment:not(.e-read-only)
    .header__title
    > div {
    @apply !bg-ip__SuccessGreen;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    .e-schedule-table
    tbody {
    @apply relative top-[-28px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-touch
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment {
    @apply left-[40px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment
    .e-appointment-details {
    @apply !p-0 !items-center;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment
    .e-appointment-details
    > div {
    @apply w-full;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment
    .e-appointment-details
    .i__Icon
    path[fill] {
    @apply fill-[#ffffff];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    > tr:first-child
    .e-left-indent
    + td
    thead
    .e-appointment
    .e-appointment-details
    .i__Iconpath[stroke] {
    @apply stroke-[#ffffff];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-schedule-table
    > tbody
    .e-appointment
    .e-appointment-details
    .img__element {
    @apply w-[20px] h-[20px] rounded-[4px] top-0;
  }
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells {
    @apply !h-[19px];
  }
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-current-day {
    @apply bg-transparent p-0 h-auto flex justify-start !rounded-[0px];
  }
  body
    .createActivityModal
    .e-week-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-day,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-day {
    @apply text-[14px];
  }
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-day,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-date,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells.e-current-day
    .e-header-day,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells.e-current-day
    .e-header-date {
    @apply text-ip__black__text__color !w-auto;
  }
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells.e-current-day
    .e-header-day,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-day {
    @apply mr-[5px] !pt-0 !text-ipBlack__textColor;
  }
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells.e-current-day
    .e-header-date,
  body
    .createActivityModal
    .e-day-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-date {
    @apply !text-ipBlack__textColor;
  }
  body .createActivityModal .e-schedule-toolbar-container {
    @apply absolute top-[2px] right-[20px] h-[36px] z-[3];
  }
  body .createActivityModal .e-table-container {
    @apply pt-[15px];
  }
  body .createActivityModal .e-time-cells-wrap {
    @apply pl-[15px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-week-view
    .e-time-cells-wrap
    .e-current-time,
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view.e-day-view
    .e-time-cells-wrap
    .e-current-time {
    @apply text-ip__Orange pl-[16px] sm:pl-[15px];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment {
    @apply !bg-[#1776ba1a] !border-b-0;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment
    .e-appointment-details
    .header__title
    path {
    @apply fill-[#ffffff];
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment:not(.e-read-only) {
    @apply !bg-ipSuccessGreen__transparentBG !border-l-[2px] !border-l-ip__SuccessGreen;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment:not(.e-read-only)
    .header__title
    > div:first-child {
    @apply !bg-ip__SuccessGreen;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment:not(.e-read-only)
    .activity__calendar__time {
    @apply !text-ip__SuccessGreen;
  }
  body
    .createActivityModal
    .e-schedule
    .e-vertical-view
    .e-day-wrapper
    .e-appointment:not(.e-read-only)
    .activity__calendar__time
    > span:nth-child(2) {
    @apply bg-ip__SuccessGreen before:border-t-ip__SuccessGreen before:border-r-ip__SuccessGreen;
  }
  body .createActivityModal .e-day-view .e-date-header-wrap thead {
    @apply table-header-group;
  }
  body .createActivityModal .rc__Label {
    @apply before:border-[2px] before:border-[#2E3234]/50;
  }
  body .createActivityModal .if__label .icon__wrapper .i__Icon{
    @apply !w-[30px] !h-[30px] !mr-0 p-[4px];
  }
  body .createActivityModal .if__label{
    @apply flex items-center; 
  }
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg path[fill],
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg circle[fill],
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg rect[fill]{
    @apply fill-primaryColor;
  }
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg path[stroke],
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg circle[stroke],
  body .createActivityModal .if__label .icon__wrapper .i__Icon svg rect[strokefill]{
    @apply stroke-primaryColor;
  }
  .tippy-box.tippy__dropdown.activityType__tippy {
    @apply w-[200px] max-w-[200px] !shadow-[0px_3px_17px_#0000001a] relative translate-y-[-4px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-10 before:rounded-[10px];
  }
  .tippy-box.tippy__dropdown.activityType__tippy .item {
    @apply flex flex-wrap items-center before:!bg-primaryColor before:opacity-20;
  }
  .tippy-box.tippy__dropdown.activityType__tippy .img__wrapper {
    @apply !p-0 !bg-transparent;
  }
  .tippy-box.tippy__dropdown.activityType__tippy .i__Icon {
    @apply !w-full !h-full !p-[3px] !bg-transparent;
  }
  .tippy-box.tippy__dropdown.activityType__tippy .i__Icon svg path[fill],
  .activityType__dropdown__btn .selected__wrapper .i__Icon svg path[fill] {
    @apply fill-primaryColor;
  }
  .tippy-box.tippy__dropdown.activityType__tippy .i__Icon svg path[stroke],
  .activityType__dropdown__btn .selected__wrapper .i__Icon svg path[stroke] {
    @apply stroke-primaryColor;
  }
  .activityType__dropdown__btn .img__loader,
  .activityType__dropdown__btn .img__element {
    @apply w-[25px] h-[25px] rounded-[7px];
  }
  .tippy-box.tippy__dropdown.activityType__tippy .i__Icon.img__element {
    @apply !rounded-[8px];
  }
  .tippy-box.tippy__dropdown.activityType__tippy .items {
    @apply max-h-[233px] overflow-y-auto relative z-[2];
  }

  .schedule__activity__contant__wrapper .buttons__wrapper .button__cs .i__Icon {
    @apply bg-ipBlue__transparentBG;
  }
  .schedule__activity__contant__wrapper
    .buttons__wrapper
    .button__cs.active
    .i__Icon,
  .schedule__activity__contant__wrapper
    .buttons__wrapper
    .button__cs:hover
    .i__Icon {
    @apply bg-ip__Blue;
  }
  .schedule__activity__contant__wrapper
    .buttons__wrapper
    .button__cs.active
    svg
    path,
  .schedule__activity__contant__wrapper
    .buttons__wrapper
    .button__cs:hover
    svg
    path {
    @apply fill-white;
  }
  .schedule__activity__contant__wrapper
    .add__box
    .links__wrapper
    .item:last-child {
    @apply pr-0 mr-0 border-r-0 before:hidden;
  }
  .schedule__activity__contant__wrapper .createMeeting__btn .i__Icon path,
  .schedule__activity__contant__wrapper .updateMeeting__btn .i__Icon path,
  .schedule__activity__contant__wrapper .deleteMeeting__btn .i__Icon path,
  .schedule__activity__contant__wrapper .copyMeeting__btn .i__Icon path {
    @apply fill-[#ffffff];
  }
  .schedule__activity__contant__wrapper .copyMeeting__btn .i__Icon {
    @apply p-0;
  }
  .schedule__activity__contant__wrapper
    .withoutIcon__datepicker
    .ip__react__datepicker
    input {
    @apply bg-none pr-[13px];
  }
  .schedule__activity__contant__wrapper .e-input-group.e-time-wrapper {
    @apply relative;
  }
  .schedule__activity__contant__wrapper
    .e-input-group.e-time-wrapper
    .e-time-icon {
    @apply w-[20px] h-[20px] p-0 absolute top-[49%] translate-y-[-50%] right-[5px];
  }
  .schedule__activity__contant__wrapper
    .e-input-group.e-time-wrapper
    .e-clear-icon {
    @apply absolute top-[calc(50%_-_1px)] translate-y-[-50%] right-[18px];
  }
  .schedule__activity__contant__wrapper .if__label__blue svg path[fill] {
    @apply fill-primaryColor;
  }
  .schedule__activity__contant__wrapper .if__label__blue svg path[stroke] {
    @apply stroke-primaryColor;
  }
  .schedule__activity__contant__wrapper
    .if__label__blue
    .status__icon
    svg
    rect {
    @apply fill-white;
  }
  /* create activity modal css end */

  /* ag grid top action header css start */
  .page__ActionHeader {
    @apply flex justify-end items-center mb-[25px] md:mb-[17px];
  }
  .page__ActionHeader .page__Title {
    @apply text-dark__TextColor text-[28px] font-biotif__Medium md:text-[22px] md:leading-[26px];
  }
  .page__ActionHeader .editLayout__Btn {
    @apply py-[7px] pb-[5px] px-[20px];
  }
  .page__ActionHeader .delete__Btn .i__Icon {
    @apply !m-0;
  }
  .page__ActionHeader .delete__Btn .i__Icon path {
    @apply fill-white;
  }
  /* ag grid top action header css end */

  /* ag grid infinite scroll table css start */
  .ag__grid__infinite__scroll .ag-header {
    @apply bg-transparent border-0;
  }
  .ag__grid__infinite__scroll .ag-root-wrapper {
    @apply border-0;
  }
  .ag__grid__infinite__scroll .ag-header-cell .ag-cell-label-container {
    @apply p-0 justify-end;
  }
  .ag__grid__infinite__scroll
    .ag-header-cell
    .ag-cell-label-container
    .ag-header-cell-label {
    @apply flex-none mr-[5px];
  }
  .ag__grid__infinite__scroll .ag-header-cell-text {
    @apply text-black font-biotif__Medium font-normal text-[14px] !block;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row{
    @apply bg-white rounded-[12px];
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-focus {
    @apply z-[2] !shadow-none;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row,
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus{
    @apply border-0 !bg-transparent hover:!bg-transparent before:!bg-transparent !bg-none;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row:last-child,
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus:last-child{
    @apply !mb-0;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row:before,
  .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus:before{
    background-image: unset !important;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-cell {
    @apply text-black font-biotif__Regular !border-0 pt-[5px] pb-[5px] !flex items-center leading-normal !overflow-visible;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-cell p {
    @apply leading-[21px] h-[21px];
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.ag-cell-wrap-text p{
    @apply h-auto;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-cell .cell__value__wrapper {
    @apply w-full;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .ag-cell:not(.ag-cell-wrap-text) .value {
    @apply max-w-full whitespace-pre overflow-hidden text-ellipsis;
  }
  .ag__grid__infinite__scroll .ag-paging-panel {
    @apply border-t-0 justify-between;
  }
  .ag__grid__infinite__scroll
    .ag-paging-panel
    .ag-paging-row-summary-panel
    span,
  .ag__grid__infinite__scroll
    .ag-paging-panel
    .ag-paging-row-summary-panel
    div {
    @apply font-biotif__Regular;
  }
  .ag__grid__infinite__scroll .ag-checkbox {
    @apply mt-[-1px] relative;
  }
  .ag__grid__infinite__scroll .ag-selection-checkbox {
    @apply !mr-[25px] xl:!mr-[10px];
  }
  .ag__grid__infinite__scroll .ag-checkbox-input {
    @apply absolute top-0 left-0 w-full h-full;
  }
  .ag__grid__infinite__scroll .agGrid__customInput {
    @apply w-[16px] h-[16px] mr-0 accent-black;
  }
  .ag__grid__infinite__scroll .agGrid__customInput ~ .cell__value__wrapper {
    @apply w-[calc(100%_-_26px)];
  }
  .ag__grid__infinite__scroll .ag-body-viewport .agGrid__customInput {
    @apply relative;
  }
  .ag__grid__infinite__scroll .ag-checkbox-input-wrapper {
    @apply !shadow-none !outline-0 !border-0;
  }
  .ag__grid__infinite__scroll .ag-checkbox-input-wrapper.ag-checked:after {
    @apply text-black;
  }
  .ag__grid__infinite__scroll .ag-body-viewport .adm__link {
    @apply text-ip__Blue hover:underline;
  }
  .ag__grid__infinite__scroll .ag__grid__toggle__btn {
    @apply p-0 w-[28px] h-[28px] flex items-center justify-center bg-transparent hover:bg-secondary__Btn__BGColor;
  }
  .ag__grid__infinite__scroll .ag__grid__toggle__btn.active {
    @apply bg-secondary__Btn__BGColor;
  }
  .ag__grid__infinite__scroll .ag__grid__toggle__btn .i__Icon {
    @apply m-0 p-[5px];
  }
  .ag__grid__infinite__scroll .ag__grid__toggle__btn.disabled__btn {
    @apply pointer-events-none opacity-50;
  }
  .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell:last-child
    .ag__grid__toggle__btn {
    @apply ml-[7px];
  }
  .ag__grid__infinite__scroll .badge {
    @apply py-[2px];
  }
  .ag__grid__infinite__scroll
    .rowLink__visible
    .ag-body-viewport
    .ag-row:hover {
    @apply hover:!bg-ipGray__transparentBG;
  }
  .ag__grid__infinite__scroll.skeleton__active .ag-body-viewport .ag-row {
    @apply !h-auto;
  }
  .ag__grid__infinite__scroll.skeleton__active .ag-full-width-container {
    @apply !h-auto;
  }
  .ag__grid__infinite__scroll.skeleton__active .ag-body-viewport {
    @apply min-h-[450px];
  }
  .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell.ag__grid__cell__ellipse:not(.ag-cell-wrap-text) {
    @apply !text-ellipsis !whitespace-pre cursor-pointer;
  }
  .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell.ag__grid__cell__ellipse
    .ag-cell-wrapper {
    @apply w-full;
  }
  .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell.ag__grid__cell__ellipse:not(.ag-cell-wrap-text)
    .ag-cell-wrapper
    .value {
    @apply w-full overflow-hidden text-ellipsis;
  }
  .ag__grid__infinite__scroll .ag-header .ag-sort-indicator-icon .ag-icon {
    @apply text-black;
  }
  .ag__grid__infinite__scroll
    .ag-header-icon.ag-header-cell-menu-button
    .ag-icon-menu:before {
    @apply text-black text-[22px] relative top-[2px];
  }
  .ag__grid__infinite__scroll .ag-sort-indicator-icon {
    @apply relative top-[-1px] grayscale brightness-[1000];
  }
  .ag__grid__infinite__scroll .ag-icon-filter:before {
    @apply text-light__TextColor text-[16px] relative top-[-4px];
  }
  .ag__grid__infinite__scroll .ag-header-icon.ag-filter-icon {
    @apply relative top-[2px];
  }
  .ag__grid__infinite__scroll .ag-overlay-wrapper {
    @apply !pt-0;
  }
  .ag__grid__infinite__scroll .ag-layout-auto-height.ag-root {
    @apply z-[3];
  }
  /* ag grid infinite scroll table css end */

  /* ag grid infinite scroll table css for mobile start */
  .agGrid__infinite__box .ag__grid__toggle__btn {
    @apply absolute top-[10px] right-[6px] w-[28px] h-[28px] flex items-center justify-center bg-transparent p-0 duration-500 hover:bg-secondary__Btn__BGColor;
  }
  .agGrid__infinite__box .ag__grid__toggle__btn .i__Icon {
    @apply p-[5px] m-0;
  }
  .agGrid__infinite__box .badge__wrapper {
    @apply max-w-full flex;
  }
  .agGrid__infinite__box .badge {
    @apply mb-0 py-[3px] px-[12px] whitespace-pre overflow-hidden text-ellipsis max-w-full inline-block;
  }
  .agGrid__infinite__box:last-child {
    @apply mb-0;
  }
  .agGrid__infinite__box .accent-primaryColor {
    @apply hidden;
  }
  .agGrid__infinite__box .agGrid__customInput {
    @apply w-[15px] h-[15px] absolute top-[15px] right-[40px];
  }
  .agGrid__infinite__box .ag__ib__row:last-of-type {
    @apply mb-0;
  }
  .agGrid__infinite__box .viewBtn {
    @apply right-[26px];
  }
  .agGrid__infinite__box .value {
    @apply whitespace-pre overflow-hidden text-ellipsis max-w-full;
  }

  /* listing page mobile filter css start */
  .tippy__dropdown.tippy__days__filter__mobile {
    @apply bg-white !shadow-[0px_3px_17px_#0000001a] rounded-[6px] !w-[195px] p-[10px] !translate-y-0;
  }
  .tippy__dropdown.tippy__days__filter__mobile .form__Group {
    @apply mb-[16px] last:mb-0;
  }
  .tippy__dropdown.tippy__days__filter__mobile .ip__Radio .rc__Label {
    @apply text-[16px] text-[#76797B] font-biotif__Regular p-0 pl-[22px] before:w-[15px] before:h-[15px] before:border-[#76797B] before:rounded-[2px] before:left-0 before:right-unset before:top-[0px] after:duration-75 after:left-[3px] after:right-unset after:top-[4px] after:w-[9px] after:h-[5px] after:bg-transparent after:border-l-[2px] after:border-l-white after:border-b-[2px] after:border-b-white after:rounded-[0px] after:-rotate-45;
  }
  .tippy__dropdown.tippy__days__filter__mobile .ip__Radio {
    @apply shadow-none mb-[8px];
  }
  .tippy__dropdown.tippy__days__filter__mobile .ip__Radio:last-child {
    @apply mb-0;
  }
  .tippy__dropdown.tippy__days__filter__mobile
    .ip__Radio
    input:checked
    + .rc__Label {
    @apply before:bg-black before:rounded-[2px] before:border-black;
  }
  /* listing page mobile filter css end */

  /* lead listing page css start */
  .ag__grid__mobile__lead .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__lead .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[18px];
  }
  .ag__grid__mobile__lead .agGrid__infinite__box .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__lead .agGrid__infinite__box .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__lead .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* lead listing page css end */

  /* contact listing page css start */
  .ag__grid__mobile__contact .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__contact .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__contact
    .agGrid__infinite__box
    .ag__ib__row:nth-child(even) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__contact
    .agGrid__infinite__box
    .ag__ib__row:nth-child(odd) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__contact .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  .ag__grid__mobile__contact .agGrid__infinite__box .ag__ib__row:nth-child(3) {
    @apply w-full pr-0 pl-0;
  }
  /* contact listing page css end */

  /* deal listing page css start */
  .ag__grid__mobile__deal .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__deal .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__deal .agGrid__infinite__box .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__deal .agGrid__infinite__box .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__deal .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* deal listing page css end */

  /* account listing page css start */
  .ag__grid__mobile__account .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__account .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__account
    .agGrid__infinite__box
    .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__account
    .agGrid__infinite__box
    .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__account .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* account listing page css end */

  /* activity listing page css start */
  .activity__listing__mobile__filter .activity__filter__wrapper {
    @apply mb-0;
  }
  .activity__listing__mobile__filter .activity__filter__wrapper .i__Icon {
    @apply !w-[36px] !h-[36px] !p-[10px] duration-500 hover:!bg-[#c8c8c8] sm:!w-[32px] sm:!h-[32px] sm:!p-[8px];
  }
  .activity__listing__mobile__filter .activity__date__filter {
    @apply ml-0 mb-0 sm:w-auto;
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .e-date-range-wrapper {
    @apply h-[36px] !border-0 sm:h-[32px];
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .e-input-group-icon.e-range-icon {
    @apply ml-0 !border-0 sm:w-[32px] sm:h-[32px];
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .activity__date__btn
    .i__Icon {
    @apply duration-500 !w-[36px] !h-[36px] p-[9px] sm:!w-[32px] sm:!h-[32px] sm:p-[7px];
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .e-date-range-wrapper:hover
    + .activity__date__btn
    .i__Icon.highlighted {
    @apply !bg-[#c8c8c8];
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .e-control.e-daterangepicker.e-input {
    @apply lg:!hidden;
  }
  .activity__listing__mobile__filter
    .activity__date__filter
    .e-date-range-wrapper
    .e-clear-icon {
    @apply w-[20px] h-[20px] min-w-[20px] min-h-[20px] bg-primaryColor rounded-full !translate-y-[0px] !top-[-8px] !right-[-8px] !border-[2px] !border-solid !border-white duration-500 z-[3] before:content-['\e7e7'] before:!text-white before:!text-[10px] hover:bg-primaryColor__hoverDark;
  }
  .ag__grid__mobile__activity .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__activity .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__activity
    .agGrid__infinite__box
    .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__activity
    .agGrid__infinite__box
    .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__activity .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* activity listing page css end */

  /* user listing page css start */
  .ag__grid__mobile__user .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__user .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__user .agGrid__infinite__box .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__user .agGrid__infinite__box .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__user .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }

  .ip__Modal__Wrapper.small__without__HT__modal.delete__user__modal
    .ip__Modal__ContentWrap {
    @apply !w-[460px];
  }
  .ip__Modal__Wrapper.small__without__HT__modal.delete__user__modal .confirmation__title{
    @apply !w-[330px];
  }

  .delete__user__modal .ip__Radio.full__Radio__Button {
    @apply shadow-none;
  }
  .delete__user__modal .ip__Radio.full__Radio__Button .rc__Label {
    @apply !p-0 !pl-[26px] !text-[16px] !text-mediumDark__TextColor before:absolute before:top-[10px] before:left-0 before:w-[20px] before:h-[20px] before:rounded-[3px] before:border-[2px] before:border-[#CCCCCC] before:bg-transparent after:w-[10px] after:h-[5px] after:absolute after:top-[9px] after:left-[5px] after:border-b-[2px] after:border-l-[2px] after:border-white after:rotate-[-45deg] after:opacity-0 after:duration-[500ms] after:bg-transparent after:rounded-[0px];
  }
  .delete__user__modal
    .ip__Radio.full__Radio__Button
    input:checked
    + .rc__Label {
    @apply !text-primaryColor before:bg-primaryColor before:border-[2px] before:border-primaryColor after:opacity-100;
  }
  .delete__user__modal .ip__Modal__Footer {
    @apply hidden;
  }
  /* user listing page css end */

  /* profile permissions listing page css start */
  .ag__grid__mobile__profilePermission .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__profilePermission .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__profilePermission
    .agGrid__infinite__box
    .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__profilePermission
    .agGrid__infinite__box
    .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__profilePermission
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* profile permissions listing page css end */

  /* department listing page css start */
  .ag__grid__mobile__department .agGrid__infinite__box {
    @apply flex flex-wrap pb-0;
  }
  .ag__grid__mobile__department .agGrid__infinite__box .ag__ib__row {
    @apply w-1/2 content-start mb-[15px];
  }
  .ag__grid__mobile__department
    .agGrid__infinite__box
    .ag__ib__row:nth-child(odd) {
    @apply pr-[5px];
  }
  .ag__grid__mobile__department
    .agGrid__infinite__box
    .ag__ib__row:nth-child(even) {
    @apply pl-[5px];
  }
  .ag__grid__mobile__department
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2) {
    @apply pr-[58px] w-full pl-0;
  }
  /* department listing page css end */

  /* ag grid infinite scroll table css for mobile end */

  /* settingLists css start */
  .setting__Box {
    @apply bg-ipWhite__bgColor w-full rounded-[12px] px-[20px] py-[24px] h-full;
  }
  .setting__Box .setting__Header {
    @apply relative z-[3] flex justify-between items-center mb-[15px];
  }
  .setting__Box .setting__Header .setting__Title {
    @apply text-ipBlack__textColor font-biotif__SemiBold text-[18px] pr-[10px];
  }
  .setting__Box .setting__Header .setting__Counts {
    @apply inline-flex items-center justify-center rounded-[100px] text-[16px] font-biotif__Medium text-white leading-[16px] pt-[7px] pb-[5px] px-[6px];
  }
  .setting__Box .setting__Body {
    @apply relative z-[3];
  }
  .setting__Box .setting__OrderList .setting__Item {
    @apply list-none relative text-[16px] font-biotif__Medium text-black__TextColor600 hover:text-primaryColor duration-300 pl-[16px] mb-[10px] before:content-[''] before:absolute before:top-[7px] before:left-0 before:w-[6px] before:h-[6px] before:rounded-full before:bg-light__TextColor hover:before:bg-primaryColor before:duration-300;
  }
  .setting__Box .setting__OrderList .setting__Item:last-child {
    @apply mb-0;
  }
  .setting__BoxWrapper:nth-child(odd) .setting__Box {
    @apply relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:rounded-[12px] before:opacity-10;
  }
  .setting__BoxWrapper:nth-child(odd) .setting__Box .setting__Counts {
    @apply bg-ip__Blue;
  }
  .setting__BoxWrapper:nth-child(even) .setting__Box {
    @apply relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-ip__Orange before:rounded-[12px] before:opacity-10;
  }
  .setting__BoxWrapper:nth-child(even) .setting__Box .setting__Counts {
    @apply bg-ip__Orange;
  }
  /* settingLists css end */

  /* setting layout comman css start */
  .ip__V__TabsWrapper {
    @apply flex flex-wrap w-full xl:mt-[10px] lg:mt-[15px];
  }
  .ip__V__TabsWrapper .ip__V__Tabs {
    @apply w-[250px] relative before:content-[''] before:absolute before:right-0 before:top-0 before:bottom-0 before:w-[1px] before:h-full before:bg-whiteScreen__BorderColor 3xl:w-[200px] xl:relative xl:w-full xl:after:content-[''] xl:after:absolute xl:after:top-0 xl:after:right-0 xl:after:h-full xl:after:w-[30px] xl:after:bg-gradient-to-r xl:after:from-[#fff0] xl:after:to-[#fff] xl:after:z-[2] xl:before:content-[''] xl:before:absolute xl:before:top-unset xl:before:bottom-0 xl:before:left-0 xl:before:right-0 xl:before:w-full xl:before:h-[1px];
  }
  .ip__V__Tabs .ip__V__TabsList {
    @apply relative z-[2] xl:overflow-x-auto xl:flex xl:whitespace-pre;
  }
  .ip__V__Tabs .ip__V__TabsList .title__for__button {
    @apply xl:hidden;
  }
  .ip__V__Tabs .ip__V__TabsList .ip__V__TabsItems {
    @apply inline-flex w-full xl:w-auto xl:mr-[10px];
  }
  .ip__V__Tabs .ip__V__TabsList .ip__V__TabsItems:last-child {
    @apply xl:mr-[20px];
  }
  .ip__V__Tabs .ip__V__TabsList.submenu {
    @apply bg-ipGray__transparentBG__light;
  }
  .ip__V__Tabs .ip__V__TabsItems.has__submenu .ip__V__TabsLink {
    @apply relative pr-[27px] after:content-[''] after:absolute after:top-[calc(50%_-_2px)] after:translate-y-[-50%] after:right-[10px] after:w-[8px] after:h-[8px] after:border-[2px] after:border-ip__black__text__color after:border-t-0 after:border-r-0 after:rotate-[-45deg];
  }
  .ip__V__Tabs .ip__V__TabsItems.has__submenu.active > .ip__V__TabsLink {
    @apply after:border-ip__white__text__color;
  }
  .ip__V__Tabs .ip__V__TabsLink {
    @apply w-full text-ip__Blue relative text-[16px] font-biotif__Medium text-left border-r border-r-whiteScreen__BorderColor border-b border-b-whiteScreen__BorderColor pt-[12px] pb-[12px] px-[15px] duration-300 hover:bg-parentBgWhite__grayBtnBG 3xl:px-[10px] 3xl:text-[14px] 3xl:pt-[10px] 3xl:pb-[10px] xl:text-black xl:py-[12px] xl:px-[15px] xl:border xl:border-[#CCCCCC]/50 xl:border-b-0 xl:rounded-t-[10px] xl:min-w-[160px] xl:text-center;
  }
  .ip__V__Tabs .ip__V__TabsItems.active > .ip__V__TabsLink {
    @apply bg-ip__Blue hover:bg-ip__Blue text-ip__white__text__color before:content-[''] before:absolute before:right-[-1px] before:top-0 before:bottom-0 before:w-[1px] before:h-full before:bg-ipWhite__bgColor xl:bg-[#F2F6F9] xl:text-primaryColor xl:hover:bg-[#F2F6F9] xl:hover:text-primaryColor xl:before:hidden xl:after:content-[''] xl:after:absolute xl:after:bottom-0 xl:after:left-0 xl:after:right-0 xl:after:w-full xl:after:h-[1px] after:bg-[#F2F6F9] xl:after:z-[2];
  }
  .ip__V__TabsWrapper .ip__V__ContentWrapper {
    @apply w-[calc(100%_-_250px)] 3xl:w-[calc(100%_-_200px)] xl:w-full;
  }
  .ip__V__TabsWrapper .ip__V__ContentWrapper .inner__scroll__wrapper {
    @apply h-[calc(100dvh_-_244px)] 3xl:h-[calc(100dvh_-_225px)] xl:h-auto sm:!pb-0;
  }
  .ip__V__TabsWrapper .ip__V__Content {
    @apply relative xl:min-h-[0px];
  }
  .ip__V__TabsWrapper .ip__V__Content .inner__scroll__wrapper {
    @apply p-[24px] 3xl:p-[15px] xl:px-0 xl:pb-0 sm:pt-[7px];
  }

  /* setting layout comman mobile css start */
  .settingM__tabs__new .items:last-child {
    @apply mb-0;
  }
  .ip__V__ContentWrapper .page__ActionHeader .action__buttons .i__Button {
    @apply sm:max-w-[50%] sm:h-[43px] sm:m-0 sm:mb-[10px];
  }
  .ip__V__ContentWrapper .page__ActionHeader {
    @apply sm:mb-[10px];
  }
  .ip__V__ContentWrapper .page__ActionHeader .header__searchBox {
    @apply sm:hidden;
  }
  .settingM__searchBox {
    @apply mb-[15px];
  }
  .settingM__searchBox .ip__input {
    @apply h-[32px] !py-[2px] rounded-[6px] placeholder:text-black/50;
  }
  .settingM__searchBox .i__Icon {
    @apply p-[4px] top-[3px];
  }
  .settingM__searchBox .i__Icon svg path {
    @apply !fill-black/80;
  }
  .settingDetails__M__wrapper .agGrid__infinite__box:last-child {
    @apply mb-0;
  }
  .settingDetails__M__wrapper .agGrid__infinite__box .ag__ib__row:nth-child(2) {
    @apply pr-[32px];
  }
  .settingDetails__M__wrapper.tagControl
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2),
  .settingDetails__M__wrapper.temperatureStatusLead
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2),
  .settingDetails__M__wrapper.leadStatus
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2) {
    @apply pr-0;
  }
  .settingDetails__M__wrapper.tagControl .agGrid__infinite__box,
  .settingDetails__M__wrapper.temperatureStatusLead .agGrid__infinite__box,
  .settingDetails__M__wrapper.leadStatus .agGrid__infinite__box {
    @apply pr-[85px];
  }
  .settingDetails__M__wrapper.tagControl .ag__ib__row:nth-of-type(2),
  .settingDetails__M__wrapper.temperatureStatusLead .ag__ib__row:nth-of-type(2),
  .settingDetails__M__wrapper.leadStatus .ag__ib__row:nth-of-type(2) {
    @apply inline-flex mb-0 absolute right-[15px] bottom-[15px] z-[3];
  }
  .settingDetails__M__wrapper.tagControl .ag__ib__row:nth-of-type(2) label,
  .settingDetails__M__wrapper.temperatureStatusLead
    .ag__ib__row:nth-of-type(2)
    label,
  .settingDetails__M__wrapper.leadStatus .ag__ib__row:nth-of-type(2) label {
    @apply inline-flex w-auto mb-0 mt-[2px] mr-[5px];
  }
  .settingDetails__M__wrapper.tagControl .ag__ib__row:nth-of-type(2) > .value,
  .settingDetails__M__wrapper.temperatureStatusLead
    .ag__ib__row:nth-of-type(2)
    > .value,
  .settingDetails__M__wrapper.leadStatus .ag__ib__row:nth-of-type(2) > .value {
    @apply w-auto;
  }
  .settingDetails__M__wrapper.tagControl
    .ag__ib__row:nth-of-type(2)
    .cell__value__wrapper
    .value,
  .settingDetails__M__wrapper.temperatureStatusLead
    .ag__ib__row:nth-of-type(2)
    .cell__value__wrapper
    .value,
  .settingDetails__M__wrapper.leadStatus
    .ag__ib__row:nth-of-type(2)
    .cell__value__wrapper
    .value {
    @apply w-[19px] h-[19px] p-[2px];
  }
  .settingDetails__M__wrapper.phoneType .ag__ib__row:nth-of-type(1),
  .settingDetails__M__wrapper.leadDeal__Source .ag__ib__row:nth-of-type(1) {
    @apply mb-[20px];
  }
  .setting__FixedWrapper__acTimeSet .setting__activity__tim__head,
  .setting__FixedWrapper__ACavailability
    .setting__activity__availability__head {
    @apply sm:hidden;
  }
  .setting__activity__tim__body,
  .setting__activity__availability__body,
  .setting__activity__emailUndo__body {
    @apply sm:border sm:border-[#CCCCCC]/50 sm:rounded-[12px] sm:p-[12px];
  }
  .setting__FixedWrapper__acTimeSet .setting__activity__tim__row .column,
  .setting__FixedWrapper__ACavailability
    .setting__activity__availability__row
    .column {
    @apply sm:mb-[20px] sm:before:content-[attr(data-title)] sm:before:text-[16px] sm:before:font-biotif__Medium sm:before:text-primaryColor sm:before:w-full sm:before:block sm:before:mb-[7px];
  }
  .setting__FixedWrapper__acTimeSet .setting__activity__tim__row:last-child,
  .setting__FixedWrapper__acTimeSet
    .setting__activity__tim__row:nth-last-of-type(1),
  .setting__FixedWrapper__acTimeSet
    .setting__activity__tim__row
    .column:last-child,
  .setting__FixedWrapper__ACavailability
    .setting__activity__availability__row:last-child,
  .setting__FixedWrapper__ACavailability
    .setting__activity__availability__row:nth-last-of-type(1),
  .setting__FixedWrapper__ACavailability
    .setting__activity__availability__row
    .column:last-child {
    @apply sm:mb-0;
  }
  .setting__FixedWrapper__ACavailability .interval__time__column .ip__Select {
    @apply sm:w-[110px];
  }
  .setting__NotificationTime__body .column {
    @apply sm:mb-[10px];
  }
  .setting__NotificationTime__body .column:last-child {
    @apply sm:mb-0;
  }

  .settingDetails__M__wrapper.activityType .agGrid__infinite__box {
    @apply flex flex-wrap;
  }
  .settingDetails__M__wrapper.activityType
    .agGrid__infinite__box
    .ag__ib__row:nth-child(1) {
    @apply w-full;
  }
  .settingDetails__M__wrapper.activityType
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2),
  .settingDetails__M__wrapper.activityType
    .agGrid__infinite__box
    .ag__ib__row:nth-child(3) {
    @apply w-[50%] xsm:w-full;
  }
  .settingDetails__M__wrapper.activityType
    .agGrid__infinite__box
    .ag__ib__row:nth-child(2) {
    @apply pr-[10px] xsm:pr-0;
  }
  .settingDetails__M__wrapper.activityType
    .agGrid__infinite__box
    .ag__ib__row:nth-child(3) {
    @apply pl-[10px] xsm:pl-0 xsm:mb-[5px];
  }
  /* setting layout comman mobile css end */
  @media screen and (max-width: 1199px) {
    .ip__V__Tabs .ip__V__TabsList::-webkit-scrollbar {
      display: none;
    }
    .ip__V__Tabs .ip__V__TabsList {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  /* setting layout comman css end */

  /* setting other pages css start */
  /* Personal Settings page css start */
  .personal__setting__edit__page .ipInfo__ViewBox,
  .company__setting__edit__page .ipInfo__ViewBox,
  .user__setting__edit__page .ipInfo__ViewBox {
    @apply w-1/3 3xl:w-1/2 lg:w-full;
  }
  .personal__setting__edit__page .noName__letter,
  .company__setting__edit__page .noName__letter,
  .user__setting__edit__page .noName__letter {
    @apply pt-[5px];
  }
  .personal__setting__edit__page .profile__img,
  .company__setting__edit__page .profile__img,
  .user__setting__edit__page .profile__img {
    @apply w-[70px] h-[70px];
  }
  .personal__setting__edit__page .profile__img .noName__letter,
  .personal__setting__edit__page .profile__img .img__loader,
  .personal__setting__edit__page .profile__img .img__element,
  .personal__setting__edit__page .profile__img .no__img__icon,
  .company__setting__edit__page .profile__img .noName__letter,
  .company__setting__edit__page .profile__img .img__loader,
  .company__setting__edit__page .profile__img .img__element,
  .company__setting__edit__page .profile__img .no__img__icon,
  .user__setting__edit__page .profile__img .noName__letter,
  .user__setting__edit__page .profile__img .img__loader,
  .user__setting__edit__page .profile__img .img__element,
  .user__setting__edit__page .profile__img .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .personal__setting__edit__page .profile__img .no__img__icon,
  .company__setting__edit__page .profile__img .no__img__icon,
  .user__setting__edit__page .profile__img .no__img__icon {
    @apply bg-ipGray__transparentBG p-[12px];
  }
  .personal__setting__edit__page .profile__img .img__loader,
  .company__setting__edit__page .profile__img .img__loader,
  .user__setting__edit__page .profile__img .img__loader {
    @apply bg-ipGray__transparentBG;
  }
  .personal__setting__edit__page .profile__img .img__element,
  .company__setting__edit__page .profile__img .img__element,
  .user__setting__edit__page .profile__img .img__element {
    @apply object-cover object-center;
  }
  .personal__setting__edit__page
    .profile__img
    .noName__letter
    .noName__letter__text,
  .company__setting__edit__page
    .profile__img
    .noName__letter
    .noName__letter__text,
  .user__setting__edit__page
    .profile__img
    .noName__letter
    .noName__letter__text {
    @apply text-[22px] leading-[22px] pt-[4px];
  }
  /* Personal Settings page css end */

  /* Permission Settings css start */
  .permission__Row {
    @apply flex flex-wrap items-start justify-between w-[900px] max-w-full mb-[15px] lg:block lg:relative lg:rounded-[10px] lg:px-[15px] lg:pt-[13px] lg:pb-[8px] lg:bg-[#f9f9f9] lg:border lg:border-[#CCCCCC]/50;
  }
  .permission__Row:last-child {
    @apply lg:mb-[22px];
  }
  .permissionBody__wp:last-child .permission__Row:last-child {
    @apply sm:mb-0;
  }
  .permission__Row .permission__TD {
    @apply px-[15px] flex flex-wrap items-center;
  }
  .permission__Row .permission__TD:first-child {
    @apply pl-0;
  }
  .permission__Row .permission__TD:last-child {
    @apply pr-0;
  }
  .permission__Row .permission__TD.permission__LB {
    @apply w-[300px] flex flex-wrap justify-between items-center 3xl:w-[230px] lg:items-start lg:w-[calc(100%_-_32px)] lg:pr-[4px];
  }
  .permission__Row .permission__TD.permission__LB .permission__Label {
    @apply w-[calc(100%_-_44px)] pr-[10px] text-[16px] text-black font-biotif__Medium capitalize;
  }
  .permission__Row .permission__TD.permission__LB .form__Group {
    @apply mb-0 flex items-center lg:relative lg:top-[2px];
  }
  .permission__Row .permission__TD.permissionEnableList {
    @apply w-[calc(100%_-_390px)] relative 3xl:w-[calc(100%_-_305px)] lg:w-full lg:px-0 lg:mt-[10px];
  }
  .permission__Row .permission__TD.permissionEdit {
    @apply w-[88px] flex justify-end 3xl:w-[75px] lg:w-auto lg:pl-0 lg:absolute lg:top-[10px] lg:right-[12px];
  }
  .permission__Row .ip__Checkbox {
    @apply relative w-[42px] h-[20px] flex items-center;
  }
  .permission__Row .ip__Checkbox .rc__Label {
    @apply text-[0px] absolute top-0 left-0 w-full h-full border-[2px] border-light__TextColor rounded-[50px] p-0 duration-300 before:w-[12px] before:h-[12px] before:rounded-full before:border-0 before:bg-light__TextColor before:top-[2px] before:left-[2px] before:duration-300;
  }
  .permission__Row .ip__Checkbox input:checked + .rc__Label {
    @apply border-ip__Blue before:bg-ip__Blue before:left-[23px] after:hidden;
  }
  .permission__ELUL {
    @apply flex flex-wrap;
  }
  .permission__ELUL__dropdown {
    @apply absolute top-[100%] left-0 w-auto max-w-full bg-ipGray__transparentBG rounded-[12px] shadow-[0px_2px_4px_#0000001a] pl-[17px] border border-whiteScreen__BorderColor pt-[14px] pb-[5px] z-[2] before:content-[''] before:absolute before:top-[-6px] before:left-[50%] before:translate-x-[-50%] before:rotate-45 before:w-[10px] before:h-[10px] before:border before:border-whiteScreen__BorderColor before:border-b-0 before:border-r-0 before:bg-ipGray__transparentBG before:z-[2] hidden;
  }
  .permission__ELUL.more__active {
    @apply relative before:content-[''] before:absolute before:top-[5px] before:right-0 before:w-[8px] before:h-[8px] before:border-[2px] before:border-ip__black__text__color before:border-t-0 before:border-r-0 before:rotate-[-45deg];
  }
  .permission__ELUL .permission__ELItem {
    @apply text-light__TextColor text-[16px] font-biotif__Medium relative pr-[30px] mb-[10px] before:content-[''] before:absolute before:w-[4px] before:h-[4px] before:rounded-full before:bg-light__TextColor before:top-[9px] before:right-[14px] capitalize lg:mb-[4px] xsm:pr-[22px] xsm:before:right-[9px];
  }
  .permissionBody__skeleton .permission__ELUL .permission__ELItem {
    @apply before:hidden;
  }
  .permission__ELUL .permission__ELItem:last-child {
    @apply pr-0 before:hidden;
  }
  .permission__Row .edit__Button {
    @apply inline-flex items-center text-light__TextColor text-[16px] font-biotif__Medium duration-300 hover:text-black lg:text-[0px];
  }
  .permission__Row .edit__Button:hover svg path {
    @apply fill-black;
  }
  .permission__Row .edit__Button .i__Icon {
    @apply relative top-[-1px] mr-[4px] lg:duration-500 lg:mr-0 lg:top-0 lg:!p-[7px] lg:w-[30px] lg:h-[30px] lg:rounded-full lg:hover:bg-[#eaeaea];
  }
  .form__Group.full__Checkbox.question__Checkbox.permissionfull__Checkbox {
    @apply bg-transparent mb-0;
  }
  .ip__Checkbox.full__Checkbox.question__Checkbox.permissionfull__Checkbox {
    @apply !border-b-0;
  }
  .ip__Checkbox.full__Checkbox.question__Checkbox.permissionfull__Checkbox
    .rc__Label {
    @apply pl-0 capitalize;
  }
  /* Permission Settings css end */

  /* email setting css start */
  /* connected email css start */
  .connectedEmail__box {
    @apply p-[25px] bg-lightScreenBg rounded-[12px];
  }
  .connectedEmail__box:last-child {
    @apply mb-0;
  }
  .connectedEmail__box .inner__box {
    @apply bg-ipWhite__bgColor rounded-[10px] p-[25px] 3xl:p-[14px];
  }
  .connectedEmail__box .edit__btn,
  .connectedEmail__box .delete__btn {
    @apply w-[42px] h-[42px] flex items-center justify-center rounded-[6px] duration-500 hover:bg-ipGray__transparentBG sm:w-[36px] sm:h-[36px];
  }
  .connectedEmail__box .edit__btn path,
  .connectedEmail__box .delete__btn path {
    @apply fill-ip__Blue;
  }
  .connectedEmail__box .reset__btn svg {
    @apply top-0;
  }
  .connectedEmail__box .reset__btn svg path {
    @apply fill-primaryColor;
  }
  .connectedEmail__box .toggleBtn .i__Icon {
    @apply p-[4px];
  }
  .connectedEmail__box .toggleBtn svg circle {
    @apply fill-black/50;
  }
  .connectedEmail__box .toggleBtn:hover svg circle {
    @apply fill-black;
  }

  .reConnect__modal .ip__Modal__Footer {
    @apply hidden;
  }
  /* connected email css end */

  /* Calendar & Conferencing css start */
  .calendar__conferencing__box:last-child {
    @apply mb-0;
  }
  /* Calendar & Conferencing css end */

  /* undo email css start */
  .setting__activity__emailUndo__row
    .activity__type__column
    .i__Icon.highlighted {
    @apply bg-primary__transparentBG sm:w-[34px] sm:h-[34px] sm:rounded-[8px] sm:p-[6px];
  }
  .setting__activity__emailUndo__row
    .activity__type__column
    .i__Icon
    svg
    path[fill] {
    @apply fill-black;
  }
  .setting__activity__emailUndo__row
    .activity__type__column
    .i__Icon
    svg
    path[stroke] {
    @apply stroke-black;
  }
  .setting__activity__emailUndo__row .if__label {
    @apply mb-0;
  }
  .setting__activity__emailUndo__row .interval__time__column .ip__input {
    @apply text-center sm:text-[14px] sm:py-[6px] sm:px-[10px];
  }
  .setting__activity__emailUndo__row .activity__type__column .text {
    @apply sm:text-[16px] sm:leading-[20px];
  }
  .setting__activity__emailUndo__row .interval__time__column .ip__Error {
    @apply sm:leading-[15px];
  }
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="control"],
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="control"]:hover,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control:hover {
    @apply sm:min-h-[36px] sm:py-[2px] sm:pl-[10px];
  }
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="indicatorContainer"]:last-child::before,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::before {
    @apply sm:left-[calc(50%_-_3px)] w-[8px];
  }
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="indicatorContainer"]:last-child::after,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::after {
    @apply sm:left-[calc(50%_+_2px)] w-[8px];
  }
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Selec
    div[class$="control"],
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="control"]:hover,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control,
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control:hover {
    @apply pr-[24px];
  }
  .setting__activity__emailUndo__row
    .ip__Select
    div[class$="IndicatorsContainer"],
  .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-21px];
  }
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    div[class$="ValueContainer"],
  .setting__activity__emailUndo__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__value-container {
    @apply !pr-[0px];
  }
  /* undo email css end */
  /* email setting css end */

  /* activity setting css start */
  /* activity default time set css start */
  .setting__activity__tim__row .activity__type__column .i__Icon.highlighted {
    @apply relative bg-white sm:w-[32px] sm:h-[32px] sm:rounded-[8px] sm:p-[6px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:rounded-[12px] before:opacity-5;
  }
  .setting__activity__tim__row .activity__type__column .i__Icon svg path[fill] {
    @apply fill-ipBlack__bgColor;
  }
  .setting__activity__tim__row
    .activity__type__column
    .i__Icon
    svg
    path[stroke] {
    @apply stroke-ipBlack__bgColor;
  }
  .setting__activity__tim__row .if__label {
    @apply mb-0;
  }
  .setting__activity__tim__row .interval__time__column .ip__input {
    @apply text-center sm:text-[14px] sm:py-[6px] sm:px-[10px];
  }
  .setting__activity__tim__row .activity__type__column .text {
    @apply sm:text-[14px] sm:text-black sm:leading-[18px];
  }
  .setting__activity__tim__row .interval__time__column .ip__Error,
  .setting__activity__alertTime__row .interval__time__column .ip__Error {
    @apply sm:leading-[15px];
  }
  .setting__activity__tim__row .img__loader {
    @apply w-[46px] h-[46px] bg-[#C7D9E7]/[0.24] rounded-[12px] mr-[10px] sm:w-[32px] sm:h-[32px] sm:rounded-[8px];
  }
  /* activity default time set css end */

  /* activity availability css start */
  .setting__activity__availability__row
    .activity__type__column
    .i__Icon.highlighted {
    @apply relative bg-white sm:w-[32px] sm:h-[32px] sm:rounded-[8px] sm:p-[6px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:rounded-[12px] before:opacity-5;
  }
  .setting__activity__availability__row
    .activity__type__column
    .i__Icon
    svg
    path[fill] {
    @apply fill-ipBlack__bgColor;
  }
  .setting__activity__availability__row
    .activity__type__column
    .i__Icon
    svg
    path[stroke] {
    @apply stroke-ipBlack__bgColor;
  }
  .setting__activity__availability__row .if__label {
    @apply mb-0;
  }
  .setting__activity__availability__row .interval__time__column .ip__input {
    @apply text-center sm:text-[14px] sm:py-[6px] sm:px-[10px];
  }
  .setting__activity__availability__row .activity__type__column .text {
    @apply sm:text-[14px] sm:leading-[18px];
  }
  .setting__activity__availability__row .interval__time__column .ip__Error {
    @apply sm:leading-[15px];
  }
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    div[class$="control"],
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    div[class$="control"]:hover,
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control,
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__control:hover {
    @apply pr-[24px] sm:min-h-[36px] sm:py-[2px] sm:pl-[10px];
  }
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    div[class$="indicatorContainer"]:last-child::before,
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::before {
    @apply sm:left-[calc(50%_-_3px)] w-[8px];
  }
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    div[class$="indicatorContainer"]:last-child::after,
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::after {
    @apply sm:left-[calc(50%_+_2px)] w-[8px];
  }
  .setting__activity__availability__row
    .ip__Select
    div[class$="IndicatorsContainer"],
  .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-18px];
  }
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    div[class$="ValueContainer"],
  .setting__activity__availability__row
    .interval__time__column
    .ip__Select
    .ip__select__dynamic__value-container {
    @apply !pr-[0px];
  }
  .setting__activity__availability__row .img__loader {
    @apply w-[46px] h-[46px] bg-[#C7D9E7]/[0.24] rounded-[12px] mr-[10px] sm:w-[32px] sm:h-[32px] sm:rounded-[8px];
  }
  /* activity availability css end */
  /* activity setting css end */

  /* Notification setting css start */
  .notification__table .form__Groupip__Checkbox {
    @apply flex items-center justify-center;
  }
  .notification__table .ip__Checkbox .rc__Label {
    @apply pl-0 w-[20px] h-[20px] before:border-[#CCCCCC];
  }
  .notification__table .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColor before:border-primaryColor;
  }
  /* Notification setting css end */
  /* setting other pages css end */

  /* add pipline modal css start */
  .add__pipeline__modal .hidden__if__label .if__label {
    @apply hidden;
  }
  .add__pipeline__modal .deal__stage__wrapper {
    @apply bg-ipGray__transparentBG rounded-[10px] py-[20px] px-[15px] pb-[10px];
  }
  .add__pipeline__modal .deal__stage__wrapper .ip__input,
  .add__pipeline__modal .deal__stage__wrapper .ip__select__dynamic__control {
    @apply bg-ip__white__text__color;
  }

  .add__pipeline__modal .deal__stage__box {
    @apply flex flex-wrap relative pr-[36px] sm:pb-[5px] sm:mb-[15px] sm:border-b sm:border-b-greyScreen__BorderColor;
  }
  .add__pipeline__modal .deal__stage__box:last-child {
    @apply sm:border-b-0 sm:mb-0;
  }
  .add__pipeline__modal .deal__stage__box .form__Group {
    @apply mb-[14px] w-[calc(50%_-_90px)] sm:w-full;
  }
  .add__pipeline__modal .deal__stage__box .form__Group:nth-child(1) {
    @apply mr-[12px] sm:mr-0;
  }
  .add__pipeline__modal .deal__stage__box .form__Group:nth-child(2) {
    @apply w-[150px] mr-[12px] sm:w-full sm:mr-0;
  }
  .add__pipeline__modal .deal__stage__box .i__Icon {
    @apply w-[30px] h-[30px] duration-500 rounded-full p-[6px] hover:bg-ip__Grey__hoverDark;
  }
  .add__pipeline__modal .deal__stage__box svg path {
    @apply fill-ip__Red duration-500 hover:fill-ip__Red__hoverDark;
  }
  .add__pipeline__modal .deal__stage__box button {
    @apply absolute top-[8px] right-0;
  }
  .add__pipeline__modal .add__deal__btn:hover svg path {
    @apply fill-ip__SuccessGreen__hoverDark;
  }
  .add__pipeline__modal .add__deal__btn svg path {
    @apply fill-ip__SuccessGreen;
  }
  /* add pipline modal css end */

  /* information view box css start */
  .ipInfo__ViewBox {
    @apply mb-[10px] px-[10px] flex flex-wrap items-start xl:mb-[15px] xl:w-full;
  }
  .ipInfo__ViewBox:last-child {
    @apply md:mb-0;
  }
  .ipInfo__ViewBox .ipInfo__View__Label {
    @apply w-[140px] text-black__TextColor600 text-[16px] font-biotif__Regular mb-[6px] relative pr-[8px] after:content-[':'] after:absolute after:top-0 after:right-0 sm:w-[145px] sm:pr-0 sm:mb-[1px] xsm:w-[115px];
  }
  .ipInfo__ViewBox .ipInfo__View__Value {
    @apply text-ipBlack__textColor pt-[2px] text-[15px] font-biotif__Regular w-[calc(100%_-_140px)] pl-[12px] flex-wrap break-words whitespace-normal sm:w-[calc(100%_-_145px)] sm:pl-[10px] sm:text-[14px] xsm:w-[calc(100%_-_116px)];
  }
  .ipInfo__ViewBox .ipInfo__View__Value.inline__edit__on__wrapper {
    @apply sm:w-full sm:pl-0 sm:mt-[4px];
  }
  .ipInfo__ViewBox .ipInfo__View__Value.link,
  .ipInfo__ViewBox .ipInfo__View__Value a {
    @apply text-ip__Blue;
  }
  .ipInfo__ViewBox .e-time-wrapper .e-time-icon{
    @apply absolute top-[8px] right-[0px];
  }
  .ipInfo__ViewBox .e-time-wrapper .e-clear-icon{
    @apply absolute top-[14px] right-[26px];
  }
  /* information view box css end */

  /* hierarchy css start */
  .hierarchy__box {
    @apply rounded-[12px] inline-block py-[16px] px-[20px] mx-[14px] mb-[40px] relative w-[350px] max-w-full cursor-pointer duration-500 sm:w-full sm:mx-0 sm:mb-[20px];
  }
  .hierarchy__box .inner__flex__wrapper {
    @apply flex items-center;
  }
  .hierarchy__box .image__wrapper {
    @apply w-[48px] h-[48px];
  }
  .hierarchy__box .image__wrapper .noName__letter {
    @apply w-full h-full;
  }
  .hierarchy__box .image__wrapper .noName__letter span {
    @apply text-[20px];
  }
  .hierarchy__box .image__wrapper img {
    @apply w-full h-full rounded-full object-center;
  }
  .hierarchy__box .image__wrapper .noImg__ip__img {
    @apply w-full h-full rounded-full flex items-center justify-center text-white text-[18px] uppercase font-biotif__SemiBold pt-[4px];
  }
  .hierarchy__box .right__details {
    @apply w-[calc(100%_-_49px)] pl-[16px] pr-[14px];
  }
  .hierarchy__box .user__name {
    @apply text-black text-[18px] font-biotif__SemiBold whitespace-pre overflow-hidden text-ellipsis;
  }
  .hierarchy__box .user__designation {
    @apply text-light__TextColor text-[12px] font-biotif__Regular whitespace-pre overflow-hidden text-ellipsis;
  }
  .hierarchy__box .contact__details {
    @apply w-full rounded-[10px] p-[15px] my-[10px] hidden;
  }
  .hierarchy__box .contact__details .i__Icon.highlighted {
    @apply !w-[22px] !h-[22px] !p-[5px];
  }
  .hierarchy__box.active .contact__details {
    @apply block;
  }
  .hierarchy__box .contact__details .value {
    @apply w-[calc(100%_-_30px)] whitespace-pre overflow-hidden text-ellipsis;
  }
  .hierarchy__box .counter {
    @apply inline-block cursor-pointer absolute bottom-[-13px] left-[50%] translate-x-[-50%] bg-white font-biotif__Medium text-[12px] pt-[8px] pb-[6px] px-[11px] rounded-[50px] leading-[12px] text-dark__TextColor duration-500;
  }
  .hierarchy__box .edit__btn {
    @apply rounded-full p-[3px] absolute z-[3] top-[6px] right-[6px] pointer-events-none opacity-0 duration-500;
  }
  .hierarchy__box.active .edit__btn {
    @apply opacity-100 pointer-events-auto;
  }
  .hierarchy__box .edit__btn .i__Icon {
    @apply w-[15px] h-[15px];
  }
  .hierarchy__box .edit__btn svg path {
    @apply fill-white;
  }
  .hierarchy__box.main__Hbox {
    @apply bg-ipOrange__transparentBG before:content-[''] before:opacity-0 before:absolute before:bottom-[-40px] before:left-[50%] before:translate-x-[-50%] before:w-[1px] before:h-[40px] before:border-dashed before:border-l before:border-light__TextColor;
  }
  .hierarchy__box.main__Hbox .image__wrapper .noImg__ip__img {
    @apply bg-ip__Orange;
  }
  .hierarchy__box.main__Hbox .counter {
    @apply border-[2px] border-ipOrange__transparentBG hover:bg-ip__Orange hover:text-white;
  }
  .hierarchy__box.main__Hbox .edit__btn {
    @apply bg-ip__Orange;
  }
  .hierarchy__box.main__Hbox .contact__details {
    @apply bg-[#FDE8D1];
  }
  .hierarchy__box.main__Hbox .contact__details .i__Icon.highlighted {
    @apply bg-[#FAB671];
  }
  .hierarchy__box.main__Hbox.active .counter {
    @apply bg-ip__Orange text-white;
  }
  .hierarchy__box.child__Hbox {
    @apply bg-ipBlue__transparentBG relative before:content-[''] before:opacity-0 before:absolute before:top-[-20px] before:right-[50%] before:h-[1px] before:w-[calc(50%_+_20px)] before:border-dashed before:border-t before:border-light__TextColor after:content-[''] after:opacity-0 after:absolute after:top-[-20px] after:left-[50%] after:translate-x-[-50%] after:w-[1px] after:h-[20px] after:border-dashed after:border-l after:border-light__TextColor;
  }
  .hierarchy__child__wrapper
    .hChild__item__wrapper:first-child
    .hChild__item__main__wrapper
    .hierarchy__box {
    right: unset;
    @apply before:left-[50%];
  }
  .hierarchy__box.child__Hbox .image__wrapper .noImg__ip__img {
    @apply bg-ip__Blue;
  }
  .hierarchy__box.child__Hbox .counter {
    @apply border-[2px] border-ipBlue__transparentBG hover:bg-ip__Blue hover:text-white;
  }
  .hierarchy__box.child__Hbox .edit__btn {
    @apply bg-ip__Blue;
  }
  .hierarchy__box.child__Hbox .contact__details {
    @apply bg-[#d1ebff];
  }
  .hierarchy__box.child__Hbox .contact__details .i__Icon.highlighted {
    @apply bg-[#7cc6ff];
  }
  .hierarchy__box.child__Hbox.active .counter {
    @apply bg-ip__Blue text-white;
  }
  .hChild__item__wrapper {
    @apply inline-block;
  }
  .hChild__item__wrapper > .hierarchy__box {
    @apply hidden;
  }
  .hChild__item__wrapper.active {
    @apply block;
  }
  .hChild__item__wrapper.active > .hierarchy__box {
    @apply inline-block;
  }
  .hierarchy__box.active {
    @apply cursor-default;
  }
  .hierarchy__box.active .counter {
    @apply cursor-default;
  }
  .hierarchy__wrapper > div:last-child .hierarchy__box:last-child {
    @apply mb-0;
  }
  /* hierarchy css end */

  /* Deal details page arrow tabs step css start */
  .ip__arrow__tabs__wrapper {
    @apply rounded-[10px] overflow-hidden w-full flex;
  }
  .ip__arrow__tabs__ul {
    @apply inline-flex whitespace-pre w-full overflow-x-auto;
  }
  .ip__arrow__tabs__ul .item {
    @apply relative inline-block mr-[48px];
  }
  .ip__arrow__tabs__ul .link {
    @apply text-[16px] font-biotif__SemiBold text-ip__black__text__color w-full text-left bg-parentBgWhite__grayBtnBG pt-[12px] pb-[12px] pl-[25px] pr-[15px] duration-500 relative hover:bg-parentBgGray__grayBtnBG flex flex-wrap h-[69px];
  }
  .ip__arrow__tabs__ul .link div {
    @apply w-full;
  }
  .ip__arrow__tabs__ul .link .arrow__img {
    @apply absolute top-0 right-[-28px] h-full z-[2];
  }
  .ip__arrow__tabs__ul .link .arrow__before {
    @apply absolute top-0 bottom-0 left-[-20px] h-full;
  }
  .ip__arrow__tabs__ul .link .arrow__after {
    @apply absolute top-0 bottom-0 right-[-28px] h-full;
  }
  .ip__arrow__tabs__ul .link .arrow__before path,
  .ip__arrow__tabs__ul .link .arrow__after path {
    @apply fill-parentBgWhite__grayBtnBG duration-500;
  }
  .ip__arrow__tabs__ul .link:hover .arrow__before path,
  .ip__arrow__tabs__ul .link:hover .arrow__after path {
    @apply fill-parentBgGray__grayBtnBG;
  }
  .ip__arrow__tabs__ul .item.active .link .arrow__before path,
  .ip__arrow__tabs__ul .item.active .link .arrow__after path,
  .ip__arrow__tabs__ul .item.active .link:hover .arrow__before path,
  .ip__arrow__tabs__ul .item.active .link:hover .arrow__after path {
    @apply fill-ipSuccessGreen__transparentBG;
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--scroll-container {
    @apply inline-block;
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--separator {
    @apply inline-block;
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--item {
    @apply w-auto inline-block mr-[48px];
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--item:last-child {
    @apply mr-0;
  }
  .ip__arrow__tabs__ul .item.active .link {
    @apply bg-ipSuccessGreen__transparentBG text-ip__SuccessGreen;
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--arrow-left,
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--arrow-right {
    @apply relative z-[3];
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--arrow-left .i__Button,
  .ip__arrow__tabs__ul
    .react-horizontal-scrolling-menu--arrow-right
    .i__Button {
    @apply rounded-none p-0 w-[46px] text-[18px] bg-ip__Grey__hoverDark text-ip__black__text__color hover:bg-ip__black__text__color hover:text-ip__white__text__color relative;
  }
  .ip__arrow__tabs__ul
    .react-horizontal-scrolling-menu--arrow-right
    .i__Button {
    @apply pl-[17px] rounded-r-[8px] drop-shadow-[-2px_0px_10px_#d9d9d9];
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--arrow-left .i__Button {
    @apply pr-[14px] rounded-l-[8px] drop-shadow-[4px_0px_10px_#d9d9d9];
  }
  .ip__arrow__tabs__ul
    .react-horizontal-scrolling-menu--arrow-right
    .i__Button:before {
    @apply content-[''] w-[44px] h-full absolute top-0 bottom-0 right-[calc(100%_-_19px)] z-[5];
    background-image: url("../../../public/images/ip__arrow__tabs__arrow__newDesign.svg");
    background-size: 103%;
    background-position: right;
  }
  .ip__arrow__tabs__ul
    .react-horizontal-scrolling-menu--arrow-left
    .i__Button:before {
    @apply content-[''] w-[44px] h-full absolute top-0 bottom-0 left-[calc(100%_-_25px)] z-[5];
    background-image: url("../../../public/images/ip__arrow__tabs__arrow__newDesign.svg");
    background-size: 103%;
    background-position: left;
  }
  .ip__arrow__tabs__ul .react-horizontal-scrolling-menu--inner-wrapper {
    @apply rounded-[10px];
  }
  /* Deal details page arrow tabs step css end */

  /* details page next steps design css start */
  /* .ip__nextStep__box .inner__wrapper {
    @apply bg-[#ECF2F6] border border-[#CCCCCC]/50 rounded-[12px] p-[15px] h-full sm:p-[10px];
  } */
  .ip__nextStep__box .inner__wrapper {
    @apply rounded-[12px] p-[15px] h-full sm:p-[10px];
  }
  .ip__nextStep__box .bg__color__wrapper {
    @apply absolute top-0 left-0 w-full h-full rounded-[12px] opacity-10;
  }
  .ip__nextStep__box .inner__wrapper .ip__nextStep__actype svg path[fill] {
    @apply fill-white;
  }
  .ip__nextStep__box .inner__wrapper .ip__nextStep__actype svg path[stroke] {
    @apply stroke-white;
  }
  .ip__nextStep__box .tags__item:last-child {
    @apply before:hidden;
  }
  .ip__nextStep__box .ip__nextStep__actype .i__Icon {
    @apply bg-primaryColor;
  }
  .ip__nextStep__box .time .i__Icon {
    @apply p-0 w-[15px] h-[15px] sm:w-[13px] sm:h-[13px];
  }
  /* .ip__nextStep__box .time .i__Icon path {
    @apply fill-primaryColor;
  } */
  .ip__nextStep__box .time .text {
    @apply text-primaryColor;
  }
  .ip__nextStep__box .ag__grid__toggle__btn {
    @apply absolute z-[4] top-[10px] right-[8px] bg-transparent p-0 duration-500 hover:bg-white;
  }
  .ip__nextStep__box .ag__grid__toggle__btn .i__Icon {
    @apply mr-0 ml-0 mt-[1px] p-[5px];
  }
  /* .ip__nextStep__box .ag__grid__toggle__btn .i__Icon circle {
    @apply fill-primaryColor;
  } */
  .ip__nextStep__box:nth-child(even) .inner__wrapper {
    @apply bg-[#FEEDDB];
  }
  .ip__nextStep__box:nth-child(even) .ip__nextStep__actype .i__Icon {
    @apply bg-[#F78310];
  }
  .ip__nextStep__box:nth-child(even) .time .i__Icon path {
    @apply fill-[#F78310];
  }
  .ip__nextStep__box:nth-child(even) .time .text {
    @apply text-[#F78310];
  }
  .ip__nextStep__box:nth-child(even) .ag__grid__toggle__btn .i__Icon circle {
    @apply fill-[#F78310];
  }
  .ip__nextStep__box .inner__wrapper .tags .tags__item:last-child {
    @apply mr-0;
  }
  /* details page next steps design css end */

  /* details page stay in touch reminder css start */
  .stay__touch__reminder__Box .icon__wrapper .i__Icon {
    @apply w-full h-full rounded-[6px] bg-primaryColor filter-none;
  }
  .stay__touch__reminder__Box .icon__wrapper .i__Icon path[fill] {
    @apply fill-white;
  }
  .stay__touch__reminder__Box .icon__wrapper .i__Icon path[stroke] {
    @apply stroke-white;
  }
  .stay__touch__reminder__Box .time .i__Icon {
    @apply p-[4px] relative top-[-2px];
  }
  .stay__touch__reminder__Box .time .i__Icon path {
    @apply fill-primaryColor;
  }
  .stay__touch__reminder__Box .icon__wrapper .noName__letter,
  .stay__touch__reminder__Box .icon__wrapper .img__loader,
  .stay__touch__reminder__Box .icon__wrapper .img__element,
  .stay__touch__reminder__Box .icon__wrapper .no__img__icon {
    @apply w-full h-full rounded-[6px];
  }
  .stay__touch__reminder__Box .icon__wrapper .no__img__icon {
    @apply bg-primaryColor;
  }
  .stay__touch__reminder__Box .icon__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .stay__touch__reminder__Box .icon__wrapper .img__element {
    @apply object-cover object-center;
  }
  .stay__touch__reminder__Box
    .icon__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[13px] leading-[28px] pb-[2px] !text-primaryColor;
  }
  /* details page stay in touch reminder css end */

  /* department */
  .add__members__radio__group {
    @apply flex flex-wrap items-center justify-between;
  }
  .add__members__radio__group .ip__Radio.full__Radio__Button .rc__Label {
    @apply bg-ipGray__transparentBG rounded-[10px] text-[16px] py-[14px] px-[10px] inline-flex items-center justify-center before:relative before:inline-block before:top-[8px] before:left-0 before:right-unset before:mr-[8px] after:hidden;
  }
  .add__members__radio__group .ip__Radio.full__Radio__Button {
    @apply shadow-none;
  }
  .add__members__radio__group
    .ip__Radio.full__Radio__Button
    .custom__checkRadio__tick {
    @apply relative left-[-8px] inline-block w-[12px] h-[12px] duration-[500ms] bg-dark__TextColor z-[3] rounded-full opacity-0 ml-[-16px] mt-[-4px];
  }
  .add__members__radio__group
    .ip__Radio.full__Radio__Button
    input:checked
    + .rc__Label {
    @apply bg-ip__Blue text-ip__white__text__color before:border-0 before:border-ip__white__text__color before:bg-ipWhite__bgColor;
  }
  .add__members__radio__group
    .ip__Radio.full__Radio__Button
    input:checked
    + .rc__Label
    .custom__checkRadio__tick {
    @apply opacity-100 bg-ip__Blue;
  }
  .members__box:last-child {
    @apply mb-0 pb-0 !border-0;
  }
  .members__box .ip__Checkbox input:checked + .rc__Label::before {
    @apply bg-ip__Blue border-ip__Blue;
  }
  .members__box:first-child {
    @apply border-t border-whiteScreen__BorderColor;
  }
  .members__box .member__img .noName__letter,
  .department__table .member__name .noName__letter {
    @apply w-[34px] h-[34px] !bg-ip__white__text__color;
  }
  .members__box .member__img .noName__letter .noName__letter__text,
  .department__table .member__name .noName__letter .noName__letter__text {
    @apply text-[14px];
  }
  .form__Group.department__add__member {
    @apply flex flex-wrap items-center justify-between sm:mb-[10px];
  }
  .form__Group.department__add__member .ip__Radio.department__add__member {
    @apply w-[calc(50%_-_8px)] sm:w-full sm:mb-[10px];
  }
  .department__admin__body {
    @apply h-[0px] overflow-y-hidden;
  }
  .department__admin__body .ip__input {
    @apply border border-greyScreen__BorderColor;
  }
  .department__admin__active + .department__admin__body {
    @apply h-auto mt-[10px];
  }
  .department__admin__body .form__Group {
    @apply mb-[5px];
  }
  /* department-end */

  /* add new activity type modal css start */
  .icon__libraryBox .i__Icon {
    @apply w-[30px] h-[30px] p-[4px] rounded-[6px];
  }
  .icon__libraryBox .i__Icon.highlighted {
    @apply bg-primary__transparentBG;
  }
  .icon__libraryBox .no__img__icon,
  .icon__libraryBox .img__element {
    @apply w-[46px] h-[46px] rounded-[10px] p-[8px];
  }
  .icon__libraryBox .img__element {
    @apply bg-ipGray__transparentBG;
  }
  .icon__libraryBox .no__img__icon .i__Icon {
    @apply w-full h-full p-0;
  }
  .icon__libraryBox svg path {
    @apply fill-ipBlack__bgColor;
  }
  .icon__libraryBox.active {
    @apply before:opacity-100;
  }
  .icon__libraryBox.active .close__btn {
    @apply block;
  }
  .icon__libraryBox .delete__Btn {
    @apply w-[16px] h-[16px] shadow-[0px_0px_10px_#c9c9c9] rounded-full absolute top-[-4px] right-[-4px] bg-ipWhite__bgColor;
  }
  .icon__libraryBox .delete__Btn .i__Icon {
    @apply p-0 w-full h-full;
  }
  .icon__libraryBox .delete__Btn path {
    @apply stroke-ip__Red;
  }

  .upload__icon__btn input:hover + .primary__Btn {
    @apply bg-ip__Blue__hoverDark;
  }
  /* add new activity type modal css end */

  /* email provider modal css start */
  .email__provider__box.active .img__box,
  .email__provider__box input:checked ~ .img__box {
    @apply border border-primaryColor;
  }
  .email__provider__box.active .checkboxSquare,
  .email__provider__box input:checked ~ .checkboxSquare {
    @apply bg-primaryColor border border-primaryColor after:opacity-100;
  }
  /* email provider modal css end */

  /* sync email select modal css start */
  .sync__email__box input:checked + .inner__wrapper,
  .sync__email__box .inner__wrapper.active {
    @apply border-primaryColor;
  }
  .sync__email__box:hover .inner__wrapper.active {
    @apply border-primaryColor;
  }
  .sync__email__box input:checked + .inner__wrapper .checkboxSquare,
  .sync__email__box .inner__wrapper.active .checkboxSquare {
    @apply bg-primaryColor border border-primaryColor after:opacity-100;
  }
  .sync__email__box .form__Group {
    @apply mb-[0px];
  }
  .sync__email__box .form__Group,
  .sync__email__box .form__Group input {
    @apply absolute top-0 left-0 w-full h-full;
  }
  .sync__email__box .form__Group .ip__Checkbox {
    @apply static;
  }
  .sync__email__box .form__Group .rc__Label {
    @apply hidden;
  }
  /* sync email select modal css end */

  /* page actions headers css start */
  .ag__grid__infinite__scroll__inner {
    height: 700px;
  }
  /* remove this before push code */
  .ag__grid__mobile__lead,
  .ag__grid__mobile__contact,
  .ag__grid__mobile__deal,
  .ag__grid__mobile__account,
  .ag__grid__mobile__activity,
  .ag__grid__mobile__user,
  .ag__grid__mobile__profilePermission,
  .ag__grid__mobile__department {
    @apply relative sm:mt-[8px];
  }
  .lead__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .contact__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .deal__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .account__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_270px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .activity__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_267px)] xl:h-[calc(100dvh_-_219px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .user__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .profilePermission__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .department__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_225px)] xl:h-[calc(100dvh_-_208px)] lg:h-[calc(100dvh_-_180px)] md:h-[calc(100dvh_-_178px)];
  }
  .ag__grid__mobile__lead .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__contact .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__deal .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__account .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__activity .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__user .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__profilePermission .mobile_infiniteScroll_hWrapper,
  .ag__grid__mobile__department .mobile_infiniteScroll_hWrapper {
    @apply h-[calc(100dvh_-_153px)];
  }

  .ag__grid__infinite__scroll__mobile.no__data {
    @apply min-h-full !flex flex-wrap items-center justify-center;
  }

  .mobile_infiniteScroll_hWrapper .no__data__wrapper {
    @apply w-[300px] max-w-full mx-auto;
  }
  .mobile_infiniteScroll_hWrapper .no__data__wrapper img {
    @apply block w-[280px] max-w-full mx-auto;
  }
  .mobile_infiniteScroll_hWrapper .no__data__wrapper .text {
    @apply text-center;
  }

  .page__ActionHeader__deal
    .ip__Select
    .deal__header__ip__Select
    .ip__select__dynamic__control {
    @apply pl-[8px] pr-[28px];
  }
  .page__ActionHeader__deal .ip__Select .ip__select__dynamic__value-container {
    @apply !pr-[5px];
  }
  .page__ActionHeader__deal
    .ip__Select
    div[class$="indicatorContainer"]:last-child::before {
    @apply w-[7px] left-[calc(50%_-_2px)];
  }
  .page__ActionHeader__deal
    .ip__Select
    div[class$="indicatorContainer"]:last-child::after {
    @apply w-[7px] left-[calc(50%_+_2px)];
  }
  .page__ActionHeader__deal .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-22px];
  }
  .page__ActionHeader__deal .ip__Select {
    @apply lg:w-full sm:w-[calc(100%_-_54px)];
  }
  .page__ActionHeader__deal .delete__Btn {
    @apply sm:!mr-[10px];
  }
  .page__InfoHeader__activity .delete__Btn {
    @apply 3xl:mr-[10px] sm:mr-[5px];
  }

  .page__ActionHeader.page__ActionHeader__Contact
    .deleteDrop__wrapper
    .delete__Btn {
    @apply lg:w-[calc(50%_-_6px)] lg:mr-0;
  }

  .pageContent.pageContent__setting {
    @apply xl:min-h-[calc(100dvh_-_68px)];
  }
  .ip__V__ContentWrapper .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_350px)] 3xl:h-[calc(100dvh_-_304px)] xl:h-[calc(100dvh_-_256px)] lg:h-[calc(100dvh_-_261px)] md:h-[calc(100dvh_-_250px)];
  }
  .ip__V__Content .page__ActionHeader {
    @apply sm:mb-[5px];
  }
  .ip__V__Content .page__ActionHeader .delete__Btn {
    @apply 3xl:h-[36px] sm:w-[calc(50%_-_5px)] sm:mr-[5px];
  }
  .ip__V__Content .page__ActionHeader .ip__form__hasIcon .i__Icon {
    @apply 3xl:top-[5px];
  }
  .ip__V__Content .page__ActionHeader .header__searchBox .ip__input {
    @apply 3xl:h-[36px] 3xl:py-[4px];
  }
  .ip__V__Content .ag__grid__infinite__scroll .no__data__wrapper .image {
    @apply w-[200px];
  }
  .ip__V__Content .ag__grid__infinite__scroll .no__data__wrapper .title {
    @apply text-[18px] mt-[13px];
  }
  .ip__V__Content .ag__grid__infinite__scroll .no__data__wrapper .text {
    @apply text-[14px];
  }
  .ip__V__Content .mobile_infiniteScroll_hWrapper {
    @apply sm:h-[calc(100dvh_-_244px)];
  }

  .page__ActionHeader button.ip__Counter__Preview__Drop {
    @apply bg-parentBgWhite__grayBtnBG ml-[10px] h-[44px] py-[5px] px-[15px] pr-[28px] text-[12px] font-biotif__Regular text-black__TextColor600 rounded-[6px] relative mb-[10px] lg:ml-0 sm:pl-[10px] sm:pr-[22px] before:content-[""] before:absolute before:top-[17px] before:right-[13px] before:w-[6px] before:h-[6px] before:border-l-[1px] before:border-l-black__TextColor600 before:border-b-[1px] before:border-b-black__TextColor600 before:rotate-[-45deg] sm:before:top-[17px] sm:before:right-[8px];
  }
  .page__ActionHeader button.ip__Counter__Preview__Drop .i__Icon {
    @apply hidden;
  }

  /* assign tag list modal */
  .assign__tag__list > div {
    @apply flex-wrap;
  }
  .assign__tag__list > div > div {
    @apply w-[25%] pr-[8px];
  }
  .assign__tag__list .badge {
    @apply w-full mr-0;
  }
  .assign__tag__list .close__btn {
    @apply before:w-[8px] after:w-[8px];
  }
  /* assign tag list modal */

  /* lead listing page css start */
  .page__ActionHeader__Lead .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__Lead .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* lead listing page css end */

  /* contact listing page css start */
  .page__ActionHeader__Contact .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__Contact .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* contact listing page css end */

  /* deal listing page css start */
  .page__ActionHeader__deal .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__deal .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* deal listing page css end */

  /* account listing page css start */
  .page__ActionHeader__account .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__account .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* account listing page css end */

  /* activity listing page css start */
  .page__ActionHeader__activity .activity__filter__btn .i__Icon {
    @apply bg-parentBgWhite__grayBtnBG;
  }
  .page__ActionHeader__activity .delete__Btn {
    @apply 3xl:mr-0 3xl:px-[13px];
  }
  .page__ActionHeader__activity .primary__Btn {
    @apply 3xl:px-[13px];
  }
  .activity__breadcrumbs .delete__Btn,
  .activity__breadcrumbs .primary__Btn {
    @apply xl:h-[36px] xl:px-[12px] xl:mb-0;
  }
  .page__ActionHeader__activity .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__activity .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* activity listing page css end */

  /* user listing page css start */
  .page__ActionHeader__user .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__user .header__searchBox .ip__form__hasIcon .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  .page__ActionHeader.page__ActionHeader__user .deleteDrop__wrapper .ip__Counter__Preview__Drop{
    @apply ml-0;
  }
  .page__ActionHeader.page__ActionHeader__user .deleteDrop__wrapper .delete__Btn{
    @apply mr-[10px]
  }
  /* user listing page css end */

  /* profile permissions listing page css start */
  .page__ActionHeader__profilePermission .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__profilePermission
    .header__searchBox
    .ip__form__hasIcon
    .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* profile permissions listing page css end */

  /* department listing page css start */
  .page__ActionHeader__department .header__searchBox .ip__input {
    @apply lg:h-[36px] lg:py-[4px] lg:pl-[30px] lg:pr-[10px] sm:h-[32px] sm:bg-transparent sm:pr-0 sm:pl-[24px] focus:border-transparent;
  }
  .page__ActionHeader__department
    .header__searchBox
    .ip__form__hasIcon
    .i__Icon {
    @apply p-[2px] w-[20px] h-[20px] top-[10px] left-[9px] lg:top-[7px] lg:left-[7px] sm:left-0 sm:top-[5px];
  }
  /* department listing page css end */

  /* page actions headers css end */

  /* action fixed button css start */
  .action__fixed__btn__PersonalSettings,
  .action__fixed__btn__CompanySettings,
  .action__fixed__btn__UserEditSettings,
  .action__fixed__btn__department,
  .action__fixed__btn__CreateProfile,
  .action__fixed__btn__leadEdit,
  .action__fixed__btn__contactEdit,
  .action__fixed__btn__dealEdit,
  .action__fixed__btn__accountEdit,
  .action__fixed__btn__activityEdit {
    @apply shadow-[0px_4px_21px_5px_#00000014] py-[20px] px-[25px] fixed bottom-0 right-0 w-[calc(100%_-_70px)] z-[4] bg-white md:w-full duration-300 md:px-[15px] md:py-[15px] md:rounded-t-[15px];
  }
  .fixed__wrapper__PersonalSettings,
  .fixed__wrapper__CompanySettings,
  .fixed__wrapper__UserEditSettings,
  .fixed__wrapper__leadEdit,
  .fixed__wrapper__contactEdit,
  .fixed__wrapper__dealEdit,
  .fixed__wrapper__accountEdit {
    @apply pb-[62px] md:pb-[50px];
  }
  .sidebar__Collapse .action__fixed__btn__PersonalSettings,
  .sidebar__Collapse .action__fixed__btn__CompanySettings,
  .sidebar__Collapse .action__fixed__btn__UserEditSettings,
  .sidebar__Collapse .action__fixed__btn__department,
  .sidebar__Collapse .action__fixed__btn__CreateProfile,
  .sidebar__Collapse .action__fixed__btn__leadEdit,
  .sidebar__Collapse .action__fixed__btn__contactEdit,
  .sidebar__Collapse .action__fixed__btn__dealEdit,
  .sidebar__Collapse .action__fixed__btn__accountEdit {
    @apply w-[calc(100%_-_280px)] xl:w-[calc(100%_-_250px)] md:w-full;
  }

  .settingAction__fixedBtn__Permission,
  .settingAction__fixedBtn__closingDate,
  .settingAction__fixedBtn__acTimeSet,
  .settingAction__fixedBtn__ACavailability,
  .settingAction__fixedBtn__ACalertTime,
  .settingAction__fixedBtn__emailUndo {
    @apply absolute bottom-0 left-0 right-0 w-full px-[25px] py-[20px] shadow-[0px_4px_21px_5px_#00000014] z-[2] bg-white xl:fixed xl:left-unset xl:w-[calc(100%_-_60px)] md:w-full md:py-[15px] md:rounded-t-[15px];
  }
  .sidebar__Collapse .settingAction__fixedBtn__Permission,
  .sidebar__Collapse .settingAction__fixedBtn__closingDate,
  .sidebar__Collapse .settingAction__fixedBtn__acTimeSet,
  .sidebar__Collapse .settingAction__fixedBtn__ACavailability,
  .sidebar__Collapse .settingAction__fixedBtn__ACalertTime,
  .sidebar__Collapse .settingAction__fixedBtn__emailUndo {
    @apply xl:w-[calc(100%_-_250px)] md:w-full md:px-[15px];
  }
  .fixed__wrapper__department {
    @apply pb-[80px] sm:pb-[60px];
  }
  /* action fixed button css end */

  /* activity listing page css start */
  /* date-range css start */
  .activity__date__filter .i__Icon {
    @apply bg-parentBgWhite__grayBtnBG;
  }
  .activity__date__filter .e-input-group-icon.e-range-icon {
    @apply w-[36px] h-[36px] p-0 opacity-0 ml-[10px];
  }
  .activity__date__filter .e-date-range-wrapper {
    @apply relative z-[2] bg-transparent h-[44px];
    background: transparent;
  }
  .activity__date__filter .e-date-range-wrapper .e-clear-icon {
    @apply absolute right-[56px] top-[50%] translate-y-[-50%] text-ipBlack__textColor hover:text-ipBlack__textColor;
  }
  .activity__date__filter .e-daterangepicker.e-input {
    @apply !hidden !h-[44px] !w-[225px] !rounded-[6px] !border !border-transparent !bg-formField__BGColor !pt-[6px] !pb-[4px] !px-[13px] !pr-[30px] !text-black__TextColor800 !text-[16px] !font-biotif__Regular !mr-[8px] placeholder:!opacity-100 placeholder:!text-disableLight__TextColor focus:!shadow-none focus:!outline-none focus:!border-ipBlack__borderColor sm:!w-[calc(100%_-_56px)];
  }
  .activity__date__filter .e-valid-input .e-daterangepicker.e-input {
    @apply !block;
  }
  /* date-range css end */

  /* filter css start */
  .activity__filter__wrapper {
    @apply relative;
  }
  .activity__filter__wrapper .filter__box {
    @apply mb-[12px];
  }
  .activity__filter__wrapper .filter__box:last-child {
    @apply mb-0;
  }
  .activity__filter__wrapper .activity__filter__dropdown {
    @apply absolute top-[calc(100%_+_10px)] left-0 z-[4] min-w-[200px] 3xl:left-unset 3xl:right-0;
  }
  .activity__filter__wrapper .activity__filter__dropdown.show {
    @apply block;
  }
  .activity__filter__wrapper .activity__filter__dropdown .inner__wrapper {
    @apply p-[12px] bg-ipBlue__transparentBG rounded-[6px] shadow-[0px_3px_17px_#0000001a];
  }
  .activity__filter__wrapper .filter__heading {
    @apply cursor-pointer text-[14px] font-biotif__Medium text-ipBlack__textColor relative mb-[5px] before:content-[''] before:absolute before:top-[9px] before:right-0 before:w-[10px] before:h-[2px] before:bg-ipBlack__bgColor after:content-[''] after:absolute after:top-[5px] after:right-[4px] after:w-[2px] after:h-[10px] after:bg-ipBlack__bgColor;
  }
  .activity__filter__wrapper .form__Group.full__Checkbox {
    @apply mb-0;
  }
  .activity__filter__wrapper .ip__Checkbox.full__Checkbox {
    @apply shadow-none;
  }
  .activity__filter__wrapper .ip__Checkbox.full__Checkbox .rc__Label {
    @apply text-light__TextColor text-[14px] pt-0 pl-0 pb-0 pr-[22px] before:w-[14px] before:h-[14px] before:right-[-3px] after:top-[calc(50%_-_1px)] after:right-0 after:w-[8px] after:h-[4px];
  }
  .activity__filter__wrapper .filter__contant.show {
    @apply block;
  }
  /* filter css end */
  /* activity listing page css end */

  /* deal listing page css start */
  .kanbanView__group__btn .listView__btn:hover .bg__wrapper {
    @apply bg-primaryColor opacity-10;
  }
  .kanbanView__group__btn .listView__btn.active .bg__wrapper {
    @apply bg-primaryColor opacity-10;
  }
  .kanbanView__group__btn .listView__btn.active path,
  .kanbanView__group__btn .kanbanView__btn.active path {
    @apply fill-ipBlack__textColor;
  }

  /* kanban view css start */
  /* .kanban__control__section {
    @apply mx-[-20px] mt-[10px];
  } */
  .kanban__control__section {
    @apply mt-[10px];
  }
  .kanban__control__section .e-kanban{
    @apply !bg-transparent !h-[calc(100vh_-_344px)] overflow-y-auto;
  }
  .kanban__control__section .e-kanban .e-kanban-table {
    @apply border-spacing-x-[20px];
  }
  .kanban__control__section .e-kanban .e-kanban-content {
    @apply pb-[20px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row:not(.e-swimlane-row)
    .e-content-cells
    .e-dropping {
    @apply border border-dashed border-[#a6a6a6];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-target-dropped-clone,
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-container
    .e-target-dropped-clone,
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-target-dragged-clone,
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-container
    .e-target-dragged-clone {
    @apply border border-dashed border-[#a6a6a6] bg-ipGray__transparentBG;
  }
  .kanban__control__section .e-kanban .e-kanban-table .e-header-cells {
    @apply rounded-b-[0px] rounded-t-[12px] bg-ipWhite__bgColor border border-whiteScreen__BorderColor w-[320px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-table.e-content-table
    .e-content-row:not(.e-swimlane-row)
    td {
    @apply bg-ipWhite__bgColor rounded-t-[0px] border border-whiteScreen__BorderColor border-t-0 rounded-b-[12px] p-0 w-[320px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-empty-card {
    @apply text-black__TextColor700 text-[16px] w-full rounded-[8px] text-center;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-header
    .e-header-cells
    .e-header-wrap
    .e-header-title {
    @apply items-center;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-table
    .e-header-cells
    .e-header-text {
    @apply text-[16px] text-light__TextColor font-biotif__Medium;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-table
    .e-header-cells
    .e-item-count {
    @apply text-[16px] text-ipBlack__textColor font-biotif__Medium capitalize;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card {
    @apply border-0 shadow-none rounded-[10px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card {
    @apply rounded-[10px] p-[14px] pb-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[10px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .cn__wrapper {
    @apply relative z-[3];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .ag__grid__toggle__btn {
    @apply p-0 bg-transparent hover:bg-[#ffffff] absolute top-[0px] right-[0px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .ag__grid__toggle__btn
    .i__Icon {
    @apply mr-0 ml-0 p-[5px] mt-[1px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .title {
    @apply text-[16px] font-biotif__Medium leading-[21px] mb-[6px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .text {
    @apply text-[14px] font-biotif__Regular text-mediumDark__TextColor leading-[20px];
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 4;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .valueTime {
    @apply flex flex-wrap items-center mt-[10px] justify-between;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .price__value {
    @apply flex items-center mb-[5px] mr-[8px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .currencySign {
    @apply w-[20px] h-[20px] rounded-[5px] text-[#ffffff] flex items-center justify-center leading-[14px] text-[14px] font-biotif__Regular pt-[1px] mr-[5px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .value,
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .time {
    @apply text-[12px] font-biotif__Medium text-mediumDark__TextColor;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .value {
    @apply pt-[2px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card
    .time {
    @apply mb-[5px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.blue__card {
    @apply before:bg-primaryColor before:opacity-10;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.blue__card
    .title {
    @apply text-primaryColor pr-[28px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.blue__card
    .currencySign {
    @apply bg-primaryColor;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.blue__card
    .ag__grid__toggle__btn
    circle {
    @apply fill-primaryColor;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.orange__card {
    @apply before:bg-ip__Orange before:opacity-10;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.orange__card
    .title {
    @apply text-ip__Orange pr-[28px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.orange__card
    .currencySign {
    @apply bg-ip__Orange;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.orange__card
    .ag__grid__toggle__btn
    circle {
    @apply fill-ip__Orange;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.green__card {
    @apply before:bg-ip__Green before:opacity-10;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.green__card
    .title {
    @apply text-ip__Green pr-[28px];
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.green__card
    .currencySign {
    @apply bg-ip__Green;
  }
  .kanban__control__section
    .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .inner__card.green__card
    .ag__grid__toggle__btn
    circle {
    @apply fill-ip__Green;
  }
  .kanban__control__section .e-card-wrapper{
    @apply !max-h-[calc(100vh_-_455px)];
  }
  /* kanban view css end */

  /* kanban-mobile */
  .kanban__mobile .kanban__mobile__header {
    @apply py-[14px] pl-[15px] pr-[35px] mb-[15px] cursor-pointer relative bg-ipGray__transparentBG rounded-[12px] before:content-[''] before:absolute before:top-[calc(50%_-_2px)] before:translate-y-[-50%] before:right-[14px] before:w-[10px] before:h-[10px] before:border-l-[2px] before:border-l-ipBlack__borderColor before:border-b-[2px] before:border-b-ipBlack__borderColor before:rotate-[-45deg];
  }
  .kanban__mobile .kanban__mobile__box.active .kanban__mobile__header {
    @apply before:rotate-[135deg] before:top-[50%];
  }
  .kanban__mobile .kanban__mobile__header .title {
    @apply text-ipBlack__textColor text-[16px] font-biotif__SemiBold;
  }
  .kanban__mobile .kanban__mobile__header .text {
    @apply text-light__TextColor text-[16px] font-biotif__Regular;
  }
  .kanban__mobile .kanban__mobile__body {
    @apply p-[14px] pb-[1px] bg-ipGray__transparentBG rounded-[10px] mb-[15px] hidden;
  }
  .kanban__mobile .kanban__mobile__box.active .kanban__mobile__body {
    @apply block;
  }
  .kanban__mobile__details__box {
    @apply mb-[14px];
  }
  .kanban__mobile__details__box .inner__box {
    @apply relative rounded-[10px] p-[14px] pb-[10px];
  }
  .kanban__mobile__details__box .ag__grid__toggle__btn {
    @apply absolute top-[12px] right-[10px] bg-transparent p-0 hover:bg-ipWhite__bgColor;
  }
  .kanban__mobile__details__box .ag__grid__toggle__btn .i__Icon {
    @apply mr-0 ml-0 mt-[1px] p-[5px];
  }
  .kanban__mobile__details__box .title {
    @apply mb-[6px] font-biotif__Medium text-[16px] leading-[21px];
  }
  .kanban__mobile__details__box .text {
    @apply text-[14px] leading-[20px] text-mediumDark__TextColor font-biotif__Regular;
  }
  .kanban__mobile__details__box .valueTime {
    @apply mt-[10px] flex flex-wrap items-center justify-between;
  }
  .kanban__mobile__details__box .valueTime .price__value {
    @apply mb-[5px] mr-[8px] flex items-center;
  }
  .kanban__mobile__details__box .valueTime .price__value .currencySign {
    @apply mr-[5px] flex h-[20px] w-[20px] items-center justify-center rounded-[5px] pt-[1px] font-biotif__Regular text-[14px] leading-[14px] text-[#ffffff];
  }
  .kanban__mobile__details__box .valueTime .price__value .value {
    @apply text-[12px] text-mediumDark__TextColor font-biotif__Medium pt-[2px];
  }
  .kanban__mobile__details__box .valueTime .price__value .time {
    @apply text-[12px] text-mediumDark__TextColor font-biotif__Medium;
  }
  .kanban__mobile__details__box.blue__box .inner__box {
    @apply bg-ipBlue__transparentBG;
  }
  .kanban__mobile__details__box.blue__box .title {
    @apply text-ip__Blue;
  }
  .kanban__mobile__details__box.blue__box .ag__grid__toggle__btn circle {
    @apply fill-ip__Blue;
  }
  .kanban__mobile__details__box.blue__box
    .valueTime
    .price__value
    .currencySign {
    @apply bg-ip__Blue;
  }
  .kanban__mobile__details__box.orange__box .inner__box {
    @apply bg-ipOrange__transparentBG;
  }
  .kanban__mobile__details__box.orange__box .title {
    @apply text-ip__Orange;
  }
  .kanban__mobile__details__box.orange__box .ag__grid__toggle__btn circle {
    @apply fill-ip__Orange;
  }
  .kanban__mobile__details__box.orange__box
    .valueTime
    .price__value
    .currencySign {
    @apply bg-ip__Orange;
  }
  .kanban__mobile__details__box.green__box .inner__box {
    @apply bg-ipGreen__transparentBG;
  }
  .kanban__mobile__details__box.green__box .title {
    @apply text-ip__Green;
  }
  .kanban__mobile__details__box.green__box .ag__grid__toggle__btn circle {
    @apply fill-ip__Green;
  }
  .kanban__mobile__details__box.green__box
    .valueTime
    .price__value
    .currencySign {
    @apply bg-ip__Green;
  }
  /* kanban-mobile-end */
  /* deal listing page css end */

  /* email listing page css start */
  /* email action header css start */
  .emailProvide__dropdown .emailProvide__dropdown__btn {
    @apply w-[200px] max-w-[200px] bg-[#ECF2F6] rounded-[10px] py-[8px] px-[12px] pr-[32px] relative before:content-[''] before:absolute before:top-[14px] before:right-[13px] before:w-[8px] before:h-[8px] before:border-l-[1px] before:border-l-primaryColor before:border-b-[1px] before:border-b-primaryColor before:rotate-[-45deg];
  }
  .emailProvide__dropdown .emailProvide__dropdown__btn > div {
    @apply flex items-center;
  }
  .emailProvide__dropdown .emailProvide__dropdown__btn .name {
    @apply w-full pl-[5px] text-left whitespace-pre overflow-hidden text-ellipsis;
  }
  .tippy-box.tippy__dropdown.emailProvide__tippy {
    @apply w-[200px] bg-[#ECF2F6] !shadow-[0px_1px_2px_#00000040] translate-x-[0px] translate-y-[-7px];
  }
  .tippy-box.tippy__dropdown.emailProvide__tippy .item {
    @apply flex items-center py-[8px] px-[11px];
  }
  .tippy-box.tippy__dropdown.emailProvide__tippy .item::before {
    @apply bg-[#1776ba1a];
  }
  .emailInbox__header .search__box .if__label {
    @apply hidden;
  }
  .emailInbox__header .search__box .ip__input {
    @apply bg-[#F2F6F9] h-[32px] rounded-[10px];
  }
  .emailInbox__header .search__box .i__Icon {
    @apply w-[22px] h-[22px] top-[4px];
  }
  .emailInbox__header .draftmail__btn:hover .i__Icon,
  .emailInbox__header .spammail__btn:hover .i__Icon,
  .emailInbox__header .delete__btn:hover .i__Icon,
  .emailInbox__header .reset__btn:hover .i__Icon,
  .emailInbox__header .filter__btn:hover .i__Icon {
    @apply hover:bg-primaryColor;
  }
  .emailInbox__header .draftmail__btn:hover svg path,
  .emailInbox__header .spammail__btn:hover svg path,
  .emailInbox__header .delete__btn:hover svg path,
  .emailInbox__header .reset__btn:hover svg path,
  .emailInbox__header .filter__btn:hover svg path {
    @apply fill-white;
  }
  .emailInbox__header .right .search__box {
    @apply 3xl:order-[-1] 3xl:ml-0 3xl:max-w-[calc(100%_-_309px)] sm:w-full sm:max-w-full;
  }
  /* email action header css end */

  /* email tabs css start */
  .emailInbox__tabs .item.active {
    @apply bg-primaryColor text-[#ffffff];
  }
  /* email tabs css end */

  .inboxMail__wrapper {
    @apply py-[5px] w-full overflow-x-auto lg:pt-0 lg:w-[calc(100%_+_30px)] lg:ml-[-15px];
  }
  .inboxMail__row {
    @apply flex items-center py-[15px] border-b border-b-whiteScreen__BorderColor duration-500 hover:shadow-[0px_0px_6px_#c6c6c6] hover:cursor-pointer hover:relative hover:z-[2] lg:relative lg:pb-[16px] lg:pt-[12px] lg:pl-[53px] lg:flex-wrap lg:pr-[15px];
  }
  .inboxMail__row.unread {
    @apply bg-[#f8fbff];
  }
  .inboxMail__row .inboxMail__cell {
    @apply px-[10px];
  }
  .inboxMail__row .time {
    @apply w-[65px] pr-0 pl-0 flex items-center justify-end text-[14px] font-biotif__Regular text-light__TextColor lg:absolute lg:top-[15px] lg:right-[15px] lg:text-[12px];
  }
  .inboxMail__row .private__btn {
    @apply w-[105px] flex items-center justify-center lg:p-[0px] lg:w-auto lg:mt-[9px];
  }
  .inboxMail__row .excerpt {
    @apply w-[calc(100%_-_645px)] pr-0 h-[23px] text-[16px] font-biotif__Regular text-light__TextColor whitespace-pre overflow-hidden text-ellipsis lg:w-full lg:p-0 lg:text-[14px] lg:whitespace-normal lg:text-inherit lg:leading-[17px] lg:h-[36px];
  }
  .inboxMail__row .excerpt .highlight__text {
    @apply font-biotif__Medium text-ipBlack__textColor lg:font-biotif__Regular lg:text-black lg:block lg:w-full lg:whitespace-pre lg:overflow-hidden lg:text-ellipsis lg:mb-[2px];
  }
  .inboxMail__row .subject {
    @apply w-[340px] text-[16px] font-biotif__Medium text-ipBlack__textColor whitespace-pre overflow-hidden text-ellipsis xl:w-[calc(50%_-_146px)] lg:w-full lg:p-0 lg:pr-[60px] lg:text-[14px] lg:mb-[5px] sm:mb-[3px];
  }
  .inboxMail__row .profile__img,
  .inboxMail__row .edit__btn {
    @apply w-[50px] flex items-center justify-center;
  }
  .inboxMail__row .profile__img .noName__letter,
  .inboxMail__row .profile__img .img__loader,
  .inboxMail__row .profile__img .img__element,
  .inboxMail__row .profile__img .no__img__icon {
    @apply !w-[30px] !h-[30px];
  }
  .inboxMail__row .profile__img .noName__letter span {
    @apply text-[12px] leading-[30px] pt-[2px];
  }
  .inboxMail__row .profile__img .no__img__icon .i__Icon {
    @apply !p-[6px] !w-full !h-full bg-ipGray__transparentBG rounded-full;
  }
  .inboxMail__row .email__address {
    @apply text-[16px] font-biotif__Regular text-ipBlack__textColor;
  }
  .inboxMail__row .edit__btn path {
    @apply fill-primaryColor duration-500 hover:fill-primaryColor__hoverDark;
  }
  .inboxMail__row .profile__img {
    @apply lg:absolute lg:left-[15px] lg:top-[14px] lg:pl-[0px] lg:w-[30px] lg:p-0;
  }
  .inboxMail__row .profile__img .image {
    @apply block w-[30px] h-[30px] rounded-full object-cover object-center;
  }
  .inboxMail__row .checkbox__wrapper {
    @apply w-[48px] pl-[20px] lg:absolute lg:p-[0px] lg:w-[20px] lg:right-[0px] lg:top-[14px] lg:hidden;
  }
  .inboxMail__row .checkbox__wrapper input:checked + .checkboxSquare {
    @apply bg-primaryColor border border-primaryColor after:opacity-100;
  }
  .inboxMail__row .provider__logo {
    @apply lg:absolute lg:p-0 lg:flex lg:items-center lg:justify-center lg:top-[51px] lg:left-[21px];
  }
  .inboxMail__row .provider__logo img {
    @apply lg:w-[17px];
  }
  .inboxMail__wrapper.draft .inboxMail__row {
    @apply border-b-0 lg:pl-[15px] lg:pb-[12px] lg:pr-[92px] lg:border-b lg:border-b-whiteScreen__BorderColor lg:last:border-b-0;
  }
  .inboxMail__wrapper.draft .inboxMail__row .edit__btn {
    @apply lg:absolute lg:top-[50%] lg:translate-y-[-50%] lg:right-[12px] lg:p-0 lg:w-auto lg:h-auto;
  }
  .inboxMail__wrapper.draft .inboxMail__row .time {
    @apply lg:top-[25px] lg:right-[43px];
  }
  .inboxMail__wrapper.draft .inboxMail__cell.excerpt {
    @apply w-[calc(100%_-_518px)] xl:w-[calc(60%_-_85px)] lg:w-full lg:pr-0 lg:h-[18px] lg:text-[12px] lg:text-black/50;
  }
  .inboxMail__wrapper.draft .inboxMail__cell.excerpt .highlight__text {
    @apply lg:text-black/50 !mb-0;
  }
  .inboxMail__wrapper.draft .inboxMail__cell.subject {
    @apply w-[340px] xl:w-[calc(40%_-_85px)] lg:w-full lg:pr-0 lg:mb-[2px];
  }
  .inboxMail__wrapper.sent .inboxMail__cell.excerpt {
    @apply w-[calc(100%_-_510px)] xl:w-[calc(50%_-_60px)] lg:w-full lg:text-black/50;
  }
  .inboxMail__wrapper.outbox .inboxMail__row .email__address {
    @apply w-[210px];
  }
  .inboxMail__wrapper.outbox .inboxMail__row .excerpt {
    @apply w-[calc(100%_-_450px)];
  }
  .inboxMail__wrapper.schedule .inboxMail__cell.subject {
    @apply w-[380px];
  }

  .inboxMail__wrapper.sent {
    @apply h-[calc(100dvh_-_293px)] overflow-y-auto 3xl:h-[calc(100dvh_-_334px)] xl:h-[calc(100dvh_-_319px)] lg:h-[calc(100dvh_-_150px)] md:h-[calc(100dvh_-_148px)];
  }
  .inboxMail__wrapper.draft {
    @apply h-[calc(100dvh_-_293px)] overflow-y-auto 3xl:h-[calc(100dvh_-_334px)] xl:h-[calc(100dvh_-_319px)] lg:h-[calc(100dvh_-_150px)] md:h-[calc(100dvh_-_148px)];
  }
  .inboxMail__wrapper.inbox {
    @apply h-[calc(100dvh_-_293px)] overflow-y-auto 3xl:h-[calc(100dvh_-_334px)] xl:h-[calc(100dvh_-_319px)] lg:h-[calc(100dvh_-_150px)] md:h-[calc(100dvh_-_148px)];
  }

  .inboxMail__wrapper.inbox .inboxMail__cell.subject {
    @apply w-[380px];
  }
  .inboxMail__wrapper.inbox .inboxMail__cell.excerpt {
    @apply w-[calc(100%_-_518px)];
  }
  .inboxMail__wrapper.schedule .time {
    @apply w-[212px] flex justify-start;
  }
  .inboxMail__wrapper.schedule .excerpt {
    @apply w-[calc(100%_-_650px)];
  }

  .compose__mobile__fixed__btn .i__Icon {
    @apply w-full h-full ml-0 mr-0 top-0;
  }

  .email__listing__mobile__header .filter__btn {
    @apply flex items-center justify-center w-[32px] h-[32px] rounded-[6px] bg-[#E6E6E6] duration-500 hover:bg-[#D0D0D0];
  }
  .email__listing__mobile__header .filter__btn .i__Icon {
    @apply w-full h-full p-[8px];
  }
  .email__listing__mobile__header .search__btn {
    @apply flex items-center justify-center w-[32px] h-[32px] rounded-[6px] duration-500;
  }
  .email__listing__mobile__header .search__btn .i__Icon {
    @apply w-full h-full p-[7px];
  }
  .email__listing__mobile__header .search__btn path {
    @apply fill-black/50;
  }

  .email__breadcrumbs {
    @apply lg:!mb-[8px] lg:hidden;
  }

  .email__mobile__menu.show {
    @apply translate-y-[0%];
  }
  .email__mobile__menu .provider__header .item.active {
    @apply bg-gradient-to-b from-[#1776BA] to-[#F78310] pointer-events-none;
  }
  .email__mobile__menu .setting__btn path {
    @apply fill-black/50 duration-500;
  }
  .email__mobile__menu .setting__btn:hover path {
    @apply fill-primaryColor;
  }
  .email__mobile__menu .menu .item.active {
    @apply text-primaryColor hover:text-primaryColor;
  }

  .email__account__list__modal .setting__btn path {
    @apply fill-black/50 duration-500;
  }
  .email__account__list__modal .setting__btn:hover path {
    @apply fill-primaryColor;
  }

  .email__mobile__filter__modal.show {
    @apply translate-y-0;
  }
  .email__mobile__filter__modal .form__Group {
    @apply mb-0;
  }
  .email__mobile__filter__modal .ip__Checkbox {
    @apply w-full !mb-[12px] last:!mb-0 shadow-none mb-[8px];
  }
  .email__mobile__filter__modal .ip__Checkbox .rc__Label {
    @apply text-[16px] font-biotif__Medium text-[#000000]/60 p-0 pl-[26px] before:w-[17px] before:h-[17px] before:border-[#76797B] before:rounded-[3px] before:left-0 before:right-unset before:top-[1px] after:duration-75 after:left-[4px] after:right-unset after:top-[6px] after:w-[9px] after:h-[5px] after:bg-transparent after:border-l-[2px] after:border-l-white after:border-b-[2px] after:border-b-white after:rounded-[0px] after:-rotate-45;
  }
  .email__mobile__filter__modal .ip__Checkbox:last-child {
    @apply mb-0;
  }
  .email__mobile__filter__modal .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColor before:rounded-[3px] before:border-primaryColor;
  }

  .email__pagination button .i__Icon svg path {
    @apply stroke-primaryColor;
  }
  .email__pagination button:hover .i__Icon svg path {
    @apply stroke-white;
  }

  /* schedule send modal css start */
  .ip__Modal__Wrapper.schedule__send__modal .ip__Modal__Footer {
    @apply !border-t-0 pb-[20px];
  }
  .schedule__send__checkbox .ip__Checkbox .rc__Label {
    @apply text-[16px];
  }
  .schedule__send__checkbox .ip__Checkbox .rc__Label::before {
    @apply border border-[#C4C4C4];
  }
  .schedule__send__checkbox .ip__Checkbox input:checked + .rc__Label::before {
    @apply border border-DarkBtn__BGcolor;
  }
  /* schedule send modal css end */

  .link__new__entity__modal .ip__Modal__Body {
    @apply overflow-visible;
  }

  /* compose mail modal css start */
  .composeMail__modal .mobile__viewBtn svg path {
    @apply fill-primaryColor;
  }
  .composeMail__modal .mobile__viewBtn:hover svg path {
    @apply fill-white;
  }

  .compose__mail__to__field .ip__Select .ip__select__dynamic__control,
  .compose__mail__cc__field .ip__Select .ip__select__dynamic__control,
  .compose__mail__bcc__field .ip__Select .ip__select__dynamic__control {
    @apply !min-h-[45px] pl-[40px] hover:pl-[40px] pr-[90px] hover:pr-[90px] bg-transparent hover:bg-transparent !border-0 !border-b border-solid !border-b-[#EDEFF1] hover:!border-0 hover:!border-b hover:border-solid hover:!border-b-[#EDEFF1] !rounded-none sm:pl-[30px] sm:hover:pl-[30px] sm:pr-0 sm:hover:pr-0;
  }
  .compose__mail__to__field .invalid__email .ip__select__dynamic__input,
  .compose__mail__cc__field .invalid__email .ip__select__dynamic__input,
  .compose__mail__bcc__field .invalid__email .ip__select__dynamic__input,
  .compose__mail__subject__field .invalid__email .ip__select__dynamic__input {
    @apply !text-ip__Red;
  }
  .compose__mail__cc__field .ip__Select .ip__select__dynamic__control {
    @apply pl-[44px] hover:pl-[44px] pr-[12px] hover:pr-[12px] sm:pr-0 sm:hover:pr-0 sm:pl-[34px] sm:hover:pl-[34px];
  }
  .compose__mail__bcc__field .ip__Select .ip__select__dynamic__control {
    @apply pl-[54px] hover:pl-[54px] pr-[12px] hover:pr-[12px] sm:pl-[42px] sm:hover:pl-[42px];
  }
  .compose__mail__to__field .ip__Select .ip__select__dynamic__indicators,
  .compose__mail__cc__field .ip__Select .ip__select__dynamic__indicators,
  .compose__mail__bcc__field .ip__Select .ip__select__dynamic__indicators {
    @apply hidden;
  }
  .compose__mail__to__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    input,
  .compose__mail__cc__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    input,
  .compose__mail__bcc__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    input {
    @apply top-0;
  }
  .compose__mail__subject__field .ip__input {
    @apply !border-0 min-h-[45px] bg-transparent pl-[86px] !border-b border-solid !border-b-[#EDEFF1] focus:!border-b focus:border-solid focus:!border-b-[#EDEFF1] sm:pl-[75px] sm:pr-0;
  }
  .compose__mail__select__field {
    @apply min-h-[45px] border-b border-solid border-b-[#EDEFF1] pt-[7px] pb-[2px] pl-[76px] pr-[15px] flex flex-wrap items-start sm:pl-0 sm:pr-0 sm:pt-[42px];
  }
  .compose__mail__select__field .compose__mail__select__dBtn {
    @apply text-[#00000080] font-biotif__Regular text-[16px] bg-[#ECF2F6] py-[3px] px-[15px] pr-[28px] rounded-[10px] relative before:content-[''] before:absolute before:top-[7px] before:right-[10px] before:w-[9px] before:h-[9px] before:border-l-[2px] before:border-l-[#00000080] before:border-b-[2px] before:border-b-[#00000080] before:rotate-[-45deg];
  }
  .compose__mail__to__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder,
  .compose__mail__cc__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder,
  .compose__mail__bcc__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder {
    @apply h-[30px] w-full;
  }
  .compose__mail__to__field .if__label,
  .compose__mail__cc__field .if__label,
  .compose__mail__bcc__field .if__label,
  .compose__mail__subject__field .if__label {
    @apply hidden;
  }
  .compose__mail__to__field
    .ip__Select
    div[class$="ValueContainer"]
    div[class$="multiValue"],
  .compose__mail__to__field
    .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value {
    @apply !bg-primaryColor;
  }
  body .compose__mail__textarea .e-richtexteditor .e-rte-content .e-content {
    @apply bg-[#ECF2F6] pb-[14px] rounded-[12px] min-h-[420px] focus:!border-0 sm:min-h-[200px] sm:!px-[14px];
  }
  body .compose__mail__textarea .e-richtexteditor .e-rte-content {
    @apply min-h-[420px] sm:min-h-[200px];
  }
  body
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content
    .templateInput__field {
    @apply w-[230px];
  }
  .composeMail__modal .left__action__bar .action__btn,
  .new__email__template .left__action__bar .action__btn {
    @apply flex items-center justify-center w-[30px] h-[30px] rounded-[6px] relative hover:bg-ipGray__transparentBG mr-[6px] sm:mr-[4px];
  }
  .composeMail__modal .left__action__bar .action__btn:last-child,
  .new__email__template .left__action__bar .action__btn:last-child {
    @apply mr-0;
  }
  .composeMail__modal .left__action__bar .action__btn .i__Icon,
  .new__email__template .left__action__bar .action__btn .i__Icon {
    @apply p-[1px];
  }
  .composeMail__modal .left__action__bar .action__btn.upload__file input,
  .new__email__template .left__action__bar .action__btn.upload__file input {
    @apply absolute top-0 left-0 w-full h-full z-[2] opacity-0 cursor-pointer;
  }
  .composeMail__modal .modalAction__btn {
    @apply absolute top-[14px] right-[16px] z-[6];
  }
  .composeMail__modal .modalAction__btn .i__Icon {
    @apply flex w-[30px] h-[30px] cursor-pointer flex-wrap items-center justify-center rounded-full p-0 duration-300 hover:bg-[#ffffff];
  }
  .composeMail__modal .modalAction__btn .i__Icon svg path {
    @apply stroke-black;
  }
  .composeMail__modal
    .template__add__box
    .ip__Select
    .ip__select__dynamic__control {
    @apply bg-[#F2F6F9] rounded-[6px];
  }
  .composeMail__modal .template__add__box .closeBtn {
    @apply absolute top-[8px] right-[6px] flex w-[30px] h-[30px] cursor-pointer flex-wrap items-center justify-center rounded-full p-0 duration-300 hover:bg-greyScreen__BorderColor;
  }
  .composeMail__modal .template__add__box .closeBtn svg path {
    @apply stroke-black;
  }
  .composeMail__modal .reply__btn__wrapper {
    @apply inline-flex absolute top-[9px] right-0 z-[4];
  }
  .composeMail__modal .reply__btn__wrapper .reply__btn .i__Icon {
    @apply p-0 relative top-[-1px] mr-[2px] lg:top-0;
  }
  .composeMail__modal .reply__btn__wrapper .reply__btn .i__Icon svg path {
    @apply fill-[#808080] duration-300;
  }
  .composeMail__modal .reply__btn__wrapper .reply__btn:hover .i__Icon svg path {
    @apply fill-primaryColor;
  }
  .composeMail__modal .reply__btn__wrapper .reply__btn:hover .text {
    @apply text-primaryColor;
  }

  /* templateInput__field css start */
  .templateInput__field {
    @apply ml-[5px] mr-[5px] mt-[2px] mb-[2px] text-[16px] font-biotif__Regular text-black/50 py-[7px] pr-[10px] pl-[36px] rounded-[10px] bg-[url('assets/images/lockOpenFilledIcon.svg')] bg-no-repeat bg-[length:20px_20px] bg-[10px_center];
  }
  .templateInput__field.templateInput__field__account {
    @apply bg-[url('assets/images/accountFilledBlueIcon.svg')];
  }
  .templateInput__field.templateInput__field__contact {
    @apply bg-[url('assets/images/phoneFilledIcon.svg')];
  }
  .templateInput__field.templateInput__field__lead {
    @apply bg-[url('assets/images/leadsFilledBlueIcon.svg')];
  }
  .templateInput__field.templateInput__field__deal {
    @apply bg-[url('assets/images/dealsFilledBlueIcon.svg')];
  }
  .templateInput__field.ip__disabled,
  .templateInput__field[disabled] {
    @apply bg-white/60 opacity-80;
  }
  /* templateInput__field css end */

  .composeMail__modal
    .connected__activity__listing
    .cal__box
    .i__Icon
    svg
    path[fill] {
    @apply fill-primaryColor;
  }
  .composeMail__modal
    .connected__activity__listing
    .cal__box
    .i__Icon
    svg
    path[stroke] {
    @apply stroke-primaryColor;
  }
  .composeMail__modal .ip__Modal__ContentWrap {
    @apply md:overflow-hidden;
  }
  .composeMail__modal .ip__Modal__Body > .right {
    @apply md:duration-500 md:translate-x-[110%];
  }
  .composeMail__modal .ip__Modal__Body > .right.active {
    @apply md:translate-x-[0px];
  }
  .composeMail__modal.ip__Modal__Wrapper .ip__Modal__Body {
    @apply md:!pl-[15px] md:!pr-[15px] sm:max-h-[calc(100dvh_-_280px)];
  }

  /* choose template tippy dropdown css start */
  .tippy__dropdown.compose__mail__select__tippy {
    @apply shadow-none px-[14px] pt-[14px] pb-[25px] rounded-[10px] min-w-[385px] !translate-y-[-4px] sm:min-w-[320px] sm:pb-[10px] xsm:min-w-[290px];
  }
  .tippy__dropdown.compose__mail__select__tippy .ip__input {
    @apply text-[14px] pl-[33px] border border-[#F1F1F1];
  }
  .tippy__dropdown.compose__mail__select__tippy .ip__form__hasIcon .i__Icon {
    @apply opacity-100 top-[8px] w-[21px] h-[21px];
  }
  .tippy__dropdown.compose__mail__select__tippy .ip__form__hasIcon svg path {
    @apply fill-black;
  }
  .tippy__dropdown.compose__mail__select__tippy
    .tippy__dropdown__ul
    .lock__icon {
    @apply relative top-[-2px];
  }
  .tippy__dropdown.compose__mail__select__tippy
    .tippy__dropdown__ul
    .item:last-child {
    @apply border-b-0;
  }
  .tippy__dropdown.compose__mail__select__tippy .types__icon .i__Icon path {
    @apply fill-[#2E3234];
  }
  .tippy__dropdown.compose__mail__select__tippy
    .types__icon
    .i__Icon:hover
    path {
    @apply fill-primaryColor;
  }
  .tippy__dropdown.compose__mail__select__tippy .types__icon .i__Icon.active {
    @apply !bg-primaryColor;
  }
  .tippy__dropdown.compose__mail__select__tippy
    .types__icon
    .i__Icon.active
    path {
    @apply fill-[#ffffff];
  }
  .tippy-box.tippy__dropdown.emailTemplateCH__tippy .item {
    @apply py-0 cursor-auto;
  }
  /* choose template tippy dropdown css end */

  .tippy-box.tippy__dropdown.compose__mail__submit {
    @apply bg-transparent !shadow-none;
  }

  .tippy-box.tippy__dropdown.tippy__dropdown__emoji .emoji__wrapper {
    @apply w-[265px] p-[6px] max-h-[220px] overflow-y-auto;
  }
  .tippy-box.tippy__dropdown.tippy__dropdown__emoji .emoji__box {
    @apply w-[28px] h-[28px] rounded-[5px] inline-flex items-center justify-center duration-500 cursor-pointer hover:bg-ip__Grey__hoverDark;
  }

  .tippy-box.tippy__dropdown.emial__linkDealLead__tippy {
    @apply !shadow-none bg-[#F2F6F9];
  }

  .composeMail__modal .recipients .ip__input {
    @apply bg-white rounded-[10px];
  }
  .composeMail__modal .recipients .ip__Select div[class$="control"],
  .composeMail__modal .recipients .ip__Select div[class$="control"]:hover,
  .composeMail__modal .recipients .ip__Select .ip__select__dynamic__control,
  .composeMail__modal
    .recipients
    .ip__Select
    .ip__select__dynamic__control:hover {
    @apply bg-white;
  }
  .composeMail__modal .recipients__box:last-child {
    @apply pb-0 mb-0 border-b-0;
  }
  .composeMail__modal .recipients__box .img__wrapper .noName__letter,
  .composeMail__modal .recipients__box .img__wrapper .img__loader,
  .composeMail__modal .recipients__box .img__wrapper .img__element,
  .composeMail__modal .recipients__box .img__wrapper .no__img__icon {
    @apply !w-full !h-full !bg-white;
  }
  .composeMail__modal .recipients__box .img__wrapper .noName__letter span {
    @apply text-[14px] !text-primaryColor;
  }
  .composeMail__modal .recipients__box .img__wrapper .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .composeMail__modal .recipients__box .closeBtn {
    @apply cursor-pointer absolute top-[50%] translate-y-[-50%] right-0 flex w-[30px] h-[30px] items-center justify-center rounded-full p-0 duration-500 hover:bg-white;
  }
  .composeMail__modal .recipients__box .closeBtn svg path {
    @apply stroke-black;
  }
  .composeMail__modal .recipients__box .img__wrapper .noName__letter__text {
    @apply leading-[44px] pt-[4px] text-[16px];
  }
  /* compose mail modal css end */

  /* manage template modal css start */
  .ip__Modal__Wrapper.new__messageEmail__modal .search__box .i__Icon {
    @apply opacity-100;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal .search__box .i__Icon svg path {
    @apply fill-black;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal .ip__Modal__Footer {
    @apply border-t-0 pt-0 pb-[25px];
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .new__message__listing__table
    .delete__btn {
    @apply bg-ipRed__transparentBG rounded-[4px] w-[27px] h-[27px] p-[6px] hover:bg-ip__Red duration-500 cursor-pointer relative top-[-2px];
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .new__message__listing__table
    .delete__btn
    svg
    path {
    @apply fill-ip__Red;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .new__message__listing__table
    .delete__btn:hover
    svg
    path {
    @apply fill-white;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .new__message__listing__table
    .nmlt__body
    .nmlt__row:last-child {
    @apply border-b-0;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal .mobile__wrapper > div button {
    @apply w-full;
  }
  .ip__Modal__Wrapper.new__messageEmail__modal .ip__Modal__Footer .i__Button {
    @apply m-0 w-[calc(50%_-_5px)] h-[42px];
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .ip__Modal__Footer
    .i__Button.secondary__Btn {
    @apply mr-[5px];
  }
  .ip__Modal__Wrapper.new__messageEmail__modal
    .ip__Modal__Footer
    .i__Button.primary__Btn {
    @apply ml-[5px];
  }
  /* manage template modal css end */

  /* new email template modal css start */
  .ip__Modal__Wrapper.new__email__template .left__action__bar {
    @apply w-[calc(100%_-_232px)] pr-[10px] sm:w-full sm:pr-0;
  }
  .ip__Modal__Wrapper.new__email__template .ip__Modal__Footer > .right {
    @apply w-auto sm:w-full sm:justify-between sm:mb-0;
  }
  .ip__Modal__Wrapper.new__email__template
    .ip__Modal__Footer
    > .right
    .i__Button {
    @apply w-[calc(50%_-_5px)];
  }
  .ip__Modal__Wrapper.new__email__template
    .ip__Modal__Footer
    > .right
    .i__Button:last-child {
    @apply mr-0;
  }
  .ip__Modal__Wrapper.new__email__template .ip__Modal__Footer {
    @apply !pb-[15px];
  }
  .new__email__template.new__email__template .subject__field {
    @apply flex flex-wrap items-center border-b border-b-black/10 pb-[15px] mb-[15px];
  }
  .new__email__template.new__email__template .subject__field .if__label {
    @apply inline-block mb-0;
  }
  .new__email__template.new__email__template .subject__field > div {
    @apply w-[calc(100%_-_57px)] pl-[12px];
  }
  .new__email__template.new__email__template .subject__field input {
    @apply focus:border-transparent p-0;
  }
  .new__email__template.new__email__template .compose__mail__select__field {
    @apply border-b-transparent pt-0 pb-0 pl-[66px] pr-0 min-h-0;
  }
  .new__email__template.new__email__template .e-richtexteditor .e-rte-content {
    @apply min-h-[200px];
  }
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content {
    @apply bg-[#ECF2F6] rounded-[12px] min-h-[200px] focus:!border-0 !px-[20px] !py-[15px] sm:min-h-[200px] sm:!px-[14px];
  }
  .new__email__template.new__email__template
    .e-richtexteditor
    .e-rte-content
    .e-content
    .templateInput__field {
    @apply w-[230px];
  }
  /* new email template modal css end */
  /* email listing page css end */

  /* stay in touch modal css start */
  .ip__Modal__Wrapper.stayinTouch__modal .ip__Modal__Footer {
    @apply border-t-0 px-[36px] pb-[22px] sm:px-[15px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .days__input__wrapper .ip__input,
  .ip__Modal__Wrapper.stayinTouch__modal .time__input__wrapper .ip__input {
    @apply w-[90px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .days__input__wrapper.error__active
    .absolute__label,
  .ip__Modal__Wrapper.stayinTouch__modal
    .time__input__wrapper.error__active
    .absolute__label {
    @apply bottom-[34px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .time__input__wrapper .e-time-icon{
    @apply absolute top-[8px] right-0;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .time__input__wrapper  .e-input-group.e-time-wrapper input.e-input{
    @apply !font-normal !font-biotif__Medium !text-sdNormal__textColor !pt-[13px];
  }

  .ip__Modal__Wrapper.stayinTouch__modal .time__input__wrapper .e-clear-icon{
    @apply absolute top-[13px] right-[26px];  
  }
  .schedule__send__modal .e-time-icon{
    @apply absolute top-[8px] right-0;
  }
   .schedule__send__modal  .e-input-group.e-time-wrapper input.e-input{
    @apply !font-normal !font-biotif__Medium !text-sdNormal__textColor !pt-[13px];
  }
  .schedule__send__modal .e-clear-icon{
    @apply absolute top-[13px] right-[26px];  
  }
  .ip__Modal__Wrapper.stayinTouch__modal .week__wrapper .ip__Radio {
    @apply mr-[5px] mb-[5px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .week__wrapper
    .form__Group:last-child
    .ip__Radio {
    @apply mr-0;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .week__wrapper .ip__Radio .rc__Label {
    @apply before:hidden after:hidden w-[54px] bg-[#ECF2F6] text-[14px] text-center font-biotif__Medium text-black/50 rounded-[6px] duration-500 py-[7px] px-[10px] sm:text-[14px] sm:px-[10px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .week__wrapper
    .ip__Radio
    input:checked
    + .rc__Label {
    @apply bg-primaryColor text-white;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .chooseOP__wrapper .ip__Error {
    @apply mt-0;
  }

  /* new design start */
  .ip__Modal__Wrapper.stayinTouch__modal
    .frequency__wrapper
    .ip__Select
    div[class$="IndicatorsContainer"],
  .ip__Modal__Wrapper.stayinTouch__modal
    .frequency__wrapper
    .ip__Select
    .ip__select__dynamic__indicators,
  .ip__Modal__Wrapper.stayinTouch__modal
    .on__the__select
    .ip__Select
    div[class$="IndicatorsContainer"],
  .ip__Modal__Wrapper.stayinTouch__modal
    .on__the__select
    .ip__Select
    .ip__select__dynamic__indicators {
    @apply mr-[-22px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .frequency__wrapper .ip__Select div[class$="control"],
  .ip__Modal__Wrapper.stayinTouch__modal .frequency__wrapper .ip__Select .ip__select__dynamic__control,
  .ip__Modal__Wrapper.stayinTouch__modal .on__the__select .ip__Select div[class$="control"],
  .ip__Modal__Wrapper.stayinTouch__modal .on__the__select .ip__Select .ip__select__dynamic__control {
    @apply pl-[10px] pr-[28px] min-h-[34px] py-0;
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .frequency__wrapper
    .ip__Select
    div[class$="singleValue"],
  .ip__Modal__Wrapper.stayinTouch__modal
    .frequency__wrapper
    .ip__Select
    .ip__select__dynamic__single-value,
  .ip__Modal__Wrapper.stayinTouch__modal
    .on__the__select
    .ip__Select
    div[class$="singleValue"],
  .ip__Modal__Wrapper.stayinTouch__modal
    .on__the__select
    .ip__Select
    .ip__select__dynamic__single-value {
    @apply text-[14px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .frequency__wrapper .required__sign,
  .ip__Modal__Wrapper.stayinTouch__modal
    .frequency__wrapper
    .form__Group
    .if__label {
    @apply hidden;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .everyDays__wrapper .ip__input {
    @apply min-h-[34px] py-0;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .week__wrapper {
    @apply flex flex-wrap items-start;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .week__wrapper .form__Group {
    @apply mb-0 inline-flex;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .rounded__radio .form__Group {
    @apply mb-[8px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .rounded__radio .rc__Label {
    @apply font-biotif__Medium text-formLabel__blueColor text-[16px] before:w-[18px] before:h-[18px] before:top-[1px] before:border-[2px] before:border-[#A9A9A9] after:bg-[#6697BF] after:w-[10px] after:h-[10px] after:left-[4px] after:top-[5px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .rounded__radio
    input:checked
    + .rc__Label {
    @apply before:border-[2px] before:border-[#6697BF] before:bg-transparent;
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .rounded__radio
    .ip__Checkbox
    .rc__Label {
    @apply before:!rounded-full after:!border-0 after:rounded-full after:!rotate-0;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .month__wrapper {
    @apply inline-flex flex-wrap w-[254px] max-w-full border-l border-l-[#D5D4DF] border-t border-t-[#D5D4DF];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .month__wrapper.disabled {
    @apply opacity-60;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .month__wrapper .month__box {
    @apply w-[36px] h-[36px] overflow-hidden border border-[#D5D4DF] border-l-0 border-t-0 duration-500 hover:bg-[#D5D4DF];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .month__wrapper .month__box.active {
    @apply bg-primaryColor text-white;
  }
  .ip__Modal__Wrapper.stayinTouch__modal
    .month__wrapper
    .month__box.active
    span {
    @apply text-white duration-500;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .month__wrapper .month__box span {
    @apply w-full h-full text-black/80 text-[12px] leading-[12px] font-biotif__SemiBold flex items-center justify-center;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .year__wrapper {
    @apply flex flex-wrap;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .year__wrapper .year__box {
    @apply w-[56px] text-[14px] text-center text-black/50 font-biotif__Medium py-[7px] px-[8px] rounded-[6px] bg-[#ECF2F6] mr-[14px] mb-[10px] duration-500;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .year__wrapper .year__box.active {
    @apply bg-primaryColor text-white;
  }
  .ip__Modal__Wrapper.stayinTouch__modal .year__wrapper .year__box:nth-child(6),
  .ip__Modal__Wrapper.stayinTouch__modal
    .year__wrapper
    .year__box:nth-child(12) {
    @apply mr-0 sm:mr-[14px];
  }
  .ip__Modal__Wrapper.stayinTouch__modal .time__input__wrapper .ip__input {
    @apply w-[115px];
  }
  /* new design end */
  /* stay in touch modal css end */

  /* details pages comman css start */
  /* horizontal tabs css start */
  .horizontalTabs__wrapper {
    @apply relative before:content-[''] before:absolute before:top-0 before:right-0 before:h-full before:w-[30px] before:bg-gradient-to-r before:from-[#fff0] before:to-[#fff] before:z-[2] after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[1px] after:bg-whiteScreen__BorderColor sm:before:hidden sm:after:hidden;
  }
  .horizontalTabs__ul {
    @apply flex whitespace-pre overflow-x-auto sm:bg-[#F2F6F9] sm:rounded-[10px] sm:border sm:border-[#CCCCCC]/50 sm:p-[5px] sm:before:content-[""] sm:before:absolute sm:before:top-[50%] sm:before:left-[5px] sm:before:w-[calc(50%_-_5px)] sm:before:h-[calc(100%_-_10px)] sm:before:bg-primaryColor sm:before:z-[2] sm:before:translate-y-[-50%] sm:before:rounded-[6px] sm:before:duration-500;
  }
  .horizontalTabs__ul.timeline__tab__active {
    @apply sm:before:left-[calc(50%_-_1px)];
  }
  .horizontalTabs__ul .item {
    @apply inline-block pr-[10px] sm:pr-0 sm:w-1/2 sm:relative sm:z-[3];
  }
  .horizontalTabs__ul .item:last-child {
    @apply pr-[20px] sm:pr-0;
  }
  .horizontalTabs__ul .link {
    @apply flex items-center justify-center py-[13px] px-[15px] min-w-[260px] rounded-t-[12px] border border-[#CCCCCC]/50 border-b-0 duration-500 hover:bg-parentBgWhite__grayBtnBG sm:min-w-[0px] sm:w-full sm:border-0 sm:py-[8px] sm:hover:bg-transparent;
  }
  .horizontalTabs__ul .link .text {
    @apply text-ipBlack__textColor text-[16px] font-biotif__Medium relative top-[2px] pl-[5px] sm:text-primaryColor sm:text-[14px] sm:top-0 sm:pl-[3px];
  }
  .horizontalTabs__ul .link .i__Icon {
    @apply sm:relative sm:top-[-1px];
  }
  .horizontalTabs__ul .link .i__Icon svg path[fill] {
    @apply fill-ipBlack__textColor;
  }
  .horizontalTabs__ul .link .i__Icon svg path[stroke] {
    @apply stroke-ipBlack__textColor;
  }
  .horizontalTabs__ul .link svg path[fill] {
    @apply sm:fill-primaryColor;
  }
  .horizontalTabs__ul .link svg path[stroke] {
    @apply sm:stroke-primaryColor;
  }
  .horizontalTabs__ul .item.active .link {
    @apply bg-[#F2F6F9] hover:bg-[#F2F6F9] relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[1px] after:bg-[#F2F6F9] after:z-[2] sm:bg-transparent sm:after:bg-transparent sm:hover:bg-transparent;
  }
  .horizontalTabs__ul .item.active .link .text {
    @apply text-primaryColor sm:text-white sm:duration-1000;
  }
  .horizontalTabs__ul .item.active svg path {
    @apply sm:duration-1000;
  }
  .horizontalTabs__ul .item.active svg path[fill] {
    @apply fill-primaryColor sm:fill-white;
  }
  .horizontalTabs__ul .item.active svg path[stroke] {
    @apply stroke-primaryColor sm:stroke-white;
  }
  /* horizontal tabs css end */

  .detailsPageNew {
    @apply sm:pb-[30px];
  }
  .detailsPageNew .breadcrumbs__wrapper {
    @apply mb-[14px];
  }
  .detailsPageNew .action__bar {
    @apply mb-[10px];
  }
  .detailsPageNew .horizontalTabs__ul .link {
    @apply min-w-[0px] px-[22px] border-none relative before:content-[''] before:absolute before:bottom-[0px] before:left-0 before:h-[3px] before:w-[0px] before:bg-primaryColor before:duration-500;
  }
  .detailsPageNew .horizontalTabs__ul .item.active .link {
    @apply bg-transparent before:w-full;
  }

  .follow__dropdown__wrapper .follower__item .profile .noName__letter,
  .follow__dropdown__wrapper .follower__item .profile .img__loader,
  .follow__dropdown__wrapper .follower__item .profile .img__element,
  .follow__dropdown__wrapper .follower__item .profile .no__img__icon {
    @apply !w-full !h-full !bg-white;
  }
  .follow__dropdown__wrapper .follower__item .profile .noName__letter span {
    @apply text-[14px] !text-primaryColor;
  }
  .follow__dropdown__wrapper .follower__item .profile .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .follow__dropdown__wrapper .follower__item .delete__btn path {
    @apply fill-ip__Red;
  }
  .follow__dropdown__wrapper .follower__item .delete__btn:hover path {
    @apply fill-white;
  }

  .detailsPageNew .contact__details p > span,
  .detailsPageNew .contact__details p > a,
  .detailsPageNew .contact__details div > a {
    @apply text-black__TextColor600 hover:text-primaryColor;
  }
  .detailsPage__action__breadcrumbs .breadcrumbs {
    @apply mb-0 mt-[5px] mr-[15px];
  }
  .detailsPage__action__breadcrumbs .editACT__btn:hover svg path {
    @apply fill-white;
  }

  .details__page__description .ipInfo__ViewBox {
    @apply mb-0 px-0;
  }
  .details__page__description .ipInfo__ViewBox .ipInfo__View__Value {
    @apply pl-0 text-black__TextColor600 sm:text-[16px];
  }
  .details__page__description .inline__editing__btn__static {
    @apply p-0;
  }
  .details__page__description .inlineEdit__On {
    @apply mt-0;
  }

  .socialBtns__wrapper .i__Icon {
    @apply bg-parentBgGray__grayBtnBG rounded-[9px] p-[8px] hover:bg-primaryColor;
  }
  .socialBtns__wrapper .i__Icon svg path[fill] {
    @apply fill-black;
  }
  .socialBtns__wrapper .i__Icon svg path[stroke] {
    @apply stroke-black;
  }
  .socialBtns__wrapper .i__Icon:hover svg path[fill] {
    @apply fill-white;
  }
  .socialBtns__wrapper .i__Icon:hover svg path[stroke] {
    @apply stroke-white;
  }
  .socialBtns__wrapper .link__wrapper {
    @apply inline-flex mr-[20px] mb-[10px];
  }
  .socialBtns__wrapper .link__wrapper > button,
  .socialBtns__wrapper .link__wrapper > span {
    @apply mr-0 sm:mr-0;
  }
  .socialBtns__wrapper .link__wrapper .socian__ani__icon__wrapper {
    @apply p-[2px];
  }

  .tippy__stayIn__touch.tippy-box.tippy__dropdown .item {
    @apply px-[10px];
  }

  .details__RelatedContact__body {
    @apply overflow-x-auto;
  }
  .details__RelatedContact__row {
    @apply min-w-[900px];
  }
  .details__RelatedContact__row:last-child {
    @apply border-b-0;
  }
  .details__RelatedContact__row .details__RelatedContact__cell.details__cell,
  .details__RelatedContact__row .details__RelatedContact__cell.phone__cell,
  .details__RelatedContact__row .details__RelatedContact__cell.email__cell {
    @apply pr-[15px];
  }
  .details__RelatedContact__row .details__RelatedContact__cell.phone__cell {
    @apply min-w-[300px] max-w-[300px];
  }
  .details__RelatedContact__row .noName__letter,
  .details__RelatedContact__row .img__loader,
  .details__RelatedContact__row .img__element,
  .details__RelatedContact__row .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .details__RelatedContact__row .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .details__RelatedContact__row .img__element {
    @apply object-cover object-center;
  }
  .details__RelatedContact__row .noName__letter .noName__letter__text {
    @apply text-[15px] leading-[40px] pt-[4px];
  }
  .details__RelatedContact__row .not__confirmed circle {
    @apply fill-[#B9B8B8];
  }
  .details__RelatedContact__row .inlineEdit__On {
    @apply mt-[0px];
  }
  .details__RelatedContact__row .inlineEdit__On .inlineEdit__action {
    @apply absolute bottom-[-35px] pt-[0px] w-auto right-0;
  }

  .sideMenu__open .related__contact__card {
    @apply lg:w-full;
  }
  /* .related__contact__card .img__wrapper .noName__letter,
  .related__contact__card .img__wrapper .img__loader,
  .related__contact__card .img__wrapper .img__element,
  .related__contact__card .img__wrapper .no__img__icon,
  .guest__card .img__wrapper .noName__letter,
  .guest__card .img__wrapper .img__loader,
  .guest__card .img__wrapper .img__element,
  .guest__card .img__wrapper .no__img__icon{
    @apply !w-full !h-full rounded-full;
  }
  .related__contact__card .img__wrapper .noName__letter span,
  .guest__card .img__wrapper .noName__letter span{
    @apply text-[14px] leading-[40px] pt-[2px];
  }
  .related__contact__card .img__wrapper .no__img__icon .i__Icon,
  .guest__card .img__wrapper .no__img__icon .i__Icon{
    @apply !p-[9px];
  }
  .related__contact__card .socialBtns__wrapper .link__wrapper{
    @apply mr-0;
  }
  .related__contact__card .socialBtns__wrapper > a button,
  .related__contact__card .socialBtns__wrapper > button{
    @apply !mr-[12px];
  } */
  .guest__card .not__confirmed svg circle {
    @apply fill-[#B9B8B8];
  }
  /* .related__contact__card{
    @apply relative flex flex-wrap items-start h-full border border-whiteScreen__BorderColor rounded-[10px] p-[14px] px-[14px] pb-[74px];
  } */

  .related__card__box {
    @apply relative flex flex-wrap items-start h-full border border-whiteScreen__BorderColor rounded-[10px] p-[14px] px-[14px] pb-[74px];
  }
  .related__card__box .inner__wrapper {
    @apply flex flex-wrap w-full;
  }
  .related__card__box .img__wrapper {
    @apply w-[40px] h-[40px];
  }
  .related__card__box .img__wrapper .noName__letter,
  .related__card__box .img__wrapper .img__loader,
  .related__card__box .img__wrapper .img__element,
  .related__card__box .img__wrapper .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .related__card__box .img__wrapper .noName__letter span {
    @apply text-[14px] leading-[40px] pt-[2px];
  }
  .related__card__box .img__wrapper .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .related__card__box .right__details {
    @apply w-[calc(100%_-_40px)] pl-[10px];
  }
  .related__card__box .toggle__btn .i__Icon {
    @apply !bg-transparent !w-[28px] !h-[28px] !rounded-[6px] !p-[5px] relative top-[0px] border-[1px] border-whiteScreen__BorderColor duration-500 hover:!bg-parentBgWhite__grayBtnBG;
  }
  .related__card__box .toggle__btn > button {
    @apply !mr-0 !mb-0;
  }
  .related__card__box .toggle__btn .socian__ani__icon__wrapper {
    @apply !bg-transparent w-[28px] h-[28px] rounded-[6px] p-[2px] relative top-[0px] border-[1px] border-whiteScreen__BorderColor duration-500 hover:!bg-parentBgWhite__grayBtnBG;
  }
  .related__card__box .toggle__btn .socian__ani__icon__wrapper:hover svg {
    @apply invert-0;
  }
  .related__card__box .edit__btn {
    @apply absolute top-[12px] right-[45px];
  }
  .related__card__box .view__btn {
    @apply absolute top-[12px] right-[10px];
  }
  .related__card__box .social__wrapper {
    @apply absolute bottom-0 pb-[7px] left-[50%] translate-x-[-50%] w-[calc(100%_-_30px)];
  }
  /* .related__card__box .social__wrapper .link__wrapper {
    @apply !mr-0;
  }
  .related__card__box .socialBtns__wrapper > button {
    @apply !mr-[12px] !mb-[10px];
  }
  .related__card__box .socialBtns__wrapper > .link__wrapper {
    @apply !mr-[12px] !mb-[10px];
  }
  .related__card__box .socialBtns__wrapper > .link__wrapper button {
    @apply !mr-[0px] !mb-[0px];
  } */
  .related__card__box .socialBtns__wrapper .link__wrapper {
    @apply !mr-[12px] flex;
  }
  .related__card__box .socialBtns__wrapper .i__Icon {
    @apply !w-[30px] !h-[30px] !p-[6px] !rounded-[8px];
  }
  .related__card__box .socialBtns__wrapper .socian__ani__icon__wrapper {
    @apply !w-[30px] !h-[30px] p-[2px];
  }
  .related__card__box .contact__name {
    @apply text-[16px] leading-[20px] font-biotif__Medium text-black__TextColor800 pr-[68px] mb-[2px] cursor-pointer inline-block hover:text-primaryColor hover:underline;
  }
  .related__card__box .contact__name > div {
    @apply h-auto;
  }
  .related__card__box .designation {
    @apply text-[14px] leading-[18px] font-biotif__Medium text-black__TextColor800 mb-[5px];
  }
  .related__card__box .phone__wrapper,
  .related__card__box .email__wrapper {
    @apply mb-[1px];
  }
  .related__card__box.related__collaborators__card__box {
    @apply pb-[14px];
  }

  .sideMenu__open .follower__card {
    @apply lg:w-full;
  }
  .follower__card .img__wrapper .noName__letter,
  .follower__card .img__wrapper .img__loader,
  .follower__card .img__wrapper .img__element,
  .follower__card .img__wrapper .no__img__icon {
    @apply !w-full !h-full rounded-full;
  }
  .follower__card .img__wrapper .noName__letter span {
    @apply text-[14px] leading-[40px] pt-[2px];
  }
  .follower__card .img__wrapper .no__img__icon .i__Icon {
    @apply !p-[9px];
  }

  /* timeline css start */
  .timeline__box__new .img__wrapper .noName__letter,
  .timeline__box__new .img__wrapper .img__loader,
  .timeline__box__new .img__wrapper .img__element,
  .timeline__box__new .img__wrapper .no__img__icon {
    @apply !w-full !h-full rounded-[10px] sm:rounded-[4px];
  }
  /* .timeline__box__new .img__wrapper .noName__letter{
    @apply pt-[3px];
  } */
  .timeline__box__new .img__wrapper .noName__letter span {
    @apply text-[14px] sm:text-[12px];
  }
  .timeline__box__new .img__wrapper .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .timeline__box__new .timelineBox__icon svg path[fill] {
    @apply fill-black;
  }
  .timeline__box__new .timelineBox__icon svg path[stroke] {
    @apply stroke-black;
  }
  .timeline__box__new {
    @apply relative;
  }
  .timeline__box__new .toggleBtn {
    @apply w-[32px] h-[32px] rounded-[6px] duration-500 flex items-center justify-center absolute top-0 right-[-5px] hover:bg-[#F2F2F2];
  }
  .timeline__box__new .toggleBtn .i__Icon {
    @apply mr-0 p-[4px];
  }
  .timeline__box__new .toggleBtn .i__Icon circle {
    @apply fill-black/50;
  }
  .timeline__box__new .toggleBtn:hover .i__Icon circle {
    @apply fill-black;
  }
  .timeline__day__repeater:last-child .timelineDay__box:last-child {
    @apply before:hidden;
  }
  .timelineDay__box .timeline__box__new__inner > .i__Icon path[fill] {
    @apply fill-black;
  }
  .timelineDay__box .timeline__box__new__inner > .i__Icon path[stroke] {
    @apply stroke-black;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .message__btn:hover
    path {
    @apply fill-white;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .pin__btn:hover
    path,
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .pin__btn.active
    path {
    @apply fill-white;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .emoji__btn:hover
    circle {
    @apply fill-white;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .emoji__btn:hover
    path {
    @apply fill-primaryColor;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .emoji__btn:hover
    rect {
    @apply fill-primaryColor;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .emoji__btn:hover
    path:last-child {
    @apply fill-white;
  }
  .timeline__main__wrapper .emoji__btn .i__ButtonLoader {
    @apply border-[#00000060] border-r-black;
  }
  .timeline__main__wrapper .emoji__btn:hover .i__ButtonLoader {
    @apply border-[#ffffff60] border-r-white;
  }
  .timelineDay__box .timeline__note__list__box .timeline__note__heading a {
    @apply text-primaryColor;
  }
  .timelineDay__box
    .timeline__note__list__box
    .timeline__note__heading
    .delete__btn:hover
    path {
    @apply fill-white;
  }
  .timelineDay__box
    .timeline__box__new__inner
    .timeline__header__action
    .toggle__btn:hover
    circle {
    @apply fill-white;
  }
  .timeline__main__wrapper .timeline__day__repeater:first-child .day__text {
    @apply !mt-0;
  }
  .timeline__main__wrapper
    .timeline__action__header
    .timeline__action__dropBtn {
    @apply flex items-center text-[#797B7D] font-biotif__Regular text-[16px] py-[5px] px-[15px] pr-[34px] rounded-[6px] relative before:content-[''] before:absolute before:top-[10px] before:right-[15px] before:w-[9px] before:h-[9px] before:border-l-[2px] before:border-l-[#00000080] before:border-b-[2px] before:border-b-[#00000080] before:rotate-[-45deg] duration-500 after:content-[''] after:absolute after:top-[50%] after:translate-y-[-50%] after:right-[-6px] after:h-[calc(100%_-_6px)] after:w-[1px] after:bg-[#CCCCCC]/60;
  }
  .timeline__main__wrapper
    .timeline__action__header
    .timeline__action__dropBtn.active {
    @apply text-primaryColor before:border-l-[2px] before:border-b-[2px] before:border-l-primaryColor before:border-b-primaryColor;
  }
  .timeline__main__wrapper
    .timeline__action__header
    .timeline__action__dropBtn
    .i__Icon {
    @apply p-0 w-[15px] h-[15px] relative top-[-2px] mr-[7px];
  }
  .timeline__main__wrapper
    .timeline__action__header
    .timeline__action__dropBtn
    .i__Icon
    svg
    path {
    @apply fill-[#797B7D];
  }
  .timeline__main__wrapper
    .timeline__action__header
    .timeline__action__dropBtn:hover
    .i__Icon
    svg
    path {
    @apply fill-white;
  }
  .timeline__main__wrapper .timeline__action__header .calendarBtn {
    @apply bg-[#F2F6F9] duration-500 hover:bg-primaryColor;
  }
  .timeline__main__wrapper
    .timeline__action__header
    .calendarBtn:hover
    svg
    path {
    @apply fill-white;
  }
  .timeline__action__filter__wrapper {
    @apply relative !max-w-[250px];
  }
  body
    .tippy-box.tippy__timePicker.timeline__action__filter__wrapper[data-placement="top-start"],
  body
    .tippy-box.tippy__timePicker.timeline__action__filter__wrapper[data-placement="top-end"],
  body
    .tippy-box.tippy__timePicker.timeline__action__filter__wrapper[data-placement="bottom-start"],
  body
    .tippy-box.tippy__timePicker.timeline__action__filter__wrapper[data-placement="bottom-end"] {
    @apply !p-0 !translate-y-[0px] !w-[200px];
  }
  .timeline__action__filter__wrapper .filter__box {
    @apply mb-[12px];
  }
  .timeline__action__filter__wrapper .filter__box:last-child {
    @apply mb-0;
  }
  .timeline__action__filter__wrapper .timeline__filter__dropdown {
    @apply static top-[100%] left-0 z-[4] min-w-[200px] 3xl:left-unset 3xl:right-0;
  }
  .timeline__action__filter__wrapper
    .timeline__filter__dropdown
    .inner__wrapper {
    @apply p-[15px] pb-[18px] bg-ipBlue__transparentBG rounded-[10px] shadow-[0px_3px_17px_#0000001a];
  }
  .timeline__action__filter__wrapper .form__Group.full__Checkbox {
    @apply mb-0;
  }
  .timeline__action__filter__wrapper .ip__Checkbox.full__Checkbox {
    @apply shadow-none mb-[7px];
  }
  .timeline__action__filter__wrapper .ip__Checkbox.full__Checkbox .rc__Label {
    @apply text-[#76797B] font-biotif__Regular text-[16px] pt-0 pr-0 pb-0 pl-[23px] before:border-[#76797B] before:w-[15px] before:h-[15px] before:left-0 before:top-[calc(50%_-_2px)] after:top-[calc(50%_-_2px)] after:left-[3px] after:w-[9px] after:h-[4px];
  }
  .timeline__action__filter__wrapper
    .ip__Checkbox.full__Checkbox
    input:checked
    + .rc__Label::before {
    @apply bg-primaryColor border border-primaryColor;
  }
  .timeline__action__header .search__box {
    @apply w-[160px];
  }
  .timeline__action__header .search__box .ip__input {
    @apply bg-transparent py-[4px] placeholder:text-[#797B7D] focus:!border-[1px] focus:!border-transparent;
  }
  .timeline__action__header .search__box.ip__form__hasIcon .i__Icon {
    @apply opacity-100 w-[22px] h-[22px] top-[5px];
  }

  .timeline__action__header .activity__date__filter .e-input-group-icon {
    @apply ml-0;
  }
  .timeline__action__header
    .activity__date__filter
    .activity__date__btn
    .i__Icon {
    @apply !w-[34px] !h-[34px] p-[8px] bg-[#F2F6F9];
  }
  .timeline__action__header
    .activity__date__filter
    .e-input-group:hover
    + .activity__date__btn
    .i__Icon {
    @apply bg-primaryColor;
  }
  .timeline__action__header
    .activity__date__filter
    .e-input-group:hover
    + .activity__date__btn
    .i__Icon
    svg
    path {
    @apply fill-white;
  }
  .timeline__action__header .activity__filter__wrapper .activity__filter__btn {
    @apply cursor-pointer;
  }
  .timeline__action__header
    .activity__filter__wrapper
    .activity__filter__btn
    .i__Icon {
    @apply !w-[34px] !h-[34px] p-[9px] bg-[#F2F6F9] hover:bg-primaryColor;
  }
  .timeline__action__header
    .activity__filter__wrapper
    .activity__filter__btn
    .i__Icon:hover
    svg
    path {
    @apply fill-white;
  }
  .timeline__action__header
    .activity__filter__wrapper
    .activity__filter__dropdown {
    @apply left-unset right-0 top-[calc(100%_+_4px)];
  }
  .timeline__action__header .activity__date__filter .e-daterangepicker.e-input {
    @apply !h-[34px] !w-[200px] !pt-[5px] !pr-[10px] !pb-[4px] !pl-[10px] !text-[14px] !bg-[#F2F6F9] !text-[#797B7D];
  }
  .timeline__action__header
    .activity__date__filter
    .e-date-range-wrapper
    .e-clear-icon {
    @apply !translate-y-0 !top-[9px] !right-[42px];
  }
  .timeline__action__header
    .activity__date__filter
    .e-date-range-wrapper
    .e-clear-icon:before {
    @apply !text-[15px];
  }
  .timeline__action__header .activity__date__filter .e-input-group {
    @apply h-[34px];
  }
  .timeline__contant__wrapper > div {
    @apply sm:text-[14px] sm:leading-[18px];
  }
  .timeline__contant__wrapper .ipInfo__View__Value {
    @apply text-[16px] font-biotif__Regular text-ip__SuccessGreen;
  }
  .timeline__contant__wrapper .note__text__readmore.linkPreview__Box {
    @apply !max-h-unset;
  }
  .timeline__contant__wrapper .note__text__readmore a {
    @apply text-primaryColor;
  }
  .emoji__dropdown__wrapper .EmojiPickerReact {
    @apply !absolute top-[calc(100%_+_0px)] right-0 z-[4];
  }
  .emoji__dropdown__wrapper .epr-header-overlay {
    @apply !py-[10px] !px-[10px];
  }
  .emoji__dropdown__wrapper .epr-header-overlay .epr-skin-tones {
    @apply hidden;
  }
  .emoji__dropdown__wrapper .epr-header-overlay .epr-search {
    @apply !py-[2px] !pr-[14px] !pl-[33px] !text-[15px] !font-biotif__Regular focus:!border-[1px] focus:!border-black;
  }
  .emoji__dropdown__wrapper .epr-header-overlay .epr-icn-search {
    @apply !top-[calc(50%_-_1px)];
  }
  .emoji__dropdown__wrapper .epr-category-nav {
    @apply !py-[2px] !px-[10px];
  }
  .emoji__dropdown__wrapper li.epr-emoji-category > .epr-emoji-category-label {
    @apply !text-mediumDark__TextColor;
  }
  .emoji__dropdown__wrapper button.epr-emoji:hover > * {
    @apply !bg-ipGray__transparentBG;
  }
  .emoji__dropdown__wrapper .epr-body {
    @apply !pb-[10px];
  }
  .emoji__dropdown__wrapper .epr-preview {
    @apply !hidden;
  }
  .emoji__dropdown__wrapper .epr-btn-clear-search {
    @apply !bg-white;
  }

  .timeline__note .img__wrapper .noName__letter,
  .timeline__note .img__wrapper .img__loader,
  .timeline__note .img__wrapper .img__element,
  .timeline__note .img__wrapper .no__img__icon {
    @apply !w-full !h-full rounded-[10px] sm:rounded-[4px];
  }
  .timeline__note .img__wrapper .noName__letter {
    @apply pt-[3px];
  }
  .timeline__note .img__wrapper .noName__letter span {
    @apply text-[14px] sm:text-[12px];
  }
  .timeline__note .img__wrapper .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .timeline__note .timelineBox__icon svg path[fill] {
    @apply fill-black;
  }
  .timeline__note .timelineBox__icon svg path[stroke] {
    @apply stroke-black;
  }
  .timeline__note .emoji__btn:hover circle {
    @apply fill-white;
  }
  .timeline__note .action__btn__wrapper .emoji__btn:hover path {
    @apply fill-primaryColor;
  }
  .timeline__note .action__btn__wrapper .emoji__btn:hover rect {
    @apply fill-primaryColor;
  }
  .timeline__note .action__btn__wrapper .emoji__btn:hover path:last-child {
    @apply fill-white;
  }
  .timeline__note .action__btn__wrapper .emoji__btn:hover .i__ButtonLoader {
    @apply border-[#ffffff60] border-r-white;
  }
  .timeline__note .action__btn__wrapper .toggle__btn:hover circle {
    @apply fill-white;
  }
  .timeline__note .action__btn__wrapper .delete__btn:hover path {
    @apply fill-white;
  }
  body .timeline__note .e-richtexteditor .e-rte-content {
    @apply !bg-transparent !p-0;
  }
  body .timeline__note .e-richtexteditor .e-rte-content .e-content {
    @apply !p-0;
  }
  body .timeline__note .e-richtexteditor .rte-placeholder {
    @apply !p-0;
  }

  .add__note__modal .ip__Modal__Header {
    @apply border-b-0;
  }
  .add__note__modal .link__preview {
    @apply bg-[#ECF2F6] !rounded-t-[0px] !border-t-[1px] !border-t-[#e9e9e9] !border-l-0 !border-b-0 !border-r-0;
  }
  .notify__to__field .ip__Select .ip__select__dynamic__control {
    @apply !min-h-[45px] pl-[92px] hover:pl-[92px] !pr-0 hover:!pr-0 bg-transparent hover:bg-transparent !border-0 !border-b border-solid !border-b-[#EDEFF1] hover:!border-0 hover:!border-b hover:border-solid hover:!border-b-[#EDEFF1] !rounded-none;
  }
  .notify__to__field .invalid__email .ip__select__dynamic__input {
    @apply !text-ip__Red;
  }
  .notify__to__field .ip__Select .ip__select__dynamic__indicators {
    @apply hidden;
  }
  .notify__to__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__input-container
    input {
    @apply top-0;
  }
  .notify__to__field
    .ip__Select
    .ip__select__dynamic__value-container--is-multi
    .ip__select__dynamic__placeholder {
    @apply h-[30px] w-full;
  }
  .notify__to__field .if__label {
    @apply hidden;
  }
  .notify__to__field
    .ip__Select
    div[class$="ValueContainer"]
    div[class$="multiValue"],
  .notify__to__field
    .ip__Select
    .ip__select__dynamic__value-container
    .ip__select__dynamic__multi-value {
    @apply !bg-primaryColor;
  }
  .notify__to__field .ip__select__dynamic__placeholder {
    @apply hidden;
  }
  .form__Group.pin__note__checkbox {
    @apply mb-[15px];
  }
  .pin__note__checkbox .rc__Label {
    @apply text-[14px] font-biotif__SemiBold text-black/70;
  }

  .add__note__modal .ip__Modal__Footer {
    @apply border-t-0 flex justify-between pb-[20px];
  }
  .add__note__modal .ip__Modal__Footer .i__Button {
    @apply pt-[14px] pb-[14px];
  }
  .add__note__modal .attachment__upload__btn .i__Icon path {
    @apply fill-[#ffffff];
  }
  .add__note__modal .ip__Modal__Body {
    @apply pb-0 px-[24px];
  }
  .add__note__modal .ip__note__body .form__Group {
    @apply mb-0;
  }
  body .add__note__modal .e-richtexteditor .e-rte-content {
    @apply bg-[#ECF2F6] min-h-[350px];
  }
  body .add__note__modal .e-richtexteditor .rte-placeholder {
    @apply text-[16px] font-biotif__Regular text-black/40;
  }
  .timeline__document__list__box .img__wrapper .img__loader {
    @apply bg-[#f5f5f5] rounded-[10px];
  }
  .attachment__view__wrapper .attachment__col .img__loader {
    @apply bg-[#f5f5f5] absolute top-0 left-0 w-full h-full rounded-[10px];
  }
  .attachment__view__wrapper .attachment__col .download__btn svg path {
    @apply fill-black !opacity-100;
  }
  .attachment__view__wrapper .attachment__col .download__btn:hover svg path {
    @apply fill-white;
  }
  .attachment__view__wrapper .attachment__col .delete__btn svg path {
    @apply stroke-black;
  }
  .attachment__view__wrapper .attachment__col .delete__btn:hover svg path {
    @apply stroke-white;
  }
  .attachment__view__wrapper .attachment__col .view__btn:hover svg path {
    @apply fill-white;
  }
  .add__note__modal .action-btns-wrapper {
    @apply inline-flex;
  }
  .add__note__modal .action-btns-wrapper .action-btn {
    @apply w-[38px] h-[38px] flex items-center justify-center rounded-[6px] duration-500 cursor-pointer hover:bg-black/10;
  }
  .add__note__modal .action-btns-wrapper .action-btn:hover path {
    @apply fill-black;
  }
  .snippet__modal .i__Icon {
    @apply flex w-[30px] h-[30px] cursor-pointer flex-wrap items-center justify-center rounded-full p-0 duration-300 hover:bg-greyScreen__BorderColor;
  }
  .snippet__modal .i__Icon path {
    @apply stroke-black;
  }
  .snippet__modal .add__btn .i__Icon {
    @apply bg-transparent hover:bg-transparent;
  }
  .snippet__modal .add__btn .i__Icon path {
    @apply stroke-white;
  }

  .tippy-box.tippy__dropdown.timeline__new__filter {
    @apply !max-w-[242px] !shadow-[0px_3px_17px_#0000001a] p-[15px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[12px] before:bg-primaryColor before:opacity-10;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .tippy-content {
    @apply relative z-[3];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter[data-placement^="bottom"],
  .tippy-box.tippy__dropdown.timeline__new__filter[data-placement^="bottom-start"] {
    @apply translate-y-[-2px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .search__box .ip__input {
    @apply bg-[#ffffff] py-[5px] px-[12px] pl-[34px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .ip__form__hasIcon .i__Icon {
    @apply top-[4px] left-[6px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .form__Group {
    @apply mb-0;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .ip__Checkbox {
    @apply w-full mb-[14px] last:mb-0;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .ip__Checkbox .rc__Label {
    @apply w-full text-[16px] font-biotif__Regular text-black__TextColor500 before:top-[-1px] before:border-[1px] before:border-black__BorderColor500 before:w-[16px] before:h-[16px] after:w-[9px] after:h-[5px] after:top-[3px] after:left-[3px] after:duration-0;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter
    .ip__Checkbox
    input:checked
    + .rc__Label {
    @apply before:bg-primaryColor before:border-[1px] before:border-primaryColor;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .filter-accordian-header {
    @apply mb-[14px] relative;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter
    .filter-accordian-header
    .ip__Checkbox
    .rc__Label {
    @apply font-biotif__Medium text-ipBlack__textColor;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter .filter-accordian {
    @apply mb-[20px] last:mb-0;
  }

  /* only-timeline-page-filter */
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter {
    @apply !max-w-[300px] !bg-white !shadow-[0px_3px_17px_#00000026] py-[10px] px-[8px] before:hidden;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter[data-placement^="bottom"],
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter[data-placement^="bottom-start"] {
    @apply translate-y-[-2px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .search__box
    .ip__input {
    @apply bg-[#dee2e64d] py-[5px] px-[12px] pl-[34px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .ip__form__hasIcon
    .i__Icon {
    @apply top-[4px] left-[6px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .form__Group {
    @apply mb-0;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .ip__Checkbox {
    @apply w-full flex items-center justify-between;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .ip__Checkbox
    .rc__Label {
    @apply w-full text-[14px] font-biotif__Regular text-black/50 pl-[23px] before:top-[calc(50%_-_2px)] before:translate-y-[-50%] before:border-[2px] before:border-black/50 before:w-[16px] before:h-[16px] after:top-[3px] after:left-[4px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .ip__Checkbox
    input:checked
    + .rc__Label {
    @apply before:bg-[#7467B7] before:border-[1px] before:border-[#7467B7];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian-header {
    @apply relative w-full mb-0;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian-header
    .ip__Checkbox
    .rc__Label {
    @apply font-biotif__Medium text-black;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian {
    @apply relative flex items-center pt-[11px] pb-[11px] pl-[10px] pr-[13px] mb-[0px] rounded-[6px] duration-0 hover:bg-[#F1F0F8];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian:hover
    .rc__Label {
    @apply !text-[#7467B7];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian.inner__child {
    @apply pt-[8px] pb-[8px] pr-[30px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian.inner__child
    .label__wrapper {
    @apply w-[calc(100%_-_32px)];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian.select__all {
    @apply rounded-none border-b-[1px] border-b-[#CCCCCC]/40 mb-[5px] pb-[12px] hover:bg-transparent;
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian-body {
    @apply !shadow-[0px_3px_17px_#00000026] rounded-[10px] bg-white absolute top-[-11px] left-[calc(100%_+_52px)] p-[10px] w-[250px];
  }
  .tippy-box.tippy__dropdown.timeline__new__filter.new__latest__filter
    .filter-accordian-body
    .ip__Checkbox {
    @apply rounded-[6px] px-[10px] pt-[11px] pb-[11px] mb-[4px] duration-0 hover:bg-[#F1F0F8] last:mb-0;
  }
  /* only-timeline-page-filter-end */

  /* timeline css end */

  /* quick view account contact modal css start */
  .tippy-box.tippy__dropdown.quickView__accContact__tippy {
    @apply shadow-[0px_2px_14px_#0000001a] w-[370px] p-[20px] pb-0 sm:p-[15px] sm:pb-0 sm:w-[290px];
  }
  .tippy-box.tippy__dropdown.quickView__accContact__tippy
    .socialBtns__wrapper
    .i__Icon.highlighted {
    @apply bg-[#E6E6E6] hover:bg-primaryColor;
  }
  .tippy-box.tippy__dropdown.quickView__accContact__tippy
    .socialBtns__wrapper
    .i__Icon
    path {
    @apply fill-black;
  }
  .tippy-box.tippy__dropdown.quickView__accContact__tippy
    .socialBtns__wrapper
    .i__Icon.highlighted:hover
    path {
    @apply fill-white;
  }
  .tippy-box.tippy__dropdown.quickView__accContact__tippy
    .toggle__btn
    .i__Icon {
    @apply duration-500 hover:bg-ipGray__transparentBG rounded-[6px] p-[6px] w-[28px] h-[28px] top-0;
  }
  .tippy-box.tippy__dropdown.quickView__accContact__tippy .email > span > span,
  .tippy-box.tippy__dropdown.quickView__accContact__tippy .phone > span > a {
    @apply text-black__TextColor600 hover:text-primaryColor;
  }
  /* quick view account contact modal css end */

  /* mark as done modal css start */
  .mark__as__done__modal .ip__select__dynamic__menu {
    @apply static;
  }
  /* mark as done modal css end */

  .description__details__page .inline-edit-off > div {
    @apply !h-auto !max-h-unset;
  }

  .details__RelatedDeal__wrapper .timeline__action__filter__wrapper .i__Button {
    @apply !mb-0;
  }

  .edit__lead__btn .i__Icon {
    @apply !w-[32px] !h-[32px] mr-[10px] mb-[10px] !rounded-[6px] p-[4px] hover:bg-primaryColor;
  }
  .edit__lead__btn .i__Icon path {
    @apply fill-black;
  }
  .edit__lead__btn .i__Icon:hover  {
    @apply fill-white;
  }
  .edit__lead__btn .socian__ani__icon__wrapper {
    @apply w-[32px] h-[32px] mr-[10px] p-[3px];
  }
  /* details pages comman css end */

  /* lead details page css start */
  .lead__details__page .editACT__btn svg path {
    @apply fill-ipBlack__textColor;
  }
  .lead__details__page .editACT__btn:hover svg path {
    @apply fill-white;
  }
  /* lead details page css end */

  /* deal details page css start */
  .detailsPageNew.deal__details__page .action__bar {
    @apply lg:mb-0;
  }
  .deal__details__page .editACT__btn svg path {
    @apply fill-black;
  }
  .social__connection .i__Icon {
    @apply w-[42px] h-[36px] rounded-[10px] p-[9px];
  }
  .social__connection .i__Icon path[fill] {
    @apply fill-white;
  }
  .social__connection .i__Icon path[stroke] {
    @apply stroke-white;
  }
  .associated_Profiles__row:last-child {
    @apply mb-0 border-b-0;
  }
  .associated_Profiles__row .img__wrapper img {
    @apply w-full h-full object-cover object-center rounded-full;
  }
  .associated_Profiles__row .img__wrapper .noName__letter,
  .associated_Profiles__row .img__wrapper .img__loader,
  .associated_Profiles__row .img__wrapper .img__element,
  .associated_Profiles__row .img__wrapper .no__img__icon {
    @apply w-[40px] h-[40px] rounded-[10px];
  }
  .associated_Profiles__row .img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .associated_Profiles__row .img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .associated_Profiles__row
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[18px] leading-[40px] pb-[3px];
  }
  .associated_Profiles__row .img__wrapper .no__img__icon .i__Icon {
    @apply p-[10px];
  }
  .associated_Profiles__row .img__wrapper .absolute__icon svg path[fill] {
    @apply fill-white;
  }
  .associated_Profiles__row .img__wrapper .absolute__icon svg path[stroke] {
    @apply stroke-white;
  }
  .associated_Profiles__row .delete__btn svg path[fill] {
    @apply fill-[#EA4335] duration-500;
  }
  .associated_Profiles__row .delete__btn:hover svg path[fill] {
    @apply fill-[#ffffff];
  }

  .tippy-box.tippy__dropdown.tippy__deal__stage .item {
    @apply px-[10px] !w-[162px] !max-w-[162px];
  }
  .deal__stage__modal
    .form__Group.department__add__member
    .ip__Radio.department__add__member {
    @apply w-[calc(50%_-_8px)];
  }
  /* deal details page css end */

  /* contact details page css start */
  .contact__details__page .detailsPage__action__breadcrumbs .stayIntouch__Btn {
    @apply mr-0;
  }
  .contact__details__page .editACT__btn svg path {
    @apply fill-black;
  }
  .contact__details__page .activityInner__topHeader__box .main__details {
    @apply w-full pr-0;
  }

  /* contact details mobile view css start */
  .details__page__topHeader__M
    .details__mobile__act__type
    .i__Icon
    svg
    path[fill] {
    @apply fill-white;
  }
  .details__page__topHeader__M
    .details__mobile__act__type
    .i__Icon
    svg
    path[stroke] {
    @apply stroke-white;
  }
  .details__page__topHeader__M .details__mobile__act__type .img__element {
    @apply w-[43px] h-[43px] rounded-[8px] !p-0 xsm:!w-[32px] xsm:!h-[32px] xsm:rounded-[6px];
  }
  .details__page__topHeader__M .details__mobile__act__type .img__loader {
    @apply w-[43px] h-[43px] rounded-[8px] bg-ipGray__transparentBG xsm:w-[32px] xsm:h-[32px] xsm:rounded-[6px];
  }
  .details__page__topHeader__M .editACT__btn__M svg path {
    @apply fill-black;
  }
  .details__page__topHeader__M .editACT__btn__M:hover svg path {
    @apply fill-white;
  }
  .details__page__topHeader__M .details__page__toggleBtn__M svg path {
    @apply fill-black;
  }
  /* contact details mobile view css end */
  /* contact details page css end */

  /* account details page css end */
  .account__details__page .editACT__btn svg path {
    @apply fill-black;
  }
  .account__details__page .editACT__btn:hover svg path {
    @apply fill-white;
  }
  /* account details page css end */

  /* activity details page css start */
  .activity__details__page .breadcrumbs__wrapper,
  .activity__details__page .action__bar {
    @apply mb-0;
  }
  .activity__details__page .detailsPage__action__breadcrumbs {
    @apply mb-0;
  }

  .activity__details__page .editACT__btn svg path {
    @apply fill-black;
  }

  .activity__innerPage__wrapper .designation__wrapper > span:last-child {
    @apply pr-0 mr-0 before:hidden;
  }
  .activity__details__page .meetingCN__box > div:last-child {
    @apply pr-0 mr-0 before:hidden;
  }
  .activityInner__topHeader__box .activityType .i__Icon svg path[fill] {
    @apply fill-white;
  }
  .activityInner__topHeader__box .activityType .i__Icon svg path[stroke] {
    @apply stroke-white;
  }
  .activityInner__topHeader__box .activityType .img__element {
    @apply w-[60px] h-[60px] rounded-[10px] !p-0;
  }
  .activityInner__topHeader__box .activityType .img__loader {
    @apply w-[60px] h-[60px] rounded-[10px] bg-ipGray__transparentBG;
  }
  .activityInner__topHeader__box .editACT__btn:hover svg path {
    @apply fill-[#ffffff];
  }
  .activityInner__topHeader__box .activityType .animated__icon__btn svg path[fill]{
    @apply !fill-[#ffffff];
  }
  .activityInner__topHeader__box .activityType .animated__icon__btn svg path[stroke]{
    @apply !stroke-[#ffffff];
  }

  .schedule__followUP__wrapper {
    @apply inline-block relative z-[3];
  }
  .schedule__followUP__wrapper .lpd__btn.active {
    @apply bg-primaryColor text-[#ffffff];
  }
  .schedule__followUP__wrapper .lpd__dropdown__list {
    @apply absolute top-[100%] left-0 min-w-full pt-[5px];
  }
  .schedule__followUP__wrapper .lpd__dropdown__list .inner__wrapper {
    @apply relative rounded-[10px] py-[4px] bg-white before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-10 before:rounded-[10px];
  }
  .schedule__followUP__wrapper .lpd__dropdown__list .lpd__dropdown__item {
    @apply relative z-[2] px-[18px] py-[7px] text-primaryColor text-[14px] font-biotif__Medium relative cursor-pointer before:content-[''] before:absolute before:left-[50%] before:translate-x-[-50%] before:bottom-0 before:w-[calc(100%_-_20px)] before:h-[1px] before:bg-primaryColor before:opacity-40;
  }
  .schedule__followUP__wrapper
    .lpd__dropdown__list
    .lpd__dropdown__item:last-child {
    @apply before:hidden;
  }

  .activity__map {
    @apply sm:relative sm:left-[-10px] sm:!w-[calc(100%_+_20px)] sm:mt-[-8px];
  }
  .activity__map #maps_tile_parent {
    @apply !rounded-[10px];
  }

  /* activity details page mobile css start */
  .details__page__topHeader__M
    .socialBtns__wrapper
    .i__Icon:hover
    svg
    path[fill] {
    @apply !fill-[#ffffff];
  }
  .details__page__topHeader__M
    .socialBtns__wrapper
    .i__Icon:hover
    svg
    path[stroke] {
    @apply !stroke-[#ffffff];
  }

  .activity__innerPage__mobile .act__mob__head .left .i__Icon path[fill] {
    @apply fill-[#ffffff];
  }
  .activity__innerPage__mobile .act__mob__head .left .i__Icon path[stroke] {
    @apply stroke-[#ffffff];
  }
  .activity__innerPage__mobile .actionToggle__btn path {
    @apply fill-[#808080] duration-500;
  }
  .activity__innerPage__mobile .actionToggle__btn:hover path {
    @apply fill-black;
  }
  .activity__innerPage__mobile .designation__wrapper > span:last-child {
    @apply pr-0 mr-0 before:hidden;
  }
  .activity__innerPage__mobile .buttons__wrapper .i__Icon svg path[fill],
  .activity__innerPage__mobile .buttons__wrapper .i__Icon svg rect[fill] {
    @apply fill-ipBlack__textColor;
  }
  .activity__innerPage__mobile
    .buttons__wrapper
    button:hover
    .i__Icon
    svg
    path[fill],
  .activity__innerPage__mobile
    .buttons__wrapper
    button:hover
    .i__Icon
    svg
    rect[fill] {
    @apply fill-primaryColor;
  }
  .guest__box__mobile .noName__letter,
  .guest__box__mobile .img__loader,
  .guest__box__mobile .img__element,
  .guest__box__mobile .no__img__icon,
  .collaborators__box__mobile .noName__letter,
  .collaborators__box__mobile .img__loader,
  .collaborators__box__mobile .img__element,
  .collaborators__box__mobile .no__img__icon {
    @apply !w-full !h-full;
  }
  .guest__box__mobile .noName__letter span,
  .collaborators__box__mobile .noName__letter span {
    @apply text-[14px];
  }
  .guest__box__mobile .no__img__icon .i__Icon,
  .collaborators__box__mobile .no__img__icon .i__Icon {
    @apply !p-[9px];
  }
  .guest__box__mobile #maps_tile_parent {
    @apply !rounded-[10px];
  }
  .guest__box__mobile .not__confirmed circle,
  .collaborators__box__mobile .not__confirmed circle {
    @apply fill-[#B9B8B8];
  }
  /* activity details page mobile css end */

  /* activity edit page css start */
  .createActivityModal.createActivityModal__mobile__page .left {
    @apply pr-0 sm:h-[calc(100dvh_-_150px)] sm:overflow-y-auto;
  }
  .createActivityModal.createActivityModal__mobile__page
    .schedule__activity__contant__wrapper
    .right {
    @apply fixed h-screen !rounded-none !z-[15];
  }
  /* activity edit page css end */

  .activity__details__page
    .details__Guests
    .details__RelatedContact__row
    .details__RelatedContact__cell.phone__cell {
    @apply max-w-[calc(100%_-_526px)];
  }
  /* activity details page css end */

  /* email details page css start */
  .ivb__submit__btns .replyBtn .i__Icon,
  .ivb__submit__btns .replyAllBtn .i__Icon,
  .ivb__submit__btns .forwardBtn .i__Icon {
    @apply p-0 relative top-[-2px] mr-[2px];
  }
  .ivb__submit__btns .replyBtn:hover .i__Icon svg path,
  .ivb__submit__btns .replyAllBtn:hover .i__Icon svg path,
  .ivb__submit__btns .forwardBtn:hover .i__Icon svg path {
    @apply fill-[#ffffff];
  }

  .inbox__view__box .profile__img__wrapper .noName__letter,
  .inbox__view__box .profile__img__wrapper .img__loader,
  .inbox__view__box .profile__img__wrapper .img__element,
  .inbox__view__box .profile__img__wrapper .no__img__icon,
  .reply-email-wrapper .profile__img__wrapper .noName__letter,
  .reply-email-wrapper .profile__img__wrapper .img__loader,
  .reply-email-wrapper .profile__img__wrapper .img__element,
  .reply-email-wrapper .profile__img__wrapper .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .inbox__view__box .profile__img__wrapper .no__img__icon .i__Icon,
  .reply-email-wrapper .profile__img__wrapper .no__img__icon .i__Icon {
    @apply !p-[8px];
  }
  .inbox__view__box .profile__img__wrapper .img__loader,
  .reply-email-wrapper .profile__img__wrapper .img__loader {
    @apply sm:bg-ipWhite__bgColor;
  }
  .inbox__view__box .profile__img__wrapper .img__element,
  .reply-email-wrapper .profile__img__wrapper .img__element {
    @apply object-cover object-center;
  }
  .inbox__view__box
    .profile__img__wrapper
    .noName__letter
    .noName__letter__text,
  .reply-email-wrapper
    .profile__img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[16px] leading-[52px] pb-[0px] pt-[2px] lg:text-[14px] lg:leading-[38px];
  }

  .inbox__view__box .attachment__view__wrapper .view-btn path {
    @apply fill-white stroke-white;
  }
  .inbox__view__box .attachment__view__wrapper .download-btn path {
    @apply fill-white;
  }

  .inbox__view__mail__list__item:first-child .readmore__toggle__btn {
    @apply hidden;
  }
  .inbox__view__mail__list__item.reply__tab__visible {
    @apply !border-b-0 mb-0 pb-0;
  }

  .reply-email-wrapper .emailReplyForm__wrapper {
    @apply w-[calc(100%_-_52px)] pl-[20px] lg:pl-0 lg:w-full;
  }
  .reply-email-wrapper .emailReplyForm__wrapper .left__action__bar {
    @apply mb-0 inline-flex w-auto lg:mb-[10px];
  }
  body
    .reply-email-wrapper
    .emailReplyForm__wrapper
    .e-richtexteditor
    .e-rte-content,
  body
    .reply-email-wrapper
    .emailReplyForm__wrapper
    .compose__mail__textarea
    .e-richtexteditor
    .e-rte-content
    .e-content {
    @apply !bg-transparent;
  }
  body
    .reply-email-wrapper
    .emailReplyForm__wrapper
    .e-richtexteditor
    .e-rte-content
    .e-content {
    @apply !p-0;
  }
  .reply-email-wrapper .emailReplyForm__wrapper .templateInput__field {
    @apply bg-[#f4f4f4];
  }
  /* .reply-email-wrapper .emailReplyForm__wrapper .reply__btn__wrapper{
    @apply hidden;
  } */
  .reply-email-wrapper .emailReplyForm__wrapper .ccBcc__btn__wrapper {
    @apply right-[113px] top-[9px];
  }
  .compose__mail__to__field .ip__Select .ip__select__dynamic__control {
    @apply pr-[82px] hover:pr-[90px];
  }
  .reply-email-wrapper
    .emailReplyForm__wrapper
    .compose__mail__to__field
    .ip__Select
    .ip__select__dynamic__control {
    @apply pr-[202px];
  }
  /* email details page css end */

  /* calendar page css start */
  .todays__event__box .contant__header.active {
    @apply before:!rotate-[45deg];
  }
  .tEvent__box.active .inner__wrapper {
    @apply before:!rotate-[45deg];
  }
  .toggleData__btn.active {
    @apply rotate-90 bg-white before:right-[12px];
  }

  /* quickLookActivityCalendar modal css start */
  .quickLookActivityCalendar__box .left .i__Icon path {
    @apply fill-white;
  }
  .quickLookActivityCalendar__box .header .right .i__Icon path,
  .quickLookActivityCalendar__box .header .right .i__Icon circle {
    @apply fill-[#2E3234]/40 stroke-[#2E3234]/40;
  }
  .quickLookActivityCalendar__box .header .right .i__Icon:hover path,
  .quickLookActivityCalendar__box .header .right .i__Icon:hover circle {
    @apply fill-black stroke-black;
  }
  body > .e-quick-popup-wrapper .quickLookActivityCalendar__box {
    @apply w-full;
  }
  body
    > .e-quick-popup-wrapper
    .quickLookActivityCalendar__box
    .header
    .cancelBtn {
    @apply block;
  }
  body > .e-quick-popup-wrapper .quickLookActivityCalendar__box .header .left {
    @apply w-[calc(100%_-_90px)];
  }
  /* quickLookActivityCalendar modal css end */
  /* calendar page css end */

  /* inline editing css start */
  .inline-edit-off {
    @apply cursor-pointer;
  }
  .inlineEdit__On {
    @apply mt-[-10px] sm:mt-[0px];
  }
  .inlineEdit__On .form__Group {
    @apply mb-0;
  }
  .inlineEdit__On .if__label {
    @apply hidden;
  }
  .inlineEdit__On .loaderActive {
    @apply before:hidden;
  }
  .inlineEdit__On .submitBtn .i__ButtonLoader {
    @apply m-0 top-0;
  }
  .inlineEdit__On .ip__react__datepicker .react-datepicker__close-icon {
    @apply after:pt-[3px];
  }
  /* inline editing css end */

  /* snippent__dropdown */
  #mention_integration_rte-edit-view_popup{
    @apply !w-[330px];
  }
  #mention_integration_rte-edit-view_popup .e-content .e-list-item .mention__li{
    @apply w-full;
  }
  #mention_integration_rte-edit-view_popup .e-content .e-list-item .mention__li .snippet__title{
    @apply font-biotif__SemiBold text-[14px] indent-0 leading-normal;
  }
  #mention_integration_rte-edit-view_popup .e-content .e-list-item .mention__li .description{
    @apply font-biotif__Regular text-[14px] indent-0 leading-normal;
  }
  /* snippent__dropdown__end */

  .tag__ag__edit__btn svg path{
    @apply fill-primaryColorSD;
  }

  /* ================= new purple design start =================== */
  :root {
    --primaryColorSD: #7467b7;
    --sdWhite__text: #ffffff;
    --sdBlack__text: #000000;
    --sdWhite__border: #ffffff;
    --sdBlack__border: #000000;
    --sdWhite__bg: #ffffff;
    --sdBlack__bg: #000000;
    --grayCardBG: #f1f1f1;
    --grayCardLightBG: #f9f9f9;
    --btnGrayColor: #ececec;
    --whiteScreenBorderColor: #e5e5e5;
    --whiteScreenDashedBorderColor: #cacaca;
    --sdNormal__textColor: #737373;
    --mediumDarkTextColorSD: #585b5d;
    --darkTextColorSD: #2e3234;
    --grayIconDarkColor: #737373;
    --grayIconDarkerColor: #585b5d;
    --grayIconLightColor: #a5a8aa;
    --formElementBG: #f1f1f1;
    --lightTextForGrayBG: #bbbbbb;
  }
  html.modal__open {
    overflow: hidden;
  }
  body.listing__page,
  body.stream__page{
    @apply !bg-[#F5F7F9];
  }
  body.listing__page .sidebar,
  body.stream__page .sidebar{
    @apply !bg-transparent;
  }
  /* body {
    @apply !bg-[#F5F7F9];
  } */

  /* comman-css */
  /* .pageContent{
    @apply !pr-[36px] !pl-0 !pt-0 !pb-0;
  } */

  /* breadcrumbs__wrapper */
  /* .breadcrumbs__wrapper{
    @apply hidden;
  } */
  /* breadcrumbs__wrapper__end */

  /* form-elements */
  .ip__Select.ip__Select__hasIcon > .i__Icon svg path[stroke],
  .ip__form__hasIcon > .i__Icon svg path[stroke] {
    @apply stroke-grayIconDarkColor;
  }
  .ip__Select.ip__Select__hasIcon > .i__Icon svg path[fill],
  .ip__form__hasIcon > .i__Icon svg path[fill] {
    @apply fill-grayIconDarkColor;
  }
  .ip__input{
    @apply text-[16px] font-biotif__Medium text-sdNormal__textColor border-[1px] border-transparent placeholder-sdNormal__textColor focus:border-primaryColorSD;
  }
  .ip__input.small__field {
    @apply pt-[7px] pb-[5px] rounded-[12px];
  }
  .ip__input.inner__shadow__field {
    @apply bg-sdWhite__bg shadow-[1px_1px_3px_0px_#cccac6e5_inset,_-1px_-1px_2px_0px_#ffffffe5_inset,_1px_-1px_2px_0px_#cccac633_inset,_-1px_1px_2px_0px_#cccac633_inset];
  }

  .new__form__elements__design .if__label {
    @apply text-darkTextColorSD text-[16px] font-biotif__Medium;
  }
  .new__form__elements__design .ip__input {
    @apply text-[16px] font-biotif__Regular text-darkTextColorSD py-[10px] rounded-[10px] bg-formElementBG placeholder-lightTextForGrayBG focus:border-[1px] focus:border-primaryColorSD;
  }

  /* custom__round__radio__btn */
  .custom__round__radio__btn .ip__Radio {
    @apply relative;
  }
  .custom__round__radio__btn .ip__Radio input {
    @apply absolute top-0 left-0 z-[2] w-full h-full cursor-pointer opacity-0;
  }
  .custom__round__radio__btn .ip__Radio .rc__Label {
    @apply pl-[20px] text-[16px] text-darkTextColorSD font-biotif__Regular before:content-[''] before:absolute before:left-0 before:top-[calc(50%_-_2px)] before:translate-y-[-50%] before:w-[14px] before:h-[14px] before:border-darkTextColorSD before:rounded-full after:content-[''] after:absolute after:top-[calc(50%_-_2px)] after:translate-y-[-50%] after:left-[4px] after:w-[6px] after:h-[6px] after:bg-primaryColorSD after:opacity-0 after:rounded-full after:duration-300;
  }
  .custom__round__radio__btn .ip__Radio input:checked + .rc__Label {
    @apply before:border-[1px] before:border-primaryColorSD before:bg-transparent after:opacity-100;
  }
  /* custom__round__radio__btn__end */
  /* searchField__small */
  .searchField__small .ip__input{
    @apply pt-[7px] pb-[6px] pl-[34px] pr-[12px] leading-[20px] !font-biotif__Regular bg-formElementBG text-sdBlack__text rounded-[8px] placeholder:text-sdNormal__textColor;
  }
  .searchField__small .i__Icon{
    @apply !w-[24px] !h-[24px] top-[4px];
  }
  /* searchField__small__end */
  /* form-elements-end */

  /* i__Button */
  .i__Button.primary__Btn__SD {
    @apply bg-primaryColorSD hover:bg-primaryColorSD;
  }
  .i__Button.outline__Btn__SD {
    @apply bg-transparent border-[1px] border-primaryColorSD duration-300 text-primaryColorSD hover:bg-primaryColorSD hover:text-white;
  }
  .ip__Modal__Wrapper__new .i__Button.primary__Btn {
    @apply bg-primaryColorSD hover:bg-primaryColorSD;
  }
  /* i__Button__end */

  /* sidebar */
  /* .sidebar{
    @apply !w-[70px] !bg-transparent;
  }
  .sidebar__Collapse .sidebar{
    @apply !w-[320px];
  }
  .s__Menu__Item .s__Menu__Text{
    @apply text-[#2E3234];
  }
  .s__Menu__Item .s__Menu__Icon svg path{
    @apply fill-[#2E3234];
  }
  .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Text{
    @apply text-primaryColorSD;
  }
  .s__Menu__Item .s__Menu__Item__Link:hover > .s__Menu__Icon svg path{
    @apply fill-primaryColorSD;
  }
  .s__Menu__Item.active > .s__Menu__Item__Link{
    @apply bg-primaryColorSD;
  }
  .s__Menu__Item.active .s__Menu__Item__Link:hover .s__Menu__Text{
    @apply !text-white;
  }
  .s__Menu__Item.active .s__Menu__Item__Link:hover .s__Menu__Icon svg path{
    @apply !fill-white;
  }
  .s__Menu__Item.logout{
    @apply w-[calc(100%_-_15px)];
  }
  .s__Menu__Item.logout .s__Menu__Item__Link{
    @apply bg-[#F1F1F1];
  }
  .s__Menu__Item.logout .s__Menu__Text{
    @apply text-[#2E3234];
  }
  .s__Menu__Item.logout .s__Menu__Icon svg path{
    @apply fill-primaryColorSD;
  }
  .s__Menu__Item.logout .s__Menu__Item__Link:hover > .s__Menu__Icon svg path{
    @apply fill-primaryColorSD;
  }
  .sidebar__CollapseBtn{
    @apply !w-[26px] !h-[26px] !top-[22px] !right-[-14px] !border-0 !p-[4px] !bg-primaryColorSD hover:!bg-sdBlack__bg;
  }
  .sidebar__CollapseBtn:hover path{
    @apply fill-sdWhite__text;
  }
  .sidebar__CollapseBtn .i__Icon{
    @apply relative left-[-1px];
  } */
  /* sidebar-end */

  /* dashboard__Header */
  /* .dashboard__Header{
    @apply fixed top-0 right-0 w-[calc(100%_-_70px)] z-[3];
  }
  .dashboard__Header .innerWrapper{
    @apply rounded-bl-[12px] bg-sdWhite__bg shadow-[0px_0px_0px_0px_#0000000a,_4px_5px_14px_0px_#0000000a,_16px_20px_26px_0px_#0000000a,_37px_45px_35px_0px_#00000005,_65px_80px_41px_0px_#00000003,_102px_125px_45px_0px_#0000];
  }
  .dashboard__Header .header__page__title{
    @apply text-[22px] text-darkTextColorSD inline-flex items-center;
  }
  .dashboard__Header .header__quick__wrapper .i__Button{
    @apply bg-sdBlack__bg before:bg-sdWhite__bg after:bg-sdWhite__bg hover:bg-primaryColorSD hover:before:bg-white hover:after:bg-white;
  }
  .notification__btn{
    @apply before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-[8px] before:bg-primaryColorSD before:opacity-0 hover:bg-transparent hover:before:opacity-20;
  }
  .notification__btn svg path{
    @apply fill-grayIconLightColor;
  }
  .notification__btn:hover svg path,
  .notification__btn.active svg path{
    @apply fill-primaryColorSD;
  }
  .sidebar__Collapse .dashboard__Header{
    @apply w-[calc(100%_-_320px)];
  } */
  /* dashboard__Header__end */

  /* .i__Dashboard__Wrapper{
    @apply w-[calc(100%_-_70px)] pt-[108px];
  }
  .sidebar__Collapse .i__Dashboard__Wrapper{
    @apply w-[calc(100%_-_320px)];
  } */

  /* ip__Modal__Wrapper__new */
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new {
    @apply fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9];
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Overlay {
    @apply fixed top-0 left-0 w-screen h-screen bg-[#0003] pointer-events-none;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__ContentWrap {
    @apply bg-white overflow-hidden rounded-[10px] relative z-[2] max-w-[calc(100%_-_20px)] lg:mb-0;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Header {
    @apply flex items-center justify-between bg-white border-b border-greyScreen__BorderColor px-[24px] py-[15px] pr-[16px] rounded-t-[12px] sm:py-[12px] sm:pt-[17px] sm:px-[15px];
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Header .title {
    @apply whitespace-pre overflow-hidden text-ellipsis text-[#2E3234] text-[18px] font-biotif__Medium w-[calc(70%_-_32px)] sm:text-[20px] sm:leading-[23px];
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Header .i__Icon {
    @apply bg-white w-[32px] h-[32px] rounded-[6px] shrink-0 cursor-pointer relative duration-300 before:content-[''] before:absolute before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%] before:w-[14px] before:h-[2px] before:rotate-45 before:bg-black before:rounded-[20px] before:duration-300 after:content-[''] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:w-[14px] after:h-[2px] after:-rotate-45 after:bg-black after:rounded-[20px] after:duration-300 hover:bg-black hover:before:bg-white hover:after:bg-white;
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Header .i__Icon svg {
    @apply w-full h-full hidden;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new
    .ip__Modal__Header
    .animated__icon__btn {
    @apply bg-white w-[32px] h-[32px] rounded-[6px] shrink-0 cursor-pointer relative duration-300  hover:bg-black hover:before:bg-white hover:after:bg-white;
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new
    .ip__Modal__Header
    .animated__icon__btn:hover
    svg {
    @apply invert;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new
    .ip__Modal__Header
    .i__Icon
    svg
    path {
    @apply stroke-black;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Body {
    @apply p-[24px] max-h-[calc(100dvh_-_250px)] overflow-y-auto md:p-[20px] lg:max-h-[calc(100dvh_-_270px)] sm:p-[15px] sm:max-h-[calc(100dvh_-_230px)];
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Footer {
    @apply !border-t-0 py-[20px] px-[24px] flex flex-wrap justify-end rounded-b-[12px] md:!pl-[15px] md:!pr-[15px] md:!pt-[15px];
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new .ip__Modal__Footer .i__Button {
    @apply mr-[10px];
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new
    .ip__Modal__Footer
    .i__Button:last-child {
    @apply mr-0;
  }
  /* ip__Modal__Wrapper__new__end */

  /* ag__grid__purple__new__design */
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header {
    @apply bg-transparent border-0;
    /* @apply bg-white border-0; */
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header .ag-header-container {
    @apply border-b-[1px] border-b-[#CCCCCC] bg-white rounded-tr-[10px] shadow-none;
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header .ag-pinned-left-header,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header .ag-pinned-right-header{
    @apply bg-white border-b-[1px] border-b-[#CCCCCC];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-row {
    @apply !h-[60px] !min-h-[60px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-root-wrapper {
    @apply border-0 rounded-t-[10px] !bg-transparent;
  }
  /* .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell {
    @apply border-r-[1px] border-r-[#CCCCCC];
  } */
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell{
    @apply border-r-0;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell:last-child .ag-header-cell-resize{
    @apply !right-[-4px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell.first__column .ag-header-cell-comp-wrapper > div{
    @apply inline-flex;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell.first__column,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell.first__column .ag-header-cell-comp-wrapper{
    @apply justify-center;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell.action__column .ag-cell-label-container{
    @apply justify-center;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell.action__column .ag-header-cell-label{
    @apply mr-0;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-cell[col-id="Action"] {
    @apply last:border-r-0;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-cell
    .ag-cell-label-container {
    @apply p-0 justify-end;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-cell
    .ag-cell-label-container
    .ag-header-cell-label {
    @apply flex-none mr-[5px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-header-cell-text {
    @apply text-mediumDarkTextColorSD font-biotif__Regular font-normal text-[16px] !block;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-row {
    @apply bg-white rounded-none !border-b-[1px] !border-whiteScreenBorderColor;
    /* box-shadow: 1px 1px 1px 0px rgba(207, 207, 207, 0.5),
      1px -1px 1px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 1px 0px rgba(207, 207, 207, 0.2); */
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.pre-node,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.pre-node.ag-row-hover,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.pre-node.ag-row-hover.ag-row-focus{
    @apply before:absolute before:top-0 before:left-0 before:w-full before:h-full before:!bg-primaryColorSD before:opacity-10 hover:before:absolute hover:before:top-0 hover:before:left-0 hover:before:w-full hover:before:h-full hover:before:!bg-primaryColorSD hover:before:opacity-10;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-row.ag-row-focus {
    @apply z-[2];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus{
    @apply border-0 !bg-white hover:!bg-white before:!bg-transparent !bg-none;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row:last-child,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus:last-child{
    @apply !mb-0;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row:before,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row.ag-row-hover.ag-row-focus:before{
    background-image: unset !important;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[16px] !border-0 pt-[5px] pb-[5px] !flex items-center leading-normal !overflow-visible;
  }
  /* .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell
    p {
    @apply leading-[21px] h-[21px];
  } */
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell.first__column{
    @apply !text-center !justify-center;
  }
  /* .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column{
    @apply relative;
  } */
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column .agGrid__customInput{
    @apply hidden;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column:hover .agGrid__customInput{
    @apply flex;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column:hover .index{
    @apply hidden;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column .agGrid__customInput.input__checked{
    @apply flex;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.first__column .agGrid__customInput.input__checked + .index{
    @apply hidden;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell
    .cell__value__wrapper {
    @apply w-full;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell:not(.ag-cell-wrap-text) .value {
    @apply text-mediumDarkTextColorSD max-w-full whitespace-pre overflow-hidden text-ellipsis;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-paging-panel {
    @apply border-t-0 justify-between;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-paging-panel
    .ag-paging-row-summary-panel
    span,
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-paging-panel
    .ag-paging-row-summary-panel
    div {
    @apply font-biotif__Regular;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-checkbox {
    @apply mt-[-1px] static;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-selection-checkbox{
    @apply !mr-0 !hidden;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-selection-checkbox + .ag-cell-value{
      @apply text-center;
    }
    .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell:first-child:hover .ag-selection-checkbox{
      @apply !flex;
    }
    .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell:first-child:hover .ag-selection-checkbox + .ag-cell-value{
      @apply !block;
    }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row-selected .ag-cell:first-child .ag-selection-checkbox{
    @apply !flex;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-row-selected .ag-cell:first-child .ag-selection-checkbox + .ag-cell-value{
    @apply !hidden;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-checkbox-input {
    @apply absolute top-0 left-0 w-full h-full;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .agGrid__customInput {
    @apply w-[16px] h-[16px] mr-0 accent-primaryColorSD;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .agGrid__customInput
    ~ .cell__value__wrapper {
    @apply w-[calc(100%_-_26px)];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .agGrid__customInput {
    @apply relative;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-checkbox-input-wrapper{
    @apply !shadow-none !outline-0 !border-0 absolute top-0 left-0 w-full h-full !bg-transparent;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-checkbox-input-wrapper:after{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-checkbox-input-wrapper.ag-checked:after {
    @apply text-primaryColorSD;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .adm__link {
    @apply text-ip__Blue hover:underline;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag__grid__toggle__btn {
    @apply p-0 w-[28px] h-[28px] mx-auto flex items-center justify-center bg-transparent hover:bg-btnGrayColor;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.active {
    @apply bg-btnGrayColor;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag__grid__toggle__btn .i__Icon{
    @apply m-0 p-[5px] w-[26px] h-[26px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn
    .i__Icon
    circle{
    @apply fill-mediumDarkTextColorSD;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.right__tick
    .i__Icon
    circle{
      @apply fill-ip__SuccessGreen;
    }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn:hover
    .i__Icon
    circle,
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.active
    .i__Icon
    circle{
    @apply fill-sdBlack__bg;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.right__tick:hover
    .i__Icon
    circle,
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.right__tick.active
    .i__Icon
    circle{
      @apply fill-ip__SuccessGreen;
    }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag__grid__toggle__btn.disabled__btn {
    @apply pointer-events-none opacity-50;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell:last-child .ag__grid__toggle__btn {
    @apply ml-[7px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell:last-child
    .ag__grid__toggle__btn.right__tick circle{
      @apply !fill-ip__SuccessGreen;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .badge {
    @apply py-[2px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .rowLink__visible
    .ag-body-viewport
    .ag-row:hover {
    @apply hover:!bg-ipGray__transparentBG;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll.skeleton__active
    .ag-body-viewport
    .ag-row {
    @apply !h-auto;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll.skeleton__active
    .ag-full-width-container {
    @apply !h-auto;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll.skeleton__active
    .ag-body-viewport {
    @apply min-h-[450px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.ag__grid__cell__ellipse:not(.ag-cell-wrap-text){
    @apply !text-ellipsis !whitespace-pre cursor-pointer;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell.ag__grid__cell__ellipse
    .ag-cell-wrapper {
    @apply w-full;
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-body-viewport .ag-cell.ag__grid__cell__ellipse:not(.ag-cell-wrap-text) .ag-cell-wrapper .value {
    @apply w-full overflow-hidden text-ellipsis;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header
    .ag-sort-indicator-icon
    .ag-icon {
    @apply text-black;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-icon.ag-header-cell-menu-button
    .ag-icon-menu:before {
    @apply text-black text-[22px] relative top-[2px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-sort-indicator-icon {
    @apply relative top-[-1px] grayscale brightness-[1000];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-icon-filter:before {
    @apply text-light__TextColor text-[16px] relative top-[-4px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-icon.ag-filter-icon {
    @apply relative top-[2px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-overlay-wrapper {
    @apply !pt-0;
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-layout-auto-height.ag-root {
    @apply z-[3];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-header-cell-resize{
    @apply opacity-100 !right-0 after:hidden before:content-[''] before:absolute before:top-0 before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-full before:w-[1px] before:bg-[#CCCCCC];
  }

  .lead__page__ag__table .ag__grid__infinite__scroll__inner,
  .deal__page__ag__table .ag__grid__infinite__scroll__inner,
  .contact__page__ag__table .ag__grid__infinite__scroll__inner,
  .activity__page__ag__table .ag__grid__infinite__scroll__inner,
  .account__page__ag__table .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_280px)];
  }
  .lead__page__ag__table.filter__visible .ag__grid__infinite__scroll__inner,
  .deal__page__ag__table.filter__visible .ag__grid__infinite__scroll__inner,
  .contact__page__ag__table.filter__visible .ag__grid__infinite__scroll__inner,
  .activity__page__ag__table.filter__visible .ag__grid__infinite__scroll__inner,
  .account__page__ag__table.filter__visible .ag__grid__infinite__scroll__inner {
    @apply h-[calc(100dvh_-_310px)];
  }

  .ag__grid__purple__new__design .ag-body-viewport .ag-cell-value{
    @apply inline-block w-full;
  }
  .ag__grid__purple__new__design .profile__img__name .img__wrapper {
    @apply w-[34px] h-[34px] shrink-0;
  }
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .noName__letter,
  .ag__grid__purple__new__design .profile__img__name .img__wrapper .img__loader,
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .img__element,
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .no__img__icon {
    @apply w-full h-full rounded-full;
  }
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .no__img__icon {
    @apply bg-ipGray__transparentBG p-[12px];
  }
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .img__loader {
    @apply bg-ipGray__transparentBG;
  }
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .img__element {
    @apply object-cover object-center;
  }
  .ag__grid__purple__new__design
    .profile__img__name
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply text-[14px] leading-[34px] pt-[4px];
  }
  .ag__grid__purple__new__design .profile__img__name .name{
    @apply text-[16px] font-biotif__Regular text-mediumDarkTextColorSD;
  }
  .ag__grid__purple__new__design .ag-cell-wrap-text .profile__img__name .name{
    @apply whitespace-normal;
  }

  .ag__grid__purple__new__design .manage__column__btn .icon__wrapper svg {
    @apply w-full h-full;
  }
  .ag__grid__purple__new__design .manage__column__btn .icon__wrapper svg path {
    @apply fill-mediumDarkTextColorSD;
  }
  .ag__grid__purple__new__design .manage__column__btn .down__arrow__icon svg {
    @apply w-full h-full;
  }
  .ag__grid__purple__new__design
    .manage__column__btn
    .down__arrow__icon
    svg
    path {
    @apply stroke-mediumDarkTextColorSD;
  }
  .ag__grid__purple__new__design .pin-unpin-header .name {
    @apply text-[16px] text-mediumDarkTextColorSD font-biotif__Regular font-normal;
  }

  .agGrid__emailMore__dropdown .action__btn path,
  .agGrid__phoneMore__dropdown .action__btn path{
    @apply fill-grayIconLightColor;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .link__wrapper,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .link__wrapper{
    @apply inline-block ml-[4px] first:ml-0;
  }
  .agGrid__emailMore__dropdown .action__btn:hover path,
  .agGrid__phoneMore__dropdown .action__btn:hover path{
    @apply fill-primaryColorSD;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon,
  .agGrid__email__wrapper .action__btn__wrapper .i__Icon,
  .agGrid__phone__wrapper .action__btn__wrapper .i__Icon{
    @apply w-[24px] h-[24px] p-[3px] !bg-transparent;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn,
  .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn{
    @apply w-[24px] h-[24px] p-[3px] !bg-transparent;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon:hover svg,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon:hover svg,
  .agGrid__email__wrapper .action__btn__wrapper .i__Icon:hover svg,
  .agGrid__phone__wrapper .action__btn__wrapper .i__Icon:hover svg,
  .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn:hover svg,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn:hover svg,
  .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn:hover svg,
  .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn:hover svg{
    @apply !invert-0;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon path,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon path,
  .agGrid__email__wrapper .action__btn__wrapper .i__Icon path,
  .agGrid__phone__wrapper .action__btn__wrapper .i__Icon path,
  .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn path,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn path,
  .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn path,
  .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn path{
    @apply fill-grayIconLightColor;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon:hover path,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon:hover path,
  .agGrid__email__wrapper .action__btn__wrapper .i__Icon:hover path,
  .agGrid__phone__wrapper .action__btn__wrapper .i__Icon:hover path,
  .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn:hover path,
  .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn:hover path,
  .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn:hover path,
  .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn:hover path{
    @apply fill-primaryColorSD;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn:hover path[stroke],
  .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn:hover path[stroke],
  .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn:hover path[stroke],
  .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn:hover path[stroke]{
    @apply stroke-primaryColorSD;
  }

  .agGrid__email__wrapper{
    @apply flex flex-wrap items-center;
  }
  .agGrid__email__wrapper .value__wrapper{
    @apply inline-flex max-w-full pr-[6px];
  }
  .agGrid__email__wrapper .action__btn__wrapper{
    @apply inline-flex opacity-0 pointer-events-none;
  }
  .agGrid__email__wrapper .value__wrapper:hover .action__btn__wrapper{
    @apply opacity-100 pointer-events-auto;
  }
  .agGrid__email__wrapper .action__btn__wrapper .action__btn{
    @apply w-[26px] h-[26px];
  }
  .agGrid__email__wrapper .action__btn__wrapper .action__btn .i__Icon{
    @apply w-full h-full rounded-full p-[3px] hover:!bg-btnGrayColor;
  }
  .agGrid__email__wrapper .action__btn__wrapper .action__btn .link__wrapper,
  .agGrid__email__wrapper .action__btn__wrapper .action__btn .link__wrapper div{
    @apply w-full h-full;
  }
  .agGrid__email__wrapper .action__btn__wrapper .action__btn.rightTick__btn .i__Icon{
    @apply !p-[5px];
  }
  .agGrid__email__wrapper .action__btn__wrapper .action__btn.email__btn .i__Icon{
    @apply p-[5px] !rounded-full;
  }

  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn{
    @apply w-[26px] h-[26px];
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn .i__Icon{
    @apply w-full h-full rounded-full p-[3px] hover:!bg-btnGrayColor;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn .link__wrapper,
  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn .link__wrapper div{
    @apply w-full h-full;
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn.rightTick__btn .i__Icon{
    @apply !p-[5px];
  }
  .agGrid__emailMore__dropdown .action__btn__wrapper .action__btn.email__btn .i__Icon{
    @apply p-[5px] !rounded-full;
  }
  .agGrid__email__wrapper .agGrid__emailMore__dropdown .action__btn__wrapper{
    @apply !opacity-100 pointer-events-auto;
  }

  .ag__grid__purple__new__design .ag-pinned-left-header {
    @apply !border-r-0;
  }
  /* .ag__grid__purple__new__design .ag-pinned-left-header,
  .ag__grid__purple__new__design .ag-pinned-left-cols-container{
    @apply relative z-[2] shadow-[1px_1px_3px_0px_#cfcfcfe6,_-1px_-1px_2px_0px_#ffffffe6,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33];
  } */

  /* row height & font size scale according option normal, large & compact */
  .ag__grid__purple__new__design .ag-cell,
  .ag__grid__purple__new__design .ag-header-cell{
    @apply !px-[15px];
  }
  .ag__grid__purple__new__design .ag-header-viewport .ag-header-cell:last-child .ag-header-cell-resize{
    @apply opacity-0;
  }
  .ag__grid__purple__new__design .pin-unpin-header{
    @apply pr-[22px];
  }
  .ag__grid__purple__new__design .pin-unpin-header .icon__wrapper{
    @apply top-[50%];
  }
  .ag__grid__purple__new__design .pin-unpin-header .icon__wrapper .i__Icon {
    @apply p-0 w-[18px] h-[18px];
  }
  .ag__grid__purple__new__design
    .ag__grid__infinite__scroll
    .ag-body-viewport
    .ag-cell
    .value {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[16px];
  }
  .ag__grid__purple__new__design .manage__column__btn .icon__wrapper {
    @apply w-[18px] h-[18px] flex items-center justify-center;
  }
  .ag__grid__purple__new__design .manage__column__btn .down__arrow__icon {
    @apply w-[13px] h-[9px];
  }

  /* compact */
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag-header,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag-header-row{
    @apply !h-[40px] !min-h-[40px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .ag-cell,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .ag-header-cell{
    @apply !px-[15px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag-header-cell-text {
    @apply text-[14px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .pin-unpin-header .name {
    @apply text-[14px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag-body-viewport .ag-cell {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[14px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag-body-viewport .ag-cell .value {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[14px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .pin-unpin-header .icon__wrapper .i__Icon {
    @apply p-0 w-[15px] h-[15px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .agGrid__customInput{
    @apply w-[14px] h-[14px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag__grid__toggle__btn{
    @apply w-[23px] h-[23px]
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.compact .ag__grid__toggle__btn .i__Icon{
    @apply w-[23px] h-[23px] p-[5px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .manage__column__btn .icon__wrapper{
    @apply w-[15px] h-[15px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .manage__column__btn .down__arrow__icon{
    @apply w-[11px] h-[7px] ml-[5px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .profile__img__name .img__wrapper{
    @apply w-[28px] h-[28px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .profile__img__name .img__wrapper .noName__letter .noName__letter__text{
    @apply text-[11px] leading-[28px] pt-[3px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .profile__img__name .name{
    @apply text-[14px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__email__wrapper .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__phone__wrapper .action__btn__wrapper .i__Icon{
    @apply w-[24px] h-[24px] p-[3px] !bg-transparent;
  }
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.compact .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn{
    @apply w-[24px] h-[24px] p-[3px] !bg-transparent;
  }
  /* compact-end */

  /* large */
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag-header,
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag-header-row{
    @apply !h-[70px] !min-h-[70px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .ag-cell,
  .ag__grid__purple__new__design .ag-theme-alpine.large .ag-header-cell{
    @apply !px-[15px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .pin-unpin-header{
    @apply pr-[26px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag-header-cell-text {
    @apply text-[20px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .pin-unpin-header .name {
    @apply text-[20px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag-body-viewport .ag-cell {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[20px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag-body-viewport .ag-cell .value {
    @apply text-mediumDarkTextColorSD font-biotif__Regular text-[20px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .pin-unpin-header .icon__wrapper .i__Icon {
    @apply p-0 w-[20px] h-[20px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .agGrid__customInput{
    @apply w-[20px] h-[20px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag__grid__toggle__btn{
    @apply w-[30px] h-[30px];
  }
  .ag__grid__purple__new__design .ag__grid__infinite__scroll .ag-theme-alpine.large .ag__grid__toggle__btn .i__Icon{
    @apply w-[30px] h-[30px] p-[6px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .manage__column__btn .icon__wrapper{
    @apply w-[20px] h-[20px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .manage__column__btn .down__arrow__icon{
    @apply w-[14px] h-[10px] ml-[5px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .profile__img__name .img__wrapper{
    @apply w-[42px] h-[42px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .profile__img__name .img__wrapper .noName__letter .noName__letter__text{
    @apply text-[16px] leading-[42px] pt-[3px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .profile__img__name .name{
    @apply text-[20px];
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__emailMore__dropdown .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__phoneMore__dropdown .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__email__wrapper .action__btn__wrapper .i__Icon,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__phone__wrapper .action__btn__wrapper .i__Icon{
    @apply w-[32px] h-[32px] p-[3px] !bg-transparent;
  }
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__emailMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__phoneMore__dropdown .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__email__wrapper .action__btn__wrapper .animated__icon__btn,
  .ag__grid__purple__new__design .ag-theme-alpine.large .agGrid__phone__wrapper .action__btn__wrapper .animated__icon__btn{
    @apply w-[32px] h-[32px] p-[3px] !bg-transparent;
  }
  /* large-end */
  /* row height & font size scale according option normal, large & compact end */
  /* ag__grid__purple__new__design__end */
  /* comman-css-end */

  .dashMainWrapper{
    @apply w-full flex;
  }
  .i__Dashboard__Wrapper{
    @apply w-[calc(100%_-_320px)] pt-[88px] ml-auto relative min-h-screen duration-300;
  }
  .pageContent{
    @apply pr-[30px];
  }

  /* page__topHeader */
  .dashboard__Header{
    @apply w-[calc(100%_-_305px)] fixed top-[0px] right-[0px] z-[5] duration-300;
  }
  .dashboard__Header .innerWrapper{
    @apply px-[30px] pl-[46px] py-[10px] flex justify-between rounded-bl-[12px] bg-sdWhite__bg shadow-[0px_0px_0px_0px_#0000000a,_4px_5px_14px_0px_#0000000a,_16px_20px_26px_0px_#0000000a,_37px_25px_30px_0px_#00000005,_65px_30px_35px_0px_#00000003];
  }
  .dashboard__Header .header__page__title{
    @apply font-biotif__Medium text-darkTextColorSD text-[22px] inline-flex items-center;
  }
  .dashboard__Header .rightWrapper{
    @apply inline-flex flex-wrap items-center;
  }

  .dashMainWrapper:not(.sidebar__Collapse) .dashboard__Header{
    @apply w-[calc(100%_-_96px)];
  }


  .page__topHeader .top__pinBar .pin__item .toggle__btn circle {
    @apply fill-primaryColorSD;
  }
  .page__topHeader .top__pinBar .pin__item svg path[fill]{
    @apply fill-[#737373];
  }
  .page__topHeader .top__pinBar .pin__item svg path[stroke]{
    @apply stroke-[#737373];
  }
  .page__topHeader .top__pinBar .pin__item.active,
  .page__topHeader .top__pinBar .pin__item:hover{
    @apply cursor-pointer;
  }
  .page__topHeader .top__pinBar .pin__item.active .icon__wrapper svg path[fill],
  .page__topHeader .top__pinBar .pin__item:hover .icon__wrapper svg path[fill]{
    @apply fill-primaryColorSD;
  }
  .page__topHeader .top__pinBar .pin__item.active .icon__wrapper svg path[stroke],
  .page__topHeader .top__pinBar .pin__item:hover .icon__wrapper svg path[stroke]{
    @apply stroke-primaryColorSD;
  }
  .page__topHeader .top__pinBar .pin__item.active .textLabel,
  .page__topHeader .top__pinBar .pin__item:hover .textLabel{
    @apply text-primaryColorSD;
  }
  .page__topHeader .top__pinBar .pin__item.active{
    @apply shadow-[1px_1px_3px_0px_#cfcfcfe6,_-1px_-1px_2px_0px_#ffffffe6,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn{
    @apply flex items-center justify-center hover:bg-primaryColorSD;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn .i__Icon{
    @apply w-full h-full p-[7px] pt-[8px];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn .i__Icon svg path{
    @apply fill-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn .i__Icon svg path[fill]{
    @apply fill-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn .i__Icon svg path[stroke]{
    @apply stroke-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn:hover .i__Icon svg path{
    @apply fill-[#ffffff];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn:hover .i__Icon svg path[fill]{
    @apply fill-[#ffffff];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__down__btn:hover .i__Icon svg path[stroke]{
    @apply stroke-[#ffffff];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom{
    @apply flex items-center justify-center relative top-[1px];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom .i__Icon{
    @apply w-[18px] h-[18px] relative top-[-2px] mr-[3px];
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom .i__Icon svg path{
    @apply fill-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom .i__Icon svg path[fill]{
    @apply fill-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom .i__Icon svg path[stroke]{
    @apply stroke-sdNormal__textColor;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom:hover .i__Icon svg path{
    @apply fill-primaryColorSD;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom:hover .i__Icon svg path[fill]{
    @apply fill-primaryColorSD;
  }
  .page__topHeader .top__pinBar .sticky__btn__wrapper .view__btn__custom:hover .i__Icon svg path[stroke]{
    @apply stroke-primaryColorSD;
  }

  .page__topHeader .bottom__actionBar .action__btn__wrapper {
    @apply h-[38px] py-[7px] px-[16px] rounded-[12px] shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33];
  }

  .list__ApplyFilter__top .add__clear__btn__wrapper{
    @apply mt-[14px] mb-[7px] items-center justify-start;
  }
  .list__ApplyFilter__top .add__clear__btn__wrapper .add__btn{
    @apply mt-0;
  }
  .list__ApplyFilter__top .add__clear__btn__wrapper .clear__btn{
    @apply relative top-[40px] left-[4px];
  }
  .list__ApplyFilter__top .checkbox__wrapper .ip__Checkbox {
    @apply w-full;
  }
  .list__ApplyFilter__top .checkbox__wrapper .ip__Checkbox .rc__Label {
    @apply py-[9px] px-[12px] pl-[36px] !w-full text-[14px] font-biotif__Medium text-black rounded-[3px] before:top-[calc(50%_-_1px)] before:translate-y-[-50%] before:left-[12px] before:w-[16px] before:h-[16px] before:border-[2px] before:border-black/50 after:w-[9px] after:h-[5px] after:left-[15px] after:top-[calc(50%_-_2px)] after:translate-y-[-50%];
  }
  .list__ApplyFilter__top .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColorSD before:border-[2px] before:border-primaryColorSD;
  }
  .list__ApplyFilter__top .e-daterangepicker{
    @apply !h-[44px] !pr-[50px] !text-[14px] !pt-[8px];
  }
  .list__ApplyFilter__top .e-date-range-wrapper .e-range-icon{
    @apply absolute top-[8px] right-0;
  }
  .list__ApplyFilter__top .e-date-range-wrapper .e-clear-icon{
    @apply absolute top-[13px] right-[24px];
  }

  .agGrid__spacing__dropdown{
    @apply overflow-hidden;
  }
  .agGrid__spacing__dropdown .item__row{
    @apply hover:bg-[#ececec];
  }
  .agGrid__spacing__dropdown .item__row.active{
    @apply hover:bg-primaryColorSD bg-primaryColorSD;
  }
  .agGrid__spacing__dropdown .item__row.active svg path[fill]{
    @apply fill-[#ffffff];
  }
  .agGrid__spacing__dropdown .item__row.active svg path[stroke]{
    @apply stroke-[#ffffff];
  }

  .pin__view__dropdown {
    @apply shadow-[0px_3px_17px_0px_#0000001a];
  }
  .pin__view__dropdown .header__pin__view .edit__save__btn svg path[fill] {
    @apply fill-grayIconDarkColor;
  }
  .pin__view__dropdown .header__pin__view input {
    @apply w-[calc(100%_-_36px)] rounded-[7px] !shadow-none py-[2px] px-[10px] focus:border-[1px] focus:border-primaryColorSD;
    outline: 0px !important;
  }
  .pin__view__dropdown .btn__item path {
    @apply fill-grayIconDarkColor;
  }
  .pin__view__dropdown .btn__item .text__Label {
    @apply text-sdNormal__textColor;
  }
  .pin__view__dropdown .btn__item:hover .text__Label {
    @apply text-primaryColorSD;
  }
  .pin__view__dropdown .btn__item:hover path {
    @apply fill-primaryColorSD;
  }
  .pin__view__dropdown .ip__Radio {
    @apply mb-[10px];
  }
  .pin__view__dropdown .ip__Radio .rc__Label {
    @apply !text-darkTextColorSD text-[16px] font-biotif__Regular pl-[20px] before:w-[14px] before:h-[14px] before:border-[1px] before:border-darkTextColorSD before:top-0 after:w-[6px] after:h-[6px] after:top-[4px] after:left-[4px] after:bg-primaryColorSD;
  }
  .pin__view__dropdown .ip__Radio input:checked + .rc__Label {
    @apply before:bg-transparent before:border-[1px] before:border-primaryColorSD;
  }

  .bottom__actionBar .action__btn__item .icon__wrapper path[fill] {
    @apply fill-sdNormal__textColor;
  }
  .bottom__actionBar .action__btn__item .icon__wrapper path[stroke] {
    @apply stroke-sdNormal__textColor;
  }
  .bottom__actionBar .action__btn__item .textLabel {
    @apply text-sdNormal__textColor;
  }
  .bottom__actionBar
    .action__btn__item
    .action__btn:hover
    .icon__wrapper
    path[fill]{
    @apply fill-primaryColorSD;
  }
  /* .bottom__actionBar
    .action__btn__item.exportBtn
    .action__btn
    .icon__wrapper
    svg{
@apply !fill-sdNormal__textColor;
    }
  .bottom__actionBar
    .action__btn__item.exportBtn
    .action__btn:hover
    .icon__wrapper
    svg{
      @apply !fill-primaryColorSD;
    } */
  .bottom__actionBar
    .action__btn__item
    .action__btn:hover
    .icon__wrapper
    path[stroke] {
    @apply stroke-primaryColorSD;
  }
  .bottom__actionBar .action__btn__item .action__btn:hover .textLabel{
    @apply text-primaryColorSD;
  }
  .bottom__actionBar .action__btn__item .action__btn.active .icon__wrapper path[fill]{
    @apply fill-primaryColorSD;
  }
  .bottom__actionBar .action__btn__item .action__btn.active .textLabel{
    @apply text-primaryColorSD;
  }
  .bottom__actionBar .action__btn__item .action__btn.active:hover .textLabel{
    @apply underline;
  }
  .bottom__actionBar .action__btn__item.date__range .action__btn {
    @apply relative overflow-hidden;
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group {
    @apply w-[20px] h-[20px] overflow-hidden;
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group
    > input.e-daterangepicker {
    @apply opacity-0;
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group.e-valid-input
    .e-clear-icon {
    @apply block absolute top-[10px] right-[22px] w-[24px] min-w-[0px] z-[9];
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group.e-valid-input {
    @apply w-[223px];
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group.e-valid-input
    > input.e-daterangepicker {
    @apply opacity-100 h-[20px] p-0 text-[15px] font-biotif__Medium text-sdNormal__textColor !w-full relative top-[11px] !bg-transparent;
  }
  .bottom__actionBar
    .action__btn__item.date__range
    .action__btn
    .e-input-group
    .e-input-group-icon {
    @apply absolute top-0 left-0 !opacity-0 w-full h-full z-[3];
  }
  .bottom__actionBar .action__btn__item.date__range .icon__wrapper {
    @apply ml-[-20px];
  }
  .bottom__actionBar .rowHeight__btn__item path[stroke] {
    @apply stroke-sdNormal__textColor;
  }

  .bottom__actionBar .action__btn__wrapper {
    @apply items-center h-[38px];
  }
  .bottom__actionBar .deal__list__kanban__view {
    @apply flex items-center;
  }
  .bottom__actionBar .deal__list__kanban__view .listView__btn {
    @apply w-[22px] h-[22px] p-[1px] rounded-[4px] flex items-center justify-center hover:!bg-btnGrayColor;
  }
  .bottom__actionBar .deal__list__kanban__view .listView__btn.active {
    @apply bg-primaryColorSD hover:!bg-primaryColorSD;
  }
  .bottom__actionBar .deal__list__kanban__view .listView__btn.active path,
  .bottom__actionBar
    .deal__list__kanban__view
    .listView__btn.active:hover
    path {
    @apply fill-[#ffffff];
  }
  .bottom__actionBar .deal__list__kanban__view .i__Icon {
    @apply w-full h-full p-0;
  }
  /* page__topHeader__end */

  /* sort__column__modal */
  .manage__column__modal.sort__column__modal .ip__Modal__Body {
    @apply !p-[24px] !pb-0;
  }
  .manage__column__modal.sort__column__modal .filters__row .delete__btn path {
    @apply fill-primaryColorSD;
  }
  .manage__column__modal.filterView__column__modal .ip__Modal__Body {
    @apply !p-[24px] !pb-0;
  }
  .manage__column__modal.filterView__column__modal .filters__row .ip__input {
    @apply h-[34px] py-[4px];
  }
  .manage__column__modal.filterView__column__modal
    .filters__row
    .delete__btn
    path {
    @apply fill-primaryColorSD;
  }
  .manage__column__modal.filterView__column__modal .e-daterangepicker{
    @apply !h-[34px] !pr-[50px] !text-[13px] !pt-[8px];
  }
  .manage__column__modal.filterView__column__modal .e-date-range-wrapper .e-range-icon{
    @apply absolute top-[3px] right-0;
  }
  .manage__column__modal.filterView__column__modal .e-date-range-wrapper .e-clear-icon{
    @apply absolute top-[8px] right-[24px]; 
  }
  .manage__column__modal.filterView__column__modal .checkbox__wrapper .no__data__wrapper{
    @apply flex  flex-wrap justify-center;
  }
  .manage__column__modal.filterView__column__modal .checkbox__wrapper .no__data__wrapper .title{
    @apply w-full text-center;
  }
  .manage__column__modal.filterView__column__modal .checkbox__wrapper .no__data__wrapper .text{
    @apply w-[350px] max-w-full text-center;
  }

  body .manage__column__modal.filterView__column__modal .ip__Modal__Body{
    @apply !pb-[30px];
  }
  .filterView__column__modal .filter__column__listing{
    @apply !h-auto;
  }
  /* sort__column__modal__end */

  /* column__option__modal */
  .column__option__modal .ip__Checkbox {
    @apply w-full;
  }
  .column__option__modal .ip__Modal__Body {
    @apply !pb-0;
  }
  .column__option__modal .checkbox__wrapper {
    @apply  pt-[10px];
  }
  .column__option__modal .checkbox__wrapper .ip__Checkbox {
    @apply w-full;
  }
  .column__option__modal .checkbox__wrapper .ip__Checkbox .rc__Label {
    @apply py-[9px] px-[12px] pl-[36px] !w-full text-[14px] font-biotif__Medium text-black rounded-[3px] before:top-[calc(50%_-_1px)] before:translate-y-[-50%] before:left-[12px] before:w-[16px] before:h-[16px] before:border-[2px] before:border-black/50 after:w-[9px] after:h-[5px] after:left-[15px] after:top-[calc(50%_-_2px)] after:translate-y-[-50%];
  }
  .column__option__modal .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColorSD before:border-[2px] before:border-primaryColorSD;
  }
  /* column__option__modal__end */

  /* manage__column__modal */
  .manage__column__modal .ip__Modal__Body {
    @apply !p-0 max-h-[calc(100dvh_-_165px)] overflow-hidden;
  }
  .manage__column__modal .ip__Modal__Footer {
    @apply !border-t-[1px] !border-t-[#F1F1F1] justify-between items-center;
  }
  .manage__column__modal .ip__Modal__Footer .delete__btn svg path {
    @apply fill-ip__Red duration-300;
  }
  .manage__column__modal .ip__Modal__Footer .delete__btn:hover svg path {
    @apply fill-white;
  }
  .manage__column__modal .horizontalTabs__wrapper {
    @apply before:hidden;
  }
  .manage__column__modal .sorting__btn .arrow.up {
    @apply top-[-1px] before:top-[-4px] before:left-[-2px] before:border-b-[5px] before:border-b-ipBlack__borderColor;
  }
  .manage__column__modal .sorting__btn .arrow.down {
    @apply bottom-[1px] before:bottom-[-4px] before:left-[-2px] before:border-t-[5px] before:border-t-ipBlack__borderColor;
  }
  .manage__column__modal .horizontalTabs__ul .item {
    @apply !pr-0 w-1/2;
  }
  .manage__column__modal .horizontalTabs__ul .link {
    @apply w-full min-w-[0px] px-[12px] py-[7px] border-none relative rounded-none before:content-[''] before:absolute before:bottom-[0px] before:left-0 before:h-[3px] before:w-[0px] before:bg-primaryColor before:duration-500;
  }
  .manage__column__modal .horizontalTabs__ul .link .text {
    @apply pl-0 font-biotif__Medium;
  }
  .manage__column__modal .horizontalTabs__ul .item.active .link {
    @apply bg-transparent before:w-full before:bg-primaryColorSD;
  }
  .manage__column__modal .horizontalTabs__ul .item.active .link .text {
    @apply text-primaryColorSD;
  }
  .manage__column__modal .checkbox__wrapper .ip__Checkbox {
    @apply w-full;
  }
  .manage__column__modal .checkbox__wrapper .ip__Checkbox .rc__Label {
    @apply py-[9px] px-[12px] pl-[36px] !w-full text-[14px] font-biotif__Medium text-black rounded-[3px] before:top-[calc(50%_-_1px)] before:translate-y-[-50%] before:left-[12px] before:w-[16px] before:h-[16px] before:border-[2px] before:border-black/50 after:w-[9px] after:h-[5px] after:left-[15px] after:top-[calc(50%_-_2px)] after:translate-y-[-50%];
  }
  .manage__column__modal .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColorSD before:border-[2px] before:border-primaryColorSD;
  }
  /* .manage__column__modal .ip__Select div[class$="control"],
  .manage__column__modal .ip__Select div[class$="control"]:hover,
  .manage__column__modal .ip__Select .ip__select__dynamic__control,
  .manage__column__modal .ip__Select .ip__select__dynamic__control:hover {
    @apply min-h-[32px] pt-[2px] pb-[2px];
  } */
  .manage__column__modal .ip__Select div[class$="placeholder"],
  .manage__column__modal .ip__Select .ip__select__dynamic__placeholder {
    @apply text-[14px];
  }
  .ip__Select div[class$="singleValue"],
  .ip__Select .ip__select__dynamic__single-value {
    @apply text-[14px] text-[#797979] font-biotif__Regular;
  }
  .manage__column__modal .ip__Select div[class$="Input"],
  .manage__column__modal .ip__Select .ip__select__dynamic__input,
  .manage__column__modal .ip__Select .ip__select__dynamic__input-container {
    @apply !text-[14px] text-[#797979];
  }
  .manage__column__modal
    .ip__Select
    div[class$="indicatorContainer"]:last-child::before,
  .manage__column__modal
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::before {
    @apply w-[7px] left-[calc(50%_-_2px)];
  }
  .manage__column__modal
    .ip__Select
    div[class$="indicatorContainer"]:last-child::after,
  .manage__column__modal
    .ip__Select
    .ip__select__dynamic__indicator-separator:last-child::after {
    @apply w-[7px] left-[calc(50%_+_2px)];
  }
  .manage__column__modal .ip__Select div[class$="IndicatorsContainer"],
  .manage__column__modal .ip__Select .ip__select__dynamic__indicators {
    @apply mr-[-19px];
  }
  .manage__column__modal .ip__Select div[class$="control"],
  .manage__column__modal .ip__Select div[class$="control"]:hover,
  .manage__column__modal .ip__Select .ip__select__dynamic__control,
  .manage__column__modal .ip__Select .ip__select__dynamic__control:hover {
    @apply pr-[22px] pl-[10px] min-h-[32px] pt-[2px] pb-[2px];
  }
  .manage__column__modal .ip__Select div[class$="MenuList"],
  .manage__column__modal .ip__Select .ip__select__dynamic__menu-list {
    @apply py-[4px];
  }
  .manage__column__modal .ip__Select div[class$="option"],
  .manage__column__modal .ip__Select .ip__select__dynamic__option {
    @apply text-[14px] py-[6px];
  }
  .manage__column__modal .ip__Select .ip__select__dynamic__single-value{
    @apply !text-[14px] !text-[#797979] !font-biotif__Medium;
  }
  .manage__column__modal .filters__column .ip__input {
    @apply py-[5px] text-[14px] text-[#797979] px-[10px];
  }
  .manage__column__modal .filters__column .ip__form__hasIcon .ip__input{
    @apply pl-[33px];
  }
  .manage__column__modal .filters__column .filters__row .delete__btn svg path {
    @apply fill-primaryColorSD duration-300;
  }
  .manage__column__modal
    .filters__column
    .filters__row
    .delete__btn:hover
    svg
    path {
    @apply fill-primaryColorSD;
  }
  .manage__column__modal .filters__column .columns__options{
    @apply !pr-0;
  }
  .manage__column__modal .filters__column .no__data__wrapper{
    @apply flex flex-wrap justify-center;
  }
  .manage__column__modal .filters__column .no__data__wrapper .title,
  .manage__column__modal .filters__column .no__data__wrapper .text{
    @apply w-full text-center;
  }
  .manage__column__modal .ip__Radio .rc__Label {
    @apply pl-[20px] before:w-[14px] before:h-[14px] before:border-[1px] before:border-black/50 before:top-[4px] after:w-[6px] after:h-[6px] after:top-[8px] after:left-[4px] after:bg-primaryColorSD;
  }
  .manage__column__modal .ip__Radio input:checked + .rc__Label {
    @apply before:bg-transparent before:border-[1px] before:border-primaryColorSD;
  }
  .manage__column__modal .first__column .list__item.active {
    @apply bg-[#E3E1F1];
  }
  .manage__column__modal .first__column .list__item.active .text {
    @apply text-primaryColorSD;
  }
  .manage__column__modal .first__column .list__item.active .arrow__btn {
    @apply border-l-primaryColorSD border-b-primaryColorSD;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new.manage__column__modal__new
    .ip__Modal__Footer {
    @apply !justify-between py-[15px] border-t-[1px] border-t-whiteScreenBorderColor;
  }
  .ip__Modal__Wrapper.ip__Modal__Wrapper__new.manage__column__modal__new
    .ip__Modal__Body {
    @apply max-h-[calc(100dvh_-_165px)] overflow-hidden;
  }
  .ip__Modal__Wrapper .filters__column .e-date-range-wrapper{
    @apply bg-[#f6f6f6];
  }
  .ip__Modal__Wrapper .filters__column .e-date-range-wrapper .e-range-icon{
    @apply !p-0 absolute top-[8px] right-[4px];
  }
  .ip__Modal__Wrapper .filters__column .e-date-range-wrapper .e-daterangepicker{
    @apply min-h-[33px] w-[calc(100%_-_52px)];
  }
  .ip__Modal__Wrapper .filters__column .e-date-range-wrapper .e-clear-icon-hide{
    @apply min-w-[18px] absolute top-[8px] right-[24px];
  }
  /* manage__column__modal__end */

  /* meeting-transcript */
  /* body{
    @apply !bg-[#F5F7F9];
  }
  body > div[style]{
    @apply !hidden;
  } */
  .allSearchWord {
    @apply bg-[#ffef00] text-black py-[2px] px-[5px] mx-[3px] rounded-[5px];
  }
  .allSearchWord.currentItem {
    @apply bg-ip__Orange text-black py-[2px] px-[5px] mx-[5px] rounded-[5px];
  }
  .videoStateCurrentWord {
    @apply bg-[#7467B7] text-white py-[2px] px-[5px] mx-[3px] rounded-[5px];
  }
  .video-main-wrapper .video-des-sec .inner__wrapper {
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .video-main-wrapper .video-des-sec .img__wrapper .noName__letter,
  .video-main-wrapper
    .video-des-sec
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply w-full h-full relative;
  }
  .video-main-wrapper
    .video-des-sec
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply z-[3] text-[13px];
  }
  .video-main-wrapper
    .video-des-sec
    .search__header__wrapper
    .ip__Select.ip__Select__hasIcon
    > .i__Icon
    svg
    path[fill],
  .video-main-wrapper
    .video-des-sec
    .search__header__wrapper
    .ip__form__hasIcon
    > .i__Icon
    svg
    path[fill] {
    @apply fill-[#313131];
  }
  .video-main-wrapper .video-des-sec .search__header__wrapper .ip__input {
    @apply bg-[#F1F1F1] pt-[5px] pb-[4px] rounded-[6px] text-black placeholder:text-[#737373];
  }
  .video-main-wrapper .video-sec .video__box {
    box-shadow: 1px 1px 3px 0px rgba(207, 207, 207, 0.9),
      -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
      1px -1px 2px 0px rgba(207, 207, 207, 0.2),
      -1px 1px 2px 0px rgba(207, 207, 207, 0.2);
  }
  .video-main-wrapper
    .video-sec
    .video__box
    .time__toggle
    .toggle__btn
    .i__Icon {
    @apply w-[22px] h-[27px] bg-white rounded-[5px] p-[6px];
    box-shadow: 0.9090909361839294px 0.9090909361839294px 0.9090909361839294px
        0px rgba(207, 207, 207, 0.5),
      0.9090909361839294px -0.9090909361839294px 0.9090909361839294px 0px rgba(207, 207, 207, 0.2),
      -0.9090909361839294px 0.9090909361839294px 0.9090909361839294px 0px rgba(207, 207, 207, 0.2);
  }
  .video-main-wrapper
    .video-sec
    .video__box
    .time__toggle
    .toggle__btn
    .i__Icon
    circle {
    @apply fill-[#585B5D];
  }
  .video-tag-wrapper video {
    @apply w-full h-auto rounded-[10px];
  }
  .video__action__wrapper .action__btn {
    @apply w-[24px] h-[24px] flex items-center justify-center;
  }
  .video__action__wrapper
    .volumn__slider__wrapper
    .e-control-wrapper.e-slider-container.e-horizontal {
    @apply h-auto;
  }
  .video__action__wrapper
    .volumn__slider__wrapper
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-handle {
    @apply w-[8px] h-[8px] !border-0 bg-white top-[-3px] !shadow-none ml-0;
  }
  .video__action__wrapper
    .volumn__slider__wrapper
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-slider {
    @apply h-auto top-0;
  }
  .video__action__wrapper
    .volumn__slider__wrapper
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-slider-track {
    @apply bg-[#737373] h-[2px] rounded-[20px] top-0;
  }
  .video__action__wrapper
    .volumn__slider__wrapper
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-range {
    @apply bg-white h-[2px] rounded-[20px] top-0;
  }
  .video__action__wrapper .volumn__slider__wrapper {
    @apply opacity-0 pointer-events-none;
  }
  .video__action__wrapper .volumn__wrapper:hover .volumn__slider__wrapper,
  .video__action__wrapper .volumn__wrapper .volumn__slider__wrapper.visible {
    @apply opacity-100 pointer-events-auto;
  }
  .video__action__wrapper
    .video__slider
    .e-control-wrapper.e-slider-container.e-horizontal {
    @apply h-auto;
  }
  .video__action__wrapper
    .video__slider
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-slider {
    @apply h-auto;
  }
  .video__action__wrapper
    .video__slider
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-slider-track {
    @apply bg-white;
  }
  .video__action__wrapper
    .video__slider
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-handle {
    @apply w-[14px] h-[14px] border-[2px] border-white bg-[#7467B7] top-[-9px] !shadow-none ml-[-2px];
  }
  .video__action__wrapper
    .video__slider
    .e-control-wrapper.e-slider-container.e-horizontal
    .e-range {
    @apply bg-[#7467B7];
  }
  .video-tag-wrapper {
    @apply overflow-y-hidden;
  }

  .recording__play .ip__Modal__Body {
    @apply p-[24px] max-h-[calc(100dvh_-_140px)];
  }
  .recording__play .video-des-sec .inner-wrapper {
    @apply h-[calc(100vh_-_305px)];
  }
  .recording__play .video-tag-wrapper {
    @apply bg-black;
  }
  .recording__play .video-tag-wrapper video {
    @apply max-h-[calc(100vh_-_258px)];
  }
  .recording__play .video-tag-wrapper.audio-tag {
    @apply h-[calc(100vh_-_259px)] rounded-[10px];
  }
  .recording__play .video__action__wrapper .bottom__toolbar .left{
    @apply w-auto flex justify-between;
  }
  .recording__play .video-tag-wrapper.audio-tag .video__action__wrapper .bottom__toolbar .left{
    @apply w-full;
  }
  .recording__play .video-tag-wrapper.audio-tag .video__action__wrapper .bottom__toolbar .right{
    @apply hidden;
  }
  .recording__play .audio__img__wrapper .inner__wrapper {
    height: 120px;
    background-image: url("https://i.ibb.co/G3jS4jN/Admired-Quick-Bream-size-restricted.gif");
    background-repeat: repeat-x;
    background-size: 194px;
    background-position: center;
  }
  /* .video-tag-wrapper .video__action__wrapper{
    @apply translate-y-[130%] opacity-0 duration-500;
  }
  .video-tag-wrapper:hover .video__action__wrapper{
    @apply translate-y-[0%] opacity-100;
  } */
  /* meeting-transcript-end */

  /* streams-page */
  .tippy-box.tippy__dropdown.top__pinBar__streams__dropdown
    .item:hover
    .item__link
    .item__text {
    @apply text-primaryColorSD;
  }
  .stream__list__wrapper .search__box .ip__input {
    @apply pt-[6px] pb-[5px] pl-[34px] pr-[12px] bg-[#F1F1F1] rounded-[8px];
  }
  .stream__list__wrapper .search__box.ip__form__hasIcon .i__Icon {
    @apply top-[4px];
  }
  .stream__list__wrapper.tippy-box.tippy__dropdown {
    @apply !shadow-none !bg-transparent;
  }

  .userFilter-modal .search__box .ip__input {
    @apply py-[5px] pl-[34px] pr-[12px] bg-[#F1F1F1] rounded-[8px];
  }
  .userFilter-modal .search__box.ip__form__hasIcon .i__Icon {
    @apply top-[6px];
  }
  .userFilter-modal .ip__Checkbox {
    @apply w-full;
  }
  .userFilter-modal .ip__Checkbox .rc__Label {
    @apply py-[9px] px-[12px] pl-[36px] !w-full text-[14px] font-biotif__Medium text-black rounded-[3px] before:top-[calc(50%_-_1px)] before:translate-y-[-50%] before:left-[12px] before:w-[16px] before:h-[16px] before:border-[2px] before:border-black/50 after:w-[9px] after:h-[5px] after:left-[15px] after:top-[calc(50%_-_2px)] after:translate-y-[-50%];
  }
  .userFilter-modal .ip__Checkbox input:checked + .rc__Label {
    @apply before:bg-primaryColorSD before:border-[2px] before:border-primaryColorSD;
  }

  .stream__page .timeline__day__repeater {
    @apply p-[28px] mb-[20px] rounded-[12px] bg-white shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33] last:mb-0;
  }
  .stream__page .timeline__day__repeater .rc__ac__item {
    @apply p-0 mb-[15px] last:mb-0;
  }
  .stream__page .timeline__day__repeater .rc__ac__item .img__wrapper {
    @apply w-[36px] h-[36px];
  }
  .stream__page .timeline__day__repeater .rc__ac__item .first__column .img__wrapper {
    @apply w-[52px] h-[52px];
  }
  .stream__page .timeline__day__repeater .rc__ac__item .link__preview .img__wrapper{
    @apply !w-[180px] !h-[130px];
  }
  .stream__page .timeline__day__repeater .rc__ac__item .link__preview .img__wrapper img{
    @apply !object-contain !object-center;
  }
  .stream__page
    .timeline__day__repeater
    .rc__ac__item
    .timeline__contant__wrapper {
    @apply w-full;
  }
  .stream__page .timeline__day__repeater .rc__ac__item .timeline__note {
    @apply inline-block w-auto max-w-full py-[10px] px-[18px] mt-[14px] mb-[10px] rounded-[10px] text-[14px] font-biotif__Medium text-sdNormal__textColor bg-sdWhite__bg shadow-[1px_1px_3px_0px_#ececece5_inset,_-1px_-1px_2px_0px_#ffffffe5_inset,_1px_-1px_2px_0px_#ececec33_inset,_-1px_1px_2px_0px_#ececec33_inset,_-1px_-1px_2px_0px_#ececec80,_1px_1px_2px_0px_#ffffff4d];
  }
  .stream__page .timeline__day__repeater .rc__ac__item .badge__group__wrapper {
    @apply py-[10px] pb-[5px] px-[15px] mt-[2px] mb-[2px] rounded-[10px] text-[14px] font-biotif__Medium text-sdNormal__textColor bg-sdWhite__bg shadow-[1px_1px_3px_0px_#ececece5_inset,_-1px_-1px_2px_0px_#ffffffe5_inset,_1px_-1px_2px_0px_#ececec33_inset,_-1px_1px_2px_0px_#ececec33_inset,_-1px_-1px_2px_0px_#ececec80,_1px_1px_2px_0px_#ffffff4d];
  }
  .stream__page
    .timeline__day__repeater
    .rc__ac__item
    .img__wrapper
    .noName__letter {
    @apply w-full h-full rounded-full !bg-transparent relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColorSD before:rounded-full before:opacity-10;
  }
  .stream__page
    .timeline__day__repeater
    .rc__ac__item
    .img__wrapper
    .noName__letter
    .noName__letter__text {
    @apply !text-primaryColorSD;
  }
  .stream__page .timeline__day__repeater .rc__ac__item .img__wrapper img {
    @apply !rounded-full;
  }
  .stream__page .timeline__day__repeater .rc__ac__item .rc__ac__contant {
    @apply w-[calc(100%_-_37px)];
  }
  .stream__page .timeline__day__repeater > .day__text {
    @apply text-[18px] font-biotif__Medium text-darkTextColorSD;
  }
  .stream__page .infinite-scroll-component {
    @apply pr-[2px] pb-[5px] !overflow-visible min-h-[calc(100vh_-_260px)];
  }
  .stream__page .timeline__contant__wrapper {
    @apply pl-0 pt-[5px];
  }
  .stream__page .rc__ActivityLogs {
    @apply w-full px-0;
  }
  .stream__page .rc__ActivityLogs .inner__wrapper {
    @apply !border-0;
  }
  .stream__page .rc__ActivityLogs .contant__wrapper{
    @apply !bg-transparent !border-0 !p-0 !h-auto;
  }
  .stream__page .rc__ActivityLogs .first__column .contant__wrapper{
    @apply !pl-[16px];
  }
  .stream__page .note__text__readmore{
    @apply before:h-[34px];
  }
  .stream__page .note__text__readmore > div{
    @apply text-[14px] text-sdNormal__textColor leading-[22px];
  }
  .stream__page .readMore__btn__wrapper{
    @apply mb-[10px];
  }
  /* streams-page-end */

/* stream__page__dashboard */
.stream__page__dashboard .header{
  @apply items-center;
}
.stream__page__dashboard .header .acType__btn{
  @apply !mb-0;
}
/* .stream__page.stream__page__dashboard .rc__ActivityLogs .contant__wrapper{
  @apply !bg-[#F3F3F3] !p-[20px];
} */
/* stream__page__dashboard__end */

 /* hierarchy-new-page */
 .hierarchy__box__new{
  @apply w-[330px] rounded-[10px] max-w-full shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33]; 
}
.hierarchy__box__new .ip__Select div[class$="control"],
.hierarchy__box__new .ip__Select div[class$="control"]:hover,
.hierarchy__box__new .ip__Select .ip__select__dynamic__control,
.hierarchy__box__new .ip__Select .ip__select__dynamic__control:hover{
  @apply min-h-[39px] py-[4px] pl-[10px];
}
.hierarchy__box__new .ip__Select div[class$="ValueContainer"],
.hierarchy__box__new .ip__Select .ip__select__dynamic__value-container{
  @apply h-[24px] leading-[24px];
}
.hierarchy__box__new .ip__Select div[class$="Input"],
.hierarchy__box__new .ip__Select .ip__select__dynamic__input,
.hierarchy__box__new .ip__Select .ip__select__dynamic__input-container{
  @apply h-[24px] leading-[24px];
}
.hierarchy__box__new .ip__Select div[class$="option"],
.hierarchy__box__new .ip__Select .ip__select__dynamic__option{
  @apply !py-[4px] !text-[14px];
}
.hierarchy__box__new .header__details{
  @apply flex items-center py-[10px] px-[10px] pl-[13px] rounded-t-[10px] shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColorSD before:rounded-t-[10px] before:opacity-10 before:hidden;  
}
.hierarchy__box__new .header__details .title{
  @apply text-darkTextColorSD text-[16px] font-biotif__Medium w-[calc(100%_-_22px)] pr-[10px] whitespace-pre overflow-hidden text-ellipsis relative z-[2];
}
.hierarchy__box__new .header__details .inline-edit-off{
  @apply w-[calc(100%_-_22px)] relative z-[3] whitespace-pre overflow-hidden text-ellipsis hover:text-primaryColorSD hover:underline;
}
.hierarchy__box__new .header__details .inlineEdit__On{
  @apply w-[calc(100%_-_22px)] relative z-[3] mt-0 pr-[6px];

}
.hierarchy__box__new .header__details .inlineEdit__On .ip__input{
  @apply bg-[#ffffff];
}
.hierarchy__box__new .header__details .inlineEdit__On .inlineEdit__action{
  @apply absolute top-[100%];
}
.hierarchy__box__new .header__details .toggle__btn{
  @apply relative z-[2] shrink-0;
}
.hierarchy__box__new .header__details .toggle__btn .i__Icon{
  @apply w-[20px] h-[26px] py-[6px] px-[5px] rounded-[5px] duration-300 hover:bg-[#ffffff];
}
.hierarchy__box__new .header__details .toggle__btn .i__Icon svg{
  @apply w-full h-full;
}
.hierarchy__box__new .header__details .toggle__btn .i__Icon svg path{
  @apply fill-mediumDarkTextColorSD;
}
.hierarchy__box__new .body__wrapper .profile__box{
  @apply flex items-start mb-[12px];
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper{
  @apply shrink-0 w-[36px] h-[36px];
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper img {
  @apply w-full h-full object-cover object-center rounded-[6px];
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .noName__letter,
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .img__loader,
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .img__element,
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .no__img__icon {
  @apply w-full h-full rounded-full;
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .img__loader {
  @apply sm:bg-ipWhite__bgColor;
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .img__element {
  @apply object-cover object-center;
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .noName__letter .noName__letter__text {
  @apply text-[14px] leading-[36px] pt-[4px];
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .noName__letter {
  @apply w-full h-full rounded-full relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:opacity-10 before:hidden;
}
.hierarchy__box__new .body__wrapper .profile__box .img__wrapper .noName__letter .bg__wrapper{
  @apply absolute top-0 left-0 w-full h-full rounded-full opacity-10;
}
/* .hierarchy__box__new .body__wrapper .profile__box .img__wrapper .noName__letter .noName__letter__text {
  @apply !text-primaryColorSD;
} */
.hierarchy__box__new .body__wrapper .profile__box .user__name__wrapper{
  @apply flex items-center pl-[10px] w-[calc(100%_-_36px)];
}
.hierarchy__box__new .body__wrapper .profile__box .user__name__wrapper > .inline-edit-off{
  @apply w-full mt-[8px] text-[14px] font-biotif__Regular text-darkTextColorSD whitespace-pre overflow-hidden text-ellipsis;
}
.hierarchy__box__new .body__wrapper .profile__box .user__name__wrapper > .inlineEdit__On{
  @apply w-full mt-[-1px];
}
.hierarchy__box__new .body__wrapper .profile__box .user__name__wrapper .name{
  @apply inline-block w-full text-[14px] font-biotif__Regular text-darkTextColorSD whitespace-pre overflow-hidden text-ellipsis mt-[8px] cursor-pointer;
}
.hierarchy__box__new .body__wrapper .profile__box .user__name__wrapper .add__manager__btn{
  @apply inline-block shrink-0 text-[14px] font-biotif__Regular text-primaryColorSD ml-[12px] hover:underline mt-[8px];
}
.hierarchy__box__new .body__wrapper .time__wrapper,
.hierarchy__box__new .body__wrapper .location__wrapper{
  @apply flex flex-wrap items-start;
}
.hierarchy__box__new .body__wrapper .time__wrapper{
  @apply mb-[6px];
}
.hierarchy__box__new .body__wrapper .time__wrapper .i__Icon,
.hierarchy__box__new .body__wrapper .location__wrapper .i__Icon{
  @apply !p-0 w-[22px] h-[22px];
}
.hierarchy__box__new .body__wrapper .time__wrapper .i__Icon path[fill],
.hierarchy__box__new .body__wrapper .location__wrapper .i__Icon path[fill]{
  @apply fill-grayIconDarkColor;
}
.hierarchy__box__new .body__wrapper .time__wrapper .i__Icon path[stroke],
.hierarchy__box__new .body__wrapper .location__wrapper .i__Icon path[stroke]{
  @apply stroke-grayIconDarkColor;
}
.hierarchy__box__new .body__wrapper .time__wrapper .text,
.hierarchy__box__new .body__wrapper .location__wrapper .text{
  @apply w-[calc(100%_-_23px)] pl-[10px] text-[14px] font-biotif__Regular text-darkTextColorSD;
}
.hierarchy__box__new .body__wrapper .field__wrapper .label{
  @apply text-[14px] text-sdNormal__textColor font-biotif__Regular mb-[2px] block leading-normal;
}
.hierarchy__box__new .body__wrapper .field__wrapper .inline-edit-off{
  @apply text-[14px] text-darkTextColorSD font-biotif__Regular leading-[18px];
}
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On{
  @apply mt-0;
}
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On .ip__input,
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On .ip__textarea{
  @apply text-[14px] font-biotif__Regular pt-[5px] pb-[6px] px-[9px];
}
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On .cancelBtn,
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On .submitBtn{
  @apply w-[28px] h-[28px];
}
.hierarchy__box__new .body__wrapper .field__wrapper .inlineEdit__On .submitBtn{
  @apply before:top-[8px] before:left-[8px];
}
.hierarchy__box__new .body__wrapper{
  @apply p-[12px] shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33];
}
.hierarchy__box__new .footer__wrapper .role__permission__btn,
.hierarchy__box__new .footer__wrapper .setting__btn,
.hierarchy__box__new .footer__wrapper .move__btn{
  @apply w-[20px] h-[20px];
}
.hierarchy__box__new .footer__wrapper .setting__btn,
.hierarchy__box__new .footer__wrapper .move__btn,
.hierarchy__box__new .footer__wrapper .right__btn{
  @apply ml-[8px]
}
.hierarchy__box__new .footer__wrapper .role__permission__btn .i__Icon,
.hierarchy__box__new .footer__wrapper .setting__btn .i__Icon,
.hierarchy__box__new .footer__wrapper .move__btn .i__Icon{
  @apply p-0 w-full h-full relative z-[2];
}
.hierarchy__box__new .footer__wrapper .setting__btn .i__Icon{
  @apply p-[2px];
}
.hierarchy__box__new .footer__wrapper .role__permission__btn .i__Icon path,
.hierarchy__box__new .footer__wrapper .setting__btn .i__Icon path,
.hierarchy__box__new .footer__wrapper .move__btn .i__Icon path{
  @apply fill-sdNormal__textColor duration-300;
}
.hierarchy__box__new .footer__wrapper .role__permission__btn:hover .i__Icon path,
.hierarchy__box__new .footer__wrapper .setting__btn:hover .i__Icon path,
.hierarchy__box__new .footer__wrapper .move__btn:hover .i__Icon path{
  @apply fill-primaryColorSD;
}
.hierarchy__box__new .footer__wrapper .down__btn{
  @apply w-[20px] h-[20px] relative text-[0px] before:content-[''] before:absolute before:top-[2px] before:left-[5px] before:w-[10px] before:h-[10px] before:border-l-[2px] before:border-b-[2px] before:border-l-sdNormal__textColor before:border-b-sdNormal__textColor before:-rotate-45 before:z-[2] hover:before:border-l-primaryColorSD hover:before:border-b-primaryColorSD;
}
.hierarchy__box__new .footer__wrapper .left__btn{
  @apply w-[20px] h-[20px] relative text-[0px] before:content-[''] before:absolute before:top-[5px] before:left-[7px] before:w-[10px] before:h-[10px] before:border-l-[2px] before:border-b-[2px] before:border-l-sdNormal__textColor before:border-b-sdNormal__textColor before:rotate-45 before:z-[2] hover:before:border-l-primaryColorSD hover:before:border-b-primaryColorSD;
}
.hierarchy__box__new .footer__wrapper .right__btn{
  @apply w-[20px] h-[20px] relative text-[0px] before:content-[''] before:absolute before:top-[5px] before:left-[3px] before:w-[10px] before:h-[10px] before:border-l-[2px] before:border-b-[2px] before:border-l-sdNormal__textColor before:border-b-sdNormal__textColor before:rotate-[-135deg] before:z-[2] hover:before:border-l-primaryColorSD hover:before:border-b-primaryColorSD;
}
.hierarchy__box__new .footer__wrapper .role__permission__btn,
.hierarchy__box__new .footer__wrapper .setting__btn,
.hierarchy__box__new .footer__wrapper .down__btn,
.hierarchy__box__new .footer__wrapper .left__btn,
.hierarchy__box__new .footer__wrapper .move__btn,
.hierarchy__box__new .footer__wrapper .right__btn{
  @apply relative after:content-[''] after:duration-300 after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:w-[calc(100%_+_6px)] after:h-[calc(100%_+_6px)] after:rounded-full after:bg-btnGrayColor after:opacity-0 hover:after:opacity-100;
}

.hierarchy-add-user-modal .no__data__wrapper{
  @apply w-full bg-transparent border-0 py-0 px-0;
}
/* hierarchy-new-page-end */
 /* timeline-design-start */

 .activity__timeline__wrapper{
  @apply rounded-[10px] shadow-[1px_1px_3px_0px_#cfcfcfe5,_-1px_-1px_2px_0px_#ffffffe5,_1px_-1px_2px_0px_#cfcfcf33,_-1px_1px_2px_0px_#cfcfcf33];
}
.activity__timeline__wrapper .activity__type__icon path[fill]{
  @apply fill-[#ffffff]
}
.activity__timeline__wrapper .activity__type__icon path[stroke]{
  @apply stroke-[#ffffff]
}
.activity__timeline__wrapper .result__top__header .action__btn circle[fill],
.activity__timeline__wrapper .result__top__header .action__btn path[fill]{
  @apply fill-grayIconDarkColor duration-300;
}
.activity__timeline__wrapper .result__top__header .action__btn:hover circle[fill],
.activity__timeline__wrapper .result__top__header .action__btn:hover path[fill]{
  @apply fill-[#ffffff];
}
.activity__timeline__wrapper .result__top__header .action__btn path[stroke]{
  @apply stroke-[#ffffff] duration-300;
}
.activity__timeline__wrapper .meeting__video__box{
  @apply px-[6px] pt-[2px];
}
.activity__timeline__wrapper .meeting__video__box .video__box{
  @apply mb-0 relative;
}
.activity__timeline__wrapper .meeting__video__box .video__box .cover__wrapper{
  @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
}
.activity__timeline__wrapper .meeting__video__box .video__box video{
  @apply rounded-[10px];
}
.activity__timeline__wrapper .meeting__video__box .view__btn path{
  @apply fill-grayIconDarkColor duration-300;
}
.activity__timeline__wrapper .meeting__video__box .view__btn:hover path{
  @apply fill-[#ffffff];
}
.video__slider__wrapper .slick-dots{
  @apply !hidden;
}
.video__slider__wrapper .slick-arrow{
  @apply w-[26px] h-[26px] rounded-full bg-primaryColorSD !text-[0px] z-[2] before:!hidden after:content-[''] after:w-[8px] after:h-[8px] after:absolute after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] after:border-l-[2px] after:border-b-[2px] after:border-l-[#ffffff] after:border-b-[#ffffff] after:-rotate-45 hover:bg-primaryColorSD focus:bg-primaryColorSD;
}
.video__slider__wrapper .slick-list{
  @apply pb-[35px];
}
.video__slider__wrapper .slick-arrow.slick-prev{
  @apply top-unset bottom-0 translate-y-0 left-[calc(50%_-_18px)] after:rotate-45 after:left-[calc(50%_+_1px)];
}
.video__slider__wrapper .slick-arrow.slick-next{
  @apply top-unset bottom-0 translate-y-0 left-[calc(50%_+_18px)] after:rotate-[-135deg] after:left-[calc(50%_-_2px)];
}

.video__slider__wrapper .slick-arrow.slick-next.slick-disabled,.video__slider__wrapper .slick-arrow.slick-prev.slick-disabled{
  @apply opacity-50 cursor-no-drop;
}

/* timeline-design-end */

.profile__img__name .img__wrapper .noName__letter{
  @apply w-[34px] h-[34px];
}
.profile__img__name .img__wrapper .noName__letter .noName__letter__text{
  @apply text-[12px] pt-[3px];
}

/* top-header-filter-view */
.filter__view__list{
  @apply border-t border-t-[#E5E5E5] p-[16px];
}
.filter__view__list .ip__Radio .rc__Label {
  @apply pl-[20px] before:w-[14px] before:h-[14px] before:border-[1px] before:border-black/50 before:top-[4px] after:w-[6px] after:h-[6px] after:top-[8px] after:left-[4px] after:bg-primaryColorSD;
}
.filter__view__list .ip__Radio input:checked + .rc__Label {
  @apply before:bg-transparent before:border-[1px] before:border-primaryColorSD;
}
/* top-header-filter-view-end */
/* ================= new purple design end =================== */
.pac-container:empty{
  @apply !hidden;
}
/* new__csNote__box */
.ip__Modal__Wrapper.ip__Modal__Wrapper__new.note__listing__modal .ip__Modal__Body{
  @apply !py-[20px] !px-[0px] !max-h-unset;
}
.ip__Modal__Wrapper.ip__Modal__Wrapper__new.note__listing__modal #noteModalDiv{
  @apply max-h-[calc(100vh_-_250px)] overflow-y-auto px-[20px];
}
.new__csNote__box{
  @apply relative mb-[24px];
}
.new__csNote__box .timeline__box__new .img__wrapper .noName__letter span{
  @apply pt-[2px];
}
.new__csNote__box .timeline__box__new .timeline__contant__wrapper{
  @apply !p-[14px] !pr-[52px] !rounded-[10px] shadow-[1px_1px_3px_0px_#ececece6_inset,_-1px_-1px_2px_0px_#ffffffe6_inset,_1px_-1px_2px_0px_#ececec33_inset,_-1px_1px_2px_0px_#ececec33_inset,_-1px_-1px_2px_0px_#ececec80,_1px_1px_2px_0px_#ffffff4d];
}
.new__csNote__box .i__Button{
  @apply absolute z-[3] top-[12px] right-[13px] !p-0 !bg-[#ffffff] shadow-[1px_1px_1px_0px_#cfcfcf80,_1px_-1px_1px_0px_#cfcfcf33,_-1px_1px_1px_0px_#cfcfcf33];
}
.new__csNote__box .i__Button .i__Icon{
  @apply !w-[24px] !h-[30px] !p-[7px] !m-0;
}
.new__csNote__box .readMore__btn__wrapper button{
  @apply text-[#737373] before:border-l-[#737373] before:border-b-[#737373] hover:text-[#000000] hover:before:border-l-[#000000] hover:before:border-b-[#000000];
}
.new__csNote__box .img__wrapper{
  @apply mr-[15px];
}
.new__csNote__box .timeline__contant__wrapper{
  @apply w-[calc(100%_-_55px)];
}
.new__csNote__box .author__time__wrapper{
  @apply pl-[54px];
}
.new__csNote__box .attachment__view__wrapper{
  @apply !inline-block;
}
.new__csNote__box .attachment__view__wrapper .attachment__view__wrapper__inner{
  @apply !mx-0 !inline-block !w-auto;
}
/* new__csNote__box__end */

/* dodument__view__list__modal */
.dodument__view__list__modal .ip__Modal__Body{
  @apply !py-[20px] !px-[0px] !max-h-unset;
}
.dodument__view__list__modal #documentRenderDiv{
  @apply max-h-[calc(100vh_-_250px)] overflow-y-auto px-[20px];
}
.dodument__view__list__modal .new__document__list__box{
  @apply relative mb-[22px] last:mb-0;
}
.dodument__view__list__modal .new__document__list__box .img__wrapper{
  @apply mr-[15px];
}
.dodument__view__list__modal .new__document__list__box .img__wrapper .noName__letter,
.dodument__view__list__modal .new__document__list__box .img__wrapper .img__loader,
.dodument__view__list__modal .new__document__list__box .img__wrapper .img__element,
.dodument__view__list__modal .new__document__list__box .img__wrapper .no__img__icon{
  @apply w-full h-full rounded-[10px];
}
.dodument__view__list__modal .new__document__list__box .img__wrapper .noName__letter span{
  @apply pt-[2px] text-[14px];
}
.dodument__view__list__modal .new__document__list__box .document__contant__wrapper{
  @apply !p-[14px] !pr-[52px] !rounded-[10px] shadow-[1px_1px_3px_0px_#ececece6_inset,_-1px_-1px_2px_0px_#ffffffe6_inset,_1px_-1px_2px_0px_#ececec33_inset,_-1px_1px_2px_0px_#ececec33_inset,_-1px_-1px_2px_0px_#ececec80,_1px_1px_2px_0px_#ffffff4d];
}
.dodument__view__list__modal .new__document__list__box .author__time__wrapper{
  @apply pl-[54px];
}
.dodument__view__list__modal .new__document__list__box .i__Button{
  @apply absolute z-[3] top-[12px] right-[13px] !p-0 !bg-[#ffffff] shadow-[1px_1px_1px_0px_#cfcfcf80,_1px_-1px_1px_0px_#cfcfcf33,_-1px_1px_1px_0px_#cfcfcf33];
}
.dodument__view__list__modal .new__document__list__box .i__Button .i__Icon{
  @apply !w-[24px] !h-[30px] !p-[7px] !m-0;
}
/* dodument__view__list__modal__end */

.tag__modal__wrapper .ip__Modal__Body{
  @apply min-h-[250px];
}

.bulkEdit__open .bulkEdit__wrapper{
  @apply translate-x-[0px];
}
.bulkEdit__open .ag__grid__purple__new__design,
.bulkEdit__open .page__topHeader{
  @apply !pointer-events-none;
}
.bulkEdit__wrapper .bulkEdit__scrollWrapper .buttons__wrapper{
  @apply absolute bottom-0 left-0 right-0 w-full;
}
.bulkEdit__wrapper .ip__Checkbox .rc__Label{
  @apply pt-[2px];
}
.bulkEdit__wrapper .ip__Checkbox input:checked + .rc__Label:before{
  @apply bg-primaryColorSD border-primaryColorSD;
}
.bulkEdit__wrapper .activity__type__dropdown{
  @apply w-full mb-0;
}
.bulkEdit__wrapper .activity__type__dropdown .activityType__dropdown__btn{
  @apply !mb-0 w-full mr-0 mt-[10px];
}
.bulkEdit__wrapper .start__field__wrapper .e-input-group{
  @apply !w-[calc(100%_-_53px)];
}
.bulkEdit__wrapper .start__field__wrapper .e-input-group .e-time-icon{
  @apply absolute top-[8px] right-0;
}
.bulkEdit__wrapper .start__field__wrapper .e-input-group .e-clear-icon{
  @apply absolute top-[13px] right-[26px];
}
.bulkEdit__wrapper .start__field__wrapper .react-datepicker-wrapper{
  @apply w-full mt-[10px];
}
.bulkEdit__wrapper .ip__select__dynamic__menu{
  @apply z-[4];
}
.bulkEdit__wrapper .react-datepicker-wrapper{
  width:100%;
}
.allowIp__modal .start__time .e-time-wrapper .e-time-icon,
.allowIp__modal .end__time .e-time-wrapper .e-time-icon{
  @apply absolute top-[7px] right-[-1px];
}
.allowIp__modal .start__time .e-clear-icon,
.allowIp__modal .end__time .e-clear-icon{
  @apply absolute top-[12px] right-[24px];
}
.activity__details__stream__box .activity__type__icon .i__Icon svg path[stroke]{
  @apply stroke-[#ffffff];
}
.activity__details__stream__box .activity__type__icon .i__Icon svg path[fill]{
  @apply fill-[#ffffff];
}
/* .activity__details__stream__box .activity__type__icon svg path[fill]{
  @apply fill-[#ffffff];
}
.activity__details__stream__box .activity__type__icon svg path[stroke]{
  @apply stroke-[#ffffff];
} */
  /* ================= new purple design end =================== */
}
/* ============================== new style end ============================== */

/* ag grid popup css start */
body .ag-tabs.ag-menu {
  /* @apply bg-#ffffff shadow-[0px_2.35714px_4.71429px_#0000001a] border-0; */
  background-color: #ffffff;
  box-shadow: 0px 2.35714px 4.71429px #0000001a;
  border: none;
}
/* .ag-theme-alpine.large {
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px;
  --ag-font-size: 32px;
}

.ag-theme-alpine.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
} */
body .ag-theme-alpine .ag-tabs.ag-menu .ag-tab-selected {
  /* @apply text-ip__Blue !border-0; */
  color: var(--ip__Blue);
  border: none !important;
}
body .ag-theme-alpine .ag-tabs-header,
body .ag-theme-alpine-dark .ag-tabs-header {
  /* @apply !border-0 !bg-ipGray__transparentBG; */
  border: none !important;
  background-color: var(--ipGray__transparentBG) !important;
}
body .ag-standard-button.ag-filter-apply-panel-button {
  /* @apply flex flex-wrap items-center justify-center rounded-[7px] px-[14px] py-[6px] font-biotif__Medium bg-secondary__Btn__BGColor text-dark__TextColor duration-300 hover:bg-secondary__Btn__BGColor__hover active:bg-secondary__Btn__BGColor__hover border-0 font-normal focus:!border-0 focus:shadow-none; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 6px 14px;
  font-family: "biotif__Medium", sans-serif;
  background-color: var(--secondary__Btn__BGColor);
  color: var(--dark__TextColor);
  border: none;
  font-weight: normal;
  transition: 0.3s;
}
body .ag-standard-button.ag-filter-apply-panel-button:hover {
  background-color: var(--secondary__Btn__BGColor__hover);
}
body .ag-standard-button.ag-filter-apply-panel-button:active {
  background-color: var(--secondary__Btn__BGColor__hover);
}
body .ag-standard-button.ag-filter-apply-panel-button:focus {
  border: none !important;
  box-shadow: none !important;
}
body .ag-standard-button.ag-filter-apply-panel-button:nth-child(2) {
  /* @apply flex flex-wrap items-center justify-center rounded-[7px] px-[14px] py-[6px] font-biotif__Medium text-ip__white__text__color bg-ip__Blue duration-500 border-0 hover:bg-ip__Blue__hoverDark font-normal focus:!border-0 focus:shadow-none; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 6px 14px;
  font-family: "biotif__Medium", sans-serif;
  color: var(--ip__white__text__color);
  background-color: var(--ip__Blue);
  transition: 0.5s;
  border: none;
  font-weight: normal;
}
body .ag-standard-button.ag-filter-apply-panel-button:nth-child(2):hover {
  background-color: var(--ip__Blue__hoverDark);
}
body .ag-standard-button.ag-filter-apply-panel-button:nth-child(2):focus {
  border: none !important;
  box-shadow: none !important;
}
body .ag-tabs.ag-menu .ag-text-field-input {
  /* @apply h-[36px] w-full max-w-full !rounded-[6px] border border-transparent bg-formField__BGColor !px-[14px] !pt-[5px] !pb-[6px] font-biotif__Regular text-[14px] leading-[26px] text-dark__TextColor focus:!border-transparent focus:shadow-none; */
  height: 36px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: var(--formField__BGColor);
  padding: 5px 14px 6px 14px !important;
  font-family: "biotif__Regular", sans-serif;
  font-size: 14px;
  line-height: 26px;
  color: var(--dark__TextColor);
}
body .ag-tabs.ag-menu .ag-text-field-input:focus {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

body .ag-tabs.ag-menu .ag-filter-select {
  /* @apply !h-auto; */
  height: auto !important;
}
body .ag-tabs.ag-menu .ag-filter-select .ag-picker-field-wrapper {
  /* @apply h-[36px] !border-0 !rounded-[6px] bg-formField__BGColor focus:shadow-none; */
  height: 36px;
  border: none !important;
  border-radius: 6px !important;
  background-color: var(--formField__BGColor);
}
body .ag-tabs.ag-menu .ag-filter-select .ag-picker-field-wrapper:focus {
  box-shadow: none;
}

body
  .ag-tabs.ag-menu
  .ag-filter-select
  .ag-picker-field-wrapper
  .ag-picker-field-display {
  /* @apply pl-[6px] text-dark__TextColor font-biotif__Regular text-[14px]; */
  padding-left: 6px;
  color: var(--dark__TextColor);
  font-family: "biotif__Regular", sans-serif;
  font-size: 14px;
}
body
  .ag-tabs.ag-menu
  .ag-filter-select
  .ag-picker-field-wrapper
  .ag-picker-field-icon {
  /* @apply relative left-[-5px]; */
  position: relative;
  left: -5px;
}
body .ag-tabs.ag-menu .ag-filter-apply-panel {
  /* @apply border-t border-whiteScreen__BorderColor; */
  border-top: 1px solid var(--whiteScreen__BorderColor);
}
body .ag-tabs.ag-menu .ag-filter-condition {
  /* @apply mb-[12px] mt-[5px]; */
  margin-bottom: 12px;
  margin-top: 5px;
}
body .ag-tabs.ag-menu .ag-input-field-input.ag-radio-button-input {
  /* @apply accent-ip__Blue; */
  accent-color: var(--ip__Blue);
}
body .ag-theme-alpine .ag-icon.ag-icon-filter {
  /* @apply text-[24px]; */
  font-size: 24px;
}
/* ag grid popup css end */

/*react-datepicker*/
.ip__react__datepicker__hasTime .react-datepicker {
  min-width: 353px;
}
.react-datepicker-popper{
  z-index:9 !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -28px !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  top: 0px !important;
  border-bottom-color: var(--whiteScreen__BorderColor) !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #ffffff !important;
  top: 1px !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: var(--whiteScreen__BorderColor) !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: #ffffff !important;
}
.react-datepicker-popper .react-datepicker__current-month {
  font-family: "Biotif-SemiBold", sans-serif;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 50px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 11px;
  color: var(--dark__TextColor);
}
.react-datepicker-popper .react-datepicker__navigation--previous,
.react-datepicker-popper .react-datepicker__navigation--next {
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  box-shadow: 1px 2px 6px #e8e8e8;
  border-radius: 8px;
  transition: 0.5s;
}
.react-datepicker-popper .react-datepicker__navigation--next {
  top: 8px;
  right: 10px;
}
.react-datepicker-popper .react-datepicker__navigation--previous {
  top: 8px;
  right: 46px;
  left: unset;
}
.react-datepicker-popper .react-datepicker__navigation--previous span,
.react-datepicker-popper .react-datepicker__navigation--next span {
  display: none;
}
.react-datepicker-popper .react-datepicker__navigation--previous::before,
.react-datepicker-popper .react-datepicker__navigation--next::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 11px;
  width: 8px;
  height: 8px;
  border-left: 2px solid #000000;
  border-bottom: 2px solid #000000;
  transform: rotate(45deg);
  transition: 0.5s;
}
.react-datepicker-popper .react-datepicker__navigation--next::before {
  transform: rotate(-135deg);
  left: 8px;
}
.react-datepicker-popper .react-datepicker__navigation--previous:hover,
.react-datepicker-popper .react-datepicker__navigation--next:hover {
  background-color: #000000;
  box-shadow: unset;
}
.react-datepicker-popper .react-datepicker__navigation--previous:hover:before,
.react-datepicker-popper .react-datepicker__navigation--next:hover:before {
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}
.react-datepicker-popper .react-datepicker__header {
  padding: 0px;
  background-color: #ffffff;
  border-bottom: none;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  background-color: var(--ipGray__transparentBG);
  border-bottom: 1px solid var(--whiteScreen__BorderColor);
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll
  .react-datepicker__month-read-view--selected-month,
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll
  .react-datepicker__year-read-view--selected-year {
  font-family: "Biotif-SemiBold", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: var(--dark__TextColor);
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  background-color: #f2f2f2;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__month-dropdown-container,
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__year-dropdown-container {
  padding: 0px;
  position: relative;
  height: 35px;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__month-dropdown-container:before,
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__year-dropdown-container:before {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%) rotate(-45deg);
  right: 10px;
  width: 7px;
  height: 7px;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__month-dropdown-container:before {
  right: 28px;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__year-dropdown-container:before {
  top: calc(50% - 3px);
  right: 44px;
}
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__month-dropdown-container
  select,
.react-datepicker-popper
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  .react-datepicker__year-dropdown-container
  select {
  width: 100%;
  text-align: center;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 7px 30px 7px 10px;
  font-family: "Biotif-Medium", sans-serif;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
}

.react-datepicker-popper .react-datepicker__month-read-view,
.react-datepicker-popper .react-datepicker__year-read-view {
  display: inline-block;
  position: relative;
  padding-right: 14px;
  visibility: visible !important;
}
.react-datepicker-popper .react-datepicker__month-dropdown-container,
.react-datepicker-popper .react-datepicker__year-dropdown-container {
  margin: 0px;
  width: 50%;
  padding: 4px 5px;
}
.react-datepicker-popper .react-datepicker__month-dropdown-container {
  border-right: 1px solid var(--whiteScreen__BorderColor);
}
.react-datepicker-popper .react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper .react-datepicker__year-read-view--down-arrow {
  border-width: 1px 1px 0 0;
  border-color: var(--dark__TextColor) !important;
  top: 4px;
  transform: rotate(135deg);
  width: 7px;
  height: 7px;
  right: 0px;
  position: absolute;
}
.react-datepicker-popper .react-datepicker__month-dropdown,
.react-datepicker-popper .react-datepicker__year-dropdown {
  background-color: var(--ipGray__transparentBG);
  border: none;
  border-radius: 0px;
  left: 0px;
  width: 50%;
  top: 79px;
  padding: 4px 0px;
  overflow-y: auto;
}
.react-datepicker-popper .react-datepicker__month-dropdown {
  height: 223px;
}
.react-datepicker-popper .react-datepicker__year-dropdown {
  left: 50%;
  padding: 0px 0px;
  height: 365px;
}
.react-datepicker-popper
  .react-datepicker__month-dropdown
  .react-datepicker__month-option,
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option {
  padding: 3px 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Biotif-SemiBold", sans-serif;
  font-weight: 400;
  color: var(--dark__TextColor);
  border-radius: 0px !important;
}
.react-datepicker-popper
  .react-datepicker__month-dropdown
  .react-datepicker__month-option:hover,
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option:hover {
  background-color: var(--ip__Grey__hoverDark);
}
.react-datepicker-popper
  .react-datepicker__month-dropdown
  .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.react-datepicker-popper
  .react-datepicker__month-dropdown
  .react-datepicker__month-option.react-datepicker__month-option--selected_month:hover,
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option.react-datepicker__year-option--selected_year,
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option.react-datepicker__year-option--selected_year:hover {
  background-color: var(--ipBlue__transparentBG);
  color: var(--ip__Blue);
}
.react-datepicker-popper .react-datepicker__month-option--selected,
.react-datepicker-popper .react-datepicker__year-option--selected {
  display: none;
}
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__navigation--years {
  width: 8px;
  height: 8px;
  border-right: 2px solid var(--dark__TextColor) !important;
  border-top: 2px solid var(--dark__TextColor) !important;
  transform: rotate(-45deg);
  margin: 0px auto;
  position: relative;
  top: 1px;
}
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming,
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  margin: 4px auto 4px auto;
}
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child
  .react-datepicker__navigation--years {
  transform: rotate(135deg);
  top: -3px;
}
/* .react-datepicker-popper
.react-datepicker__year-dropdown
.react-datepicker__year-option:first-child,
.react-datepicker-popper
.react-datepicker__year-dropdown
.react-datepicker__year-option:last-child {
background-color: var(--ip__Grey__hoverDark);
padding: 8px 0px;
margin-bottom: 8px;
} */
.react-datepicker-popper
  .react-datepicker__year-dropdown.react-datepicker__year-dropdown--scrollable
  .react-datepicker__year-option:last-child {
  padding: 3px 10px;
  margin-top: 0px;
}
.react-datepicker-popper
  .react-datepicker__year-dropdown.react-datepicker__year-dropup--scrollable
  .react-datepicker__year-option:first-child {
  padding: 3px 10px;
  margin-bottom: 0px;
}
.react-datepicker-popper
  .react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child {
  margin-bottom: 0px;
  margin-top: 8px;
}

.react-datepicker-popper .react-datepicker {
  border: 1px solid var(--whiteScreen__BorderColor);
  /* box-shadow: 5px 10px 20px rgba(222, 226, 230, 0.3); */
  box-shadow: #110c2e26 0px 15px 50px 0px;
  background-color: #ffffff;
}
.react-datepicker-popper .react-datepicker__day-name,
.react-datepicker-popper .react-datepicker__day,
.react-datepicker-popper .react-datepicker__time-name {
  width: 31px;
  line-height: 31px;
  font-family: "Biotif-Medium", sans-serif;
  color: var(--dark__TextColor);
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  background-color: var(--ip__Blue) !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.react-datepicker-popper
  .react-datepicker__day-name.react-datepicker__day--outside-month,
.react-datepicker-popper
  .react-datepicker__day.react-datepicker__day--outside-month,
.react-datepicker-popper
  .react-datepicker__time-name.react-datepicker__day--outside-month,
.react-datepicker-popper
  .react-datepicker__day.react-datepicker__day--disabled {
  color: var(--disableLight__TextColor);
}
.react-datepicker-popper .react-datepicker__day:hover {
  background-color: var(--ipGray__transparentBG);
}

/* with-time */
.react-datepicker__time-container {
  @apply border-l-whiteScreen__BorderColor border-t border-t-whiteScreen__BorderColor mt-[47px] pt-[6px] !w-[84px];
}
.react-datepicker-time__header {
  @apply font-biotif__SemiBold text-ip__black__text__color font-normal;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--ipGray__transparentBG);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--ipBlue__transparentBG);
  color: var(--ip__Blue);
  font-weight: normal;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  height: 230px !important;
}
/* with-time-end */
/*react-datepicker-end*/

/* image-viewer */
.react-viewer-transition {
  transition: unset !important;
}
/* image-viewer-end */

/* Calendar */
body .e-schedule .e-schedule-toolbar,
.e-schedule .e-schedule-toolbar .e-toolbar-items {
  background-color: transparent;
}
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-item.e-separator {
  display: none !important;
}
body .e-toolbar .e-tbar-btn-text,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  padding: 0px;
  font-size: 14px;
  font-family: "Biotif-Medium", sans-serif;
  color: var(--primaryColor) !important;
}
body
  .e-toolbar
  .e-toolbar-items:first-child
  > .e-toolbar-right
  .e-toolbar-item:last-child {
  margin-right: 0px;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  height: auto;
  margin: 0px;
  /* padding: 8px 16px 6px 16px; */
  /* height: 39.6px; */
  border-radius: 0px;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom: none;
  border-right: none;
  background-color: var(--primary__transparentBG);
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-right
  .e-toolbar-item
  .e-tbar-btn.e-btn {
  height: 32px;
  width: 82px !important;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item
  .e-tbar-btn.e-btn {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item
  .e-tbar-btn.e-btn
  .e-btn-icon {
  font-weight: 700;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-prev
  .e-tbar-btn.e-btn,
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-next
  .e-tbar-btn.e-btn {
  min-width: 25px;
  min-height: 25px;
  height: 25px;
  border-radius: 50% !important;
  padding-bottom: 3px !important;
  margin-top: -2px;
}
body
  .createActivityModal
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-prev
  .e-tbar-btn.e-btn
  .e-icons.e-btn-icon,
body
  .createActivityModal
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-next
  .e-tbar-btn.e-btn
  .e-icons.e-btn-icon {
  line-height: 26px;
  min-height: 26px;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-prev
  .e-tbar-btn.e-btn {
  padding-right: 4px !important;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-prev
  .e-tbar-btn.e-btn:hover,
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-next
  .e-tbar-btn.e-btn:hover {
  background-color: #f2f2f2 !important;
}
body
  .createActivityModal
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-prev
  .e-tbar-btn.e-btn:hover,
body
  .createActivityModal
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-next
  .e-tbar-btn.e-btn:hover {
  background-color: #ffffff !important;
}
body
  .createActivityModal
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day {
  background-color: transparent !important;
}
body .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item.e-date-range {
  order: -1;
  margin-right: 8px;
  min-width: 96px;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-date-range
  .e-tbar-btn.e-btn
  .e-btn-icon {
  display: none !important;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-toolbar-item.e-date-range
  .e-tbar-btn.e-btn
  .e-tbar-btn-text {
  font-size: 16px;
  font-family: "Biotif-Medium", sans-serif;
  color: #000000 !important;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item.e-day .e-tbar-btn.e-btn {
  border-left: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item:first-child .e-tbar-btn.e-btn {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item.e-today:nth-last-child(2)
  .e-tbar-btn.e-btn,
body .e-toolbar .e-toolbar-items .e-toolbar-item:last-child .e-tbar-btn.e-btn {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-right: 1px solid var(--whiteScreen__BorderColor);
}
body .e-toolbar .e-toolbar-items .e-toolbar-item.e-month .e-tbar-btn.e-btn {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover,
body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus {
  margin: 0px;
  background-color: var(--lightScreenBg);
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-right
  .e-toolbar-item
  .e-tbar-btn.e-btn:hover,
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-right
  .e-toolbar-item
  .e-tbar-btn.e-btn:focus {
  height: 32px;
  width: 82px !important;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0px;
  min-width: unset;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator):last-child {
  border-right: none;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item {
  min-height: unset;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item.e-add {
  display: none;
}
body .e-toolbar .e-toolbar-items .e-toolbar-item.e-active-view .e-tbar-btn {
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor) !important;
}
body
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item.e-active-view
  .e-tbar-btn
  .e-tbar-btn-text {
  color: var(--ipWhite__textColor) !important;
}
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right,
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  height: auto;
  position: static;
}
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  display: flex;
  flex-wrap: wrap;
}
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  position: relative;
}
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right[style] {
  right: 35px !important;
}
body
  .e-toolbar
  .e-toolbar-items.e-tbar-pos
  .e-toolbar-right
  .e-toolbar-item.e-today,
body
  .e-toolbar
  .e-toolbar-items.e-tbar-pos
  .e-toolbar-right
  .e-toolbar-item.e-work-week,
body
  .e-toolbar
  .e-toolbar-items.e-tbar-pos
  .e-toolbar-right
  .e-toolbar-item.e-agenda {
  display: none;
}
body .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center {
  margin-left: 0px !important;
}
body
  .e-toolbar
  .e-toolbar-items.e-tbar-pos
  .e-toolbar-left
  .e-toolbar-item:nth-child(1) {
  margin-left: 0px;
  margin-right: 6px;
}
body .e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 38px !important;
}
body .e-schedule .e-toolbar .e-hor-nav {
  border: none !important;
  background-color: var(--primary__transparentBG);
  border-radius: 5px;
  transition: 0.5s;
  min-height: 32px;
  height: 32px;
  top: 3px;
}
body .e-schedule .e-toolbar .e-popup-down-icon.e-icons {
  color: var(--dark__TextColor);
  transition: 0.5s;
}
body .e-schedule .e-toolbar .e-hor-nav:hover {
  background-color: var(--ip__Grey__hoverDark);
}
body .e-schedule .e-toolbar .e-hor-nav:hover .e-popup-down-icon.e-icons {
  color: var(--ipBlack__textColor);
}

body .e-schedule-toolbar-container .e-toolbar .e-toolbar-pop {
  background-color: var(--ipWhite__bgColor);
  box-shadow: 0px 3px 17px #0000001a;
}
body .e-schedule-toolbar-container .e-toolbar .e-toolbar-pop .e-toolbar-item {
  padding: 7px 18px;
  position: relative;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0px;
  height: 1px;
  width: calc(100% - 20px);
  transform: translateX(-50%);
  background-color: var(--greyScreen__BorderColor);
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item:last-child:before {
  display: none;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item
  .e-tbar-btn.e-btn {
  padding: 0px;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item
  .e-tbar-btn.e-btn:hover {
  background-color: transparent;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item
  .e-icons {
  color: var(--ipBlack__textColor) !important;
  position: relative;
  top: -1px;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item
  .e-tbar-btn-text {
  color: var(--ipBlack__textColor) !important;
  font-family: "Biotif-Medium", sans-serif;
  margin-left: 2px;
  padding: 0px !important;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item:hover
  .e-icons,
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item:hover
  .e-tbar-btn-text {
  color: var(--primaryColor) !important;
}
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item.e-active-view
  .e-icons,
body
  .e-schedule-toolbar-container
  .e-toolbar
  .e-toolbar-pop
  .e-toolbar-item.e-active-view
  .e-tbar-btn-text {
  color: var(--primaryColor) !important;
}

body .e-schedule {
  border: none;
}
body .e-schedule .e-month-view .e-date-header-wrap table td {
  background-color: var(--primary__transparentBG);
  border: none;
  font-size: 12px;
  font-family: "Biotif-SemiBold", sans-serif;
  color: #323a46;
  text-align: center;
  padding: 17px 10px 15px 10px !important;
}
body .e-schedule .e-month-view .e-date-header-wrap table td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
body .e-schedule .e-month-view .e-date-header-wrap table td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
body .e-toolbar .e-tbar-btn.e-btn .e-icons {
  color: var(--dark__TextColor) !important;
}
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-tbar-btn.e-btn.e-tbtn-txt
  .e-icons.e-icon-right.e-btn-icon.e-icon-down-arrow {
  margin-top: -2px;
}
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-tbar-btn.e-icon-btn:focus,
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-tbar-btn.e-icon-btn:hover {
  border-radius: 0px;
}
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-prev
  .e-tbar-btn.e-icon-btn:hover,
body
  .e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-left
  .e-prev
  .e-tbar-btn.e-icon-btn:focus {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
body .e-schedule .e-schedule-toolbar {
  box-shadow: unset;
  margin-bottom: 20px;
  min-height: unset !important;
}
body .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
body
  .e-schedule
  .e-month-agenda-view
  .e-date-header-wrap
  table
  td.e-current-day {
  color: var(--primaryColor);
}
body .e-schedule .e-outer-table > tbody > tr:first-child > td {
  padding-bottom: 16px;
}
body
  .e-schedule-table.e-outer-table
  tbody
  tr:last-child
  > td
  > .e-content-wrap {
  border: 1px solid var(--whiteScreen__BorderColor);
}
/* body .e-month-view .e-schedule-table.e-outer-table tbody tr:last-child > td > .e-content-wrap{
  height:930px !important;
} */
body .e-day-view .e-schedule-table .e-content-wrap,
body .e-week-view .e-schedule-table .e-content-wrap {
  border: 1px solid var(--whiteScreen__BorderColor);
}
body .e-schedule .e-month-view .e-content-wrap,
body .e-schedule .e-vertical-view .e-content-wrap,
body .e-schedule .e-vertical-view .e-time-cells-wrap {
  height: calc(100vh - 250px) !important;
}
body .e-date-header-container {
  padding-right: 0px !important;
}
body .e-date-header-container .e-date-header-wrap {
  border-right: none !important;
}
body .e-schedule .e-month-view .e-work-cells,
body .e-schedule .e-month-agenda-view .e-work-cells {
  border-color: var(--whiteScreen__BorderColor);
  background-color: transparent;
}
body .e-schedule .e-month-view .e-work-cells:hover,
body .e-schedule .e-month-agenda-view .e-work-cells:hover {
  background-color: var(--lightScreenBg);
}
body .e-schedule .e-month-view .e-work-cells {
  height: 150px;
  position: relative;
}
body .e-schedule .e-month-view .e-date-header,
body .e-schedule .e-date-header {
  height: 24px;
  line-height: 24px;
  font-family: "Biotif-SemiBold", sans-serif;
  color: var(--dark__TextColor);
  font-size: 14px;
  width: 24px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px !important;
  border-radius: 50%;
}
body .e-schedule .e-month-view .e-date-header:hover {
  background-color: #d0d0d0;
  text-decoration: none;
}
body .e-schedule .e-month-view .e-disable-dates.e-disable-cell .e-date-header {
  pointer-events: none !important;
}
body .e-schedule .e-month-view .e-other-month .e-date-header,
body .e-schedule .e-other-month .e-date-header,
body .e-schedule .e-month-view .e-other-month.e-work-days .e-date-header,
body .e-schedule .e-other-month.e-work-days .e-date-header {
  opacity: 0.5;
}
body .e-schedule .e-month-view .e-current-date .e-date-header,
body .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  width: 24px;
  text-decoration: none;
  background-color: var(--primaryColor);
}
body .e-schedule .e-month-view .e-work-cells.e-other-month,
body .e-schedule .e-month-agenda-view .e-work-cells.e-other-month,
body .e-schedule .e-month-view .e-work-cells.e-other-month.e-work-days,
body .e-schedule .e-month-agenda-view .e-work-cells.e-other-month.e-work-days {
  background-color: #f9f9f9;
  pointer-events: none;
}
body .e-schedule .e-month-view .e-work-cells.e-work-days,
body .e-schedule .e-month-agenda-view .e-work-cells.e-work-days {
  background-color: var(--ipWhite__bgColor);
}
body .e-schedule .e-month-view .e-work-cells.e-work-days:hover,
body .e-schedule .e-month-agenda-view .e-work-cells.e-work-days:hover {
  background-color: var(--lightScreenBg);
}
body .e-schedule .e-appointment {
  background-color: var(--primary__transparentBG) !important;
  border: none !important;
  border-left: 2px solid var(--primaryColor) !important;
  border-bottom: 2px solid var(--ipWhite__borderColor) !important;
  cursor: pointer;
}
body .calendar__page .e-schedule .e-appointment.e-past-appointment {
  background-color: var(--ipSuccessGreen__transparentBG) !important;
  border-left: 2px solid var(--ip__SuccessGreen) !important;
}
/* body .e-schedule .e-month-view .e-appointment{
  width:100% !important;
} */
body .e-schedule .e-appointment:hover {
  background-color: #d7e8f5 !important;
}
body .e-schedule .e-appointment .e-subject {
  font-size: 12px !important;
  font-family: "Biotif-SemiBold", sans-serif;
  color: #333333;
}
body .e-schedule .e-appointment .e-time {
  font-size: 12px;
  font-family: "Biotif-Regular", sans-serif;
  color: #333333;
  width: 52px;
}
body .e-schedule .e-vertical-view .e-appointment .e-time {
  width: 100%;
  white-space: pre;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
body .e-schedule .e-month-view .e-appointment .e-subject,
body .e-schedule .e-month-view .e-appointment .e-time {
  line-height: 24px;
}
body .e-schedule .e-month-view .e-appointment .e-subject {
  order: -1;
  font-size: 12px !important;
  font-family: "Biotif-Semibold", sans-serif;
  color: #333333 !important;
  width: calc(100% - 52px);
  padding-left: 4px;
}
body .e-schedule .e-vertical-view .e-appointment .e-subject {
  width: 100%;
}
body .e-schedule .e-month-view .e-selected-cell .e-more-indicator,
body .e-schedule .e-month-view .e-more-indicator {
  color: var(--primaryColor);
  font-size: 12px;
  font-family: "Biotif-Medium", sans-serif;
}

body .e-more-popup-wrapper {
  box-shadow: #110c2e26 0px 15px 50px 0px;
  border: 1px solid var(--whiteScreen__BorderColor);
  border-radius: 0px;
  padding-bottom: 14px;
  min-width: 194px;
}
body .e-more-popup-wrapper .e-appointment .e-subject {
  line-height: 22px;
}
body .e-more-popup-wrapper .e-appointment {
  margin-bottom: 2px !important;
}
body .e-more-popup-wrapper .e-more-event-header {
  height: auto;
  margin-bottom: 4px;
}
body .e-more-popup-wrapper .e-more-event-date-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  position: relative;
  top: 6px;
}
body .e-more-popup-wrapper .e-more-event-date-header .e-header-day,
body .e-more-popup-wrapper .e-more-event-date-header .e-header-date {
  font-family: "Biotif-Medium", sans-serif;
  font-size: 16px;
  color: var(--ipBlack__Color);
  padding: 0px;
  padding-bottom: 5px;
  text-decoration: none !important;
}
body .e-more-popup-wrapper .e-more-event-date-header .e-header-date {
  margin-left: 5px;
}
body .e-more-popup-wrapper .e-more-event-close {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ipBlack__Color) !important;
}
body .e-more-popup-wrapper .e-more-event-close:hover {
  background-color: var(--ipGray__transparentBG);
}
body .e-more-popup-wrapper .e-more-event-content {
  padding-top: 0px;
  margin-top: 10px;
}
body .e-schedule .e-month-view td.e-work-cells {
  overflow: visible;
}
body .e-schedule .e-month-view .e-appointment-wrapper {
  margin-top: 2px;
  z-index: 2;
  /* width:100% !important; */
}

body .e-header-popup {
  box-shadow: #110c2e26 0px 15px 50px 0px;
  background-color: var(--ipWhite__bgColor);
}
body .e-header-popup .e-header .e-title {
  font-family: "Biotif-Medium", sans-serif;
  color: var(--ipBlack__textColor);
  position: relative;
  top: 4px;
}
body .e-header-popup .e-header-calendar {
  border: 1px solid var(--whiteScreen__BorderColor);
}
body .e-header-popup .e-prev:hover,
body .e-header-popup .e-next:hover {
  background-color: var(--lightScreenBg) !important;
}
body .e-header-popup .e-week-header th {
  color: var(--dark__TextColor);
  font-family: "Biotif-Medium", sans-serif;
}
body .e-header-popup .e-day {
  font-size: 16px;
  font-family: "Biotif-Regular", sans-serif;
  color: var(--dark__TextColor);
}
body .e-header-popup td:hover span.e-day {
  background-color: var(--ipGray__transparentBG) !important;
  color: var(--dark__TextColor) !important;
}
body .e-header-popup td.e-focused-date span.e-day,
body .e-header-popup td.e-selected span.e-day,
body .e-header-popup td.e-selected.e-focused-date span.e-day,
body .e-header-popup td.e-selected.e-focused-date:hover span.e-day {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
}
body .e-header-popup .e-footer-container {
  background-color: var(--ipGray__transparentBG);
}
body .e-header-popup .e-header .e-icon-container .e-prev,
body .e-header-popup .e-header .e-icon-container .e-next {
  width: 28px;
  height: 28px;
  background-color: var(--ipWhite__bgColor);
  box-shadow: 1px 2px 6px #e8e8e8;
  border-radius: 8px;
  transition: 0.5s;
}
body .e-header-popup .e-header .e-icon-container .e-prev .e-icons,
body .e-header-popup .e-header .e-icon-container .e-next .e-icons {
  color: var(--ipBlack__textColor);
  transition: 0.5s;
}
body .e-header-popup .e-header .e-icon-container .e-prev .e-icons {
  position: relative;
  top: -2px;
}
body .e-header-popup .e-header .e-icon-container .e-next .e-icons {
  position: relative;
  top: -1px;
  left: 0px;
}
body .e-header-popup .e-header .e-icon-container .e-prev:hover,
body .e-header-popup .e-header .e-icon-container .e-next:hover {
  background: var(--ipBlack__bgColor);
  background-color: var(--ipBlack__bgColor) !important;
}
body .e-header-popup .e-header .e-icon-container .e-prev:hover .e-icons,
body .e-header-popup .e-header .e-icon-container .e-next:hover .e-icons {
  color: var(--ipWhite__textColor);
}
body .e-calendar .e-content td.e-focused-date span.e-day,
body .e-calendar .e-content td.e-focused-date:hover span.e-day,
body .e-calendar .e-content td.e-focused-date:focus span.e-day,
body .e-bigger.e-small .e-calendar .e-content td.e-focused-date span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content
  td.e-focused-date:hover
  span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content
  td.e-focused-date:focus
  span.e-day {
  background-color: var(--lightScreenBg);
}
body .e-calendar .e-content td.e-selected span.e-day,
body .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: var(--primaryColor);
}
body .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
body .e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content.e-year
  td.e-selected:hover
  span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content.e-decade
  td.e-selected:hover
  span.e-day {
  background-color: var(--primaryColor);
}
body .e-btn.e-flat.e-primary,
body .e-css.e-btn.e-flat.e-primary {
  color: var(--primaryColor) !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
  transition: 0.5s;
}
body .e-btn.e-flat.e-primary:hover,
body .e-css.e-btn.e-flat.e-primary:hover {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
}
body .e-calendar .e-content.e-year td > span.e-day,
body .e-calendar .e-content.e-decade td > span.e-day {
  height: 34px;
  line-height: 34px;
}

/* week-view */
body .e-week-view .e-date-header-wrap thead,
body .e-week-view .e-date-header-wrap tbody .e-all-day-row,
body .e-day-view .e-date-header-wrap thead,
body .e-day-view .e-date-header-wrap tbody .e-all-day-row {
  display: none;
}
body .e-week-view .e-date-header-wrap tbody .e-header-row .e-header-cells,
body .e-day-view .e-date-header-wrap tbody .e-header-row .e-header-cells {
  background-color: var(--primary__transparentBG);
  /* padding: 10px 10px; */
  padding: 3px 10px;
  height: 50px;
  border: none;
  pointer-events: none;
  font-size: 12px;
  font-family: "Biotif-SemiBold", sans-serif;
  color: #323a46;
  text-align: center;
  text-transform: uppercase;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day {
  color: #ffffff;
}
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day {
  color: var(--primaryColor);
  background-color: var(--primary__transparentBG);
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells:first-child,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells:last-child,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-day,
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-date,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-day,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-date {
  width: 100%;
  text-align: center;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-day,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-day {
  font-size: 10px;
  font-family: "Biotif-SemiBold", sans-serif;
  text-transform: uppercase;
  color: var(--dark__TextColor);
  line-height: normal;
  padding-bottom: 0px;
  text-decoration: none;
  padding-top: 2px;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-day {
  font-size: 12px;
  text-transform: uppercase;
  color: #323a46 !important;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-date,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells
  .e-header-date {
  font-size: 14px;
  font-family: "Biotif-SemiBold", sans-serif;
  color: var(--dark__TextColor);
  line-height: normal;
  text-decoration: none;
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day {
  background-color: var(--primaryColor);
}
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-day,
body
  .e-week-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-date,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-day,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-date {
  color: #ffffff !important;
}
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-day,
body
  .e-day-view
  .e-date-header-wrap
  tbody
  .e-header-row
  .e-header-cells.e-current-day
  .e-header-date {
  color: var(--primaryColor) !important;
}
body .e-schedule .e-vertical-view .e-left-indent {
  width: 70px !important;
}
body .e-schedule .e-touch .e-vertical-view .e-left-indent {
  width: 58px !important;
}
body .e-schedule .e-vertical-view .e-left-indent .e-header-cells {
  height: 50px;
}
body .e-week-view .e-left-indent tr:last-child,
body .e-day-view .e-left-indent tr:last-child {
  display: none;
}
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-left-indent-wrap
  table
  tbody
  td,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-left-indent-wrap
  table
  tbody
  td {
  border: none;
}
body .e-schedule .e-vertical-view.e-week-view .e-time-cells-wrap table td,
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-time-cells-wrap
  .e-current-time,
body .e-schedule .e-vertical-view.e-day-view .e-time-cells-wrap table td,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-time-cells-wrap
  .e-current-time {
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 12px;
  font-family: "Biotif-Medium", sans-serif;
  color: var(--light__TextColor);
}
/* body .e-schedule .e-vertical-view .e-schedule-table > tbody > tr > td{
  overflow:visible;
} */
/* body .e-schedule .e-vertical-view .e-time-cells-wrap{
  overflow:visible;
} */
body .e-schedule .e-vertical-view .e-time-cells-wrap table td {
  vertical-align: top;
  overflow: visible;
}
body .e-schedule .e-vertical-view .e-time-cells-wrap table td > span {
  position: relative;
  top: -8px;
  display: block !important;
}
body
  .e-schedule
  .e-vertical-view
  .e-time-cells-wrap
  table
  tr:first-child
  td
  > span {
  top: -4px;
}
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-time-cells-wrap
  .e-current-time,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-time-cells-wrap
  .e-current-time {
  color: var(--primaryColor);
  padding-left: 1px;
  display: none;
}
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-timeline-wrapper
  .e-current-timeline,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-timeline-wrapper
  .e-current-timeline {
  border-top: 1px solid var(--primaryColor);
  position: relative;
}
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-timeline-wrapper
  .e-current-timeline:before,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-timeline-wrapper
  .e-current-timeline:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--primaryColor);
}
body .e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 1px dotted var(--primaryColor);
}

body .e-schedule .e-vertical-view.e-week-view .e-work-cells,
body .e-schedule .e-vertical-view.e-day-view .e-work-cells {
  border-right: 1px solid var(--whiteScreen__BorderColor);
  border-bottom: 1px solid var(--whiteScreen__BorderColor);
  border-top: none;
  border-left: none;
  background-color: var(--ipWhite__bgColor);
}
body .e-schedule .e-vertical-view.e-week-view .e-work-cells:hover,
body .e-schedule .e-vertical-view.e-day-view .e-work-cells:hover {
  background-color: var(--lightScreenBg);
}
body
  .e-schedule
  .e-vertical-view.e-week-view
  .e-schedule-table
  > tbody
  > tr:nth-child(odd)
  .e-work-cells,
body
  .e-schedule
  .e-vertical-view.e-day-view
  .e-schedule-table
  > tbody
  > tr:nth-child(odd)
  .e-work-cells {
  border-bottom: none;
}
/* week-view-end */

body .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
body .e-calendar .e-content td.e-selected:hover span.e-day,
body .e-calendar .e-content td.e-selected.e-focused-date span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content
  td.e-today.e-selected:hover
  span.e-day,
body .e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content
  td.e-selected.e-focused-date
  span.e-day {
  background-color: var(--primaryColor);
}
body .e-calendar .e-content td.e-today.e-selected span.e-day,
body .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  box-shadow: inset 1px 0 var(--primaryColor), inset 0 1px var(--primaryColor),
    inset -1px 0 var(--primaryColor), inset 0 -1px var(--primaryColor);
  background-color: var(--primaryColor);
  color: #ffffff !important;
}
body .e-calendar .e-content td.e-focused-date.e-today span.e-day,
body
  .e-bigger.e-small
  .e-calendar
  .e-content
  td.e-focused-date.e-today
  span.e-day {
  box-shadow: inset 1px 0 var(--primaryColor), inset 0 1px var(--primaryColor),
    inset -1px 0 var(--primaryColor), inset 0 -1px var(--primaryColor);
}
.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  color: var(--primaryColor);
  box-shadow: inset 1px 0 var(--primaryColor), inset 0 1px var(--primaryColor),
    inset -1px 0 var(--primaryColor), inset 0 -1px var(--primaryColor);
}

body .e-dlg-overlay {
  background-color: #0009;
}
body .e-dialog {
  box-shadow: unset;
  border-radius: 12px;
}
body .e-dialog .e-dlg-header {
  width: calc(100% - 40px);
}
body .e-dialog .e-dlg-header .e-title-text {
  font-family: "Biotif-Medium", sans-serif;
  font-size: 22px;
  color: var(--ipBlack__textColor);
}
body .e-dlg-header-content {
  border-bottom: 1px solid var(--greyScreen__BorderColor) !important;
  background-color: var(--lightGray__BgColor);
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  padding: 20px 16px 20px 24px !important;
}
body .e-dlg-header-content .e-dlg-closeicon-btn {
  height: 30px;
  width: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0px;
}
body .e-dlg-header-content .e-dlg-closeicon-btn:hover {
  background-color: var(--greyScreen__BorderColor) !important;
}
body .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 10px;
}
body .e-dialog .e-footer-content {
  padding: 15px 24px 15px 24px;
  border-top: 1px solid var(--greyScreen__BorderColor);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-top: 0px;
}
body .e-dialog .e-footer-content .e-btn.e-event-cancel {
  background-color: var(--secondary__Btn__BGColor);
  color: var(--dark__TextColor);
  transition-duration: 300ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 11px 30px;
  text-align: center;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
}
body .e-dialog .e-footer-content .e-btn.e-event-cancel:hover {
  background-color: var(--secondary__Btn__BGColor__hover);
  color: var(--dark__TextColor);
}
body .e-dialog .e-footer-content .e-btn.e-event-save {
  background-color: var(--primaryColor);
  color: #ffffff;
  transition-duration: 300ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 11px 30px;
  text-align: center;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  margin-right: 15px;
}
body .e-dialog .e-footer-content .e-btn.e-event-save:hover {
  background-color: var(--ip__Blue__hoverDark);
  color: #ffffff;
}

body .e-dialog .e-dlg-content {
  max-height: calc(100dvh - 250px);
  overflow-y: auto;
  padding: 30px !important;
  width: 100%;
  background-color: var(--ipWhite__bgColor);
}
input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background-color: var(--primaryColor);
}
body .e-title-location-row,
body .e-start-end-row {
  margin: 0px -10px;
  width: calc(100% + 20px) !important;
  padding-bottom: 0px !important;
}
body .e-schedule-dialog .e-subject-container,
body .e-schedule-dialog .e-location-container,
body .e-schedule-dialog .e-start-container,
body .e-schedule-dialog .e-end-container,
body .e-schedule-dialog .e-start-time-zone-container,
body .e-schedule-dialog .e-end-time-zone-container {
  width: 50%;
  padding: 0px 10px !important;
  margin-bottom: 15px;
}
body .e-schedule-dialog .e-control-wrapper {
  border-bottom: none !important;
}
body .e-schedule-dialog .e-float-line {
  display: none !important;
}
body .e-float-input input,
body .e-float-input textarea,
body .e-float-input.e-control-wrapper input,
body .e-float-input.e-control-wrapper textarea,
body .e-float-input input:hover,
body .e-float-input textarea:hover,
body .e-float-input.e-control-wrapper input:hover,
body .e-float-input.e-control-wrapper textarea:hover {
  width: 100% !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
  background-color: var(--formField__BGColor);
  padding: 9px 13px 9px 13px !important;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  transition: 0.5s;
}
body .e-float-input input:focus,
body .e-float-input textarea:focus,
body .e-float-input.e-control-wrapper input:focus,
body .e-float-input.e-control-wrapper textarea:focus {
  border: 1px solid var(--ipBlack__borderColor) !important;
}
body .e-float-input input::placeholder,
body .e-float-input textarea::placeholder,
body .e-float-input.e-control-wrapper input::placeholder,
body .e-float-input.e-control-wrapper textarea::placeholder {
  color: var(--disableLight__TextColor);
  opacity: 1;
}
body .e-float-input,
body .e-float-input.e-control-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
}
body .e-float-input:not(.e-input-group),
body .e-float-input.e-control-wrapper:not(.e-input-group),
body .e-input-group,
body .e-input-group.e-control-wrapper {
  display: flex;
  flex-wrap: wrap;
}
body .e-schedule-dialog .e-label-top {
  margin-bottom: 5px !important;
  display: block !important;
  font-family: "Biotif-Medium", sans-serif !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: var(--mediumDark__TextColor) !important;
  transform: unset !important;
  position: static !important;
  width: 100% !important;
  order: -1;
}
body .e-datetime-wrapper .e-input-group-icon.e-date-icon {
  margin: -43px 8px 0px auto !important;
  font-size: 15px;
}
body .e-datetime-wrapper .e-clear-icon {
  margin: -30px 7px 0px auto !important;
  font-size: 15px;
  padding: 0px !important;
  height: 18px !important;
  display: inline-block !important;
  min-height: unset !important;
}
body .e-datetime-wrapper .e-input-group-icon.e-time-icon {
  margin: -30px 7px 0px auto !important;
  font-size: 15px;
  display: block !important;
  opacity: 1;
  color: var(--mediumDark__TextColor);
}

.calendar__page .e-scroll-container,
.calendar__page .e-vertical-view .e-time-cells-wrap,
.calendar__page .e-vertical-view .e-content-wrap {
  height: auto !important;
}
/* Calendar-end */

/* syncfusion-design */
body .e-input-group.e-time-wrapper {
  box-shadow: unset !important;
  border: none !important;
}
body .e-input-group.e-time-wrapper input.e-input {
  width: 100%;
  border-radius: 6px;
  border-width: 1px !important;
  border-color: transparent !important;
  background-color: var(--formField__BGColor);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 20px;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  height: 44px;
}
body .e-input-group.e-time-wrapper input.e-input:focus {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 20px;
  border-width: 1px !important;
  border-color: var(--ipBlack__borderColor) !important;
  border-radius: 6px;
}

body .e-timepicker.e-popup {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -10px,
    rgba(0, 0, 0, 0.3) 0px 10px 30px -20px;
  background-color: var(--ipWhite__bgColor);
  border: 1px solid var(--whiteScreen__BorderColor);
}
body .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  border-bottom: 1px solid var(--whiteScreen__BorderColor);
}
body .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  background-color: var(--ipGray__transparentBG);
  border-bottom: 1px solid var(--whiteScreen__BorderColor);
}
body .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: var(--primaryColor);
  color: #ffffff;
}

body .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
body
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(
    .e-error
  ) {
  box-shadow: unset !important;
  border: none !important;
}
body .e-input-group:not(.e-success):not(.e-warning):not(.e-error) .ip__input,
body
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input {
  width: 100%;
  border-radius: 6px;
  border-width: 1px !important;
  border-color: transparent !important;
  background-color: var(--formField__BGColor);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 20px;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  height: 44px;
}
body
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input::placeholder,
body
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input::placeholder {
  color: var(--black__TextColor400);
  opacity: 1;
}
body
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input:focus,
body
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input:focus {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 20px;
  border-width: 1px !important;
  border-color: var(--ipBlack__borderColor) !important;
  border-radius: 6px;
}
body
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input.e-autocomplete:focus,
body
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error)
  .ip__input.e-autocomplete:focus {
  border-color: transparent !important;
}

body .e-multi-select-wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
body .e-multiselect.e-input-group {
  width: 100%;
  border-radius: 6px;
  border-width: 1px !important;
  border-color: transparent !important;
  background-color: var(--formField__BGColor);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 0px;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  height: 44px;
}
body .e-multiselect.e-input-group.e-input-focus {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 0px;
  border-width: 1px !important;
  border-color: var(--ipBlack__borderColor) !important;
  border-radius: 6px;
}
body .e-multi-select-wrapper .e-searcher.e-multiselect-box {
  float: none;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100px;
  padding: 0px 12px 0px 0px;
}
body .e-multi-select-wrapper .e-searcher.e-multiselect-box input[type="text"] {
  text-indent: 0px;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 14px;
  color: var(--dark__TextColor);
}
body .e-multi-select-wrapper .e-searcher.e-multiselect-box input {
  color: var(--ipBlack__textColor);
  font-size: 14px;
  font-family: "Biotif-Regular", sans-serif;
}
body .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  color: var(--light__TextColor);
  right: 4px;
  margin-top: 0px;
  top: -3px;
}
body .e-multi-select-wrapper .e-chips-close.e-close-hooker:hover {
  color: var(--ipBlack__textColor);
}
body .e-multi-select-wrapper .e-chips,
body .e-multiselect:not(.e-disabled) .e-multi-select-wrapper .e-chips:hover,
body
  .e-multiselect:not(.e-disabled)
  .e-multi-select-wrapper
  .e-chips.e-chip-selected,
body
  .e-multiselect:not(.e-disabled)
  .e-multi-select-wrapper
  .e-chips.e-chip-selected:hover {
  background-color: var(--primaryColor);
  border-radius: 6px;
  padding: 5px 8px 4px 8px;
  height: auto;
  margin: 0px 5px 0px 0px;
  float: none;
  max-width: unset;
  overflow: visible;
}
body .e-multi-select-wrapper .e-chips-collection {
  white-space: pre;
  overflow-x: auto;
  display: inline-flex;
  width: auto;
  flex-shrink: 0;
  max-width: calc(100% - 112px);
  margin-right: 12px;
}
body .e-multi-select-wrapper .e-chips:last-child,
body
  .e-multiselect:not(.e-disabled)
  .e-multi-select-wrapper
  .e-chips:last-child:hover {
  margin: 0px 0px 0px 0px;
}
body .e-multi-select-wrapper .e-chips > .e-chipcontent {
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
  font-size: 12px;
  font-family: "Biotif-Medium", sans-serif;
  white-space: pre;
  overflow: visible;
  text-overflow: unset;
}
body .e-multi-select-wrapper .e-chips-close {
  width: 14px;
  height: 14px;
  position: relative;
  top: -1px;
}
body .e-multi-select-wrapper .e-chips .e-chips-close::before {
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
  font-size: 13px;
}
body .e-input-group.e-control-wrapper.e-ddl {
  position: relative;
}
body .e-input-group.e-control-wrapper.e-ddl .e-clear-icon {
  color: var(--light__TextColor);
  right: 2px;
  margin-top: 0px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
body .e-input-group.e-control-wrapper.e-ddl .e-clear-icon:hover {
  color: var(--dark__TextColor);
}
body .e-input-group.e-control-wrapper.e-ddl .e-control.e-lib.e-input {
  width: 100%;
  border-radius: 6px;
  border-width: 1px !important;
  border-color: transparent !important;
  background-color: var(--formField__BGColor);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 32px;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  height: 44px;
}
body
  .e-input-group.e-control-wrapper.e-ddl
  .e-control.e-lib.e-input::placeholder {
  color: var(--text-disableLight__TextColor);
  opacity: 1;
}
/* body .e-input-group.e-control-wrapper.e-ddl .e-control.e-lib.e-input:focus{
padding-top: 9px;
padding-bottom: 9px;
padding-left: 13px;
padding-right: 20px;
border-width: 1px !important;
border-color:var(--ipBlack__borderColor) !important;
border-radius: 6px;
} */

body .e-ddl.e-popup {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 20px -10px,
    rgba(0, 0, 0, 0.3) 0px 10px 30px -20px;
  background-color: var(--formField__BGColor);
  border-top: 1px solid var(--whiteScreen__BorderColor);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: -3px;
}
body .e-dropdownbase .e-list-item {
  border-bottom: 1px solid var(--whiteScreen__BorderColor) !important;
  background-color: transparent;
  font-family: "Biotif-Regular", sans-serif;
  font-size: 16px;
  color: var(--ipBlack__Color);
  padding: 8px 12px;
  transition: 0.5s;
}
body .e-dropdownbase .e-list-item:hover,
body .e-dropdownbase .e-list-item.e-item-focus {
  background-color: var(--ipWhite__bgColor);
}

body .e-datepicker.e-popup-wrapper {
  box-shadow: #110c2e26 0px 15px 50px 0px;
  border: 1px solid var(--whiteScreen__BorderColor);
  background-color: var(--ipWhite__bgColor);
}
body .e-datepicker.e-popup-wrapper .e-calendar .e-header .e-title {
  font-family: "Biotif-Medium", sans-serif;
  color: var(--ipBlack__textColor);
  position: relative;
  top: 4px;
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-prev,
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-next {
  width: 28px;
  height: 28px;
  background-color: var(--ipWhite__bgColor);
  box-shadow: 1px 2px 6px #e8e8e8;
  border-radius: 8px;
  transition: 0.5s;
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-prev
  .e-icons,
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-next
  .e-icons {
  color: var(--ipBlack__textColor);
  transition: 0.5s;
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-prev
  .e-icons {
  position: relative;
  top: -3px;
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-next
  .e-icons {
  position: relative;
  top: -2px;
  left: 1px;
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-prev:hover,
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-next:hover {
  background-color: var(--ipBlack__bgColor);
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-prev:hover
  .e-icons,
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-header
  .e-icon-container
  .e-next:hover
  .e-icons {
  color: var(--ipWhite__textColor);
}
body .e-datepicker.e-popup-wrapper .e-calendar .e-content th {
  color: var(--dark__TextColor);
  font-family: "Biotif-Medium", sans-serif;
}
body .e-datepicker.e-popup-wrapper .e-calendar .e-content span.e-day {
  color: var(--dark__TextColor);
  font-family: "Biotif-Regular", sans-serif;
  padding-top: 1px;
}
body .e-datepicker.e-popup-wrapper .e-calendar .e-content span.e-day:hover {
  background-color: var(--ipGray__transparentBG);
}
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-content
  td.e-focused-date.e-today
  span.e-day,
body
  .e-datepicker.e-popup-wrapper
  .e-calendar
  .e-content
  td.e-focused-date.e-today
  span.e-day:hover {
  box-shadow: unset;
  background-color: var(--primaryColor);
  color: #ffffff;
}
body .e-datepicker.e-popup-wrapper .e-calendar .e-footer-container {
  background-color: var(--ipGray__transparentBG);
}

body .e-daterangepicker.e-popup {
  box-shadow: #110c2e26 0px 15px 50px 0px;
  border: 1px solid var(--whiteScreen__BorderColor);
  background-color: var(--ipWhite__bgColor);
}
.e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small .e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small.e-daterangepicker.e-popup .e-separator {
  background-color: var(--whiteScreen__BorderColor);
}
body .e-daterangepicker.e-popup.e-preset-wrapper {
  min-width: 520px !important;
}
body .e-daterangepick-mob-popup-wrap .e-daterangepicker.e-popup {
  min-width: 296px !important;
  display: flex;
  justify-content: center;
  box-shadow: unset;
  flex-wrap: wrap;
  background-color: transparent;
  border: none;
}
body .e-daterangepick-mob-popup-wrap .e-daterangepicker.e-popup .e-footer {
  width: calc(100% - 4px);
  max-width: calc(100% - 4px);
}
body .e-daterangepicker.e-popup .e-range-header {
  background-color: var(--gray__BGColor);
  position: relative;
}
body .e-daterangepicker.e-popup .e-range-header .e-day-span {
  height: auto;
  color: var(--black__TextColor700);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
body .e-daterangepicker.e-popup .e-range-header .e-start-label,
body .e-daterangepicker.e-popup .e-range-header .e-end-label {
  color: var(--black__TextColor700);
  font-family: "Biotif-Regular", sans-serif;
}
body .e-daterangepicker.e-popup .e-range-header .e-change-icon {
  color: var(--black__TextColor700);
  position: relative;
  top: -10px;
}
body .e-daterangepicker.e-popup .e-footer {
  background-color: #ffffff;
  border-top: 1px solid var(--whiteScreen__BorderColor);
}
body .e-daterangepicker.e-popup .e-footer .e-apply {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
  margin: 0px !important;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  transition: 0.5s;
  margin-left: 10px !important;
  margin-right: 10px !important;
  box-shadow: unset !important;
}
body .e-daterangepicker.e-popup .e-footer .e-apply:hover {
  background-color: var(--primaryColor__hoverDark) !important;
  color: #ffffff !important;
}
body .e-daterangepicker.e-popup .e-footer .e-cancel {
  background-color: var(--secondary__Btn__BGColor) !important;
  color: var(--ipBlack__textColor) !important;
  margin: 0px !important;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  transition: 0.5s;
  border: none;
  box-shadow: unset !important;
}
body .e-daterangepicker.e-popup .e-footer .e-cancel:hover {
  background-color: var(--secondary__Btn__BGColor__hover) !important;
}
body .e-daterangepicker.e-popup .e-presets:nth-child(1) {
  max-height: 240px;
}
body .e-daterangepicker.e-popup .e-presets .e-list-item {
  padding: 8px 10px;
  line-height: normal !important;
  height: auto !important;
  font-family: "Biotif-Medium", sans-serif;
  color: var(--black__TextColor800);
}
body .e-daterangepicker.e-popup .e-presets .e-list-item:hover,
body .e-daterangepicker.e-popup .e-presets .e-list-item.e-hover {
  background-color: var(--parentBgWhite__grayBtnBG);
}
body .e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
body .e-daterangepicker.e-popup .e-presets .e-list-item.e-active:hover,
body .e-daterangepicker.e-popup .e-presets .e-list-item.e-active.e-hover {
  background-color: var(--primaryColor);
  color: #ffffff;
}
body .e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
  border-top-right-radius: 6px;
  border-left: 1px solid var(--whiteScreen__BorderColor);
}
body .e-daterangepicker.e-popup .e-date-range-container.e-range-border {
  /* border-right: 1px solid var(--whiteScreen__BorderColor); */
  border-right: none;
}
body .e-daterangepicker.e-popup .e-calendar {
  box-shadow: unset;
}
body .e-daterangepicker.e-popup .e-calendar .e-header .e-title {
  color: var(--black__TextColor800);
  font-family: "Biotif-Medium", sans-serif;
}
body .e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover {
  color: var(--black__TextColor800);
}
body .e-daterangepicker.e-popup .e-calendar .e-content th {
  color: var(--black__TextColor800);
  font-family: "Biotif-Medium", sans-serif;
}
body .e-daterangepicker.e-popup .e-calendar .e-content span.e-day {
  color: var(--black__TextColor800);
  font-family: "Biotif-Regular", sans-serif;
  padding-top: 1px;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-year
  .e-cell:hover
  span.e-day {
  background-color: var(--parentBgWhite__grayBtnBG);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-year
  .e-cell.e-other-year
  .e-day {
  color: var(--black__TextColor800);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-decade
  .e-cell:hover
  span.e-day {
  background-color: var(--parentBgWhite__grayBtnBG);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-decade
  .e-cell.e-other-year
  .e-day {
  color: var(--black__TextColor800);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  .e-other-month
  span.e-day {
  color: var(--black__TextColor400);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td:hover
  span.e-day:hover {
  background-color: var(--parentBgWhite__grayBtnBG);
}
body .e-daterangepicker.e-popup .e-calendar .e-content td.e-today span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-focused-date.e-today:not(.e-other-month)
  span.e-day {
  background-color: transparent;
  box-shadow: inset 1px 0 var(--primaryColor), inset 0 1px var(--primaryColor),
    inset -1px 0 var(--primaryColor), inset 0 -1px var(--primaryColor) !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-selected
  span.e-day {
  color: #ffffff !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-cell.e-start-date.e-selected
  span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-cell.e-start-date.e-selected:hover
  span.e-day {
  color: #ffffff !important;
  background-color: var(--primaryColor) !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-today.e-selected
  span.e-day {
  color: var(--primaryColor) !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-today.e-selected.e-range-hover
  span.e-day {
  color: #ffffff !important;
}
body .e-calendar .e-content td:hover span.e-day {
  background-color: var(--gray__BGColor);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-today.e-other-month
  span.e-day {
  box-shadow: unset !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content
  td.e-focused-date.e-today
  span.e-day:hover {
  background-color: var(--ipGray__transparentBG);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover
  span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-end-date.e-selected.e-range-hover
  span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover
  span.e-day:hover,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-end-date.e-selected.e-range-hover
  span.e-day:hover {
  background-color: var(--primaryColor) !important;
  color: #fff;
}
body .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover {
  position: relative;
  background-color: transparent;
}
body .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  opacity: 0.1;
}
body .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover .e-day {
  background-color: transparent;
  color: var(--black__TextColor800);
}
body .e-daterangepicker.e-popup .e-calendar .e-header .e-prev,
body .e-daterangepicker.e-popup .e-calendar .e-header .e-next {
  width: 28px;
  height: 28px;
  background-color: var(--ipWhite__bgColor);
  box-shadow: 1px 2px 6px #e8e8e8;
  border-radius: 8px;
  transition: 0.5s;
  transition: 0.4s;
  position: relative;
  top: -5px;
}
body .e-daterangepicker.e-popup .e-calendar .e-date-icon-prev,
body .e-daterangepicker.e-popup .e-calendar .e-date-icon-next {
  color: var(--ipBlack__textColor);
  position: relative;
  top: -2px;
  transition: 0.4s;
}
body .e-daterangepicker.e-popup .e-calendar .e-header .e-prev:hover,
body .e-daterangepicker.e-popup .e-calendar .e-header .e-next:hover {
  background-color: var(--ipBlack__bgColor);
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-header
  .e-prev:hover
  .e-date-icon-prev,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-header
  .e-next:hover
  .e-date-icon-next {
  color: var(--ipWhite__textColor);
}
body .e-daterangepicker.e-popup .e-calendar .e-header {
  padding-bottom: 2px !important;
  padding-top: 12px !important;
}
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-year
  td.e-focused-date
  span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-year
  td.e-focused-date
  span.e-day:hover,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-decade
  td.e-focused-date
  span.e-day,
body
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-decade
  td.e-focused-date
  span.e-day:hover {
  background-color: var(--primaryColor);
  color: #ffffff;
}

body .e-inplaceeditor .e-editable-action-buttons {
  display: inline-flex;
}
body .e-inplaceeditor .e-editable-action-buttons .e-icon-btn {
  padding: 0px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
body .e-inplaceeditor .e-editable-action-buttons .e-icon-btn.e-btn-save {
  background-color: var(--primaryColor) !important;
  border: 1px solid var(--primaryColor);
}
body
  .e-inplaceeditor
  .e-editable-action-buttons
  .e-icon-btn.e-btn-save
  .e-icons {
  color: #ffffff !important;
}
body .e-inplaceeditor .e-editable-action-buttons .e-icon-btn.e-btn-save:hover {
  background-color: var(--primaryColor__hoverDark) !important;
}
body
  .e-inplaceeditor
  .e-editable-action-buttons
  .e-icon-btn.e-btn-save:hover
  .e-icons {
  color: #ffffff !important;
}
body .e-inplaceeditor .e-editable-action-buttons .e-icon-btn.e-btn-cancel {
  background-color: var(--secondary__Btn__BGColor) !important;
  border: 1px solid var(--secondary__Btn__BGColor);
}
body
  .e-inplaceeditor
  .e-editable-action-buttons
  .e-icon-btn.e-btn-cancel
  .e-icons {
  color: var(--dark__TextColor) !important;
}
body
  .e-inplaceeditor
  .e-editable-action-buttons
  .e-icon-btn.e-btn-cancel:hover {
  background-color: var(--secondary__Btn__BGColor__hover) !important;
}
body
  .e-inplaceeditor
  .e-editable-action-buttons
  .e-icon-btn.e-btn-cancel:hover
  .e-icons {
  color: var(--dark__TextColor) !important;
}

body .e-richtexteditor:not(.e-rte-toolbar-enabled) {
  border: none;
}
body .e-richtexteditor .e-rte-content .e-content {
  padding: 12px 16px 0px 16px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 12px);
  z-index: 5;
  font-family: "biotif__Regular", sans-serif;
  font-size: 16px !important;
}
body .e-richtexteditor .rte-placeholder {
  z-index: 4;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 12px 16px 0px 16px;
  font-family: "biotif__Regular", sans-serif;
  font-size: 16px !important;
  color: var(--disableLight__TextColor);
  opacity: 1;
}
/* syncfusion-design-end */

/* reach-text-editor-actionbar */
body .e-rte-quick-popup {
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 10px -1px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding: 7px;
}
body .e-rte-quick-popup .e-rte-quick-toolbar {
  background-color: transparent;
  border: none;
}
body .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items {
  background-color: transparent;
  margin: 0px !important;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item {
  margin: 0px !important;
}
body .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-separator {
  display: none !important;
}
body .e-rte-quick-popup .e-rte-quick-toolbar,
body .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos),
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item {
  min-height: unset;
}
body
  .e-rte-quick-popup.e-rte-inline-popup
  .e-rte-quick-toolbar
  .e-toolbar-item.e-rte-inline-color-template,
body
  .e-rte-quick-popup.e-rte-inline-popup
  .e-rte-quick-toolbar
  .e-toolbar-item.e-rte-inline-template {
  min-width: unset;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn,
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn:hover {
  min-height: 30px;
  min-width: 30px;
  line-height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: unset;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn.e-dropdown-btn {
  padding-left: 7px;
  padding-right: 5px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn:hover {
  background-color: var(--ipGray__transparentBG);
}
body .e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background-color: transparent;
  opacity: 0.5;
}
body
  .e-rte-elements.e-rte-quick-popup
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item.e-rte-inline-color-template
  .e-tbar-btn.e-btn
  .e-icons.e-btn-icon {
  min-width: unset;
  margin-right: 3px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn.e-dropdown-btn
  .e-caret {
  margin-right: 0px !important;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn
  .e-formats {
  font-size: 12px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn
  .e-icons.e-background-color {
  font-size: 15px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn
  .e-icons.e-font-color {
  font-size: 15px;
  position: relative;
  top: -1px;
}
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control
  .e-rte-color-content
  .e-rte-elements,
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control
  .e-rte-color-content
  .e-rte-elements {
  border-bottom-width: 2px;
}
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover {
  padding-left: 7px !important;
  padding-right: 1px !important;
}
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control
  .e-rte-color-content,
body
  .e-rte-toolbar.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control
  .e-rte-color-content {
  width: auto;
  margin-right: 1px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn.e-dropdown-btn.e-font-size-tbar-btn
  > span:first-child {
  margin-right: 2px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item
  .e-btn.e-dropdown-btn.e-font-size-tbar-btn
  .e-rte-dropdown-btn-text {
  font-size: 14px;
  color: #646464;
  position: relative;
  top: -1px;
}
body
  .e-rte-quick-popup
  .e-rte-quick-toolbar
  .e-toolbar-items:not(.e-tbar-pos)
  .e-toolbar-item.e-rte-inline-template
  .e-btn.e-rte-dropdown-popup
  .e-icons {
  min-width: unset;
  margin-right: 3px;
}
body
  .e-rte-elements.e-rte-quick-popup
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn.e-btn
  .e-icons.e-btn-icon.e-caret {
  min-width: unset;
}
body .e-dropdown-popup {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 10px -1px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.14);
}
body .e-dropdown-popup ul .e-item {
  color: #000000;
}
body .e-dropdown-popup ul .e-item:hover {
  background-color: var(--ipGray__transparentBG);
}
body .e-dropdown-popup ul .e-item.e-active {
  background-color: var(--primaryColor);
  color: #ffffff;
}
body .e-dropdown-popup.e-alignment-tbar-btn ul .e-item .e-icons {
  margin-left: 0px;
}
body .e-dropdown-popup.e-rte-fontcolor-dropdown,
body .e-dropdown-popup.e-rte-backgroundcolor-dropdown {
  padding: 5px 5px 0px 5px;
}
body .e-dropdown-popup.e-rte-fontcolor-dropdown .e-icons.e-font-color::before,
body
  .e-dropdown-popup.e-rte-backgroundcolor-dropdown
  .e-icons.e-font-color::before {
  display: none;
}
body .e-dialog,
body .e-dialog.e-rte-link-dialog,
body .e-dialog.e-rte-img-dialog {
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 1px 10px -1px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.14);
  border: none;
}
body .e-dialog.e-rte-link-dialog .e-dlg-header-content,
body .e-dialog.e-rte-img-dialog .e-dlg-header-content {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  border-bottom-width: 1px !important;
  border-color: var(--greyScreen__BorderColor) !important;
  background-color: var(--lightGray__BgColor);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 15px 15px 12px 15px !important;
  display: flex;
  align-items: center;
  position: relative;
}
body .e-dialog.e-rte-link-dialog .e-dlg-header-content .e-dlg-header,
body .e-dialog.e-rte-img-dialog .e-dlg-header-content .e-dlg-header {
  font-family: "Biotif-Medium", sans-serif;
  font-size: 16px;
  width: calc(100% - 40px);
}
body .e-dialog.e-rte-link-dialog .e-dlg-header-content .e-dlg-closeicon-btn,
body .e-dialog.e-rte-img-dialog .e-dlg-header-content .e-dlg-closeicon-btn {
  width: 26px !important;
  height: 26px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  background-color: transparent !important;
  line-height: 30px;
  padding: 0px;
  padding-top: 2px;
  padding-left: 1px;
  transition: 0.5s;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  left: unset !important;
  float: none;
  min-width: unset;
}
body
  .e-dialog.e-rte-link-dialog
  .e-dlg-header-content
  .e-dlg-closeicon-btn:hover,
body
  .e-dialog.e-rte-img-dialog
  .e-dlg-header-content
  .e-dlg-closeicon-btn:hover {
  background-color: var(--greyScreen__BorderColor) !important;
}
body
  .e-dialog.e-rte-link-dialog
  .e-dlg-header-content
  .e-dlg-closeicon-btn
  .e-icon-dlg-close,
body
  .e-dialog.e-rte-img-dialog
  .e-dlg-header-content
  .e-dlg-closeicon-btn
  .e-icon-dlg-close {
  font-size: 16px;
  color: #000000;
  width: auto;
}
body .e-dialog.e-rte-link-dialog .e-footer-content,
body .e-dialog.e-rte-img-dialog .e-footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body .e-dialog.e-rte-link-dialog .e-btn,
body .e-dialog.e-rte-img-dialog .e-btn {
  background-color: var(--secondary__Btn__BGColor);
  color: var(--dark__TextColor);
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding-left: 22px;
  padding-right: 22px;
  text-align: center;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 13px;
  line-height: 14px;
}
body .e-dialog.e-rte-link-dialog .e-btn.e-primary,
body .e-dialog.e-rte-img-dialog .e-btn.e-primary {
  background-color: var(--ip__Blue) !important;
  color: #ffffff !important;
  order: 2;
}
body .e-dialog.e-rte-link-dialog .e-btn.e-primary:hover,
body .e-dialog.e-rte-img-dialog .e-btn.e-primary:hover {
  background-color: var(--ip__Blue__hoverDark) !important;
}
body .e-dialog.e-rte-link-dialog .e-dlg-content,
body .e-dialog.e-rte-img-dialog .e-dlg-content {
  padding: 20px !important;
}
body .e-dialog.e-rte-link-dialog label,
body .e-dialog.e-rte-img-size-dialog label {
  margin-bottom: 3px;
  font-family: "Biotif-Medium", sans-serif;
  font-size: 14px;
  color: var(--formLabel__blueColor);
  display: block;
  line-height: 19px;
}
body .e-dialog.e-rte-link-dialog .e-input,
body .e-dialog.e-rte-img-dialog .imgUrl .e-input,
body .e-dialog.e-rte-img-link-dialog .e-input,
body .e-dialog.e-rte-img-alt-dialog .e-input,
body .e-dialog.e-rte-img-size-dialog .e-input {
  width: 100%;
  border-radius: 6px;
  border-width: 1px;
  border-color: transparent;
  background-color: var(--formField__BGColor);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 13px;
  padding-right: 13px;
  font-family: Biotif-Regular, sans-serif;
  font-size: 16px;
  color: var(--dark__TextColor);
  height: 40px;
}
body .e-dialog.e-rte-link-dialog .e-input:focus,
body .e-dialog.e-rte-img-dialog .imgUrl .e-input:focus,
body .e-dialog.e-rte-img-link-dialog .e-input:focus,
body .e-dialog.e-rte-img-alt-dialog .e-input:focus,
body .e-dialog.e-rte-img-size-dialog .e-input:focus {
  border-color: #000000 !important;
  box-shadow: unset !important;
}
body .e-dialog.e-rte-link-dialog .e-input::placeholder,
body .e-dialog.e-rte-img-dialog .imgUrl .e-input::placeholder,
body .e-dialog.e-rte-img-link-dialog .e-input::placeholder,
body .e-dialog.e-rte-img-alt-dialog .e-input::placeholder,
body .e-dialog.e-rte-img-size-dialog .e-input::placeholder {
  color: var(--disableLight__TextColor);
  opacity: 1;
}
body .e-dialog.e-rte-img-link-dialog .e-checkbox-wrapper .e-frame,
body .e-dialog.e-rte-link-dialog .e-checkbox-wrapper .e-frame {
  width: 18px;
  height: 18px;
  box-shadow: unset;
}
body .e-dialog.e-rte-img-link-dialog .e-checkbox-wrapper .e-frame.e-check,
body .e-dialog.e-rte-link-dialog .e-checkbox-wrapper .e-frame.e-check {
  background-color: var(--ip__Blue);
  border-color: var(--ip__Blue);
  box-shadow: unset;
}
body .e-dialog.e-rte-img-link-dialog .e-checkbox-wrapper .e-label,
body .e-dialog.e-rte-link-dialog .e-checkbox-wrapper .e-label {
  font-family: "Biotif-Regular", sans-serif;
  color: #000000;
  margin-left: 8px;
  position: relative;
  top: 1px;
}
body .e-dialog.e-rte-img-dialog .e-linkheader {
  font-family: "Biotif-Regular", sans-serif;
  color: #000000;
  padding-top: 10px;
}
body .e-dialog.e-rte-img-dialog .e-img-uploadwrap .e-droptext {
  border: 2px dashed var(--greyScreen__BorderColor);
  border-radius: 10px;
  padding: 15px;
  max-width: 100%;
}
body .e-dialog.e-rte-img-dialog .e-rte-upload-text {
  color: #000000;
  font-family: "Biotif-Medium", sans-serif;
  font-size: 18px;
}
body .e-dialog.e-rte-img-dialog .e-browsebtn {
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding-left: 22px;
  padding-right: 22px;
  text-align: center;
  font-family: "Biotif-SemiBold", sans-serif;
  font-size: 13px;
  line-height: 14px;
  background-color: var(--ip__Blue) !important;
  color: #ffffff !important;
  border: none;
  box-shadow: unset;
  outline: none;
}
body .e-dialog.e-rte-img-dialog .e-browsebtn:focus {
  box-shadow: unset;
  outline: none;
}
body .e-dialog.e-rte-img-dialog .e-upload-files {
  margin-top: 6px;
}
body .e-dialog.e-rte-img-dialog .e-upload-files .e-file-container {
  min-height: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 90px 10px 0px;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list:last-child {
  border-bottom: none;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-status {
  display: none;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-name,
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-type,
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-size {
  font-family: "Biotif-Regular", sans-serif !important;
  font-size: 14px !important;
  color: black !important;
  padding-top: 0px !important;
  line-height: normal !important;
  padding-bottom: 0px !important;
  display: inline-block;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-name {
  width: auto;
  max-width: calc(100% - 32px);
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-size {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-remove-btn {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  background-color: transparent;
  border-radius: 50%;
  color: black;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  transition: 0.5s;
}
body .e-dialog.e-rte-img-dialog .e-upload-file-list .e-file-remove-btn:hover {
  background-color: var(--secondary__Btn__BGColor__hover);
  color: black;
}
/* reach-text-editor-actionbar-end */

/* date-range mobile css start */
body .e-daterangepick-mob-popup-wrap {
  background-color: #0009;
}
body .e-daterangepick-mob-popup-wrap .e-presets {
  width: 200px !important;
  background-color: #fff !important;
  border-radius: 15px;
  max-height: 285px !important;
}
body .e-daterangepick-mob-popup-wrap .e-ul li {
  padding: 8px 15px !important;
  font-size: 18px !important;
}
body .e-daterangepick-mob-popup-wrap .e-daterangepicker.e-popup .e-calendar {
  border-radius: 0px;
  padding: 5px 10px 15px 10px;
}
body
  .e-daterangepick-mob-popup-wrap
  .e-daterangepicker.e-popup
  .e-range-header
  .e-day-span {
  position: static;
  transform: translateX(0px);
}
body .e-daterangepick-mob-popup-wrap .e-range-header {
  padding: 18px 18px 10px 18px !important;
}
body .e-daterangepick-mob-popup-wrap .e-range-header .e-start-end {
  border: none !important;
}
body .e-daterangepick-mob-popup-wrap .e-range-header .e-start-end .e-btn {
  height: 38px !important;
  padding: 5px 10px !important;
  line-height: 18px !important;
  font-family: "Biotif-Medium", sans-serif;
  border: 1px solid var(--primaryColor) !important;
  opacity: 1 !important;
  color: #2e3234 !important;
}
body
  .e-daterangepick-mob-popup-wrap
  .e-range-header
  .e-start-end
  .e-btn.e-start-btn {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
body
  .e-daterangepick-mob-popup-wrap
  .e-range-header
  .e-start-end
  .e-btn.e-end-btn {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
body
  .e-daterangepick-mob-popup-wrap
  .e-range-header
  .e-start-end
  .e-btn.e-active {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
}
/* date-range mobile css end */

@media screen and (max-width: 1199px) {
  body .e-schedule .e-month-view .e-work-cells {
    height: 118px;
  }
  body .e-schedule .e-month-view .e-date-header-wrap table td {
    font-size: 13px;
  }
  body .e-schedule .e-month-view .e-date-header,
  body .e-schedule .e-date-header {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    width: 24px !important;
  }
  body
    .e-schedule
    .e-month-view
    .e-appointment
    .e-appointment-details
    .e-subject {
    font-size: 10px;
    line-height: 14px;
    width: calc(100% - 47px);
  }
  body .e-schedule .e-month-view .e-appointment .e-appointment-details .e-time {
    font-size: 9px;
    line-height: 14px;
    width: 47px;
  }
  body .e-schedule .e-month-view .e-appointment {
    height: 19px;
  }
  body .e-schedule .e-month-view .e-selected-cell .e-more-indicator,
  body .e-schedule .e-month-view .e-more-indicator {
    font-size: 10px;
  }
  body
    .e-month-view
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap {
    height: 730px !important;
  }
  body .e-schedule .e-month-view .e-content-wrap,
  body .e-schedule .e-vertical-view .e-content-wrap,
  body .e-schedule .e-vertical-view .e-time-cells-wrap {
    height: auto !important;
  }
}
@media screen and (max-width: 991px) {
  body .e-schedule .e-month-view .e-date-header-wrap table td {
    font-size: 12px;
    padding: 13px 10px 10px 10px !important;
  }
  .e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-subject,
  body .e-schedule .e-appointment .e-subject {
    font-size: 12px;
  }
  body .e-more-popup-wrapper .e-more-event-date-header .e-header-day,
  body .e-more-popup-wrapper .e-more-event-date-header .e-header-date {
    font-size: 14px;
  }
  body .e-schedule .e-month-view .e-work-cells {
    height: 100px;
  }
}
@media screen and (max-width: 767px) {
  .react-datepicker-popper
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
    .react-datepicker__month-dropdown-container::before {
    right: 20px;
  }
  .react-datepicker-popper
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
    .react-datepicker__year-dropdown-container::before {
    right: 38px;
  }

  body .e-schedule .e-month-view .e-appointment .e-appointment-details .e-time {
    display: none;
  }
  body
    .e-schedule
    .e-month-view
    .e-appointment
    .e-appointment-details
    .e-subject {
    width: 100%;
  }
  body
    .e-month-view
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap {
    height: 630px !important;
  }
}
@media screen and (max-width: 575px) {
  .ip__select {
    background-size: 13px;
  }

  .react-datepicker-popper .react-datepicker__day-name,
  .react-datepicker-popper .react-datepicker__day,
  .react-datepicker-popper .react-datepicker__time-name {
    width: 31px;
    line-height: 31px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    height: 182px !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 26px;
    padding: 4px 5px;
    font-size: 12px;
  }
  .react-datepicker__time-container,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 70px !important;
  }
  .react-datepicker-time__header {
    font-size: 14px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ip__react__datepicker__hasTime .react-datepicker {
    min-width: 304px;
  }

  body
    .e-toolbar
    .e-toolbar-items
    .e-toolbar-left
    .e-toolbar-item
    .e-tbar-btn.e-btn {
    padding: 8px 12px 6px 12px;
  }

  body .e-schedule .e-schedule-toolbar {
    margin-bottom: 12px;
  }
  body .e-schedule .e-month-view .e-date-header,
  body .e-schedule .e-date-header {
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    width: 18px !important;
  }
  body .e-schedule .e-month-view .e-work-cells {
    height: 70px;
  }
  body .e-week-view .e-date-header-wrap tbody .e-header-row .e-header-cells,
  body .e-day-view .e-date-header-wrap tbody .e-header-row .e-header-cells {
    padding: 10px 4px;
  }
  body .e-schedule .e-vertical-view.e-week-view .e-time-cells-wrap table td,
  body
    .e-schedule
    .e-vertical-view.e-week-view
    .e-time-cells-wrap
    .e-current-time,
  body .e-schedule .e-vertical-view.e-day-view .e-time-cells-wrap table td,
  body
    .e-schedule
    .e-vertical-view.e-day-view
    .e-time-cells-wrap
    .e-current-time {
    font-size: 10px;
  }
  body .e-schedule .e-vertical-view .e-left-indent {
    width: 56px !important;
  }

  body .e-schedule .e-outer-table > tbody > tr:first-child > td {
    padding-bottom: 12px;
  }
  body
    .e-week-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-day {
    font-size: 10px;
  }
  body
    .e-week-view
    .e-date-header-wrap
    tbody
    .e-header-row
    .e-header-cells
    .e-header-date {
    font-size: 12px;
  }
  body
    .e-month-view
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap {
    height: 500px !important;
  }

  /* .horizontalTabs__ul .item.active .link .text{
  color:var(--primaryColor);
  animation-name:detailspageinfotimelinetabtext;
  animation-duration:1.3s;
  animation-fill-mode: forwards;
}
@keyframes detailspageinfotimelinetabtext{
  0%   {color:var(--primaryColor);}
  100% {color:white;}
}
.horizontalTabs__ul .item.active .link .i__Icon path{
  fill:var(--primaryColor);
  animation-name:detailspageinfotimelinetabsvg;
  animation-duration:1.3s;
  animation-fill-mode: forwards;
}
@keyframes detailspageinfotimelinetabsvg{
  0%   {fill:var(--primaryColor);}
  100% {fill:white;}
} */
}

@media screen and (max-width: 767px) {
  body .e-daterangepicker.e-popup {
    margin-left: 10px;
    margin-right: 10px;
  }
  body .e-daterangepicker .e-calendar {
    max-width: 200px;
    min-width: 200px;
  }
  body .e-daterangepicker .e-calendar .e-content.e-month td,
  body .e-daterangepicker .e-bigger.e-small .e-calendar .e-content.e-month td {
    height: 26px;
  }
  body .e-daterangepicker.e-popup .e-calendar .e-content.e-month span.e-day {
    height: 26px;
    line-height: 26px;
    width: 26px;
    font-size: 12px;
  }
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-calendar
    .e-content.e-month
    span.e-day {
    height: 30px;
    line-height: 30px;
    width: 30px;
    font-size: 14px;
  }
  body .e-daterangepicker.e-popup .e-calendar th,
  body .e-daterangepicker.e-popup .e-bigger.e-small .e-calendar th {
    height: 26px;
  }
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-calendar
    th,
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-bigger.e-small
    .e-calendar
    th {
    height: 30px;
  }
  body .e-daterangepicker.e-popup .e-range-header .e-start-label,
  body .e-daterangepicker.e-popup .e-range-header .e-end-label {
    font-size: 14px;
  }
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-range-header
    .e-start-label,
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-range-header
    .e-end-label {
    font-size: 16px;
  }
  body .e-daterangepicker.e-popup .e-calendar .e-header .e-title {
    font-size: 14px;
  }
  body
    .e-daterangepick-mob-popup-wrap
    .e-daterangepicker.e-popup
    .e-calendar
    .e-header
    .e-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 374px) {
  .react-datepicker-popper .react-datepicker__current-month {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .react-datepicker-popper .react-datepicker__navigation--previous,
  .react-datepicker-popper .react-datepicker__navigation--next {
    width: 22px;
    height: 22px;
    box-shadow: 1px 2px 4px #e8e8e8;
  }
  .react-datepicker-popper .react-datepicker__navigation--previous::before,
  .react-datepicker-popper .react-datepicker__navigation--next::before {
    width: 6px;
    height: 6px;
    top: 8px;
    left: 9px;
  }
  .react-datepicker-popper .react-datepicker__navigation--next::before {
    left: 7px;
  }
  .react-datepicker-popper .react-datepicker__navigation--next {
    top: 7px;
    right: 8px;
  }
  .react-datepicker-popper .react-datepicker__navigation--previous {
    top: 7px;
    right: 40px;
  }
  .react-datepicker__time-container {
    margin-top: 37px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 26px;
    padding: 4px 5px;
    font-size: 11px;
  }
  .react-datepicker-time__header {
    font-size: 12px;
  }
  .react-datepicker__time-container,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 65px;
  }
  .react-datepicker-popper .react-datepicker__day-name,
  .react-datepicker-popper .react-datepicker__day,
  .react-datepicker-popper .react-datepicker__time-name {
    width: 31px;
    line-height: 31px;
    font-size: 14px;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    height: 150px !important;
  }
  .ip__react__datepicker__hasTime .react-datepicker {
    min-width: 269px;
  }
  .react-datepicker-popper .react-datepicker__month-dropdown,
  .react-datepicker-popper .react-datepicker__year-dropdown {
    top: 68px;
    height: 161px;
  }
  .react-datepicker-popper
    .react-datepicker__month-dropdown
    .react-datepicker__month-option,
  .react-datepicker-popper
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    font-size: 12px;
  }
  .react-datepicker-popper
    .react-datepicker__year-dropdown
    .react-datepicker__year-option {
    font-size: 11px;
  }
  .react-datepicker-popper
    .react-datepicker__year-dropdown
    .react-datepicker__year-option:first-child {
    margin-bottom: 4px;
  }
  .react-datepicker-popper
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll
    .react-datepicker__month-read-view--selected-month,
  .react-datepicker-popper
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll
    .react-datepicker__year-read-view--selected-year {
    font-size: 11px;
  }
  .react-datepicker-popper .react-datepicker__month-read-view--down-arrow,
  .react-datepicker-popper .react-datepicker__year-read-view--down-arrow {
    width: 6px;
    height: 6px;
    top: 6px;
  }

  body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
  body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover,
  body .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus,
  body
    .e-toolbar
    .e-toolbar-items
    .e-toolbar-left
    .e-toolbar-item
    .e-tbar-btn.e-btn {
    /* padding: 6px 6px 4px 6px; */
    height: 34.6px;
  }
  .e-schedule
    .e-schedule-toolbar
    .e-toolbar-items
    .e-toolbar-item.e-date-range
    .e-tbar-btn
    .e-tbar-btn-text {
    font-size: 12px;
  }
  body .e-schedule .e-toolbar .e-hor-nav {
    min-height: 34px !important;
    height: 34px;
    top: 2px;
  }
  body
    .e-month-view
    .e-schedule-table.e-outer-table
    tbody
    tr:last-child
    > td
    > .e-content-wrap {
    height: 450px !important;
  }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body .e-schedule.e-device .e-vertical-view .e-time-cells-wrap {
  width: 58px;
}
body .e-schedule .e-vertical-view .e-content-wrap {
  width: calc(100% - 58px);
}
body .e-schedule.e-device .e-vertical-view td > .e-time-cells-wrap {
  width: 100%;
}
body .e-schedule .e-vertical-view td > .e-content-wrap {
  width: 100%;
}
.e-new-event {
  display: none !important;
}

/* quickLookActivityCalendar modal css start */
body .e-quick-popup-wrapper {
  box-shadow: unset;
}
/* quickLookActivityCalendar modal css end */

/* mobile calendar quick view modal css start */
body > .e-quick-popup-wrapper {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100dvh !important;
  z-index: 1004 !important;
  padding: 15px 15px !important;
}
/* mobile calendar quick view modal css end */

/* mobile calendar view more event modal css start */
body > .e-more-popup-wrapper .e-more-event-popup {
  width: 100%;
  height: 100dvh;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
}
body > .e-more-popup-wrapper .e-more-event-header {
  margin: 0px;
  padding: 10px 15px 10px 15px;
}
body > .e-more-popup-wrapper .e-more-event-date-header .e-header-day,
body > .e-more-popup-wrapper .e-more-event-date-header .e-header-date {
  font-size: 18px;
  font-family: "Biotif-Bold", sans-serif;
}
body > .e-more-popup-wrapper .e-more-event-close {
  width: 30px;
  height: 30px;
}
body > .e-more-popup-wrapper .e-more-event-close.e-btn.e-round .e-btn-icon {
  font-size: 19px !important;
}
body > .e-more-popup-wrapper .e-more-event-content {
  max-height: calc(100dvh - 60px) !important;
  overflow-y: auto;
}
body > .e-more-popup-wrapper .e-more-event-content .e-appointment {
  background-color: var(--primary__transparentBG) !important;
  border: none !important;
  border-left: 4px solid var(--primaryColor) !important;
  border-bottom: 2px solid var(--ipWhite__borderColor) !important;
  cursor: pointer;
  margin-bottom: 5px !important;
  padding: 4px 0px;
  height: auto !important;
}
body > .e-more-popup-wrapper .e-appointment .e-subject {
  font-size: 16px !important;
  font-family: "Biotif-SemiBold", sans-serif;
  color: #333333;
  padding-left: 6px;
}
/* mobile calendar view more event modal css end */

/* .e-mention */
.e-mention.e-popup {
  box-shadow: 0px 3px 17px #0000001a;
  border: 1px solid #e1e1e1;
  border-radius: 12px !important;
  overflow: hidden;
  max-width: 270px;
}
.e-mention.e-popup .e-list-item.e-hover {
  background-color: #eeeeee;
}
.e-mention.e-popup .e-list-item.e-active {
  background-color: var(--primaryColor);
  color: #ffffff !important;
}
.e-mention.e-popup .mention__li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.e-mention.e-popup .mention__li .img__wrapper {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.e-mention.e-popup .mention__li .img__wrapper div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.e-mention.e-popup .mention__li .img__wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.e-mention.e-popup .mention__li .img__wrapper .noName__letter,
.e-mention.e-popup .mention__li .img__wrapper .img__loader,
.e-mention.e-popup .mention__li .img__wrapper .img__element,
.e-mention.e-popup .mention__li .img__wrapper .no__img__icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.e-mention.e-popup .mention__li .img__wrapper .no__img__icon .i__Icon {
  background-color: var(--ipGray__transparentBG);
  border-radius: 50%;
  padding: 7px;
}
.e-mention.e-popup .mention__li .img__wrapper .img__loader {
  background-color: var(--ipGray__transparentBG);
  padding: 10px;
}
.e-mention.e-popup .mention__li .img__wrapper .img__element {
  object-fit: cover;
  object-position: center;
}
.e-mention.e-popup .mention__li .img__wrapper .noName__letter {
  background-color: var(--ipBlue__transparentBG) !important;
}
.e-mention.e-popup
  .mention__li
  .img__wrapper
  .noName__letter
  .noName__letter__text {
  font-size: 13px !important;
  line-height: 28px !important;
  padding-top: 1px;
  color: var(--primaryColor) !important;
  text-indent: 0px;
}
.e-mention.e-popup .mention__li .details__wrapper {
  width: calc(100% - 40px);
  padding-left: 12px;
}
.e-mention.e-popup .mention__li .details__wrapper .person {
  font-size: 16px;
  font-family: "Biotif-Medium", sans-serif;
  color: #000000;
  line-height: normal;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 0px;
}
.e-mention.e-popup .mention__li .details__wrapper .email {
  font-size: 16px;
  font-family: "Biotif-Regular", sans-serif;
  color: grey;
  line-height: normal;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 0px;
}
.e-mention.e-popup .e-list-item.e-active .mention__li .details__wrapper .person,
.e-mention.e-popup .e-list-item.e-active .mention__li .details__wrapper .email {
  color: #ffffff;
}
/* .e-mention-end */

@media screen and (max-width: 575px) {
  .timePicker__open .tippy__timePicker__root {
    animation-name: tippy__timePicker__mobile;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    transition: 0.4s !important;
  }
  @keyframes tippy__timePicker__mobile {
    0% {
      transform: translateX(0px);
      transform: translateY(110%);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(0%);
    }
  }
  body:not(.timePicker__open) .tippy__timePicker__root {
    animation-name: tippy__timePicker__mobile__off;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    transition: 0.4s !important;
  }
  @keyframes tippy__timePicker__mobile__off {
    0% {
      transform: translateX(0px);
      transform: translateY(0%);
    }
    100% {
      transform: translateX(0px);
      transform: translateY(110%);
    }
  }
}

/* email-print-css-start */
@media print {
  .sidebar,
  .dashboard__Header,
  .inbox__view__page__wrapper .breadcrumbs__wrapper,
  .inbox__view__actionbar,
  .ivb__submit__btns {
    @apply !hidden;
  }
  .i__Dashboard__Wrapper {
    @apply !w-full;
  }
  .pageContent {
    @apply !p-0;
  }
}
/* email-print-css-end */

/* animated-icon-css */
.socian__ani__icon__wrapper {
  @apply w-[36px] h-[36px] flex items-center justify-center rounded-[8px] bg-[#eee] hover:bg-primaryColor;
}
.socian__ani__icon__wrapper .i__Icon {
  @apply !bg-transparent;
}
.socian__ani__icon__wrapper:hover svg {
  @apply invert;
}
.socian__ani__icon__wrapper.email__icon__wrapper {
  @apply w-[36px] h-[36px] flex items-center justify-center rounded-[8px] bg-[#eee] hover:bg-primaryColor;
}
.socian__ani__icon__wrapper.email__icon__wrapper:hover svg {
  @apply invert;
}
.quicklook__socialBtns__wrapper .socian__ani__icon__wrapper {
  @apply w-[32px] h-[32px] flex items-center justify-center rounded-[8px] bg-[#eee] hover:bg-primaryColor;
}
.quicklook__socialBtns__wrapper .socian__ani__icon__wrapper:hover svg {
  @apply invert;
}
.detailsPageNew .horizontalTabs__ul .animated__icon__wrapper {
  @apply w-[30px] h-[30px] grayscale brightness-0;
}
.detailsPageNew .horizontalTabs__ul .item.active .animated__icon__wrapper {
  @apply grayscale-0 brightness-100;
}
.animated__icon__wrapper__activity svg {
  @apply invert;
}
.socialBtns__wrapper .i__Icon.socian__ani__icon__wrapper {
  @apply p-[7px];
}
.socialBtns__wrapper .i__Icon.socian__ani__icon__wrapper:hover svg {
  @apply invert-0;
}
/* animated-icon-css-end */

.e-input-group.e-error, .e-input-group.e-control-wrapper.e-error, .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border:none !important;
}
input.e-input, .e-input-group input, .e-input-group.e-control-wrapper input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input{
  height:44px;
  border:none !important;
  background-color:var(--formField__BGColor) !important;
  border-radius: 6px !important;
  color:var(--sdNormal__textColor) !important;
  font-family: "Biotif-Medium", sans-serif;
  font-size: 16px;
}

.stream__logs__next + .rc__ActivityLogs{
  @apply w-full;
}
.stream__logs__next + .rc__ActivityLogs .contant__wrapper{
  @apply h-[420px];
}
.react-datepicker-wrapper .react-datepicker__input-container input{
  width:100%;
  background-color:var(--formField__BGColor);
  padding-left:13px;
  padding-right:13px;
  font-size:16px;
  color:var(--sdNormal__textColor);
  border:1px solid transparent;
  font-family: "Biotif-Medium", sans-serif;
  border-radius:8px;
  padding-top:9px;
  padding-bottom:9px;
}
.react-datepicker-wrapper .react-datepicker__input-container input::placeholder{
  color:var(--sdNormal__textColor);
  opacity:1;
}
.react-datepicker-wrapper .react-datepicker__input-container input:focus{
  border:1px solid var(--primaryColorSD);
  box-shadow:unset;
  outline:none;
}
.manage__column__modal .react-datepicker-wrapper .react-datepicker__input-container input{
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  font-size:14px;
  border-radius:6px;
}
.ag-theme-alpine.large {
  --ag-grid-size: 10px;
  --ag-list-item-height: 40px !important;
  --ag-font-size: 32px;
}

.ag-theme-alpine.compact {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px !important;
  --ag-font-size: 10px;
}

.ag-horizontal-left-spacer{
  overflow:hidden !important;
}
.ag-horizontal-right-spacer{
  display:none !important;
}
.ag-pinned-right-cols-container{
  left: -15px;
}

.ag-cell-range-selected-1:not(.ag-cell-focus),
.ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing){
  background-color:transparent !important;   
}


.react-flow__edgelabel-renderer{
  z-index:9999 !important;
}

.ag-cell-wrap-text .ag-cell-value,
.ag-cell-wrap-text .ag-group-value{
  overflow:visible !important;
  text-overflow:normal !important;
  flex:unset !important;
}

/* react-tel-input */
body .react-tel-input .form-control{
  @apply w-full py-[9px] pt-[11px] px-[13px] pl-[56px] rounded-[6px] bg-formField__BGColor text-[16px] font-biotif__Medium text-sdNormal__textColor border-[1px] border-transparent focus:border-primaryColorSD !shadow-none focus:bg-formField__BGColor;
}
body .react-tel-input .selected-flag.open:before{
  @apply border-transparent shadow-none;
}
/* react-tel-input-end */
#userSessionsDiv .infinite-scroll-component{
  @apply !overflow-x-hidden;
}

body .react-datepicker-wrapper .react-datepicker__close-icon:after{
  font-size: 20px !important;
  height: 22px;
  width: 22px;
  padding-right: 3px;
  padding-bottom: 3px;
  box-sizing: border-box;
  display: flex;
  vertical-align: middle;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  color:#000000;
  background-color:transparent;
}
body .react-datepicker-wrapper .react-datepicker__close-icon:hover:after{
  background-color:#ffffff;
}
